<app-tabs (groupingChange)="onGroupingChange($event)" [tabs]="concertinaTabs" [selectedFrameFilter]="frameFilterType"
    [(isCartVisible)]="isCartVisible" [userBundle]="initialConfig['userBundle']" [filters]="sortableItems"
    [audienceSelectionEnabled]="audienceSelectionEnabled" [selectedAudienceList]="selectedAudienceList"
    [initialConfig]="initialConfig" [frameStatusFiltersEnabled]="frameStatusFiltersEnabled"
    [frameSelectionEnabled]="frameSelectionEnabled" (tabChanged)="tabChanged($event)"
    (redirectToResultPage)="redirectToResultPage()" (statusFilterChanged)="statusFilterChange($event)">
</app-tabs>
<div class="tab">
    <div class="tab-content fadeIn animated" (window:resize)="onResize()"
        style="padding:10px;position: relative;">
        <div class="control_group frame-filter-input" *ngIf="frameSearchFilterEnabled && showFrameSearchFilter">
            <input id="frameSearchFilter" type="text" class="form_control" [value]="searchFrame"
                [formControl]="filterFrames"
                placeholder="{{initialConfig.userBundle['result.text.searchFrame'] || 'Search Frame'}}">
            <span (click)="clearFrameSearch()" class="clear-search" title="Remove search"
                *ngIf="searchFrame.length > 0">X</span>
        </div>

        <div id="header-scroll-container" (scroll)="onScrollHeader()">
            <div id="header-large-container">
                <div id="header-container"></div>
            </div>
        </div>
        <div id="scroll-container" (scroll)="onScroll($event)" style="font-family:'FontAwesome';">
            <div id="large-container">
                <div id="container"></div>
                <div id="loading-container"></div>
            </div>
        </div>
        <!-- <div id="container" style="overflow-y: scroll;overflow-x: hidden;font-family:'FontAwesome';"
            (scroll)="onScroll($event)">
        </div> -->
    </div>
</div>

<div class="footer-buttons left" *ngIf="isCampaignRefrenceTab() &&
!concertinaDataService.basketMode && concertinaDataService.concertinaData"
    [ngClass]="{'slideCartMoreLeft': !isFilterAreaOpen}">
    <button class="btn btn-primary"
        [title]="swapZoneService.swapMode? initialConfig.userBundle['vp.swap.text.swapmodeon'] || 'Swapmode ON' : initialConfig.userBundle['vp.swap.text.swapmodeoff'] || 'Swapmode OFF' "
        [ngClass]="{'greenClass': swapZoneService.swapMode, 'redClass' :  !swapZoneService.swapMode }"
        (click)="toggleSwapMode()">
        <img src='../../../../../../../images/swap.png' alt="swap"> </button>


    <button *ngIf="swapZoneService.swapMode" (click)="concertinaCanvasService.performMultiSwap()"
        class="btn btn-primary" title="{{initialConfig.userBundle['vp.text.performSwap'] || 'Perform Swap'}}">
        <em class="fa fa-arrow-right"></em>
    </button>
</div>

<div class="footer-buttons" *ngIf="frameSelectionEnabled" [ngClass]="{'slideCartMoreLeft': isCartVisible}">
    <div ngbDropdown id="FI-toggle-button" [autoClose]="false" [placement]="'top'" #plannerSettingContainer
        class="form-control_geo dropdown">
        <button class="btn btn-primary map_btn" ngbDropdownToggle [disabled]="!concertinaDataService.concertinaData">
            <em class="fa fa-cog"></em>
        </button>
        <div class="dropdown-menu" [ngClass]="{'custom_drop_menu_2_items': initialConfig?.uiControl?.hideShowAdvertiserIdEnabled, 'custom_drop_menu': !initialConfig?.uiControl?.hideShowAdvertiserIdEnabled}" aria-labelledby="dropdownBasic1" ngbDropdownMenu>
            <div class="mapsetting-item">
                <ui-switch [(ngModel)]="concertinaCanvasService.showFI" size="small" (change)="toggleFI()"></ui-switch>
                <span>&nbsp;&nbsp;{{initialConfig.userBundle['vp.swap.text.fixedOrInnovate'] ||
                    'Fixed/Innovate'}}</span>
            </div>
            <div class="mapsetting-item" *ngIf="initialConfig.uiControl.hideShowAdvertiserIdEnabled">
                <ui-switch [(ngModel)]="concertinaCanvasService.hideAdvertiserId" size="small" (change)="toggleHideAdvertiserId()"></ui-switch>
                <span>&nbsp;&nbsp;{{initialConfig.userBundle['vp.text.hideAdvertiserId'] ||
                    'Hide Advertiser ID'}}</span>
            </div>
        </div>
    </div>
    <button *ngIf="isCampaignRefrenceTab() && swapZoneService.swapMode &&
    !concertinaDataService.basketMode" id="swap-details-button"
    (click)="openSwapZone()" class="btn btn-primary "
        title="{{initialConfig.userBundle['vp.swap.text.swapDetails'] || 'Swap details'}}">
        <em class="fa fa-tasks" aria-hidden="true"></em>
    </button>
    <button id="reset-selection-button" (click)="resetSelection()" class="btn btn-primary"
        title="{{initialConfig.userBundle['common.resetSelection'] || 'Reset All Selection'}}">
        <em class="fa fa-refresh" aria-hidden="true"></em>
    </button>
    <button id="save-button" (click)="saveSelectedFrames()" [disabled]="!concertinaCanvasService.hasNewSelection"
        class="btn btn-primary" title="{{initialConfig.userBundle['common.save.cart'] || 'Save Cart'}}">
        <em class="fa fa-save"></em>
    </button>
    <button id="cart-button" (click)="toggleCart()" class="btn btn-primary"
        title="{{initialConfig.userBundle['geoplanner.text.togglecart'] || 'Toggle Cart'}}">
        <em class="fa fa-shopping-cart"></em>
    </button>
    <button id="result-button" (click)="redirectToResultPage()" [disabled]="cartService.cartData.length ==0"
        [ngClass]="{'slideResultMoreLeft': isCartVisible}" class="btn btn-primary"
        title="{{initialConfig.userBundle['geoplanner.text.gotoresult'] || 'Go to Result'}}">
        <em class="fa fa-arrow-circle-right"></em>
    </button>
</div>

<!-- Cart box -->
<div *ngIf="frameSelectionEnabled" class="sidebar-left_geo sidebar-right_geo cart_summarybar"
    [ngStyle]="{'right': isCartVisible?'0px': '-'+(cartWidth) +'px', 'width': cartWidth +'px'}">
    <app-cart-summary [initialConfig]="initialConfig" (delete)="onDeleteFromCartSummary()"
        (furnitureIconClick)="onShowFurnitureImages($event)" (closeCartSummary)="toggleCart()"
        (cartDetailClose)="onCartDetailClose($event)">
    </app-cart-summary>
</div>
<!-- Cart box end-->

<!-- Swap zone -->
<app-swap-zone *ngIf="frameSelectionEnabled" [initialConfig]="initialConfig" (closeSwapZone)="onSwapZoneClose()">
</app-swap-zone>
<!-- Swap zone end -->

<!-- Frame Detail  -->
<div class="modal_popover bottom framedetailclass" *ngIf="frameSelectionEnabled" [hidden]="!showFrameDetails"
    #frameDetailsContainer style="position: absolute;bottom: auto;width: 250px;"
    [ngStyle]="{'top': frameDetailObj.top,'left': frameDetailObj.left}">
    <div class="titlebar">
        <span class="imgIcon" (click)="onShowFurnitureImages(frameDetailObj.frame)">
            <em class="fa fa-picture-o"></em>
        </span>
        {{initialConfig.userBundle['geoplanner.text.framedetail'] || 'Frame Details'}}
        <div class="close-button">
            <button (click)="showFrameDetails = false">X</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="slider">
                <div class="slides">
                    <p>
                        <span>{{initialConfig.userBundle['vp.text.familyName'] || 'Family Name'}}:</span>
                        {{frameDetailObj.frame.familyName}}
                    </p>
                    <p>
                        <span>{{initialConfig.userBundle['vp.text.subFamilyName'] || 'Sub Family Name'}}:</span>
                        {{frameDetailObj.frame.subFamilyName}}
                    </p>
                    <p>
                        <span>{{initialConfig.userBundle['geoplanner.text.frame_type'] || 'Frame Type'}}: </span>
                        {{frameDetailObj.frame.frameTypeName}}
                    </p>
                    <p>
                        <span>{{initialConfig.userBundle['vp.text.illumination'] || 'Illumination'}}: </span>
                        {{frameDetailObj.frame.illuminated}}
                    </p>
                    <p>
                        <span>{{initialConfig.userBundle['geoplanner.text.format'] || 'Format'}}: </span>
                        {{frameDetailObj.frame.frameSizeFamilyName || '-'}}
                    </p>
                    <p>
                        <span>{{initialConfig.userBundle['vp.text.furnitureName'] || 'Furniture Name'}}:</span>
                        {{frameDetailObj.frame.furnitureName}}
                    </p>
                    <p>
                        <span>{{initialConfig.userBundle['vp.text.orientationName'] || 'Orientation Name'}}:</span>
                        {{frameDetailObj.frame.orientationName}}
                    </p>
                    <p>
                        <span>{{initialConfig.userBundle['vp.text.address'] || 'Address'}}:</span>
                        {{frameDetailObj.frame.address}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Frame Detail ends  -->

<!-- Concertina tooltip start-->
<div id="concertina-tooltip" class="modal_popover bottom concertina-tooltip"
    *ngIf="concertinaTooltip.show && !concertinaTooltip.isString"
    [ngStyle]="{'top': concertinaTooltip.top,'left': concertinaTooltip.left, 'max-height': '150px'}">
    <p class="tooltip-text" *ngFor="let item of concertinaTooltip.data; trackBy: trackByItem; let last = last"
        [style.color]="isDuplicateIndustry(concertinaTooltip.data, item)">
        {{item}}
    </p>
</div>

<div id="concertina-tooltip" class="modal_popover bottom concertina-tooltip"
    *ngIf="concertinaTooltip.show && concertinaTooltip.isString"
    [ngStyle]="{'top': concertinaTooltip.top,'left': concertinaTooltip.left}">
    <p class="tooltip-text">
        {{concertinaTooltip.data}}
    </p>
</div>
<!-- Concertina tooltip end-->

<!-- Frame Detail  -->
<!-- <div [hidden]="!frameSelectionEnabled" #frameDetailsContainer class="modal_popover bottom framedetailclass" [hidden]="!showFrameDetails"
    style="position: absolute;bottom: auto;width: 28%" [ngStyle]="{'top': frameDetailObj.top,'left': frameDetailObj.left}">
    <div class="titlebar">{{initialConfig.userBundle['geoplanner.text.framedetail'] || 'Frame Details'}}
        <div class="close-button">
            <button (click)="showFrameDetails = false">X</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-6">
                <span>{{initialConfig.userBundle['vp.text.furniture'] || 'Furniture'}}:</span>
                <input type="text" [(ngModel)]="frameDetailObj.frame.furnitureId" readonly>

                <span>{{initialConfig.userBundle['vp.text.family'] || 'Family'}}:</span>
                <input type="text" [(ngModel)]="frameDetailObj.frame.family" readonly>

                <span>{{initialConfig.userBundle['vp.text.subFamily'] || 'Sub Family'}}:</span>
                <input type="text" [(ngModel)]="frameDetailObj.frame.subFamily" readonly>

                <span>{{initialConfig.userBundle['vp.text.frameType'] || 'Frame Type'}}:</span>
                <input type="text" [(ngModel)]="frameDetailObj.frame.frameTypeName" readonly>

                <span>{{initialConfig.userBundle['vp.text.illumination'] || 'Illumination'}}:</span>
                <input type="text" [(ngModel)]="frameDetailObj.frame.illumination" readonly>
            </div>
            <div class="col-md-6">
                <span>{{initialConfig.userBundle['vp.text.illustration'] || 'Illustration'}}:</span>
                <div class="">
                    <span class="imgIcon" (click)="onShowFurnitureImages(frameDetailObj.frame)">
                        <em class="fa fa-picture-o"></em>
                    </span>
                </div>
                <span>{{initialConfig.userBundle['vp.text.format'] || 'Format'}}:</span>
                <input type="text" [(ngModel)]="frameDetailObj.frame.productFormatName" readonly>

                <span>{{initialConfig.userBundle['vp.text.orientation'] || 'Orientation'}}:</span>
                <input type="text" [(ngModel)]="frameDetailObj.frame.orientation" readonly>

            </div>
        </div>
    </div>
</div> -->
<!-- Frame Detail ends  -->

<!-- Booking Details  -->
<app-booking-details *ngIf="bookingDetailsEnabled" [bookingDetailsParams]="bookingDetailsParams"
    [initialConfig]="initialConfig"></app-booking-details>
<!-- Booking Details ends-->
