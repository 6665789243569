import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { GridOptions, ColDef } from 'ag-grid-community';
import { ExcelCell, ExportToExcel, ExcelColumn } from '../../../core/utils/exportToExcel';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { DataShareService } from '../../../core/services/data-share.service';
import {
  AgCheckboxComponent,
  AgCustomFilterMenuComponent,
  AgCustomHeaderComponent,
  AgTextCellEditorComponent,
  AgNumberCellRendererComponent,
  AgCurrencyCellRendererComponent,
  AgTextCellRendererComponent,
  AgPercentageCellRendererComponent,
  AgCustomSort,
  AgCustomRowUtil,
  AgTextFloatingFilterComponent,
  AgDateCellRendererComponent,
  AgSelectCellEditorComponent
} from '../ag-grid-support/index';
import * as _ from 'lodash';
import * as toasterConfig from '../../../core/toasterConfig';
import { LocaleData } from '../../../core/utils/LocaleData';
import { EnvironmentId } from '../../../core/enum';
import { AppHeaderService } from 'app/root/app-header/app-header.service';

@Component({
  selector: 'app-discount-grid-hk',
  templateUrl: './discount-grid-hk.component.html',
  styleUrls: ['../ag-grid-custom-style.css', '../ag-grid-col-select-style.css', './discount-grid-hk.component.css']
})
export class DiscountGridHkComponent implements OnInit, AfterViewInit {

  @ViewChild(NgbDropdown) toggleColumn: NgbDropdown;

  @Input() campaignDetails: any;

  @ViewChild('toggleColumnContainer') toggleColumnContainer: ElementRef;

  precisionOnPrice = 2;
  precisionOnPercentage = 5;
  gridData: any = {};
  sortObj: AgCustomSort;
  /**
   * Ag-Grid Options Object
   */
  gridOptions: GridOptions;

  /**
   * Grid Column Configuration
   * @type {any[]}
   * @memberof CartDetailComponent
   */
  columnDefs: ColDef[];

  /**
   * column list to show in show/hide column dropdown
   */
  columnList: any = [];

  /**
   * list of Cart Data
   * @memberof CartDetailComponent
   */
  rowData: any[] = [];

  /**
   * Grid footer data
   */
  footerRowData: any[];

  /**
   * user bundle data
   */
  userBundle: any = {};

  numericFields = [];
  dateRangeFields = [];
  selectFields = [];
  rateTypeData = [];
  groupingField: string = null;
  public toasterConfig: any = toasterConfig.toasterconfig;
  readonly TOTAL = 'discount.common.total';
  readonly HUNDRED = 100;
  environmentId = EnvironmentId.HKAI;
  commercialConfig;
  campaignDetailsClone;

  constructor(
    private dataShareService: DataShareService,
    private toaster: LogHelperService,
    private datePipe: DatePipe,
    private appHeaderService: AppHeaderService
  ) {
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    // this.dataShareService.setInitialConfig(this.config.InitialConfig);
    this.commercialConfig = this.dataShareService.getCommercialConfig();
    this.environmentId = this.dataShareService.getInitialConfig().systemData.environmentId;
    this.gridData.discounts = this.campaignDetails.discountGridData.discounts;

    this.rateTypeData.push({ id: 0, text: 'Select' });
    if (this.campaignDetails.discountGridData.rateTypes) {
      this.campaignDetails.discountGridData.rateTypes.forEach((element) => {
        this.rateTypeData.push({ id: element, text: element });
      });
    }

    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.setFooterRows();
    this.prepareRowData();
    this.prepareColDef();
    this.reset();
    // we pass an empty gridOptions in, so we can grab the api out
    this.campaignDetailsClone = _.cloneDeep(this.campaignDetails);
    this.gridOptions = {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
      },
      context: {
        componentParent: this
      },
      onRowDataChanged: (event) => {
        event.api.setPinnedBottomRowData(this.footerRowData);
      },
      scrollbarWidth: 10,
      onGridReady: this.onGridReady,
      getRowHeight: this.getRowHeight,
      headerHeight: 34,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      pinnedBottomRowData: this.footerRowData,
      isFullWidthCell: this.isFullWidthCell,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      suppressScrollOnNewData: true,
      onFilterChanged: this.onFilterChanged,
      onCellEditingStopped: this.onCellEditingStopped,
      groupingField: this.groupingField,
      suppressMovableColumns: true,
      isExternalFilterPresent: () => true,
      // tslint:disable-next-line:ter-arrow-parens
      doesExternalFilterPass: (node) => node.data.isVisible
    } as GridOptions;
  }

  ngAfterViewInit() {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    document.body.appendChild(document.getElementsByTagName('toaster-container')[0]);
  }

  onFilterChanged = (e) => {
    let isAnyFilter = false;
    _.each(e.api.getFilterModel(), (item) => {
      if (item.value) {
        isAnyFilter = true;
      }
    });
    if (!isAnyFilter) {
      this.rowData.forEach((r) => {
        r.isFilterPass = true;
      });
    }
    // Refresh Grid Header checkbox component
    /* tslint:disable:no-string-literal */
    this.gridOptions.api['headerRootComp'].forEachHeaderElement((element) => {
      if (element.column.colId === 'selectChk' && element.columnApi && element.childComponents && element.childComponents.length > 1) {
        const headerCmpIns = element.childComponents[1].getFrameworkComponentInstance();
        headerCmpIns.refresh(headerCmpIns.params);
      }
    });
    this.rowData = _.clone(this.rowData);
  }

  onGridReady = () => {
    this.columnList = this.gridOptions.columnApi.getAllColumns();
  }

  clearAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    this.gridOptions.api.onFilterChanged();
  }

  getRowHeight = (params): number => {
    let height = 0;
    if (params.node.rowPinned === 'bottom' && params.node.rowTop === 0) {
      height = 34;
    } else if (params.data.isVisible) {
      height = 30;
    }
    return height;
  }

  isFullWidthCell = (rowNode): boolean => {
    return rowNode.data.gridSummary;
  }

  fullWidthCellRenderer = (): HTMLElement => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.innerHTML = this.getGridSummary();
    return eDiv;
  }

  onCellEditingStopped = (event) => {
    if (event.rowPinned !== 'bottom') {
      let grossPrice = 0;
      switch (event.column.colId) {
        case 'discount':
          if (!this.commercialConfig.uiControl.consistentDiscount) {
            grossPrice = this.environmentId !== EnvironmentId.HKAI ? event.data.grossPrice : event.data.smartBricsValueTotal;
            event.data.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, event.data.discount);
            event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
            event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
            event.data.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(event.data.netAfterDiscount, event.data.miscCost);
          }
          break;
        case 'grossPrice':
          if (this.environmentId === EnvironmentId.HKAI) {
            event.data.loadingFeePct = this.calculateLoadingFeePct(event.data.loadingFee, event.data.grossPrice);
            event.data.loadingFee = this.calculateLoadingFee(event.data.grossPrice, event.data.loadingFeePct);
            event.data.smartBricsValueTotal = this.calculateSmartBricsValueTotal(event.data.grossPrice, event.data.loadingFee);
          }
          grossPrice = this.environmentId !== EnvironmentId.HKAI ? event.data.grossPrice : event.data.smartBricsValueTotal;
          event.data.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, event.data.discount);
          event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          event.data.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(event.data.netAfterDiscount, event.data.miscCost);
          event.data.grossPricePerWeek = this.calculateSmartBricsValuePerWeek(event.data.grossPrice, event.data.discountDays);
          break;
        case 'agencyCommissionPercent':
          event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          break;
        case 'agencyCommission':
          event.data.agencyCommissionPercent = this.calculateAgencyCommissionPercent(event.data.agencyCommission, event.data.netAfterDiscount);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          break;
        // SM-3317, Nishit
        case 'netAfterDiscount':
          if (!this.commercialConfig.uiControl.consistentDiscount) {
            grossPrice = this.environmentId !== EnvironmentId.HKAI ? event.data.grossPrice : event.data.smartBricsValueTotal;
            event.data.discount = this.calculateDiscount(grossPrice, event.data.netAfterDiscount);
            event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
            event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
            event.data.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(event.data.netAfterDiscount, event.data.miscCost);
          }
          break;
        case 'loadingFee':
          if (this.environmentId === EnvironmentId.HKAI) {
            event.data.loadingFeePct = this.calculateLoadingFeePct(event.data.loadingFee, event.data.grossPrice);
            event.data.smartBricsValueTotal = this.calculateSmartBricsValueTotal(event.data.grossPrice, event.data.loadingFee);
            grossPrice = this.environmentId !== EnvironmentId.HKAI ? event.data.grossPrice : event.data.smartBricsValueTotal;
            event.data.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, event.data.discount);
            event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
            event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
            event.data.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(event.data.netAfterDiscount, event.data.miscCost);
          }
          break;
        case 'loadingFeePct':
          event.data.loadingFee = this.calculateLoadingFee(event.data.grossPrice, event.data.loadingFeePct);
          event.data.smartBricsValueTotal = this.calculateSmartBricsValueTotal(event.data.grossPrice, event.data.loadingFee);
          grossPrice = this.environmentId !== EnvironmentId.HKAI ? event.data.grossPrice : event.data.smartBricsValueTotal;
          event.data.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, event.data.discount);
          event.data.agencyCommission = this.calculateAgencyComission(event.data.netAfterDiscount, event.data.agencyCommissionPercent);
          event.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(event.data.netAfterDiscount, event.data.agencyCommission);
          event.data.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(event.data.netAfterDiscount, event.data.miscCost);
          break;
        case 'miscCost':
          event.data.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(event.data.netAfterDiscount, event.data.miscCost);
          break;
        default:
          break;
      }
      this.rowData = _.clone(this.rowData);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    const dropdownEle: any = this.toggleColumn;
    if (dropdownEle.isOpen() && ($event.button !== 2 && !dropdownEle._isEventFromToggle($event)
      && !!this.toggleColumnContainer.nativeElement && !this.toggleColumnContainer.nativeElement.contains($event.target))) {
      dropdownEle.close();
    }
  }

  prepareRowData() {
    this.rowData = [];
    for (const curRow of this.gridData.discounts) {
      curRow.isFilterPass = true;
      if (this.environmentId === EnvironmentId.HKAI) {
        curRow.loadingFee = this.calculateLoadingFee(curRow.grossPrice, curRow.loadingFeePct);
        curRow.smartBricsValueTotal = this.calculateSmartBricsValueTotal(curRow.grossPrice, curRow.loadingFee);
      }
      const grossPrice = this.environmentId !== EnvironmentId.HKAI ? curRow.grossPrice : curRow.smartBricsValueTotal;
      curRow.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, curRow.discount);
      curRow.agencyCommission = this.calculateAgencyComission(curRow.netAfterDiscount, curRow.agencyCommissionPercent);
      curRow.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(curRow.netAfterDiscount, curRow.agencyCommission);
      if (this.environmentId === EnvironmentId.HKAI) {
        curRow.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(curRow.netAfterDiscount, curRow.miscCost);
      }
      curRow.isSelected = false;
      curRow.isVisible = true;
      if (this.environmentId === EnvironmentId.HKAI && curRow.discount < 0) {
        curRow.discount = 0;
      }
      if (!curRow.rateType) {
        curRow.rateType = curRow.endDate ? 'Normal' : 0; // select `select` if footer else select `normal`
      }
      this.rowData.push(curRow);
    }
    this.campaignDetails.discountGridData.discounts = _.clone(this.rowData);
  }

  setFooterRows() {
    this.footerRowData = [
      { isVisible: true, pinRowIndex: 0 },
      {
        gridSummary: 'This is last grid summary row',
        isVisible: true
      }
    ];
  }

  getGridSummary() {
    return `<span>${this.userBundle['discount.common.totalFrames']}: ${this.getChildRows().length} &#45;
      ${this.userBundle['discount.common.selectedFrames']}: ${this.getSelectedChildRows().length}</span>`;
  }

  weekPinnedRowCellRenderer = (params) => {
    const eDiv: HTMLElement = document.createElement('div');
    eDiv.className = 'actionBtnContainer';
    if (params.rowIndex === 0) {
      // apply Icon
      const eImgApply: HTMLImageElement = document.createElement('img');
      eImgApply.src = 'images/general/apply.png';
      eImgApply.title = 'Apply';
      eImgApply.onclick = this.apply;
      eDiv.appendChild(eImgApply);

      // reset Icon
      const eImgReset: HTMLImageElement = document.createElement('img');
      eImgReset.src = 'images/general/reset.png';
      eImgReset.title = 'Reset';
      eImgReset.onclick = this.reset;
      eDiv.appendChild(eImgReset);

      // Spread Icon
      const eImgSpread: HTMLImageElement = document.createElement('img');
      eImgSpread.src = 'images/general/spread.png';
      eImgSpread.title = 'Spread';
      eImgSpread.onclick = this.spread;
      eDiv.appendChild(eImgSpread);
    }
    return eDiv;
  }

  /**
   * This method is used to prevent editing and set prior value for discount and netAfterDiscount for hk:ia
   * @param $event ag-grid event
   */
  onRowEditingStarted($event) {
    if ($event.colDef.field === 'discount' && 
    ($event.data.channelName && this.commercialConfig.uiControl.consistentDiscount)) {
      this.gridOptions.api.stopEditing();
      this.campaignDetails.bookingDetails[$event.rowIndex].discount = this.campaignDetailsClone.bookingDetails[$event.rowIndex].discount;
    }
    if ($event.colDef.field === 'netAfterDiscount' && 
    ($event.data.channelName && this.commercialConfig.uiControl.consistentDiscount)) {
      this.gridOptions.api.stopEditing();
      this.campaignDetails.bookingDetails[$event.rowIndex].netAfterDiscount = this.campaignDetailsClone.bookingDetails[$event.rowIndex].netAfterDiscount;
    }
  }
  // tslint:disable-next-line: no-big-function   // tslint:disable-next-line: cognitive-complexity
  prepareColDef() {
    this.columnDefs = [];
    this.columnDefs.push({
      headerName: '', width: 30, editable: false, colId: 'selectChk',
      pinned: 'left', filter: false,
      cellRendererFramework: AgCheckboxComponent,
      pinnedRowCellRenderer: this.blankCellRenderer,
      headerComponentFramework: AgCheckboxComponent,
      cellClass: (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        if (params.node.rowPinned === 'bottom') {
          retArr.push('borderBottom');
        }
        return retArr;
      }
    });
    if (!this.isColumnHidden('bookingReference')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.bookingReference'], field: 'bookingReference', width: 130, editable: false,
        pinned: 'left', pinnedRowCellRenderer: this.weekPinnedRowCellRenderer
      });
    }
    if (!this.isColumnHidden('invoiceTypeName')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.invoiceTypeName'], field: 'invoiceTypeName', width: 130, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    }
    if (!this.isColumnHidden('channelName')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.channelName'], field: 'channelName', width: 130, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    }
    if (!this.isColumnHidden('networkName')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.networkName'], field: 'networkName', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    }
    if (!this.isColumnHidden('numberOfFrames')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.numberFrames'], field: 'numberOfFrames', width: 100, editable: false,
        cellRendererFramework: AgNumberCellRendererComponent,
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    }
    if (!this.isColumnHidden('startDate')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.startDate'], field: 'startDate', width: 100, editable: false,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    }
    if (!this.isColumnHidden('endDate')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.endDate'], field: 'endDate', width: 100, editable: false, pinned: false,
        cellRendererFramework: AgDateCellRendererComponent,
        cellRendererParams: {
          dateFormat: LocaleData.displayDateFormat
        },
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    }
    if (!this.isColumnHidden('weekDays')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.weekDays'], field: 'weekDays', width: 150, editable: false,
        pinnedRowCellRenderer: this.blankCellRenderer,
      });
    }
    if (!this.isColumnHidden('smartBricsValuePerWeek')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.smartBricsValuePerWeek'], field: 'grossPricePerWeek', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRenderer: this.blankCellRenderer
      });
    }
    if (!this.isColumnHidden('grossPrice')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.smartBricsValue'], field: 'grossPrice', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          prefixText: () => {
            return this.userBundle[this.TOTAL];
          },
          gridSumRow: 1
        }
      });
    }
    if (!this.isColumnHidden('loadingFeePct')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.loadingFeePct'],
        field: 'loadingFeePct', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentage,
          calcCellValue: this.calcAvgLoadingFeePctPinnedRow,
          gridSumRow: 1
        },
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentage
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          cellEditable: () => {
            return true;
          },
          maxValue: 100
        }
      });
    }
    if (this.environmentId === EnvironmentId.HKAI) {
      this.setLoadingFeeCol();
    }
    if (!this.isColumnHidden('smartBricsValueTotal')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.smartBricsValueTotal'], field: 'smartBricsValueTotal', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          prefixText: () => {
            return this.userBundle[this.TOTAL];
          },
          gridSumRow: 1
        }
      });
    }
    if (!this.isColumnHidden('discount')) {
      const columnDefObject = {
        headerName: this.userBundle['discount.hk.discount'],
        field: 'discount',
        width: 150,
        editable: true,
        pinnedRowCellRendererFramework: AgPercentageCellRendererComponent,
        pinnedRowCellRendererParams: {
          onlyNumber: true,
          maxValue: 100,
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentage,
          calcCellValue: this.calcAvgDiscountPinnedRow,
          gridSumRow: 1
        },
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          cellEditable: () => {
            return false;
          },
          maxValue: 100
        },
        cellEditorFramework: AgTextCellEditorComponent
      };

      if (!this.commercialConfig.uiControl.consistentDiscount) {
        columnDefObject['editable'] = true;
        columnDefObject['cellEditorParams'] = {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          cellEditable: () => {
            return true;
          },
          maxValue: 100
        };
        columnDefObject['cellRendererParams'] = {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentage,
        };
        columnDefObject['cellEditorFramework'] = AgTextCellEditorComponent;
      }
      this.columnDefs.push(columnDefObject);
    }
    if (!this.isColumnHidden('netAfterDiscount')) {
      const columnDefObject = {
        headerName: this.userBundle['discount.hk.netAfterDiscount'],
        field: 'netAfterDiscount',
        width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          prefixText: (params) => {
            return (params.netAfterDiscount === 0 || (params.netAfterDiscount && params.netAfterDiscount !== '')) ? '' : this.userBundle[this.TOTAL];
          },
          gridSumRow: 1,
          decimalPoints: 2
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          cellEditable: () => {
            // Change for SM-3317, Nishit
            return false;
          }
        },
        cellEditorFramework: AgTextCellEditorComponent
      };
      if (!this.commercialConfig.uiControl.consistentDiscount) {
        // SM-3317, Nishit
        columnDefObject['cellRendererParams'] = {
          cellEditable: () => {
            return true;
          },
          decimalPoints: 2
        };
        columnDefObject['cellEditorFramework'] = AgTextCellEditorComponent;
        columnDefObject['cellEditorParams'] = {
          onlyNumber: true,
          decimalPoints: 2,
          cellEditable: () => {
            // Change for SM-3317, Nishit
            return true;
          }
        };
      }
      this.columnDefs.push(columnDefObject);
    }
    if (!this.isColumnHidden('agencyCommissionPercent')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.agencyCommissionPercent'],
        field: 'agencyCommissionPercent', width: 150, editable: true,
        cellRendererFramework: AgPercentageCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: this.precisionOnPercentage
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: this.precisionOnPercentage,
          cellEditable: () => {
            return true;
          },
          maxValue: 100
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          gridSumRow: 1
        }
      });
    }
    if (!this.isColumnHidden('agencyCommission')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.agencyCommission'],
        field: 'agencyCommission', width: 150, editable: true,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: 2
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          prefixText: (params) => {
            return (params.agencyCommission === 0 || (params.agencyCommission && params.agencyCommission !== '')) ? '' : this.userBundle[this.TOTAL];
          },
          gridSumRow: 1,
          decimalPoints: 2
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          cellEditable: () => {
            return true;
          }
        }
      });
    }
    if (!this.isColumnHidden('netAfterAgencyCommission')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.netAfterAgencyCommission'], field: 'netAfterAgencyCommission', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          decimalPoints: 2
        },
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          gridSumRow: 1
        }
      });
    }
    if (!this.isColumnHidden('miscCost')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.miscCost'], field: 'miscCost', width: 100, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          gridSumRow: 1,
          decimalPoints: 2
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: 2
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          cellEditable: () => {
            return true;
          }
        }
      });
    }
    if (!this.isColumnHidden('netAfterDiscountAndMiscCost')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.netAfterDiscountAndMiscCost'], field: 'netAfterDiscountAndMiscCost', width: 150, editable: false,
        cellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          prefixText: () => {
            return this.userBundle[this.TOTAL];
          },
          gridSumRow: 1
        }
      });
    }
    if (this.environmentId !== EnvironmentId.HKAI) {
      this.setLoadingFeeCol();
    }
    if (!this.isColumnHidden('rateType')) { // SM-4113, VJ: 17-09-2019
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.rateType'] || 'Rate Type', field: 'rateType', width: 100, editable: false, filter: false,
        cellRendererFramework: AgSelectCellEditorComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          isCellRenderer: true,
          valKey: 'id',
          textKey: 'text',
          dataArr: (params) => {
            return params.endDate ? this.rateTypeData.filter(f => f.id !== 0) : this.rateTypeData; // keep `select` in data source if footer else remove `select`
          },
          value: (params) => {
            if (!params.rateType) {
              params.rateType = params.endDate ? 'Normal' : 0; // select `select` if footer else select `normal`
            }
            return params.rateType;
          },
          onSelectChange: (params) => {
            if (params.data.rateType !== 'Normal' && params.data.rateType !== 'Select') {
              params.data.netAfterDiscount = 0;
              params.data.discount = 100;
              params.data.agencyCommission = this.calculateAgencyComission(params.data.netAfterDiscount, params.data.agencyCommissionPercent);
              params.data.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(params.data.netAfterDiscount, params.data.agencyCommission);
              params.data.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(params.data.netAfterDiscount,
                params.data.miscCost);
              const parameters = {
                rowNodes: [params.node]
              };
              params.api.redrawRows(parameters);
              this.campaignDetailsClone = _.cloneDeep(this.campaignDetails);
            }
          }
        }
      });
    }

    if (!this.isColumnHidden('comments_booking_grid')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.germany.comments'], field: 'comments', width: 120, editable: true, pinned: false,
        cellRendererFramework: AgTextCellRendererComponent,
        cellRendererParams: {
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          }
        },
        pinnedRowCellRendererFramework: AgTextCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: (rowData) => {
            return ((!rowData.isParent) && (!rowData.locked));
          }
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: false,
          cellEditable: (rowData) => {
            return (!rowData.isParent);
          },
          maxLength: 200
        }
      });
    }

    this.numericFields = [
      'numberOfFrames',
      'grossPrice',
      'discount',
      'netAfterDiscount',
      'agencyCommissionPercent',
      'agencyCommission',
      'netAfterAgencyCommission',
      'miscCost',
      'loadingFee',
      'grossPricePerWeek',
      'loadingFeePct',
      'smartBricsValueTotal',
      'netAfterDiscountAndMiscCost'
    ];

    this.dateRangeFields = [];
    this.selectFields = [];

    this.processColDef();

    this.sortObj = new AgCustomSort(this.numericFields, this.dateRangeFields, this.selectFields, this.groupingField, true);
  }

  processColDef() {
    const decimalFieldsForFilter = [
      'grossPrice',
      'discount',
      'netAfterDiscount',
      'agencyCommissionPercent',
      'agencyCommission',
      'netAfterAgencyCommission',
      'miscCost',
      'loadingFee',
      'grossPricePerWeek',
      'loadingFeePct',
      'smartBricsValueTotal',
      'netAfterDiscountAndMiscCost'
    ];

    for (let i = 1; i < this.columnDefs.length; i++) {
      this.columnDefs[i].floatingFilterComponentFramework = AgTextFloatingFilterComponent;
      this.columnDefs[i].floatingFilterComponentParams = {
        value: '',
        suppressFilterButton: true,
        decimalRound: decimalFieldsForFilter.indexOf(this.columnDefs[i].field) > -1 ? 2 : null
      };

      this.columnDefs[i].filterParams = {
        newRowsAction: 'keep'
      };
      this.columnDefs[i].filterFramework = AgCustomFilterMenuComponent;
      this.columnDefs[i].headerComponentFramework = AgCustomHeaderComponent;
      this.columnDefs[i].cellClass = (params): string[] => {
        const retArr: string[] = [];
        if (params.data.isParent || params.node.rowPinned === 'bottom') {
          retArr.push('fontBold');
        }
        if (params.node.rowPinned === 'bottom') {
          retArr.push('borderBottom');
        }
        if (params.data.isSelected) {
          retArr.push('selectedRow');
        }
        return retArr;
      };
    }
  }

  setLoadingFeeCol() {
    if (!this.isColumnHidden('loadingFee')) {
      this.columnDefs.push({
        headerName: this.userBundle['discount.hk.loadingFee'], field: 'loadingFee', width: 150, editable: true,
        pinnedRowCellRendererFramework: AgCurrencyCellRendererComponent,
        pinnedRowCellRendererParams: {
          cellEditable: () => {
            return true;
          },
          gridSumRow: 1,
          decimalPoints: 2
        },
        cellRendererFramework: AgCurrencyCellRendererComponent,
        cellRendererParams: {
          cellEditable: () => {
            return true;
          },
          decimalPoints: 2
        },
        cellEditorFramework: AgTextCellEditorComponent,
        cellEditorParams: {
          onlyNumber: true,
          decimalPoints: 2,
          cellEditable: () => {
            return true;
          }
        }
      });
    }
  }

  sortData(colId, order) {
    this.rowData = this.sortObj.sort(colId, order, this.rowData);
    // restore original data order when sorting is cleared
    if (this.sortObj.sortColumns.length === 0) {
      this.rowData = _.clone(this.gridData.discounts);
    }
  }

  blankCellRenderer() {
    return '';
  }

  apply = () => {

    const selectedChildRows = this.getSelectedChildRows();
    if ((this.footerRowData[0].discount === 0 || this.footerRowData[0].discount) && (this.commercialConfig.uiControl.consistentDiscount)) {
      // SM-9052 - Discount should Apply to all row for hk:airport
      for (const row of this.rowData) {
        row.discount = this.footerRowData[0].discount;
        const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
        row.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, row.discount);
        row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
        row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
        row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount, row.miscCost);
      }
      this.rowData = _.clone(this.rowData);
    } 
    if (selectedChildRows.length > 0) {
      if ((this.footerRowData[0].discount || this.footerRowData[0].grossPrice === 0 || this.footerRowData[0].grossPrice ||
        this.footerRowData[0].netAfterDiscount === 0 || this.footerRowData[0].netAfterDiscount ||
        this.footerRowData[0].miscCost === 0 || this.footerRowData[0].miscCost ||
        this.footerRowData[0].loadingFee === 0 || this.footerRowData[0].loadingFee ||
        this.footerRowData[0].loadingFeePct === 0 || this.footerRowData[0].loadingFeePct ||
        this.footerRowData[0].agencyCommissionPercent === 0 || this.footerRowData[0].agencyCommissionPercent ||
        this.footerRowData[0].agencyCommission === 0 || this.footerRowData[0].agencyCommission || this.footerRowData[0].rateType)) {

        for (const row of selectedChildRows) {
          // SM-4113, VJ, 08-10-2019
          if (this.footerRowData[0].rateType !== '0' && this.footerRowData[0].rateType !== 0) {
            row.rateType = this.footerRowData[0].rateType;
            if (this.footerRowData[0].rateType !== null && this.footerRowData[0].rateType !== 'Normal') {
              row.netAfterDiscount = 0;
              row.discount = this.HUNDRED;
              row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
              row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
              row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount,
                row.miscCost);
            }
          }

          // below code added for SM-2942 and SM-3226, Nishit
          if (this.footerRowData[0].agencyCommission === 0 || this.footerRowData[0].agencyCommission) {
            row.agencyCommission = this.footerRowData[0].agencyCommission;
            row.agencyCommissionPercent = this.calculateAgencyCommissionPercent(row.agencyCommission, row.netAfterDiscount);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
          }

          if (this.footerRowData[0].grossPrice === 0 || this.footerRowData[0].grossPrice) {
            row.grossPrice = this.footerRowData[0].grossPrice;
            const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
            row.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, row.discount);
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.loadingFeePct = this.calculateLoadingFeePct(row.loadingFee, row.grossPrice);
            row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount, row.miscCost);
            if (this.environmentId === EnvironmentId.HKAI) {
              row.loadingFee = this.calculateLoadingFee(row.grossPrice, row.loadingFeePct);
            }
            row.grossPricePerWeek = this.calculateSmartBricsValuePerWeek(row.grossPrice, row.discountDays);
          }

          if ((this.footerRowData[0].discount === 0 || this.footerRowData[0].discount) && (!this.commercialConfig.uiControl.consistentDiscount)) {
            row.discount = this.footerRowData[0].discount;
            const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
            row.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, row.discount);
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount, row.miscCost);
          }

          if ((this.footerRowData[0].netAfterDiscount === 0 || this.footerRowData[0].netAfterDiscount) && (!this.commercialConfig.uiControl.consistentDiscount)) {
            row.netAfterDiscount = this.footerRowData[0].netAfterDiscount;
            const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
            row.discount = this.calculateDiscount(grossPrice, row.netAfterDiscount);
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount, row.miscCost);
          }

          if (this.footerRowData[0].agencyCommissionPercent || this.footerRowData[0].agencyCommissionPercent === 0) {
            row.agencyCommissionPercent = this.footerRowData[0].agencyCommissionPercent;
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
          }

          if (this.footerRowData[0].miscCost === 0 || this.footerRowData[0].miscCost) {
            row.miscCost = this.footerRowData[0].miscCost;
            row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount,
              row.miscCost);
          }

          if (this.footerRowData[0].loadingFee === 0 || this.footerRowData[0].loadingFee) {
            row.loadingFee = this.footerRowData[0].loadingFee;
            if (this.environmentId === EnvironmentId.HKAI) {
              row.loadingFeePct = this.calculateLoadingFeePct(row.loadingFee, row.grossPrice);
              row.smartBricsValueTotal = this.calculateSmartBricsValueTotal(row.grossPrice, row.loadingFee);
              const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
              row.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, row.discount);
              row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
              row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
              row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount,
                row.miscCost);
            }
          }

          if (this.footerRowData[0].loadingFeePct === 0 || this.footerRowData[0].loadingFeePct) {
            row.loadingFeePct = this.footerRowData[0].loadingFeePct;
            row.loadingFee = this.calculateLoadingFee(row.grossPrice, row.loadingFeePct);
            row.smartBricsValueTotal = this.calculateSmartBricsValueTotal(row.grossPrice, row.loadingFee);
            const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
            row.netAfterDiscount = this.calculateNetAfterDiscount(grossPrice, row.discount);
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount, row.miscCost);
          }
        }
        this.resetApply();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
    this.campaignDetailsClone = _.cloneDeep(this.campaignDetails);
  }

  calculateAgencyComission(netAfterDiscount, agencyCommissionPercent) {
    const agencyDiscount = (netAfterDiscount !== 0 && agencyCommissionPercent !== 0) ? (netAfterDiscount * agencyCommissionPercent) / 100 : 0;
    return Number(agencyDiscount.toFixed(this.precisionOnPrice));
  }

  calculateAgencyCommissionPercent(agencyCommission, netAfterDiscount) {
    const agencyDiscountPercent = (netAfterDiscount !== 0 && agencyCommission !== 0) ? (agencyCommission * 100) / netAfterDiscount : 0;
    return Number(agencyDiscountPercent.toFixed(this.precisionOnPercentage));
  }

  calculateNetAfterAgencyComission(netAfterDiscount, agencyCommission) {
    const afteragencyCommission = (netAfterDiscount !== 0) ? (netAfterDiscount - agencyCommission) : 0;
    return Number(afteragencyCommission.toFixed(this.precisionOnPrice));
  }

  calculateLoadingFeePct(loadingFee, grossPrice) {
    const loadingFeePerc = (loadingFee !== 0 && grossPrice !== 0) ? (parseFloat(loadingFee) / parseFloat(grossPrice)) * 100 : 0;
    return Number(loadingFeePerc.toFixed(this.precisionOnPercentage));
  }

  calculateSmartBricsValueTotal(grossPrice, loadingFee) {
    const smartBricsValueTotal = parseFloat(grossPrice) + parseFloat(loadingFee);
    return Number(smartBricsValueTotal.toFixed(this.precisionOnPrice));
  }

  calculateNetAfterDiscountAndMiscCost(netAfterDiscount, miscCost) {
    const netAfterDiscountAndMiscCost = parseFloat(netAfterDiscount) + parseFloat(miscCost);
    return Number(netAfterDiscountAndMiscCost.toFixed(this.precisionOnPrice));
  }

  calculateLoadingFee(grossPrice, loadingFeePerc) {
    const loadingFee = parseFloat(grossPrice) * parseFloat(loadingFeePerc) / 100;
    return Number(loadingFee.toFixed(this.precisionOnPrice));
  }

  calculateSmartBricsValuePerWeek(grossPrice, discountDays: number) {
    let smartBricsValuePerWeek = 0;
    if (discountDays !== 0) {
      smartBricsValuePerWeek = parseFloat(grossPrice) / (Number(discountDays) / 7);
    }
    return Number(smartBricsValuePerWeek.toFixed(this.precisionOnPrice));
  }

  spread = () => {
    const selectedChildRows = this.getSelectedChildRows();
    if ((this.footerRowData[0].netAfterDiscount || this.footerRowData[0].netAfterDiscount === 0) && (this.commercialConfig.uiControl.consistentDiscount)) {
      // SM-9052 - Discount should Apply to all row for hk:airport
      let sumCards = 0;
      this.rowData.forEach(e => sumCards += this.environmentId !== EnvironmentId.HKAI ? e.grossPrice : e.smartBricsValueTotal);
      for (const row of this.rowData) {
        const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
        if (sumCards !== 0) {
          row.netAfterDiscount = Number(((this.footerRowData[0].netAfterDiscount * grossPrice) / sumCards).toFixed(this.precisionOnPrice));
        } else {
          row.netAfterDiscount = 0;
        }
        row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
        row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
        row.discount = this.calculateDiscount(grossPrice, row.netAfterDiscount);
        row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount, row.miscCost);
      }
      this.resetSpread();
      this.rowData = _.clone(this.rowData);
    } else if (selectedChildRows.length > 0) {
      if (this.footerRowData[0].agencyCommission || this.footerRowData[0].agencyCommission === 0) {
        const agencyCommissionPerRow = parseFloat(this.footerRowData[0].agencyCommission) / selectedChildRows.length;
        for (const row of selectedChildRows) {
          if (row && row.isSelected) {
            if (agencyCommissionPerRow !== 0) {
              row.agencyCommission = Number(agencyCommissionPerRow.toFixed(this.precisionOnPrice));
            } else {
              row.agencyCommission = 0;
            }
            row.agencyCommissionPercent = this.calculateAgencyCommissionPercent(row.agencyCommission, row.netAfterDiscount);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
          }
        }
        this.resetSpread();
        this.rowData = _.clone(this.rowData);
      }
      // added code for SM-3251
      if ((this.footerRowData[0].netAfterDiscount || this.footerRowData[0].netAfterDiscount === 0) && (!this.commercialConfig.uiControl.consistentDiscount)) {
        let sumCards = 0;
        selectedChildRows.forEach(e => sumCards += this.environmentId !== EnvironmentId.HKAI ? e.grossPrice : e.smartBricsValueTotal);
        for (const row of selectedChildRows) {
          if (row && row.isSelected) {
            // made changes for SM-3251
            // if (netAfterDiscountPerRow !== 0) {
            //   row.netAfterDiscount = netAfterDiscountPerRow;
            // } else {
            //   row.netAfterDiscount = 0;
            // }
            const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
            if (sumCards !== 0) {
              row.netAfterDiscount = Number(((this.footerRowData[0].netAfterDiscount * grossPrice) / sumCards).toFixed(this.precisionOnPrice));
            } else {
              row.netAfterDiscount = 0;
            }
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.discount = this.calculateDiscount(grossPrice, row.netAfterDiscount);
            row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount, row.miscCost);
          }
        }
        this.resetSpread();
        this.rowData = _.clone(this.rowData);
      }
      if (this.environmentId === EnvironmentId.HKAI &&
        (this.footerRowData[0].loadingFee || this.footerRowData[0].loadingFee === 0)) {
        let sumCards = 0;
        selectedChildRows.forEach(e => sumCards += this.environmentId !== EnvironmentId.HKAI ? e.grossPrice : e.smartBricsValueTotal);
        for (const row of selectedChildRows) {
          if (row && row.isSelected) {
            const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
            if (sumCards !== 0) {
              row.loadingFee = Number(((this.footerRowData[0].loadingFee * grossPrice) / sumCards).toFixed(this.precisionOnPrice));
            } else {
              row.loadingFee = 0;
            }
            row.loadingFeePct = this.calculateLoadingFeePct(row.loadingFee, row.grossPrice);
            row.smartBricsValueTotal = this.calculateSmartBricsValueTotal(row.grossPrice, row.loadingFee);
            row.netAfterDiscount = this.calculateNetAfterDiscount(row.smartBricsValueTotal, row.discount);
            row.agencyCommission = this.calculateAgencyComission(row.netAfterDiscount, row.agencyCommissionPercent);
            row.netAfterAgencyCommission = this.calculateNetAfterAgencyComission(row.netAfterDiscount, row.agencyCommission);
            row.netAfterDiscountAndMiscCost = this.calculateNetAfterDiscountAndMiscCost(row.netAfterDiscount,
              row.miscCost);
          }
        }
        this.resetSpread();
        this.rowData = _.clone(this.rowData);
      }
    } else {
      this.toaster.logInfo('Select atleast one row!');
    }
    this.campaignDetailsClone = _.cloneDeep(this.campaignDetails);
  }

  resetSpread = () => {
    this.footerRowData[0].agencyCommission = null;
    this.footerRowData[0].netAfterDiscount = null;
    this.footerRowData[0].loadingFee = null;
  }

  reset = () => {
    this.resetApply();
    this.resetSpread();
    this.rowData = _.clone(this.rowData);
  }

  resetApply = () => {
    const columnArray = ['discount', 'netAfterDiscount', 'miscCost', 'loadingFee', 'loadingFeePct',
      'grossPrice', 'agencyCommissionPercent', 'agencyCommission', 'rateType', 'loadingFeePct'];
    for (const column of columnArray) {
      this.footerRowData[0][column] = null;
    }
  }

  // Data functions
  getChildRows(): any[] {
    return AgCustomRowUtil.getChildRows(this.rowData);
  }

  getSelectedChildRows(): any[] {
    return AgCustomRowUtil.getSelectedChildRows(this.rowData);
  }

  calcAvgDiscount(rows) {
    const valueObj = { value1: 0, value2: 0 };
    rows.forEach((row) => {
      const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
      valueObj.value1 += parseFloat(grossPrice);
      valueObj.value2 += parseFloat(grossPrice) * parseFloat(row.discount);
    });
    let value = 0;
    if (valueObj.value1 !== 0) {
      value = valueObj.value2 / valueObj.value1;
    }
    return Number(value.toFixed(this.precisionOnPercentage));
  }

  calcAvgDiscountPinnedRow = (rows) => {
    if (this.footerRowData) {
      if (this.footerRowData[0].discount === 0 || (this.footerRowData[0].discount && this.footerRowData[0].discount !== '')) {
        return Number(this.footerRowData[0].discount).toFixed(this.precisionOnPercentage);
      } else {
        return this.calcAvgDiscount(rows);
      }
    } else {
      return this.calcAvgDiscount(rows);
    }
  }

  calcAvgLoadingFeePct(rows) {
    const valueObj = { value1: 0, value2: 0 };
    rows.forEach((row) => {
      const grossPrice = this.environmentId !== EnvironmentId.HKAI ? row.grossPrice : row.smartBricsValueTotal;
      valueObj.value1 += parseFloat(grossPrice);
      valueObj.value2 += parseFloat(row.loadingFeePct) * parseFloat(grossPrice);
    });
    let value = 0;
    if (valueObj.value1 !== 0) {
      value = valueObj.value2 / valueObj.value1;
    }
    return Number(value.toFixed(this.precisionOnPercentage));
  }

  calcAvgLoadingFeePctPinnedRow = (rows) => {
    if (this.footerRowData) {
      if (this.footerRowData[0]['loadingFeePct'] === 0 || (this.footerRowData[0]['loadingFeePct'] && this.footerRowData[0]['loadingFeePct'] !== '')) {
        return Number(this.footerRowData[0]['loadingFeePct']).toFixed(this.precisionOnPercentage);
      } else {
        return this.calcAvgLoadingFeePct(rows);
      }
    } else {
      return this.calcAvgLoadingFeePct(rows);
    }
  }

  calculateDiscount(grossPrice, netAfterDiscount) {
    const mediaValue = parseFloat(grossPrice);
    const agencyNet = parseFloat(netAfterDiscount);
    const discount = (mediaValue !== 0) ? ((mediaValue - agencyNet) / mediaValue * 100) : 0;
    return Number(discount.toFixed(this.precisionOnPercentage));
  }

  /**
   * @description : Calculate the netAfterDiscount value.
   * @param {number} rateCard - RateCard value
   * @param {number} discount - value of discount
   * @returns Calculated netAfterDiscount value
   */
  calculateNetAfterDiscount(grossPrice, discount) {
    const netAfterDiscount = parseFloat(grossPrice) * (1 - (parseFloat(discount) / 100));
    return Number(netAfterDiscount.toFixed(this.precisionOnPrice));
  }

  /**
   *  download cart data in excel format
   */
  downloadExcel() {
    const exportToExcel: ExportToExcel = new ExportToExcel();
    const sheetData: ExcelCell[][] = [];
    const excelColumn: ExcelColumn[] = [];
    // Add Header Row
    let sheetRow: ExcelCell[] = [];
    for (let j = 1; j < this.columnDefs.length; j++) {
      const cell: ExcelCell = {
        text: this.columnDefs[j].headerName,
        fontColor: '#FFFFFF',
        bgColor: '#000000',
        borderColor: '#CCCCCC',
        isFontBold: true
      };
      const col: ExcelColumn = {
        width: this.columnDefs[j].field === 'week' ? 15 : 12
      };
      excelColumn.push(col);
      sheetRow.push(cell);
    }
    sheetData.push(sheetRow);

    // Add Data rows
    let rowsInserted = 0;
    for (const row of this.rowData) {
      if (row.isParent) {
        continue;
      }

      sheetRow = [];
      sheetRow = this.populateSheetRow(sheetRow, row, rowsInserted);
      rowsInserted++;
      sheetData.push(sheetRow);
    }
    const cartText = 'discount';
    exportToExcel.download(cartText, sheetData, excelColumn);
  }

  populateSheetRow(sheetRow: any[], row, rowsInserted) {
    for (let j = 1; j < this.columnDefs.length; j++) {
      let cellVal = row[this.columnDefs[j].field];
      if (this.selectFields.indexOf(this.columnDefs[j].field) > -1) {
        cellVal = row[`${this.columnDefs[j].field}_sort`];
      }
      if ((this.columnDefs[j].field === 'startDate' || this.columnDefs[j].field === 'endDate') && cellVal) {
        cellVal = this.datePipe.transform((new Date(cellVal)), LocaleData.displayDateFormat);
      }
      const cell: ExcelCell = {
        text: cellVal,
        fontColor: '#000000',
        bgColor: rowsInserted % 2 === 0 ? '#FFFFFF' : '#f6f6f6',
        borderColor: '#CCCCCC'
      };
      sheetRow.push(cell);
    }

    return sheetRow;
  }

  toggleColumnVisible(colId, isVisible) {
    const isVisibleLocal = !isVisible;
    this.gridOptions.columnApi.setColumnVisible(colId, isVisibleLocal);
  }

  isColumnHidden(key: string): boolean {
    let columnHidden = false;
    if (this.campaignDetails.uiControl && this.campaignDetails.uiControl.hiddenColumns) {
      columnHidden = this.campaignDetails.uiControl.hiddenColumns.indexOf(key) > -1;
    }
    return columnHidden;
  }

  trackByField(index, column) {
    return column?.colDef?.field;
  }

}
