import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DataShareService, LoaderService } from '../../core/services';
import * as _ from 'lodash';
import { GLOBAL } from '../../core/utils/app.constant';
import { Brand, TemplateProps, UiControl } from '../../models';
import { SystemFlags } from '../../models/index';
import { TreeViewComponent } from '../../core/components/tree-view/tree-view.component';
import { NgbDateStruct, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapSvgIcon } from '../../geo-map/mapSvgIcon';
import { mathRandomNumber } from '../../core/utils/mathRandom';
import { NgbDatepickerHelper } from '../../core/components/ngb-datepicker/ngb-datepicker-helper';
import { ResultService } from '../result.service';
import * as moment from 'moment';
declare let MarkerClusterer;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  providers: [ResultService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapComponent implements OnInit {
  isDisabled;
  userData: any;
  firstLoad = false;
  toggleClicked = false;
  markerCluster: any;
  circleCluster: any;
  iconImages: any[] = [];
  mapObj: google.maps.Map;
  systemFlags = SystemFlags;
  node: any;
  state: any;
  openedModal: any;
  @Input() userBundle: object = {};
  @Input() isDown: boolean;
  @Input() mapHelper: object = {};
  @Input() uiControl: UiControl;
  @Input() isOnline: boolean;
  @Input() resultSummary: any = {};
  @Output() toggleAccordion = new EventEmitter<any>();
  private latlngBounds: any;
  assetData: any = [];
  proximityData: any = {};
  requestHelper: any = {};
  lat: number = GLOBAL.DEFAULT_LATITUDE;
  lng: number = GLOBAL.DEFAULT_LONGITUDE;
  mapLegendArray: any[] = [];
  openLegendBoxFlag = false;
  openDateFilterLegendBoxFlag = false;
  minStartDate: NgbDateStruct;
  minEndDate: NgbDateStruct;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  campaignStartDate: NgbDateStruct;
  campaignEndDate: NgbDateStruct;
  showPastDates = false;
  /**
   * @description specific startDate day properties cached object for ngbDatePicker day template
   * @memberof FilterAreaComponent
   */
   startDayObjCache = {};
  /**
   * @description specific endDate day properties cached object for ngbDatePicker day template
   * @memberof FilterAreaComponent
   */
   endDayObjCache = {};

  public baiduAPI: string;
  // public baiduOpts: MapOptions;
  public googleMapOptions: google.maps.MapOptions;
  dynamicHeight = '300px';
  loadBaiduMap = false;
  markers: any = [];
  hex = 'hex';
  @ViewChild('content') modalContent;
  // For Showing POI markers on the map
  private treeViewComponent: TreeViewComponent;
  @ViewChild(TreeViewComponent) set SetTreeViewComponent(value: TreeViewComponent) {
    if (value) {
      this.treeViewComponent = value;
      if (this.firstLoad || this.toggleClicked) {
        this.loaderService.show();
        this._cdr.markForCheck();
        setTimeout(() => {
          this.loadNodes().then(() => {
            this._cdr.markForCheck();
            this.loaderService.hide();
            this._cdr.markForCheck();
          }, () => {
            this.loaderService.hide();
            this._cdr.markForCheck();
          });
        }, 1000);
      }
    }
  }
  clusterer = null;
  iconShapes = ['circle', 'diamond', 'heart', 'plus', 'polygons', 'triangle'];
  templateProps: TemplateProps = {
    displayId: 'key',
    displayName: 'displayText',
    displayRadioButton: false,
    searchPlaceHolder: '',
    showId: false,
    allowCustomCheckBoxEvents: true,
    localSolverEnabled: false,
    checkboxIconEnabled: true
  };
  treeOptions = {
    idField: 'key',
    displayField: 'displayText',
    childrenField: 'points',
    useCheckbox: false,
    useVirtualScroll: true,
    nodeHeight: 34,
    scrollContainer: document.body.parentElement
  };
  showPOIs = true;
  imagePath = 'images/svg/';
  activeAccordion = false;
  accordianToggleStyle: any = {};
  tempProximityData: any = [];
  poiData: any[] = [];
  mapSvgIcon: MapSvgIcon;
  @Input() datesToDisableInCalendar: any = [];

  constructor(private resultService: ResultService, private dataShareService: DataShareService,private _cdr: ChangeDetectorRef, private modalService: NgbModal, private loaderService: LoaderService) {
    this.mapSvgIcon = new MapSvgIcon();
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
      this.markerCluster = null;
    }
    this.isDisabled = (date: NgbDateStruct) => {
      return this.datesToDisableInCalendar.some((disabledDate) => {
        const ngbDate: NgbDateStruct = {
          year: moment(disabledDate).year(),
          month: moment(disabledDate).month() + 1,
          day: moment(disabledDate).date(),
        };
        return (
          date.year === ngbDate.year &&
          date.month === ngbDate.month &&
          date.day === ngbDate.day
        );
      });
    };
  }

  ngOnInit() {
    const uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.requestHelper = this.mapHelper['requestHelper'];
    this.assetData = this.mapHelper['assetData'];
    this.proximityData = this.mapHelper['proximityData'];
    this.uiControl = { ...uiControl, ...this.uiControl };
    this.userData = this.dataShareService.getInitialConfigByKey('userData');    
    this.getCampaignStartEndDates();
    this.groupDataForMap();
    if (!this.uiControl.baiduMapEnabled) {
      this.googleMapOptions = {
        center: this.isOnline === true && this.assetData.length ?
          new google.maps.LatLng(this.userData.latitude, this.userData.longitude) : null,
        fullscreenControl: true,
        streetViewControl: true,
        clickableIcons: false,
        zoom: this.userData.zoomLevel || 5,
        mapTypeControl: false
      };
      this.latlngBounds = this.isOnline === true ? new window['google'].maps.LatLngBounds() : null;
      if (this.isOnline) {
        this.mapHelper['assetData'].forEach((location) => {
          this.latlngBounds.extend(new window['google'].maps.LatLng(location.latitude, location.longitude));
        });
        // create POIs        
        Promise.all(this.getAllIconImagesSource()).then(() => {          
          this.createPOIData().then((result) => {
            if (result) {
              this.firstLoad = true;
              this.updateMarkerWithClusters();
            }
          });
        });
      }
      this.onResize();
    } else {
      for (const marker of this.assetData) {
        marker.icon = this.getIconURL(marker);
      }
      this.loadBaiduMap = true;
    }
  }

  getAllIconImagesSource() {
    const promises = [];
    for (const iconShape of this.iconShapes) {
      const icon = `${this.imagePath + iconShape}.svg`;
      const promise = this.mapSvgIcon.urlToString(icon).then((svgStr) => {
        const imageVal = { shape: iconShape, img: svgStr };
        this.iconImages.push(imageVal);
      });
      promises.push(promise);
    }
    return promises;
  }

    // This method is not in use for now,
    // as we have implemented vector image concept to show markers on AGM map for performance improvement but,
    // this might be useful in future.
    async getSVGIconURL(poi) {
    const imageVal = _.find(this.iconImages, (imgVal) => { return imgVal.shape === poi.shape; });
    const svgStr = this.mapSvgIcon.replaceColors(imageVal.img, poi.colorCode);
    const doc = this.mapSvgIcon.parseSVG(svgStr);
    const serializedSVG = new XMLSerializer().serializeToString((doc));            
    return  `data:image/svg+xml;base64, ${window.btoa(serializedSVG)}`;
  }

  async createPOIData() {
    return new Promise((resolve) => {
      if (this.proximityData) {
        this.loaderService.show();
        this._cdr.markForCheck();
        if (this.proximityData.pointsOfInterest) {
          let tempPOIData = this.proximityData.pointsOfInterest.categories;
          const tempCate = this.replaceKeysInObj(tempPOIData, 'subcategories', 'points');
          tempPOIData = _.values(tempCate);
          const tempBrands = this.replaceKeysInObj(tempPOIData, 'brands', 'points');
          tempPOIData = _.values(tempBrands);
          tempPOIData.forEach((poi: any, index: number) => {
            poi.selected = true;
            const shape = this.getIconShape();
            const randomColor = Math.floor(mathRandomNumber() * 16777215).toString(16);
            poi.shape = shape;
            poi.colorCode = `#${randomColor}`;
            poi.mixedColored = false;
            tempPOIData[index] = poi;
            poi.points = _.values(poi.points);
            poi.points.forEach((subCate) => {
              subCate.shape = poi.shape;
              subCate.colorCode = poi.colorCode;
              subCate.mixedColored = false;
              subCate.selected = poi.selected;
              subCate.points = _.values(subCate.points);
              subCate.points.forEach((subBrand) => {
                subBrand.shape = poi.shape;
                subBrand.colorCode = poi.colorCode;
                subBrand.selected = poi.selected;
                const tmpsubBrand: any = this.replaceKeysInObj(subBrand, 'points', 'points1');
                subBrand.points1 = _.values(tmpsubBrand.points1);
                delete subBrand.points;
                subBrand.points1.forEach((point, index) => {
                  point.selected = true;
                  point.shape = poi.shape;
                  point.colorCode = `#${randomColor}`;
                  point.type = subBrand.key;
                  this.poiData.push(point);
                  subBrand.points1[index] = point;
                });
              });
            });
            this.tempProximityData.push(poi);            
          });
        }
        if (this.proximityData.points) {
          const objPoints: any = {};
          objPoints.key = 'points';
          objPoints.displayText = 'Points Data';
          objPoints.selected = true;
          objPoints.shape = 'plus';
          const randomColor = Math.floor(mathRandomNumber() * 16777215).toString(16);
          objPoints.colorCode = `#${randomColor}`;
          const manualData = this.proximityData.points.manual;
          const fileData = this.proximityData.points.file;
          objPoints.points1 = [];
          if (manualData) {
            manualData.forEach((singleData: any) => {
              const tmpsingleData: any = this.replaceKeysInObj(singleData, 'points', 'points1');
              singleData.points1 = _.values(tmpsingleData.points1);
              delete singleData.points;
              singleData.points1.forEach((poi: any, index: number) => {
                poi.selected = true;
                poi.shape = objPoints.shape;
                poi.colorCode = `#${randomColor}`;
                poi.type = poi.key;
                poi.type = objPoints.key;
                poi.displayText = `point${index}`;
                objPoints.points1.push(poi);
                this.poiData.push(poi);
              });
            });
          }
          let startIndex = objPoints.points1.length;
          if (fileData) {
            fileData.forEach((file: any) => {
              const fileData: any = this.replaceKeysInObj(file, 'points', 'points1');
              file.points1 = _.values(fileData.points1);
              delete file.points;
              file.points1.forEach((poi: any) => {
                poi.selected = true;
                poi.shape = objPoints.shape;
                poi.colorCode = `#${randomColor}`;
                poi.type = objPoints.key;
                poi.displayText = `point${startIndex}`;
                objPoints.points1.push(poi);
                this.poiData.push(poi);
                startIndex++;
              });
            });
          }
          this.tempProximityData.push(objPoints);
        }
        resolve(true);
        this._cdr.markForCheck();
      } else {
        resolve(false);
      }
    });
  }

  clearOverlays() {
    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers.length = 0;
  }

  // Sets the map on all markers in the array.
  setMapOnAll(map: google.maps.Map | null) {
    for (const marker of this.markers) {
      marker.setMap(map);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  hideMarkers(): void {
    this.setMapOnAll(null);
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
      this.markerCluster = null;
    }
    this._cdr.markForCheck();
  }

  // Shows any markers currently in the array.
  showMarkers(): void {
    this.setMapOnAll(this.mapObj);
    if (this.markerCluster) {
      this.markerCluster.addMarkers(this.markers);
    }
    this._cdr.markForCheck();
  }

  getSelectedShapePath(shape): string { 
    let path = 'M16,958.362a10,10,0,1,0,10,10A10,10,0,0,0,16,958.362Z';
    switch (shape) {     
      case 'circle':        
        path = 'M 10.00,-0.00 C 4.48,-0.00 0.00,4.48 0.00,10.00 0.00,15.52 4.48,20.00 10.00,20.00 15.52,20.00 20.00,15.52 20.00,10.00 20.00,4.48 15.52,-0.00 10.00,-0.00 10.00,-0.00 10.00,-0.00 10.00,-0.00 Z';
        break;
        case 'diamond':          
          path = 'M 10.00,0.00 C 10.00,0.00 20.00,10.00 20.00,10.00 20.00,10.00 10.00,20.00 10.00,20.00 10.00,20.00 0.00,10.00 0.00,10.00 0.00,10.00 10.00,0.00 10.00,0.00 Z';
        break;
        case 'heart':
          path = 'M19.627,9.748a5.64,5.64,0,0,0-7.088,1.274A5.65,5.65,0,0,0,2.5,14.527a5.526,5.526,0,0,0,.263,1.741c.924,4.37,9.623,9.953,9.623,9.953s8.8-5.406,9.814-9.756a5.568,5.568,0,0,0,.3-1.737,5.657,5.657,0,0,0-2.871-4.981Z';
        break;
        case 'plus':
        path = 'M 20.00,7.70 C 20.00,7.70 12.29,7.70 12.29,7.70 12.29,7.70 12.29,-0.00 12.29,-0.00 12.29,-0.00 7.70,-0.00 7.70,-0.00 7.70,-0.00 7.70,7.70 7.70,7.70 7.70,7.70 -0.01,7.70 -0.01,7.70 -0.01,7.70 -0.01,12.30 -0.01,12.30 -0.01,12.30 7.70,12.30 7.70,12.30 7.70,12.30 7.70,20.00 7.70,20.00 7.70,20.00 12.29,20.00 12.29,20.00 12.29,20.00 12.29,12.30 12.29,12.30 12.29,12.30 20.00,12.30 20.00,12.30 20.00,12.30 20.00,7.70 20.00,7.70 Z';
        break;
        case 'polygons':
        path = 'M9.985,0,20,7.25,16.16,19.017H3.81L0,7.25Z';
        break;
        case 'triangle':
          path = 'M 10.00,0.00 C 10.00,0.00 15.01,8.51 15.01,8.51 15.01,8.51 20.00,17.00 20.00,17.00 20.00,17.00 -0.00,17.00 -0.00,17.00 -0.00,17.00 4.99,8.51 4.99,8.51 4.99,8.51 10.00,0.00 10.00,0.00 Z';
        break;    
      default:
        path = 'M19.627,9.748a5.64,5.64,0,0,0-7.088,1.274A5.65,5.65,0,0,0,2.5,14.527a5.526,5.526,0,0,0,.263,1.741c.924,4.37,9.623,9.953,9.623,9.953s8.8-5.406,9.814-9.756a5.568,5.568,0,0,0,.3-1.737,5.657,5.657,0,0,0-2.871-4.981Z';
        break;
    }
    return path;

  }

  async createPOIMarkers() {
    return new Promise((resolve) => {
      this.clearOverlays();
      this.poiData.forEach(async (poi) => {
        const svgMarker = {
          path: this.getSelectedShapePath(poi.shape),          
          fillColor: poi.colorCode,
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 1,
          anchor: new google.maps.Point(15, 15),
          origin: new google.maps.Point(0, 0)          
        };
        const marker: any = new google.maps.Marker({
          position: new google.maps.LatLng(poi.latitude, poi.longitude),
          icon: svgMarker        
        });
        const circle = new google.maps.Circle({
          visible: poi.selected,
          center: new google.maps.LatLng(poi.latitude, poi.longitude),
          draggable: false,
          strokeColor: '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          editable: false,
          radius: poi.radius
        });
        circle.bindTo('center', marker, 'position');
        circle.bindTo('map', marker, 'map');
        marker.key = poi.key;
        marker.selected = poi.selected;
        if (poi.selected) {
          this.markers.push(marker);
        }
      });
      resolve(this.markers);
      this._cdr.markForCheck();
    });
  }

  createMarkerClusters(): void {
    if (this.mapObj) {
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
        this.markerCluster = null;
      }
      // Cluster all the markers
      if (this.markers.length > 0) {
        this.markerCluster = new MarkerClusterer(this.mapObj, this.markers, {
          imagePath: 'scripts/3rdParty/markerclusterer/images/m',
          maxZoom: this.poiData.length > 0 ? 10 : -1,
          gridSize: this.poiData.length > 0 ? 50 : -1,
          ignoreHidden: false
        });
      }
      this._cdr.markForCheck();
    }
  }

  onMapReady(map) {
    this.mapObj = map;
    this._cdr.markForCheck();
    this.createMarkerClusters();
  }

  loadNodes = async () => {
    return new Promise((resolve, reject) => {
      if (this.treeViewComponent) {
        for (let i = 0; i < this.tempProximityData.length; i++) {
          if (this.treeViewComponent.tree.treeModel.nodes[i].key) {
            const id = this.treeViewComponent.tree.treeModel.nodes[i].key;
            const node = this.treeViewComponent.tree.treeModel.getNodeById(id);
            if (node) {
              node.setIsSelected(this.treeViewComponent.tree.treeModel.nodes[i].selected);
            }
          }
        }
        if (this.treeViewComponent.tree.treeModel) {
          this.treeViewComponent.tree.treeModel.expandAll();
          this.firstLoad = false;
          this.toggleClicked = false;
        }
      } else {
        reject(false);
      }

      resolve(true);
    });
  }

  getIconShape(): string {
    const chosenShape = this.iconShapes[Math.round((mathRandomNumber() % this.iconShapes.length))];
    if (chosenShape === 'plus' || !chosenShape) {
      return this.getIconShape();
    }
    return chosenShape;
  }

  /**
  * @description This method is to replace nested level keys for object passed
  * eg. In array, we need to replace 'categories' to `points` to properly bind the data with angular-tree-component for proximity data on map
  * @author Sagar Vaishnav
  * @date 2021-11-25
  * @param {any} obj
  * @param {string} key
  * @param {string} newKey
  * @memberof MapComponent
  */
  replaceKeysInObj(obj: any, key: string, newKey: string) {
    const newObj = {};
    for (const ogKey in obj) {
      if (ogKey === key) {
        newObj[newKey] = obj[ogKey];
      } else if (typeof obj[ogKey] === 'object') {
        newObj[ogKey] = this.replaceKeysInObj(obj[ogKey], key, newKey);
      } else {
        newObj[ogKey] = obj[ogKey];
      }
    }
    return newObj;
  }

  groupDataForMap() {
    let resultData;
    /// Gets the data from the json  GloabalData.requestHelper.legend on which the grouping has to be done///////
    resultData = _.groupBy(this.mapHelper['assetData'], this.mapHelper['requestHelper'].legend);
    resultData = _.sortBy(resultData, (num) => {
      return num;
    });
    resultData = resultData.reverse();
    _.forEach(resultData, (obj, i) => {
      if (Number(i) < GLOBAL.LEGEND_COUNT) {
        const item = obj[0];
        GLOBAL.LEGEND_ARRAY[i]['Key'] = item[this.mapHelper['requestHelper'].legend];
      }
    });
    _.forEach(GLOBAL.LEGEND_ARRAY, (obj) => {
      obj.Selected = false;
    });
    this.mapLegendArray = GLOBAL.LEGEND_ARRAY;
  }

  getIconURL(marker) {
    let iconURL = '';
    const legendID = marker[this.mapHelper['requestHelper'].legend];
    const imagePath = 'images/';
    switch (legendID) {
      case GLOBAL.LEGEND_ARRAY[0]['Key']:
        iconURL = `${imagePath}pin-f0391a.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[1]['Key']:
        iconURL = `${imagePath}pin-fe851f.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[2]['Key']:
        iconURL = `${imagePath}pin-fff000.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[3]['Key']:
        iconURL = `${imagePath}pin-0acc77.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[4]['Key']:
        iconURL = `${imagePath}pin-38E0EB.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[5]['Key']:
        iconURL = `${imagePath}pin-FE70A2.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[6]['Key']:
        iconURL = `${imagePath}pin-7fcafe.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[7]['Key']:
        iconURL = `${imagePath}pin-ffcc00.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[8]['Key']:
        iconURL = `${imagePath}pin-f971db.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[9]['Key']:
        iconURL = `${imagePath}pin-FEB89F.png`;
        break;
      case GLOBAL.LEGEND_ARRAY[10]['Key']:
        iconURL = `${imagePath}pin-BDD51D.png`;
        break;
      default:
        iconURL = `${imagePath}pin-BDD51D.png`;
        break;
    }
    return iconURL;
  }

  filterLegends(legend) {
    this.assetData = this.mapHelper['assetData'];
    legend.Selected = !legend.Selected;
    const legendKey = this.mapHelper['requestHelper'].legend;
    const filterData = [];
    for (let i=0;i< this.mapLegendArray.length;i++) {
      const obj = this.mapLegendArray[i];
      if (obj['Selected']) {
        const filterObject = {};
        filterObject[legendKey] = obj['Key'];
        const filteredData = _.filter(this.mapHelper['assetData'], function (item) {
          if (item.marketingName === filterObject[legendKey]) {
            return item;
          }
        });
        filterData.push(...filteredData);
      }
    }
    if (filterData.length > 0) {
      this.assetData = filterData;
    }
  }

  openLegendBox() {
    this.openLegendBoxFlag = !this.openLegendBoxFlag;
  }

  openDateFilterLegendBox() {
    this.openDateFilterLegendBoxFlag = !this.openDateFilterLegendBoxFlag;
  }

  onResize() {
    this.dynamicHeight = `${screen.width * 0.445}px`;
    this._cdr.detectChanges();
  }

  onAccordionClick() {
    this.activeAccordion = !this.activeAccordion;
  }

  async toggleAllPOIs() {
    this.loaderService.show();
    this._cdr.markForCheck();
    this.showPOIs = !this.showPOIs;
    this.toggleClicked = true;
    for (const poiObj of this.poiData) {
      if (this.showPOIs) {
        poiObj.selected = true;
      } else {
        poiObj.selected = false;
      }
    }
    for (let i = 0; i < this.tempProximityData.length; i++) {
      if (this.treeViewComponent.tree.treeModel.nodes[i].key) {
        this.treeViewComponent.tree.treeModel.nodes[i].selected = this.showPOIs;
        const id = this.treeViewComponent.tree.treeModel.nodes[i].key;
        const node = this.treeViewComponent.tree.treeModel.getNodeById(id);
        if (node) {
          this.updateRecursiveNodeValue(node, 'selected');
        }
      }
    }
    this.loadNodes().then(() => {
      this.loaderService.hide();
      this._cdr.markForCheck();
    }, () => {
      this.loaderService.hide();
      this._cdr.markForCheck();
    });
    if (this.showPOIs) {
      this.showMarkers();
    } else {
      this.hideMarkers();
    }
    this.updateMarkerWithClusters();
  }

  async onSelectDeSelectNode(selectEvent: any) {
    return new Promise((resolve) => {
      const node = this.treeViewComponent.tree.treeModel.getNodeById(selectEvent.node.data.key);
      const prevState = node.data.selected;
      if (selectEvent.eventName === 'select') {
        node.data.selected = true;
        if (node.data.points1) {
          node.data.points1.forEach((element) => {
            element.selected = true;
          });
        }
      } else if (selectEvent.eventName === 'deselect') {
        node.data.selected = false;
        if (node.data.points1) {
          node.data.points1.forEach((element) => {
            element.selected = false;
          });
        }
      }
      this._cdr.markForCheck();
      if (prevState !== node.data.selected) {
        this.updateRecursiveNodeValue(node, 'selected');
        this.updateMarkerWithClusters();
      }
      resolve(true);
    });
  }

  updateMixedColoredIcon(node: any) {
    const parentNode = this.treeViewComponent.getParentNode(node.data.key);
    if (parentNode) {
      if (node.hasChildren) {
        node.children.forEach((subCate) => {
          if (subCate.data.colorCode !== node.data.colorCode) {
            node.data.mixedColored = true;
            const parentNode = this.treeViewComponent.getParentNode(subCate.data.key);
            if (parentNode) {
              parentNode.data.mixedColored = true;
            }
          } else {
            node.data.mixedColored = false;
          }
          if (subCate.hasChildren) {
            subCate.children.forEach((brand) => {
              if (!brand.hasChildren) {
                if (brand.data.colorCode !== subCate.data.colorCode) {
                  subCate.data.mixedColored = true;
                  const parentNode = this.treeViewComponent.getParentNode(subCate.data.key);
                  if (parentNode) {
                    parentNode.data.mixedColored = true;
                  }
                } else {
                  const parentNode = this.treeViewComponent.getParentNode(subCate.data.key);
                  if (parentNode) {
                    parentNode.data.mixedColored = false;
                  }
                }
              }
            });
          } else {
            const parentNode = this.treeViewComponent.getParentNode(node.data.key);
            if (parentNode) {
              parentNode.data.mixedColored = true;
            }
          }
        });
      } else {
        const parentNode = this.treeViewComponent.getParentNode(node.data.key);
        if (parentNode) {
          if (parentNode.data.colorCode !== node.data.colorCode) {
            parentNode.data.mixedColored = true;
            const subParentNode = this.treeViewComponent.getParentNode(parentNode.data.key);
            if (subParentNode) {
              subParentNode.data.mixedColored = true;
            } else {
              parentNode.data.mixedColored = false;
            }
          }
        }
      }
    } else {
      node.data.mixedColored = false;
      if (node.hasChildren) {
        node.children.forEach((subCate) => {
          subCate.data.mixedColored = false;
          if (subCate.hasChildren) {
            subCate.children.forEach((brand) => {
              brand.data.mixedColored = false;
            });
          }
        });
      }
    }
  }

  updateMarkerWithClusters() {
    this.loaderService.show();
    this._cdr.markForCheck();
    this.createPOIMarkers().then(() => {
      this.createMarkerClusters();
      this.loaderService.hide();
      this._cdr.markForCheck();
    });
  }

  onColorChangeNode(node: any) {
    this.updateRecursiveNodeValue(node, 'colorCode');
    this.updateMixedColoredIcon(node);
    this.updateMarkerWithClusters();
  }

  onShapeChange(node: any) {
    this.updateRecursiveNodeValue(node, 'shape');
    this.updateMarkerWithClusters();
  }

  async updateRecursiveNodeValue(node, propertyName) {
    return new Promise((resolve) => {
      if (node.hasChildren) {
        node.children.forEach((subcategory) => {
          subcategory.data[propertyName] = node.data[propertyName];
          if (subcategory.hasChildren) {
            subcategory.data[propertyName] = node.data[propertyName];
            subcategory.children.forEach((brand) => {
              if (brand.data.points1) {
                brand.data[propertyName] = node.data[propertyName];
                const points = brand.data.points1;
                points.forEach((point) => {
                  point[propertyName] = node.data[propertyName];
                  this.poiData.forEach((poi) => {
                    if (poi.key === node.data.key) {
                      poi[propertyName] = node.data[propertyName];
                    }
                  });
                });
              }
            });
          } else {
            if (subcategory.data.points1) {
              const points1 = subcategory.data.points1;
              points1.forEach((point) => {
                point[propertyName] = node.data[propertyName];
                this.poiData.forEach((poi) => {
                  if (poi.key === node.data.key || poi.key === point.key) {
                    poi[propertyName] = node.data[propertyName];
                  }
                });
              });
            }
          }
        });
      } else {
        if (node.data.points1) {
          const points2 = node.data.points1;
          points2.forEach((element) => {
            element[propertyName] = node.data[propertyName];
            this.poiData.forEach((poi) => {
              if (poi.key === node.data.key) {
                poi[propertyName] = node.data[propertyName];
              }
            });
          });
        }
      }
      resolve(true);
      this._cdr.markForCheck();
    });
  }

  open(content) {
    this.modalService.open(content);
  }

  getNodeValue(node) {
    this.node = node;
    this.openedModal = this.modalService.open(this.modalContent, { size: 'sm' });
  }

  updateIconShape(node, shape): void {
    node.data.shape = shape;
    this.onShapeChange(node);
  }

  updateColorCode(node): void {
    this.onColorChangeNode(node);
  }

  close() {
    this.openedModal.close();
  }

  trackByItem(index, item) {
    return item;
  }  

  /**
   * start date select event handler
   * @param inst - selected date instance
   */
   onSelectFrom(inst: NgbDateStruct): void {
    this.startDate = inst;
    const monday = NgbDatepickerHelper.convertDateStructToDate(inst);
    this.minEndDate = inst;    
    this.endDayObjCache = {};
    if (monday.getDay() === this.uiControl.defaultInChargeDay) {
      const endDate = new Date(inst.year, inst.month - 1,
        (inst.day + ((7 * this.uiControl.defaultInChargeLength) - 1)));
      this.endDate = NgbDatepickerHelper.convertDateToDateStruct(endDate);
    } else {
      this.endDate = null;
    }    
  }

  /**
   * open start/end date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
   openDatePicker($event: Event, datePicker: NgbInputDatepicker, otherDatePicker: NgbInputDatepicker): void {
    otherDatePicker.close();
    $event.preventDefault();
    $event.stopPropagation();
    datePicker.toggle();
  }

  /**
   * @description get the specific dates property   
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof FilterAreaComponent
   */
   getStartDayProp(date: NgbDateStruct, key: string): boolean {    
    const daystring = this.cacheStartDateObj(date);
    return this.startDayObjCache[daystring][key];
  }
  
  /**
   * @description cache specific days object if is not exists   
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof FilterAreaComponent
   */
   cacheStartDateObj(date: NgbDateStruct): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.startDayObjCache[daystring]) {
      this.startDayObjCache[daystring] = this.prepareStartDayObj(date);
    }
    return daystring;
  }

  /**
   * @description prepare a startDate day object of ngb-datepicker   
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof FilterAreaComponent
   */
   prepareStartDayObj(date: NgbDateStruct) {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };
    const selectedDay: any = NgbDatepickerHelper.convertDateStructToDate(date);
    let minDate = (new Date(NgbDatepickerHelper.convertDateStructToDate(this.campaignStartDate)).setHours(0, 0, 0, 0));
    if (this.showPastDates) {
      minDate = this.getMondayOfWeek(this.uiControl.visualPlannerPastWeeks * 7).setHours(0, 0, 0, 0);
    }    
    if (selectedDay < minDate) {
      obj.isDisabled = true;
    } else {
      let weekNo = selectedDay.getWeek() + 1;
      if (this.uiControl.inchargeContinuousFromLastYear && selectedDay.getFullYear() > 2020) {
        // SM-5949, Should be applied from next year, shold not applied till 2020
        // If last week start of last year was highlighted then first week of current year should not be highlighted
        weekNo = selectedDay.getWeekFromDate(GLOBAL.continuousFromDate) + 1;
      }

      const condition = ((weekNo % this.uiControl.defaultInChargeLength === 0)
        && (selectedDay.getDay() === this.uiControl.defaultInChargeDay));
      obj.isWeekday = condition;
    }
    return obj;
  }

  getMondayOfWeek(noOfDays) {
    const days = noOfDays; // Days you want to subtract
    const date = new Date();
    const last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    const day = last.getDate();
    const month = last.getMonth() + 1;
    const year = last.getFullYear();
    const d = new Date(`${year}-${month}-${day}`);
    const dayF = d.getDay();
    const diff = d.getDate() - dayF + (dayF === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  /**
   * @description get the specific dates property   
   * @param {NgbDateStruct} date current day
   * @param {string} key property key to retrive
   * @returns {boolean}
   * @memberof FilterAreaComponent
   */
   getEndDayProp(date: NgbDateStruct, disabled: boolean, key: string): boolean {
    const daystring = this.cacheEndDateObj(date, disabled);
    return this.endDayObjCache[daystring][key];
  }

  /**
   * end date select event handler
   */
  onSelectTo(inst: NgbDateStruct): void {
    this.endDate = inst;    
  }

  /**
   * @description cache specific days object if is not exists   
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof FilterAreaComponent
   */
   cacheEndDateObj(date: NgbDateStruct, disabled: boolean): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!this.endDayObjCache[daystring]) {
      this.endDayObjCache[daystring] = this.prepareEndDayObj(date, disabled);
    }
    return daystring;
  }

  /**
   * @description prepare a startDate day object of ngb-datepicker   
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof FilterAreaComponent
   */
   prepareEndDayObj(date: NgbDateStruct, disabled: boolean) {
    const obj = {
      isToday: false,
      isDisabled: disabled,
      isWeekday: false
    };

    if (!obj.isDisabled) {
      const today = new Date();
      obj.isToday = NgbDatepickerHelper.isEqual(today, date);

      const selecteddaye = NgbDatepickerHelper.convertDateStructToDate(date);

      let weekNo = selecteddaye.getWeek();
      if (this.uiControl.inchargeContinuousFromLastYear && selecteddaye.getFullYear() > 2020) {
        weekNo = selecteddaye.getWeekFromDate(GLOBAL.continuousFromDate);
      }

      const condition = ((weekNo % this.uiControl.defaultInChargeLength === 0)
        && (selecteddaye.getDay() === (this.uiControl.defaultInChargeDay - 1)));
      obj.isWeekday = condition;
    } else {
      // SM-10730 - To disable the dates with gray color in calendar
      const selecteddaye = NgbDatepickerHelper.convertDateStructToDate(date);
      this.datesToDisableInCalendar.forEach(disabledate => {
        disabledate.setHours(0, 0, 0, 0);
        if( moment(disabledate).format('DD-MM-YYYY') === moment(selecteddaye).format('DD-MM-YYYY')) {
          obj.isDisabled = false;
          return;
        }
      });
    }

    return obj;
  }

  formatDate(date) {
    // Format date from any dateformat to yyyy-MM-dd
    let newDate = moment(date, this.uiControl.dateFormat.toUpperCase()).format(GLOBAL.DATE_PARSE_FORMAT.toUpperCase());    
     return newDate;    
  }

  getCampaignStartEndDates() {
    let campaignStartIndex = _.findIndex(this.resultSummary.columnHeaders, (e) => {
      return e == this.userBundle['workspace.incharge.bric.range.startDate'].toUpperCase();
    });
    let campaignEndIndex = _.findIndex(this.resultSummary.columnHeaders, (e) => {
      return e == this.userBundle['workspace.incharge.bric.range.endDate'].toUpperCase();
    });
     this.campaignStartDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.formatDate(this.resultSummary.targetValues[0][campaignStartIndex])));
     this.campaignEndDate = NgbDatepickerHelper.convertDateToDateStruct(new Date(this.formatDate(this.resultSummary.targetValues[0][campaignEndIndex])));    
  }

  resetDateFilter() {
    this.startDate = null;
    this.endDate = null;
    this.assetData = this.mapHelper['assetData'];
  }

  applyDateFilterSelection() {   
    let startDate = '';
    let endDate = '';    
    startDate = this.startDate ? moment(NgbDatepickerHelper.convertDateStructToDate(this.startDate)).format('YYYY-MM-DD') : '';
    endDate = this.endDate ? moment(NgbDatepickerHelper.convertDateStructToDate(this.endDate)).format('YYYY-MM-DD') : '';
        
    let requestParams: object = {
      'action': 'getFilterResult',
      'bricsCampaignId': GLOBAL.BRIC_CAMPAIGN_ID,
      'startDate': startDate,
      'endDate':  endDate
    };
    this.resultService.getResultData(requestParams).subscribe((data) => {
      if (data.status === 'OK') {        
        this.assetData = data.data;
        this.onResize();
      }
    });    
  }
}