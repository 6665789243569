import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ResultService } from '../result/result.service';
import { DataShareService } from '../core/services/data-share.service';
import { StateService } from '../core/services/state.service';
import { SbModalPopupService } from '../core/components/sb-modal-popup/sb-modal-popup.service';
import { AppNameEnum } from '../core/enum';
import * as _ from 'lodash';
import { Filter, InitialConfigModel, SystemFlags } from '../models';
import { SearchService } from '../search/search.service';
import { GLOBAL } from '../core/utils/app.constant';
import { AppHeaderService } from '../../../../root/app-header/app-header.service';
import { CommercialService } from '../commercial';
import { Subscription } from 'rxjs';
import { LogHelperService } from '../core/services';

@Component({
  selector: 'app-result-map',
  templateUrl: './result-map.component.html',
  styleUrls: ['./result-map.component.css'],
  providers: [ResultService, SbModalPopupService]
})
export class ResultMapComponent implements OnInit, OnDestroy {
  filter: Filter;
  datesToDisableInCalendar: any = [];
  campaignSaveDetails = {
    'campaignName': '',
    'hideFinance': true
  };

  user = {};
  readOnly = false;
  resultData: object = {};
  resultSummary: object = {};
  mapHelper: any = null;
  userBundle: object = {};
  uiControl: object = {};
  resultSummaryLabels: object = {};
  requestParams: object = {
    'action': 'getResultDetail',
    'bricsCampaignId': GLOBAL.BRIC_CAMPAIGN_ID
  };
  activeAccordion: boolean;
  disableBookedConfirmedCampaign = false;
  bookingStatusCancelled = false;
  isLoadedCampaign = false;
  showHidePerformance = false;
  /**
   * It defines initial configuration data.
   */
  public initialConfig: InitialConfigModel;
  public isOnline = true; // whether user is online or not, it is set from header component
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private resultService: ResultService,
    private dataShareService: DataShareService,
    private datePipe: DatePipe,
    private stateService: StateService,
    private searchService: SearchService,
    private appHeaderService: AppHeaderService,
    private commercialService: CommercialService,
    private changeDetectorRef: ChangeDetectorRef,
    private logHelper: LogHelperService
  ) {
    this.isOnline = GLOBAL.isOnline;
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.ngOnInit();
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  ngOnInit() {
    // Map integration goes here
    this.dataShareService.appName = AppNameEnum.result;
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.isLoadedCampaign = SystemFlags.isLoadedCampaign;
    this.loadResultData();
    this.getUserBundleData();
    this.restoreData();
    this.user = this.dataShareService.getUserModel();
    this.activeAccordion = _.isUndefined(this.initialConfig.uiControl.resultDefaultExpandMap) ? true : this.initialConfig.uiControl.resultDefaultExpandMap; // SM-8216    
  }

  getUserBundleData() {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.resultSummaryLabels['target'] = this.userBundle['result.summary.title.target'];
    this.resultSummaryLabels['actual'] = this.userBundle['result.summary.title.actual'];
    this.resultSummaryLabels['error'] = this.userBundle['result.error.coverageAndFrequency'];
  }

  loadResultData() {
    // Availability code goes here
    this.resultService.getResultData(this.requestParams).subscribe((data) => {
      if (data.status === 'OK') {
        this.resultData = data.data;
        this.uiControl = data.data.uiControl;
        if(data.data.columnSummary){
          this.getListOfAvailableDates(Object.entries(data.data.columnSummary))
        }
        // sm-9010 fetching showCCP3LinkButton value from initialConfig
        const { showCCP3LinkButton } = this.dataShareService.getInitialConfigByKey('uiControl');
        this.uiControl['showCCP3LinkButton'] = showCCP3LinkButton ? showCCP3LinkButton : false;

        this.resultSummaryLabels['header'] = data.data.audienceInfo;
        this.resultSummary = this.resultData['resultSummary'];
        this.mapHelper = this.resultData['mapHelper'];
      } else if (data.status === 'KO') {
        this.logHelper.logError(data.message);
      }
    }, (error) => {
      console.log(error);
    });
  }

  onAccordionClick() {
    this.activeAccordion = !this.activeAccordion;
  }

  redirectToWorkspacePage() {
    this.router.navigate(['/workspace']);
  }

  restoreData() {
    this.readOnly = SystemFlags.readOnly;

    this.campaignSaveDetails.campaignName = this.getCampaignTitle();

    // Shreni :: SBRICS :332//

    const campaignSearch = this.stateService.getCampaign();
    if (campaignSearch) {
      if (campaignSearch.bookingStatusId === 4 || campaignSearch.bookingStatusId === 2) { // for SBRICS-405, Nishit
        this.disableBookedConfirmedCampaign = true;
      }
    }
    // VJ SONI : 16Nov2015 : SBRICS-794
    if (!_.isUndefined(campaignSearch.bookingStatusId)) {
      if (campaignSearch.bookingStatusId === 4) {
        this.showHidePerformance = true;
      } else if (campaignSearch.bookingStatusId === 2) {
        this.showHidePerformance = false;
      } else if (campaignSearch.bookingStatusId === 5) {
        this.bookingStatusCancelled = true;
      }
    } else {
      this.showHidePerformance = false;
    }

  }

  /**
   * @description get campaign title from state
   * @author Alkesh Shah
   * @returns
   * @memberof ResultMapComponent
   */
  getCampaignTitle() {
    /// Store the Campaign Title//
    let title = null;

    if (this.stateService.getResultPageObject('common').name) {
      title = this.stateService.getResultPageObject('common').name;
    }

    // Case of Loaded Campaign
    if (this.stateService.getWorkspaceObject('common').campaignDetail) {
      const campaignDetail = this.stateService.getWorkspaceObject('common').campaignDetail;
      if (campaignDetail.title) {
        title = campaignDetail.title;
      }
      if (campaignDetail.campaignTitle) {
        title = campaignDetail.campaignTitle;
      }
    }
    // Case when title is set in Commercial Page//
    if (this.stateService.getCommercialObject('commercialDetails') &&
      this.stateService.getCommercialObject('commercialDetails').title) {
      title = this.stateService.getCommercialObject('commercialDetails').title;
    }
    return title;
  }

  /**
   * @description This funcution will create a title for save, request option etc
   * @author Nishit Parekh
   * @memberof ResultMapComponent
   */
  populateCampainTitle(campaignSaveDetails) {
    let campaignObj;
    let advertizer;
    if (campaignSaveDetails.campaignName === null) {
      if (this.stateService.getCampaign()) {

        const customDate = this.datePipe.transform(new Date(), 'ddMMyy');
        const lastName = this.initialConfig.userData.userName.split('.')[1];
        const userInitials = lastName.substring(0, 1).toUpperCase() + lastName.substring(1, lastName.length);
        campaignObj = this.stateService.getSearchPageObject('selectedText');

        if (campaignObj.advertiserName === undefined) {
          advertizer = '';
        } else {
          advertizer = campaignObj.advertiserName;
        }

        campaignSaveDetails.campaignName = `${customDate} ${userInitials} ${advertizer}`;
      }
    } else {
      const campaignTitle = this.campaignSaveDetails.campaignName.split('_');
      if (campaignTitle[2] === '') {
        campaignObj = this.stateService.getSearchPageObject('selectedText');
        if (campaignObj.advertiserName === undefined) {
          advertizer = '';
        } else {
          advertizer = campaignObj.advertiserName;
        }
        campaignSaveDetails.campaignName = `${campaignTitle[0]} ${campaignTitle[1]} ${advertizer}`;
      }
    }
    return campaignSaveDetails;
  }

  resetUIAndNavigateToSearch() {
    this.searchService.resetCampaign(false, false, true);
    SystemFlags.isLoadedCampaign = false;
    this.appHeaderService.changeResetConfig({ getConfig: true, getBricStructure: true });
    this.commercialService.setGoToOtherPage(true);
    this.dataShareService.activateResultTab(false);
    this.router.navigate(['/search']);
  }

  shouldResetCampaign() {
    const refreshCampaign = this.searchService.resetCampaign(true, true);
    if (refreshCampaign) {
      refreshCampaign.then(() => {
        this.router.navigate(['/search']);
      });
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

   /**
   * @description get list of dates which are to be disabled
   * @returns
   * @memberof ResultMapComponent
   */
   findMissingDates(dates) {
    const uniqDatesArry = dates.filter(
      (date, i, self) =>
        self.findIndex((d) => d.getTime() === date.getTime()) === i
    );
    let missingDates = [];
    for (let i = 0; i < uniqDatesArry.length - 1; i++) {
        let currentDate = new Date(uniqDatesArry[i]);
        let nextDate = new Date(uniqDatesArry[i + 1]);

        while (currentDate.getTime() !== nextDate.getTime()) {
            currentDate.setDate(currentDate.getDate() + 1);
            if (currentDate.getTime() !== nextDate.getTime()) {
                missingDates.push(new Date(currentDate));
            }
        }
    }
    return missingDates;
} 

  /**
   * @description get list of available dates selected
   * @returns
   * @memberof ResultMapComponent
   */
  getListOfAvailableDates(columns: any){
    try {
      let dates = [];
      for (let i = 0; i < columns.length; i++) {
        let currentDate = new Date(columns[i][1].startDate);
        let endDate = new Date(columns[i][1].endDate);
        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
      const datesTobeDisable = this.findMissingDates(dates)
      this.datesToDisableInCalendar = datesTobeDisable
    } catch (error) {
      console.log(error)
    }   
  }
}
