import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { DataShareService } from '../../../core/services/data-share.service';
import { SharedService } from '../../../core/services/shared.service';
import { FilterDataService } from '../../../core/services/filter-data.service';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { BrickBaseService } from '../../../core/services/brick-base.service';
import { LocationBase } from '../location-base';
import * as _ from 'lodash';
import { TreeViewComponent } from '../../../core/components/tree-view/tree-view.component';
import { GLOBAL } from '../../../core/utils/app.constant';
import { TemplateProps } from '../../../models/tree-view';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { FileUploadService } from '../../../core/services';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.css']
})
export class LocationFilterComponent extends LocationBase implements OnInit, AfterViewInit {

  readOnlyModal = false;
  templateProps: TemplateProps;

  @ViewChild(TreeViewComponent)
  public treeViewComponent: TreeViewComponent;
  options = {
    idField: 'id',
    displayField: 'name',
    childrenField: 'child',
    useCheckbox: true,
    scrollContainer: document.body.parentElement
  };
  /**
   * It will take data to configure filter window.
   */
  @Input() resolveObject: any;
  /**
   * It calls event to close filter winodw.
   */
  @Output() closeSidebar = new EventEmitter<string>();
  /**
   * Constructor
   * @param dataShareService Service to share data between compoenents.
   * @param sharedService Common shared service.
   * @param filterDataService Service which used to handle filter data.
   * @param brickBaseService Service for brick-base data.
   * @param logHelper Service for log messages.
   */
  constructor(
    dataShareService: DataShareService,
    sharedService: SharedService,
    brickBaseService: BrickBaseService,
    private filterDataService: FilterDataService,
    private logHelper: LogHelperService,
    objectiveService: ObjectiveService,
    appHeaderService: AppHeaderService,
    public fileUploadService: FileUploadService
  ) {
    super(dataShareService, sharedService, brickBaseService, objectiveService, appHeaderService);
  }

  /**
   * Function which loads initially.
   */
  ngOnInit() {
    this.geoTree = _.cloneDeep(GLOBAL.geoTree);
    const selectedValues = this.resolveObject.SelectedValue && !this.resolveObject.SelectedValue.hasOwnProperty('-99')
      ? this.resolveObject.SelectedValue : {};
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.init(this.resolveObject.ColumnConfig, selectedValues);
    this.showContent(0);
    this.templateProps = {
      displayId: 'id',
      displayName: 'name',
      displayRadioButton: false,
      searchPlaceHolder: this.initialConfig.userBundle.search,
      showId: false,
      allowCustomCheckBoxEvents: false,
    };
  }

  /**
   * To display content of particular selection.
   * @param index Index of selected item.
   */
  showContent(index: number): void {
    this.selectedItem = index;
    this.searchForText = this.initialConfig.userBundle['geoplanner.text.searchFor'] || 'Search for';
    this.searchTitle = this.lookups.lookup[index].displayName;
    setTimeout(() => {
      if (this.treeViewComponent) {
        // @ts-ignore
        this.treeViewComponent.tree.sizeChanged();
      }
    });
  }

  /**
   * To store selected items/data.
   */
  save() {
    if (this.isValidSelection()) {
      if (this.fileList) {
        const selectedValue: any = {};
        this.uploadFiles(this.fileUploadService, selectedValue).then(() => {
          this.filterDataService.setLocation(this.selected, Object.keys(this.fileList).length > 0 ? selectedValue.fileList : undefined);
          this.closeSidebar.emit();
        });
      } else {
        this.filterDataService.setLocation(this.selected, undefined);
        this.closeSidebar.emit();
      }
    } else {
      this.logHelper.logError(this.initialConfig.userBundle['common.error.noDataSelect']);
    }
  }

  /**
   * To close filter window.
   */
  close() {
    this.closeSidebar.emit();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const selectedKey = Object.keys(this.geoTree);
      let selectedValues = [];
      _.map(selectedKey, (obj) => {
        selectedValues = [...this.geoTree[obj], ...selectedValues];
      });
      if (this.treeViewComponent) {
        selectedValues.forEach((node) => {
          this.treeViewComponent.tree.treeModel.getNodeById(node.id).setIsSelected(true);
        });
      }
    }, 100);
  }

  /**
   * @description get selected Treeview Value
   * @author Kishan Patel
   * @memberof LocationModalComponent
   */
  geoTreeViewValue() {
    this.getSelectedNodesFromSate();
    const treeData = this.treeData;
    const selectedProducts = this.selectedNodeIds.map((id) => {
      const productDetails = this.treeViewComponent.getNodeById(id);
      return {
        id: productDetails.data.id,
        name: productDetails.data.name,
      };
    });

    const selectedKey = Object.keys(this.geoTree);
    _.map(selectedKey, (obj) => {
      this.geoTree[obj] = [];
    });

    if (selectedProducts.length > 0) {
      const resultData = _.map(selectedProducts, (data) => {
        return { id: data.id, name: data.name };
      });

      for (const result of resultData) {
        this.loopTreeData(treeData, GLOBAL.geoArea.area106, result);
      }
    }
    Object.assign(this.selected, this.geoTree);

  }

  /**
   * This method gets the selected Nodes from the Sate
   *
   * @memberof
   */
  getSelectedNodesFromSate() {
    const leafNodes = this.treeViewComponent.getSelectedLeafNodeIds();
    this.selectedNodeIds = [];
    for (const key in leafNodes) {
      if (leafNodes.hasOwnProperty(key) && leafNodes[key]) {
        this.getTreeNodes(key);
      }
    }
  }

  /**
   * Method to get the acutal nodes to be represented on UI
   * If all the children are selected, dispaly only the Parent Node
   * @param {*} key
   * @memberof
   */
  getTreeNodes(key) {
    const parent = this.treeViewComponent.getParentNode(key);
    const parentId = parent ? parent['id'] : null;
    if (parentId) {
      if (this.treeViewComponent.getNodeById(parentId)) {
        if (this.treeViewComponent.getNodeById(parentId).isPartiallySelected) {
          this.selectedNodeIds.push(parseInt(key, 10)); // Push the child node
        } else {
          if (!this.selectedNodeIds.includes(parentId)) {
            const parentObj = this.treeViewComponent.getParentNode(parseInt(parentId, 10));
            const parentOfParent = parentObj && parentObj.id ? parentObj.id : null;
            if (parentOfParent) {
              this.getTreeNodes(parentId);
            } else {
              this.selectedNodeIds.push(parentId);
            }
          }
        }
      } else {
        this.selectedNodeIds.push(parseInt(key, 10));
      }
    }
  }

  onSelectNode(_) {
    this.geoTreeViewValue();
  }

  onDeSelectNode(_) {
    this.geoTreeViewValue();
  }

}
