import * as _ from 'lodash';
import { CurrencyFormatPipe } from '../pipes/currency-format.pipe';
import { DecimalPipe } from '@angular/common';
import { DistanceUnit } from '../enum';
import { GLOBAL } from './app.constant';
export class TooltipTextGenerator {
  userBundle = {};
  allSelectionText = 'All Selection';

  /**
   * @description Set Tooltip for channel Brick
   * @author Kishan Patel
   * @param {*} brickName - display birck Name
   * @param {*} selected  - all selected Value
   * @returns {string}
   * @memberof TooltipTextGenerator
   */
  getTooltipForEnvironmentBrick(brickName, selected): string {
    const valuesLocal = selected || {};
    if (Object.keys(valuesLocal).length === 0) {
      return;
    }
    let tooltipString = '<div class="contents">';
    tooltipString = `${tooltipString}<h5>${brickName}</h5>`;
    _.each(valuesLocal, (val) => {
      if (val) {
        _.each(val, (obj) => {
          if (obj) {
            if (Object.keys(obj).length > 0) {
              if (obj.displayCode) {
                tooltipString = `${tooltipString}<div class="marginoffset">${obj.code}</div>`;
              } else {
                tooltipString = `${tooltipString}<div class="marginoffset">${obj.name || obj.lookupName || obj.productName} ${obj.relative ? '<em class="relativeFilterPercentage pl-10">%</em>' : ''}</div>`;
              }
            }
          }
        });
      }
    });
    tooltipString = `${tooltipString}</div>`;
    return tooltipString;
  }

  /**
   * @description Set Tooltip for channel Brick
   * @author Kishan Patel
   * @param {*} lookups - selected lookups
   * @param {*} selected  - all selected Value
   * @returns {string}
   * @memberof TooltipTextGenerator
   */
  getTooltipForFormatLocationChannelBrick(selected, lookups, userBundle): string {
    const valuesLocal = selected || {};
    const fileList = selected.fileList;

    let tooltipString = '<div class="contents">';
    _.each(valuesLocal, (val, key) => {
      const selectedLookup = _.find(lookups, (lookup) => {
        if (lookup.selectionIds) {
          const selectionId = _.find(lookup.selectionIds, (sId) => {
            return sId === Number(key);
          });
          lookup.displayName = userBundle[`ui.workspace.selectionId.${selectionId}.name`];
          return userBundle[`ui.workspace.selectionId.${selectionId}.name`];
        } else {
          if (lookup.selectionId === Number(key)) {
            return lookup['displayName'];
          }
        }
      });
      if (val && val.length) {
        if (selectedLookup) {
          tooltipString = `${tooltipString}<h5>${selectedLookup.displayName}</h5>`;
        }

        _.each(val, (obj) => {
          if (obj) {
            if (Object.keys(obj).length > 0) {
              if (obj.displayCode) {
                tooltipString = `${tooltipString}<div class="marginoffset">${obj.code}</div>`;
              } else {
                tooltipString = `${tooltipString}<div class="marginoffset">${obj.name || obj.lookupName || obj.productName} ${obj.relative ? '<em class="relativeFilterPercentage pl-10">%</em>' : ''}</div>`;
              }
            }
          }
        });
      } else if (fileList && fileList[key]) {
        if (fileList[key].length !== 0 && selectedLookup) {
          tooltipString = `${tooltipString}<h5>${selectedLookup.displayName}</h5>`;
        }
        _.each(fileList[key], (file) => {
          if (Object.keys(file).length > 0) {
            tooltipString = `${tooltipString}<div class="marginoffset">${file.exclude ? '-' : '+'} ${file.userSelectionName}  (${file.totalCount} | ${file.validCount})</div>`;
          }
        });
      }
    });
    tooltipString = `${tooltipString}</div>`;
    return tooltipString;
  }

  /**
   * @description Set Tooltip for Tag
   * @author Kishan Patel
   * @param {*} selectedValues
   * @param {string} heading
   * @returns
   * @memberof TooltipTextGenerator
   */
  getTooltipForTag(selectedValues, heading: string) {
    const selectedTags = [];
    let tooltipString = '<div class="contents">';
    tooltipString = `${tooltipString}<h5>${heading || 'Selected Tags'}</h5>`;
    _.each(selectedValues.allTabSelection, (val) => {
      if (val.length > 0) {
        _.each(val, (tag) => {
          selectedTags.push(tag);
        });
      }
    });
    selectedTags.forEach((element) => {
      tooltipString = `${tooltipString}<div class="marginoffset">${element.tagName} ${element.relative ? '<em class="relativeFilterPercentage pl-10">%</em>' : ''}</div>`;
    });

    tooltipString = `${tooltipString}</div>`;
    return tooltipString;
  }

  /**
   * @description Add Tooltip for Product catalogue
   * @author Kishan Patel
   * @param {*} selectedValues
   * @param {*} userBundle
   * @returns
   * @memberof TooltipTextGenerator
   */
  getTooltipForProductCatalogue(selectedValues, userBundle) {
    let tooltipString = '<div class="contents">';
    tooltipString = `${tooltipString}<h5>${userBundle['workspace.pcm.productId']} : ${selectedValues.idProductCatalogue}</h5>`;
    tooltipString = `${tooltipString}<h5>${userBundle['workspace.pcm.productCatalogueName']} : ${selectedValues.productCatalogueName}</h5>`;
    tooltipString = `${tooltipString}</div>`;
    return tooltipString;
  }

  /**
   * @description tooltip for List Bricks
   * @author Kishan Patel
   * @param {*} selectedValues
   * @param {*} userBundle
   * @returns {string}
   * @memberof TooltipTextGenerator
   */
  getToolTipForList(selectedValues, userBundle): string {
    let excluded = 0;
    let included = 0;
    let totalCountIncluded = 0;
    let totalCountExcluded = 0;
    if (selectedValues.routeFrameIds && Object.keys(selectedValues.routeFrameIds).length > 0) {
      const tempRouteFrameIds = selectedValues.routeFrameIds.requestJSON;

      if (Object.keys(tempRouteFrameIds.exclude).length > 0) {
        excluded += tempRouteFrameIds.exclude.validCount;
        totalCountExcluded += tempRouteFrameIds.exclude.totalCount;
      }
      if (Object.keys(tempRouteFrameIds.include).length > 0) {
        included += tempRouteFrameIds.include.validCount;
        totalCountIncluded += tempRouteFrameIds.include.totalCount;
      }
    }
    if (selectedValues.listUpload && Object.keys(selectedValues.listUpload).length > 0) {
      const listUpload = selectedValues.listUpload;
      listUpload.fileList.forEach((element) => {
        if (element.exclude) {
          excluded += element.validCount;
          totalCountExcluded += element.totalCount;
        } else {
          included += element.validCount;
          totalCountIncluded += element.totalCount;
        }
      });
    }
    const totalCount = totalCountIncluded + totalCountExcluded;
    let tooltipString = '<div class="contents">';
    tooltipString = `${tooltipString}${userBundle['workspace.collection.list.tooltip.rowsInFile']} : ${totalCount}<br />`;
    tooltipString = `${tooltipString}${userBundle['workspace.collection.list.tooltip.validIncludedFrames']} : ${included} <br />`;
    tooltipString = `${tooltipString}${userBundle['workspace.collection.list.tooltip.validExcludedFrames']} : ${excluded}`;
    tooltipString = `${tooltipString}</div>`;
    return tooltipString;
  }

  /**
   * @description tooltip for Multi Target Brick
   * @author Nishit Parekh
   * @param {*} selectedValues
   * @param {*} userBundle
   * @returns {string}
   * @memberof TooltipTextGenerator
   */
  getToolTipForMultiTarget(selectedValues, userBundle: object, enabledPCM: boolean): string {
    let included = 0;
    if (enabledPCM) {
      let tooltipStringVal = '<div class="contents">';
      tooltipStringVal = `${tooltipStringVal}${userBundle['workspace.pcm.bricname.multitarget']}<br />`;
      if (selectedValues) {
        if (selectedValues.allowDays) {
          tooltipStringVal += `${userBundle['workspace.pcm.multiTarget.bric.allowDays']}<br />`;
        }
        if (selectedValues.allowDayParts) {
          tooltipStringVal += `${userBundle['workspace.pcm.multiTarget.bric.allowDayParts']}<br />`;
        }
        if (selectedValues.allowHours) {
          tooltipStringVal += `${userBundle['workspace.pcm.multiTarget.bric.allowHours']}<br />`;
        }
        if (selectedValues.allowSOT) {
          tooltipStringVal += `${userBundle['workspace.pcm.multiTarget.bric.allowSOT']}<br />`;
        }
        if (selectedValues.allowCustomSOT) {
          tooltipStringVal += `${userBundle['workspace.pcm.multiTarget.bric.allowCustomSOT']}`;
        }
        tooltipStringVal = `${tooltipStringVal}</div>`;
        return tooltipStringVal;
      } else {
        return userBundle['workspace.pcm.bricname.multitarget'];
      }
    }
    if (selectedValues.listUpload && Object.keys(selectedValues.listUpload).length > 0) {
      const listUpload = selectedValues.listUpload;
      listUpload.fileList.forEach((element) => {
        included += element.validCount;
      });
    }
    let tooltipString = '<div class="contents">';
    tooltipString = `${tooltipString}${userBundle['workspace.collection.list.tooltip.validIncludedFrames']} : ${included} <br />`;
    tooltipString = `${tooltipString}</div>`;
    return tooltipString;
  }

  /**
   * @description Pricing Tag Tooltip
   * @author Kishan Patel, Amit Mahida
   * @param {*} selectedValues
   * @param {*} userBundle
   * @returns {string}
   * @memberof TooltipTextGenerator
   */
  getToolTipForPricingTagBrick(selectedValues, userBundle): string {
    if (selectedValues.hasOwnProperty('-99')) {
      return;
    }
    let tooltipString = '<div class="contents">';
    const selectionId = 28;
    if (selectedValues && selectedValues[selectionId] && selectedValues[selectionId][0] && selectedValues[selectionId][0].openRateCard) {
      // SM-9077: RateCard feature
      tooltipString += `<h5>${userBundle['workspace.collection.networks.selectedRatecard'] || 'Selected Ratecard'}</h5>`;
    } else {
      tooltipString += `<h5>${userBundle['workspace.collection.networks.selectedNetworks']}</h5>`;
    }

    for (const key in selectedValues) {
      if (!Array.isArray(selectedValues[key]) && selectedValues[key].hasOwnProperty('-99')) {
        return;
      }
      selectedValues[key].forEach((element) => {
        tooltipString += `<div class="marginoffset">${element.networkName}</div>`;
      });
    }

    tooltipString += '</div>';
    return tooltipString;
  }

  /**
   * @description add Tooltip for Channel Bricks
   * @author Kishan Patel
   * @param {*} selectedValues
   * @returns {string}
   * @memberof TooltipTextGenerator
   */
  // getTooltipForChannelBrick(brickName, selectedValues): string {
  //   let tooltipString = '<div class="contents">';
  //   tooltipString = `${tooltipString}<h5>${brickName}</h5>`;
  //   if (isArray(selectedValues)) {
  //     selectedValues.forEach((element) => {
  //       tooltipString = `${tooltipString}<div class="marginoffset">${element.productName}</div>`;
  //     });
  //   }
  //   tooltipString = `${tooltipString}</div>`;
  //   return tooltipString;
  // }

  /**
   * @description add Tool tip for Proximity Bricks
   * @author Kishan Patel
   * @param {*} brickName
   * @param {*} selectedValues
   * @returns {string}
   * @memberof TooltipTextGenerator
   */
  getTooltipForProximityBrick(selectedValues, userBundle): string {
    if (selectedValues.hasOwnProperty('-99')) {
      return;
    }

    const distanceType = {};
    distanceType[DistanceUnit.Meters] = userBundle['common.metres'];
    distanceType[DistanceUnit.KiloMeters] = userBundle['common.kilometres'];
    distanceType[DistanceUnit.Miles] = userBundle['common.miles'];
    let tooltipString = '';
    if (selectedValues.postcodeSelection && (selectedValues.postcodeSelection.selectedModelValues.length > 0
      || selectedValues.postcodeSelection.listUpload.length > 0
      || selectedValues.postcodeSelection.distance.distancevalue != null)) {
      tooltipString = '<div class="contents">';
      tooltipString = `${tooltipString}<h5>${userBundle['workspace.collection.bric.proximity.postcode']}</h5>`;
      if (selectedValues.postcodeSelection.selectedModelValues.length > 0) {
        tooltipString = `${tooltipString}<span><b>${userBundle['workspace.collection.bric.proximity.postcode']}</b> :</span>`;
        selectedValues.postcodeSelection.selectedModelValues.forEach((element) => {
          tooltipString = ` ${tooltipString}<span>${element.postcode}</span>,`;
        });
        tooltipString = tooltipString.substring(0, tooltipString.length - 1);
      }

      if (selectedValues.postcodeSelection.listUploadSelection === true
        && selectedValues.postcodeSelection.listUpload.length > 0) {
        tooltipString = `${tooltipString}<div>`;
        tooltipString = `${tooltipString}<span class="bold">
        ${userBundle['workspace.collection.bric.proximity.postcode']}
        ${userBundle['workspace.collection.proximity.tooltip.count']} :
        ${selectedValues.postcodeSelection.listUpload[0].validCount}</span>`;
        tooltipString = `${tooltipString}</div>`;
      }

      if (selectedValues.postcodeSelection.distance.distancevalue != null) {
        tooltipString = `${tooltipString}<div>`;
        tooltipString = `${tooltipString}<span class="bold">
        ${userBundle['common.distance']}<span>:
        ${selectedValues.postcodeSelection.distance.distancevalue}
        ${distanceType[selectedValues.postcodeSelection.distance.distanceType]}
        </div>`;
      }

      if (selectedValues.postcodeSelection.include === 'true') {
        tooltipString = `${tooltipString}<div class="bold"<span>${userBundle['common.include']}</span></div>`;
      } else {
        tooltipString = `${tooltipString}<div class="bold"<span>${userBundle['common.exclude']}</span></div>`;
      }
      tooltipString = `${tooltipString}</div>`;
    }

    if (selectedValues.points && (selectedValues.points.selectedModelValues.length > 0
      || selectedValues.points.listUpload.length > 0
      || selectedValues.points.distance.distancevalue != null)) {
      tooltipString = `${tooltipString}<div class="contents">`;
      tooltipString = `${tooltipString}<h5>${userBundle['workspace.collection.bric.proximity.points'] || 'Points'}</h5>`;
      if (selectedValues.points.selectedModelValues.length > 0) {
        tooltipString = `${tooltipString}<span><b>${userBundle['workspace.collection.bric.proximity.points'] || 'Points'}</b> :</span>`;
        selectedValues.points.selectedModelValues.forEach((element) => {
          tooltipString = ` ${tooltipString}<span>${element.lat} : ${element.lon}</span>,`;
        });
        tooltipString = tooltipString.substring(0, tooltipString.length - 1);
      }

      if (selectedValues.points.listUploadSelection === true
        && selectedValues.points.listUpload.length > 0) {
        tooltipString = `${tooltipString}<div>`;
        tooltipString = `${tooltipString}<span class="bold">
        ${userBundle['workspace.collection.bric.proximity.points'] || 'Points'}
        ${userBundle['workspace.collection.proximity.tooltip.count']} :
        ${selectedValues.points.listUpload[0].validCount}</span>`;
        tooltipString = `${tooltipString}</div>`;
      }

      if (selectedValues.points.distance.distancevalue != null) {
        tooltipString = `${tooltipString}<div>`;
        tooltipString = `${tooltipString}<span class="bold">
        ${userBundle['common.distance']}<span>:
        ${selectedValues.points.distance.distancevalue}
        ${distanceType[selectedValues.points.distance.distanceType]}
        </div>`;
      }

      if (selectedValues.points.include === 'true') {
        tooltipString = `${tooltipString}<div class="bold"<span>${userBundle['common.include']}</span></div>`;
      } else {
        tooltipString = `${tooltipString}<div class="bold"<span>${userBundle['common.exclude']}</span></div>`;
      }
      tooltipString = `${tooltipString}</div>`;
    }

    if (selectedValues.pointOfInterest && (selectedValues.pointOfInterest.selectedModelValues.length > 0
      || selectedValues.pointOfInterest.distance.distancevalue != null)) {
      tooltipString = `${tooltipString}<div class="contents left">`;
      tooltipString = `${tooltipString}<h5>${userBundle['workspace.collection.bric.proximity.poi']}</h5>`;
      if (selectedValues.pointOfInterest.selectedModelValues.length > 0) {
        tooltipString = `${tooltipString}<div><span class="bold">${userBundle['workspace.collection.bric.proximity.poi.dataSource']}
         : </span>`;
        tooltipString = `${tooltipString}${selectedValues.pointOfInterest.selectedDatasource.poiTypeName} </div>`;
        tooltipString = `${tooltipString}<div><span class="bold">${userBundle['workspace.collection.bric.proximity.poi.attribute']}
        : </span>`;

        if (selectedValues.pointOfInterest.selectedAttribute) {
          tooltipString = `${tooltipString}${selectedValues.pointOfInterest.selectedAttribute.poiAttributeName} </div>`;
        } else {
          tooltipString = `${tooltipString}${selectedValues.pointOfInterest.selectedAttributeTree.name} </div>`;
        }

        tooltipString = `${tooltipString}<div><span class="bold">${userBundle['workspace.collection.bric.proximity.poi.value']}
        : </span>`;
        selectedValues.pointOfInterest.selectedModelValues.forEach((element) => {
          tooltipString = ` ${tooltipString}<span>${element.poiName}</span>,`;
        });
        tooltipString = tooltipString.substring(0, tooltipString.length - 1);

        if (GLOBAL.localSolverEnabled && selectedValues.pointOfInterest.selectedModelValues.filter(s => s.relative).length) {
          tooltipString = `${tooltipString}<div><span class="bold">${userBundle['workspace.collection.bric.proximity.poi.relative'] || 'Relative'}
        : </span>`;
          selectedValues.pointOfInterest.selectedModelValues.forEach((element) => {
            if (element.relative) {
              tooltipString = ` ${tooltipString}<span>${element.poiName}</span>,`;
            }
          });
        }
        tooltipString = tooltipString.substring(0, tooltipString.length - 1);
      }

      if (selectedValues.pointOfInterest.distance.distancevalue != null) {
        tooltipString = `${tooltipString}<div>`;
        tooltipString = `${tooltipString}<span class="bold">
        ${userBundle['common.distance']}<span>:
        ${selectedValues.pointOfInterest.distance.distancevalue}
        ${distanceType[selectedValues.pointOfInterest.distance.distanceType]}
        </div>`;
      }

      if (selectedValues.pointOfInterest.include === 'true') {
        tooltipString = `${tooltipString}<div class="bold"<span>${userBundle['common.include']}</span></div>`;
      } else {
        tooltipString = `${tooltipString}<div class="bold"<span>${userBundle['common.exclude']}</span></div>`;
      }
      tooltipString = `${tooltipString}</div>`;
    }

    return tooltipString;
  }

  getToolTipForAllAudienceBrick(selectedValues, userBundle): string {
    let tooltipString = '<div class="contents">';
    tooltipString += `<h5>${userBundle['workspace.collection.bric.secondaryAudience.heading']}</h5>`;
    if (selectedValues.categories) {
      selectedValues.categories.forEach((element) => {
        tooltipString += `<div class="marginoffset">${element.audienceCategoryName}</div>`;
      });
    }
    tooltipString += '</div>';

    return tooltipString;
  }

  getToolTipForVolumeBrick(selectedValues, userBundle, decimalPipe: DecimalPipe, isPCMEnabled): string {

    let tooltipString = '<div class="contents">';
    tooltipString += `<h5>${userBundle['workspace.rating.bric.volume.subHeading']}</h5>`;
    if (Object.keys(selectedValues).length) {
      if (selectedValues.impressions) {
        const impressionslabel = isPCMEnabled ? userBundle['workspace.rating.bric.volume.impressions-pcm'] : userBundle['workspace.rating.bric.volume.impressions'];
        tooltipString += `<div><span class="">${impressionslabel}: </span>`;
        tooltipString += `${decimalPipe.transform(selectedValues.impressions)} ${selectedValues.relative ? '%' : ''} </div>`;
      }

      if (selectedValues.allocated && !isPCMEnabled) {
        tooltipString += `<div><span class="">${userBundle['workspace.rating.bric.volume.impressionsAllocated.approx']}: </span>`;
        tooltipString += `${decimalPipe.transform(selectedValues.allocated)} </div>`;
      }

      if (selectedValues.minImpressions) {
        tooltipString += `<div><span class="">${userBundle['impression.common.minImpressions']}: </span>`;
        tooltipString += `${decimalPipe.transform(selectedValues.minImpressions)} </div>`;
      }

      if (selectedValues.maxImpressions) {
        tooltipString += `<div><span class="">${userBundle['impression.common.maxImpressions']}: </span>`;
        tooltipString += `${decimalPipe.transform(selectedValues.maxImpressions)} </div>`;
      }

      if (selectedValues.sotFloor != null) {
        tooltipString += `<div><span class="">${userBundle['workspace.common.floor']}: </span>`;
        tooltipString += `${selectedValues.sotFloor} % </div>`;
      }

      if (selectedValues.sotCeiling != null) {
        tooltipString += `<div><span class="">${userBundle['workspace.common.ceiling']}: </span>`;
        tooltipString += `${selectedValues.sotCeiling} % </div>`;
      }

      if (selectedValues.actualFtgTarget && (selectedValues.actualFtgAllocated || selectedValues.actualFtgAllocated === 0)) {
        tooltipString += `<div><span class="">${userBundle['impression.common.actualFtgTarget']}: </span>`;
        tooltipString += `${decimalPipe.transform(selectedValues.actualFtgTarget)} </div>`;
        tooltipString += `<div><span class="">${userBundle['impression.common.actualFtgAllocated']}: </span>`;
        tooltipString += `${decimalPipe.transform(selectedValues.actualFtgAllocated)} </div>`;
      }
    }
    tooltipString += '</div>';

    return tooltipString;
  }

  getToolTipForBudgetBrick(selectedValues, userBundle, currencyPipe: CurrencyFormatPipe, isPcmMode = false): string {
    let tooltipString = '<div class="contents">';
    tooltipString += `<h5>${userBundle['workspace.pcm.export.price']}</h5>`;
    if (selectedValues.hasOwnProperty('-99')) {
      tooltipString += `<div><span class="">${this.allSelectionText} </span></div>`;
    } else if (Object.keys(selectedValues).length) {
      if (selectedValues.price) {
        const priceLabel = isPcmMode ? userBundle['workspace.budget.bric.price.price-pcm'] : userBundle['workspace.budget.bric.price.price'];
        tooltipString += `<div><span class="">${priceLabel}: </span>`;

        if (selectedValues.relative) {
          tooltipString += `${selectedValues.price} %</div>`;
        } else {
          tooltipString += `${currencyPipe.transform(selectedValues.price)} </div>`;
        }
      }

      if (selectedValues.allocated) {
        tooltipString += `<div><span class="">${userBundle['workspace.budget.bric.price.priceAllocated.approx']}: </span>`;
        tooltipString += `${currencyPipe.transform(selectedValues.allocated)} </div>`;
      }

      if (selectedValues.minPrice) {
        tooltipString += `<div><span class="">${userBundle['workspace.common.minPrice']}: </span>`;
        tooltipString += `${currencyPipe.transform(selectedValues.minPrice)} </div>`;
      }

      if (selectedValues.maxPrice) {
        tooltipString += `<div><span class="">${userBundle['workspace.common.maxPrice']}: </span>`;
        tooltipString += `${currencyPipe.transform(selectedValues.maxPrice)} </div>`;
      }

      if (selectedValues.sotFloor != null) {
        tooltipString += `<div><span class="">${userBundle['workspace.common.floor']}: </span>`;
        tooltipString += `${selectedValues.sotFloor} % </div>`;
      }

      if (selectedValues.sotCeiling != null) {
        tooltipString += `<div><span class="">${userBundle['workspace.common.ceiling']}: </span>`;
        tooltipString += `${selectedValues.sotCeiling} % </div>`;
      }
    }
    tooltipString += '</div>';

    return tooltipString;
  }

  getToolTipForPrimaryAudience(selectedValues, bricText): string {
    let tooltipString = '<div class="contents">';
    tooltipString += `<h5>${bricText}</h5>`;
    if (selectedValues.hasOwnProperty('-99')) {
      tooltipString += `<div><span class="">${this.allSelectionText} </span></div>`;
    } else {
      Object.keys(selectedValues).forEach((key) => {
        selectedValues[key].forEach((audienceCategory) => {
          tooltipString += `<div class="marginoffset">${audienceCategory.audienceCategoryName}</div>`;
        });
      });
      tooltipString += '</div>';
    }
    return tooltipString;
  }
}
