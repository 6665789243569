import { Row } from './row';
import { CampaignParameters } from './processBrick';
import { Filter } from './filters';
import * as _ from 'lodash';
import { ProductHelper } from './product';
import { ProductCatalogue } from '../productCatalogue';

export class HistoryStackData {
  lockButtons: boolean[];
  lockAllButton: boolean;
  rows: Row[];
  brickRequestJSON: any[];
  campaignParameters: CampaignParameters;
  productDetails: ProductHelper[];
  productCatalogue: ProductCatalogue;
  productEditMode = false; // PCM Mode: Flag to identify the EditMode for Product
  isPastColumnHidden = false;
  allocationEngines: string[] = [];

  constructor(filter: Filter) {
    filter.removeHighlighters();
    this.rows = _.cloneDeep(filter.rows);
    this.brickRequestJSON = _.cloneDeep(filter.brickRequestJSON);
    this.lockAllButton = _.cloneDeep(filter.lockAllButton);
    this.campaignParameters = _.cloneDeep(filter.campaignParameters);
    this.productDetails = _.cloneDeep(filter.productDetails);
    this.productCatalogue = _.cloneDeep(filter.productCatalogue);
    this.productEditMode = filter.productEditMode;
    this.isPastColumnHidden = filter.isPastColumnHidden;
    this.allocationEngines = _.cloneDeep(filter.allocationEngines);
  }
}
