import { ViewChild, Directive } from '@angular/core';
import { TreeViewComponent } from '../../core/components/tree-view/tree-view.component';
import { DataShareService } from '../../core/services/data-share.service';
import { LogHelperService } from '../../core/services/log-helper.service';
import { DistanceConverterService } from '../../core/services/distance-converter.service';
import { ProximityFilterService } from './proximity-filter.service';
import { PoiType, UiControl } from './../../models/initial-config.model';
import { ProximityData, ProximityDistance } from './../../models/workspace/proximity-window';
import { DistanceUnit, ProximityTypeSelection } from '../../core/enum';
import { IActionMapping, ITreeState, TreeNode } from '@circlon/angular-tree-component';
import { CellValues } from '../../models';
import { POIHolder } from '../../models/initial-config.model';
import { TemplateProps } from '../../models/tree-view';
import * as _ from 'lodash';
import { GLOBAL } from '../../core/utils/app.constant';
import { ATTRIBUTE_IDS } from './attribute-ids';
import { LoaderService } from '../../core/services';

@Directive()
export class ProximityBaseDirective {

  @ViewChild(TreeViewComponent) treeViewComponent: TreeViewComponent;

  userBundle: any;
  uiControl: UiControl;
  selectedValue: any;
  /**
   * @description master datafor poi type dropdowns
   * @type {PoiType}
   * @memberof ProximityBase
   */
  poiTypes: PoiType[] = [];
  /**
   * @description Proximity object to store data for POI & Postcodes
   * @type {*}@memberof ProximityBase
   */
  proximity: ProximityData;

  /**
   * @description Hold the cached file object
   * @type {*}@memberof ProximityBase
   * @author Amit Mahida
   */
  fileInput: any;

  /**
   * @description Hold the cached file object for points list upload
   * @type {*}
   * @memberof ProximityBase
   */
  pointsFileInput: any;

  /**
   * @description To maintain the readonly state of the sidebar
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  readOnlyModal = false;
  /**
   * @description enum for Distance unit e.g. Meters, Kms, Miles
   * @memberof ProximityBase
   */
  distanceUnit = DistanceUnit;
  /**
   * @description enum for Proximity TypeSelection i.e. Postcode, POI, Points
   * @memberof ProximityBase
   */
  proximityTypeSelection = ProximityTypeSelection;

  currentTopSelectedNodeIndex = -1;

  nodeIds: string[] = [];

  showSelected = false;

  visibleNodesId: string[] = [];

  poiData: any = [];

  objectKeys = Object.keys;

  state: ITreeState;
  public treeData: POIHolder[] = [];

  actionMapping: IActionMapping = {
    mouse: {
      checkboxClick: (__, node: TreeNode) => {
        if (!node) {
          return;
        }
        this.treeViewComponent.handleLabelClick(node);
      }
    }
  };

  treeOptions = {
    idField: 'id',
    displayField: 'poiName',
    childrenField: 'poi',
    useCheckbox: true,
    nodeClass: (node: TreeNode) => {
      return (this.displayOnlySelected(node));
    },
    useVirtualScroll: true,
    nodeHeight: 34,
    scrollContainer: document.body.parentElement,
    actionMapping: this.actionMapping
  };

  templateProps: TemplateProps = {
    displayId: 'id',
    displayName: 'poiName',
    displayRadioButton: false,
    searchPlaceHolder: '',
    showId: false,
    allowCustomCheckBoxEvents: true,
    localSolverEnabled: GLOBAL.localSolverEnabled
  };

  treeAttributeIds = [
    ATTRIBUTE_IDS.category,
    ATTRIBUTE_IDS.subcategory,
    ATTRIBUTE_IDS.brand
  ];
  isPOITabLoaded = false;
  readonly CONNECTING = 'common.error.connecting';
  readonly DISTANCE_FOR_POSTCODE = 'common.error.proximity.distanceForPostcode';
  readonly DISTANCE_FOR_POSTCODE_STR = 'Please enter distance for selected postcodes!';
  readonly RADIO_SELECTION = 'workspace.error.proxmimity.radioSelection';

  constructor(
    private dataShareService: DataShareService,
    public proximityService: ProximityFilterService,
    public logHelperService: LogHelperService,
    private distanceConverterService: DistanceConverterService,
    private loaderService: LoaderService,
  ) { }

  /**
   * @description If poiEnhancedTree is true, show only selected node when reopen saved modal.If poiEnhancedTree is false, only enable same lavel of selected node. (SM-8090)
   * @returns 'hide' or '' if poiEnhancedTree otherwise 'disable-node' or ''.
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  displayOnlySelected(node: TreeNode): string {
    if (this.uiControl.poiEnhancedTree) {
      if (this.showSelected && Object.keys(this.selectedValue).length > 0 && !this.selectedValue.hasOwnProperty('-99') && this.treeViewComponent && this.treeViewComponent.tree) {
        const isSelected = false;
        if (this.treeViewComponent.searchText !== '' && node.data.poiName.toLowerCase().includes(this.treeViewComponent.searchText.toLowerCase()) && this.treeViewComponent.searchedNodesId.indexOf(node.id) === -1) {
          this.treeViewComponent.searchedNodesId.push(node.id);
          this.showChildNode(node);
        }
        return '';
      } else {
        if (this.visibleNodesId.indexOf(node.id) === -1) {
          this.visibleNodesId.push(node.id);
        }

        if (this.treeViewComponent && this.treeViewComponent.searchText !== ''
          && node.data.poiName.toLowerCase().includes(this.treeViewComponent.searchText.toLowerCase())
          && this.treeViewComponent.searchedNodesId.indexOf(node.id) === -1) {
          this.treeViewComponent.searchedNodesId.push(node.id);
          this.showChildNode(node);
        }
        return '';
      }
    } else {
      return (
        (this.proximity.pointOfInterest.selectedAttributeId &&
          this.proximity.pointOfInterest.selectedAttributeId !== node.level) ? 'disable-node' : '');
    }
  }

  /**
   * @description For show all nested chield nodes.
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  showChildNode(node) {
    if (node.hasChildren) {
      node.children.forEach((chieldNode) => {
        if ((this.showSelected && this.treeViewComponent.tree.treeModel.selectedLeafNodeIds.hasOwnProperty(chieldNode.id)) || !this.showSelected) {
          chieldNode.show();
        }
        this.showChildNode(chieldNode);
      });
    }
  }

  /**
   * @description Check, is parent selected.
   * @returns true If parent node is selected.
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  checkIsParentSelected(node): boolean {
    let isSelected = false;
    if (node.realParent) {
      const nodeData = node.realParent.data;
      if (_.findIndex(this.proximity.pointOfInterest.selectedModelValues, (selectedNode) => { return _.isMatch(selectedNode, nodeData); }) > -1) {
        isSelected = true;
      } else {
        isSelected = this.checkIsParentSelected(node.realParent);
      }
    }
    return isSelected;
  }

  init(selected: any) {
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.poiTypes = this.dataShareService.getInitialConfigByKey('poiTypes');
    this.selectedValue = selected || {};
    if (this.selectedValue != null && Object.keys(this.selectedValue).length > 0 && !this.selectedValue.hasOwnProperty('-99')) {
      this.showSelected = true;
      this.setSelectedValues();
    } else {
      this.showSelected = false;
      this.proximity = new ProximityData();
      if (this.poiTypes && this.poiTypes.length) {
        this.proximity.pointOfInterest.attributeData = this.poiTypes[0].poiAttributes;
        this.proximity.pointOfInterest.datasource = [{
          poiTypeId: this.poiTypes[0].poiTypeId,
          poiTypeName: this.poiTypes[0].poiTypeName
        }];
        // Default selection for Attribute dropdown in proximity
        this.proximity.pointOfInterest.selectedAttributeId = this.uiControl.proximityPOITree ? null : this.proximity.pointOfInterest.attributeData[0].poiAttributeId;
        this.proximity.pointOfInterest.selectedAttribute = this.uiControl.proximityPOITree ? null : this.proximity.pointOfInterest.attributeData[0];
        this.proximity.pointOfInterest.selectedDatasourceId = this.proximity.pointOfInterest.datasource[0].poiTypeId;
        this.proximity.pointOfInterest.selectedDatasource = this.proximity.pointOfInterest.datasource[0]; // For Datascource dropdown
      }
      this.proximity.fileName = this.userBundle['proximity.uploadFile'];
      this.proximity.pointsFileName = this.userBundle['proximity.uploadFile'];
    }
    this.setProximityPoiTree();
  }

  setSelectedValues() {
    this.proximity = this.selectedValue;
    this.proximity.file = this.selectedValue.postcodeSelection.file;
    this.proximity.pointsFile = this.selectedValue.points.file;
    if (this.selectedValue.postcodeSelection.listUpload
      && this.selectedValue.postcodeSelection.listUpload.length > 0) {
      this.proximity.fileName = this.selectedValue.fileName
        || this.selectedValue.postcodeSelection.listUpload[0].userSelectionName;
    }

    if (this.selectedValue.points.listUpload
      && this.selectedValue.points.listUpload.length > 0) {
      this.proximity.pointsFileName = this.selectedValue.pointsFileName
        || this.selectedValue.points.listUpload[0].userSelectionName;
    }
  }

  setProximityPoiTree() {
    if (this.uiControl.proximityPOITree) {
      this.templateProps.searchPlaceHolder = this.userBundle.search;
      this.treeData = [];
      this.getPointOfInterestTreeData();
      this.proximity.pointOfInterest.attributeTreeData = [
        {
          id: 1,
          name: _.map(
            _.filter(
              this.poiTypes[0].poiAttributes,
              (val: any) => (this.treeAttributeIds.includes(val.poiAttributeId))
            ),
            (val: any) => val.poiAttributeName
          ).join(' / ')
        },
        {
          id: 2,
          name: _.map(
            _.filter(
              this.poiTypes[0].poiAttributes,
              (val: any) => (val.poiAttributeId === ATTRIBUTE_IDS.location)
            ),
            (val: any) => val.poiAttributeName
          ).join(' / ')
        }
      ];
      this.proximity.pointOfInterest.attributeTreeData = this.proximity.pointOfInterest.attributeTreeData.filter(val => val.name !== '');
      if (!this.proximity.pointOfInterest.selectedAttributeTreeId) {
        this.proximity.pointOfInterest.selectedAttributeTreeId = 1;
      }
    }
  }

  /**
   * Initialize Proximity
   */
  initializeProximity() {
    if (this.selectedValue != null && Object.keys(this.selectedValue).length > 0 && !this.selectedValue.hasOwnProperty('-99')) {
      this.setSelectedValues();
    } else {
      this.proximity = new ProximityData();
      if (this.poiTypes && this.poiTypes.length) {
        this.proximity.pointOfInterest.attributeData = this.poiTypes[0].poiAttributes;
        this.proximity.pointOfInterest.datasource = [{
          poiTypeId: this.poiTypes[0].poiTypeId,
          poiTypeName: this.poiTypes[0].poiTypeName
        }];
        // Default selection for Attribute dropdown in proximity
        this.proximity.pointOfInterest.selectedAttributeId = this.uiControl.proximityPOITree ? null : this.proximity.pointOfInterest.attributeData[0].poiAttributeId;
        this.proximity.pointOfInterest.selectedAttribute = this.uiControl.proximityPOITree ? null : this.proximity.pointOfInterest.attributeData[0];
        this.proximity.pointOfInterest.selectedDatasourceId = this.proximity.pointOfInterest.datasource[0].poiTypeId;
        this.proximity.pointOfInterest.selectedDatasource = this.proximity.pointOfInterest.datasource[0]; // For Datascource dropdown
      }
      this.proximity.fileName = this.userBundle['proximity.uploadFile'];
      this.proximity.pointsFileName = this.userBundle['proximity.uploadFile'];
    }
    this.setProximityPoiTree();
  }

  showContent(index: number): void {
    if (
      index === 1 &&
      !this.isPOITabLoaded &&
      this.uiControl.proximityPOITree &&
      this.treeAttributeIds.includes(Number(this.proximity.pointOfInterest.selectedAttributeId))
    ) {
      this.isPOITabLoaded = true;
      setTimeout(() => {
        if (this.treeViewComponent) {
          if (this.proximity.pointOfInterest && this.proximity.pointOfInterest.selectedModelValues && this.proximity.pointOfInterest.selectedModelValues.length > 0) {
            const selectedModelValues = [];
            this.proximity.pointOfInterest.selectedModelValues.forEach((selected) => {
              for (const ele of this.treeData) {
                if (ele.poiId === selected.poiId && ele.poiName === selected.poiName) {
                  if (GLOBAL.localSolverEnabled && selected.relative) {
                    ele['relative'] = selected.relative;
                    this.updateRelativeFilterRecursively(ele, selected.relative);
                  }
                  selectedModelValues.push(ele);
                }
                if (Array.isArray(ele.poi)) {
                  for (const e of ele.poi) {
                    if (e.poiId === selected.poiId && e.poiName === selected.poiName) {
                      if (GLOBAL.localSolverEnabled && selected.relative) {
                        e['relative'] = selected.relative;
                        this.updateRelativeFilterRecursively(e, selected.relative);
                      }
                      selectedModelValues.push(e);
                    }
                    if (Array.isArray(e.poi)) {
                      for (const f of e.poi) {
                        if (f.poiId === selected.poiId && f.poiName === selected.poiName) {
                          if (GLOBAL.localSolverEnabled && selected.relative) {
                            f['relative'] = selected.relative;
                            this.updateRelativeFilterRecursively(f, selected.relative);
                          }
                          selectedModelValues.push(f);
                        }
                      }
                    }
                  }
                }
              }
            });
            this.treeData = _.cloneDeep(this.treeData);
            this.proximity.pointOfInterest.selectedModelValues = _.cloneDeep(_.uniqBy(selectedModelValues, 'id'));
          }
          Object.keys(this.treeViewComponent.tree.treeModel.selectedLeafNodeIds).forEach((key) => {
            this.treeViewComponent.tree.treeModel.getNodeById(key).setIsSelected(false);
          });
          this.state = {
            ...this.state,
            selectedLeafNodeIds: {}
          };
          if (this.showSelected) {
            this.proximity.pointOfInterest.selectedModelValues.forEach((data) => {
              const node = this.treeViewComponent.tree.treeModel.getNodeById(data.id);
              if (this.visibleNodesId.indexOf(node.id) === -1) {
                this.visibleNodesId.push(node.id);
              }
              if (node.realParent) {
                this.expandParentNode(node);
              }
            });
          }

          this.loaderService.show();
          setTimeout(async () => {
            this.proximity.pointOfInterest.selectedModelValues.forEach((data) => {
              const emptyITreeState: ITreeState = { selectedLeafNodeIds: {} };
              const { selectedLeafNodeIds } = this.treeViewComponent.state || emptyITreeState;
              selectedLeafNodeIds[data[this.templateProps.displayId]] = true;
              this.treeViewComponent.addSelectionLeafNodeRecursive(data[this.treeViewComponent.options.childrenField], selectedLeafNodeIds, data.relative);
              this.treeViewComponent.state = {
                ...this.treeViewComponent.state,
                selectedLeafNodeIds: Object.assign({}, selectedLeafNodeIds)
              };
            });
            this.loaderService.hide();
          }, 800);
        }
      }, (this.proximity.pointOfInterest && this.proximity.pointOfInterest.selectedModelValues && this.proximity.pointOfInterest.selectedModelValues.length > 0 && !this.treeViewComponent) ? 4500 : 100);
    }
  }

  /**
   * Show Content for POI Tree
   * Campaign must be loaded campaign
   */
  showContentForPOITreeLoadedCampaign() {
    let selectedModelValues = [];
    this.proximity.pointOfInterest.selectedModelValues.forEach((selected) => {
      this.treeData.forEach((ele) => {
        if (this.isSamePOI(ele, selected)) {
          selectedModelValues.push(ele);
        }
        const nestedPOI = this.showContentFromNestedPOI(ele, selected);
        selectedModelValues = selectedModelValues.concat(nestedPOI);
      });
    });
    this.proximity.pointOfInterest.selectedModelValues = _.cloneDeep(_.uniqBy(selectedModelValues, 'poiId'));
  }

  /**
   * To show Content From Nested POI List
   * @param ele Element
   * @param selected Selected Model value
   */
  showContentFromNestedPOI(ele: any, selected: any): Array<any> {
    const selectedModelValues = [];
    if (Array.isArray(ele.poi)) {
      ele.poi.forEach((e) => {
        if (this.isSamePOI(e, selected)) {
          selectedModelValues.push(e);
        }
        if (Array.isArray(e.poi)) {
          e.poi.forEach((f) => {
            if (this.isSamePOI(f, selected)) {
              selectedModelValues.push(f);
            }
          });
        }
      });
    }
    return selectedModelValues;
  }

  /**
   * TO check selected POI is same or not
   * @param element Element
   * @param selected Selected Model value
   */
  isSamePOI(element: any, selected: any): boolean {
    if (element.poiId === selected.poiId && element.poiName === selected.poiName) {
      return true;
    }
    return false;
  }

  /**
   * @description Validates POI data
   * @returns true if POI tab is valid else false
   * @memberof ProximityBase
   * @author Amit Mahida, Nishit Parekh
   */
  validationForPointOfInterest(): boolean {
    const distancevalue = this.proximity.pointOfInterest.distance.distancevalue;
    const len = this.proximity.pointOfInterest.selectedModelValues.length;

    if ((distancevalue && len) || (!distancevalue && !len)) {
      return true;
    } else if (len && !distancevalue) {
      this.logHelperService.logError(this.userBundle['worksapce.error.proximity.poi.distance']);
      return false;
    } else if (distancevalue && !len) {
      this.logHelperService.logError(this.userBundle['worksapce.error.proximity.poi.options']);
      return false;
    }
  }

  /**
   * @description Sets file id if its a list upload
   * @param {any} data
   * @param {any} idselection
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  setFileid(data, idselection: number): void {
    if (data != null) {
      switch (idselection) {
        case ProximityTypeSelection.PostCode: {
          this.setFileIdForPostCodeOrPoints(data, 'postcodeSelection');
          break;
        }
        case ProximityTypeSelection.Points: {
          this.setFileIdForPostCodeOrPoints(data, 'points');
          break;
        }
        case ProximityTypeSelection.POI: {
          this.proximity.pointOfInterest.userSelectionId = data.userSelectionId;
          break;
        }
      }
    } else {
      this.logHelperService.logError(this.userBundle[this.CONNECTING]);
    }
  }

  /**
   * Set FileId For PostCode Or Points
   * @param data Data
   * @param key It can be either 'postcodeSelection' or 'points'
   */
  setFileIdForPostCodeOrPoints(data, key: 'postcodeSelection' | 'points') {
    if (data.validCount >= 0 && data.userSelectionName) {
      this.proximity[key].listUpload[0] = data;
    } else if (this.proximity[key].listUploadSelection
      && this.proximity[key].distance.distancevalue > 0) {
      this.proximity[key].listUpload = this.selectedValue[key].listUpload;
    }
    this.proximity[key].userSelectionId = data.userSelectionId;
  }

  /**
   * @description Sets values to pass if the data is selected only for one i.e either POI or Postcodes
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  setModelValuesForPostcode(): void {
    if (this.proximity.postcodeSelection.listUploadSelection) {
      this.proximity.postcodeSelection.selectedModelValues = [];
    } else {
      this.proximity.postcodeSelection.listUpload = [];
    }
  }

  /**
   * @description Sets values to pass if the data is selected only for one i.e either POI or Points
   * @author Amit Mahida
   * @memberof ProximityBase
   */
  setModelValuesForPoints(): void {
    if (this.proximity.points.listUploadSelection) {
      this.proximity.points.selectedModelValues = [];
    } else {
      this.proximity.points.listUpload = [];
    }
  }

  /**
   * @description Function to set selection Id fo selected model values
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  setSelectionId(): void {
    if (Object.keys(this.selectedValue).length > 0) {
      if ((this.proximity.postcodeSelection.listUpload.length === 0)
        && (this.proximity.postcodeSelection.selectedModelValues.length === 0)) {
        this.proximity.postcodeSelection.userSelectionId = null;
      }

      if ((this.proximity.points.listUpload.length === 0)
        && (this.proximity.points.selectedModelValues.length === 0)) {
        this.proximity.points.userSelectionId = null;
      }

      if ((this.proximity.pointOfInterest.selectedModelValues.length === 0)) {
        this.proximity.pointOfInterest.userSelectionId = null;
      }
    }
  }

  /**
   * @description Generated finalised proximity object to send in request params.
   * @returns @proximityObj
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  proximityRequestObj(): object {
    if ((!this.uiControl.proximityPOITree || !this.proximity.pointOfInterest.poiAttributes.length || this.proximity.pointOfInterest.selectedAttributeId === 4)
      && this.proximity.pointOfInterest.selectedModelValues) {
      this.proximity.pointOfInterest.poiAttributes = [
        {
          poiAttributeId: Number(this.proximity.pointOfInterest.selectedAttributeId),
          poi: this.proximity.pointOfInterest.selectedModelValues.map(p => p.poiId)
        }
      ];
    }
    const proximityObj = {
      poiTypeId: this.proximity.pointOfInterest.selectedDatasource.poiTypeId,
      satisfyAll: this.proximity.pointOfInterest.radioButtons.selection === 0,
      distance: parseFloat(this.proximity.pointOfInterest.distance.distancevalue.toString()),
      unit: this.proximity.pointOfInterest.distance.distanceType,
      include: this.proximity.pointOfInterest.include,
      action: 'postcode',
      poiAttributes: this.proximity.pointOfInterest.poiAttributes
    };
    if (this.proximity.pointOfInterest.poiAttributes && this.proximity.pointOfInterest.poiAttributes.length) {
      proximityObj.action = 'poi';
    }

    return proximityObj;
  }

  /**
   * @description Checks for valid postcodes
   * @returns true if postcodes value is valid else false
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  validationForPostcode(): boolean {
    if ((this.proximity.postcodeSelection.selectedModelValues.length > 0 || this.proximity.postcodeSelection.file)) {
      if (this.proximity.postcodeSelection.distance.distancevalue) {
        return true;
      } else {
        const msg = this.userBundle[this.DISTANCE_FOR_POSTCODE]
          || this.DISTANCE_FOR_POSTCODE_STR;
        this.logHelperService.logError(msg);
        return false;
      }

    } else {
      const msg = this.userBundle['common.error.proximity.valueForPostcode']
        || 'Please provide values for postcodes to continue!';
      this.logHelperService.logError(msg);
      return false;
    }
  }

  /**
   * @description Checks for valid points
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof ProximityBase
   */
  validationForPoints(): boolean {
    if ((this.proximity.points.selectedModelValues.length > 0 || this.proximity.points.file)) {
      if (this.proximity.points.distance.distancevalue) {
        return true;
      } else {
        const msg = this.userBundle['common.error.proximity.distanceForPoints']
          || 'Please enter distance for selected points!';
        this.logHelperService.logError(msg);
        return false;
      }

    } else {
      const msg = this.userBundle['common.error.proximity.valueForPoints']
        || 'Please provide values for points to continue!';
      this.logHelperService.logError(msg);
      return false;
    }
  }

  /**
   * @description Resets the selected values for POI
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  resetOptions(): void {
    const selectedAttribute = this.proximity.pointOfInterest.attributeData.filter((val) => {
      return val.poiAttributeId.toString() === this.proximity.pointOfInterest.selectedAttributeId;
    });
    this.proximity.pointOfInterest.selectedAttribute = selectedAttribute[0];
    this.proximity.pointOfInterest.selectedModelValues = [];
  }

  /**
   * @description Resets the selected values for POI treeView
   * @memberof ProximityFilterComponent
   * @author Amit Mahida
   */
  resetOptionsTree() {
    const selectedAttributeTree = this.proximity.pointOfInterest.attributeTreeData.filter((val) => {
      return Number(val.id) === Number(this.proximity.pointOfInterest.selectedAttributeTreeId);
    });
    this.proximity.pointOfInterest.selectedAttributeTree = selectedAttributeTree[0];
    this.proximity.pointOfInterest.selectedModelValues = [];
    this.proximity.pointOfInterest.poiAttributes = [];
    if (this.proximity.pointOfInterest.selectedAttributeTree.id === 2) {
      this.proximity.pointOfInterest.selectedAttributeId = ATTRIBUTE_IDS.location;
      const selectedAttribute = this.proximity.pointOfInterest.attributeData.filter((val) => {
        return Number(val.poiAttributeId) === Number(this.proximity.pointOfInterest.selectedAttributeId);
      });
      this.proximity.pointOfInterest.selectedAttribute = selectedAttribute[0];
    } else {
      this.proximity.pointOfInterest.selectedAttributeId = null;
      this.proximity.pointOfInterest.selectedAttribute = null;
    }
    if (this.treeViewComponent) {
      this.treeViewComponent.tree.treeModel.selectedLeafNodeIds = {};
      this.state = {
        ...this.state,
        selectedLeafNodeIds: {}
      };
      if (this.treeViewComponent.searchText === '') {
        this.treeViewComponent.tree.treeModel.collapseAll();
      }
      this.showSelected = false;
      this.currentTopSelectedNodeIndex = -1;
      this.treeData = this.generatePOITreeData();
    } else {
      this.showSelected = false;
      this.currentTopSelectedNodeIndex = -1;
      this.treeData = this.generatePOITreeData();
    }

    this.restoreRelativeSelection();
  }

  /**
   * @description Makes service call to get matching postcodes for the provided search key
   * @param {string} query
   * @returns
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  loadPostCode = (query: string) => {
    const data: any = new Object();
    data.postcode = query;

    return this.proximityService.getPostcode(data).map((response: any) => {
      if (response.data) {
        const datapostcode = response.data.postcode;
        return datapostcode.filter((element: any) => {
          return element.postcode.toLowerCase().replace(' ', '').indexOf(query.toLowerCase().replace(' ', '')) !== -1;
        });
      } else {
        return [];
      }
    });
  }

  /**
   * @description Post code auto complete lookup matching callback function of ngx-chips
   * @memberof ProximityBase
   * @author Alkesh Shah
   */
  postCodeMatchingFn = (): boolean => {
    // data is already filtered by back-end so it will always return true
    return true;
  }

  /**
   * @description Handles the radio button state for POI
   * @param {any} event
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  categoryAddRemoveTags(): void {
    if (this.proximity.pointOfInterest.selectedModelValues.length > 1) {
      if (this.proximity.pointOfInterest.radioButtons.selection == 0) {
        this.proximity.pointOfInterest.radioButtons.selection = 0;
      } else {
        this.proximity.pointOfInterest.radioButtons.selection = 1;
      }
      this.proximity.pointOfInterest.radioButtons.disabled = false;
    } else {
      this.proximity.pointOfInterest.radioButtons.selection = null;
      this.proximity.pointOfInterest.radioButtons.disabled = true;
    }
  }

  /**
   * @description Observable method to provide data to attributes in POI
   * @param {string} query
   * @returns
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  loadAttributeData = (query: string) => {
    const data = {
      poiTypeId: this.proximity.pointOfInterest.selectedDatasource.poiTypeId,
      poiAttributeId: this.proximity.pointOfInterest.selectedAttribute.poiAttributeId,
      poiName: query
    };
    return this.proximityService.getPointOfInterest(data)
      .map((response: any) => {
        return response.data.poi.filter((element: any) => {
          return element.poiName.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
      });
  }

  /**
   * @description Updates distance value based on distance unit selection
   * @param {any} type
   * @param {any} tab
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  onChangeDistanceType(type: string, proximityType: number): void {
    switch (proximityType) {
      case ProximityTypeSelection.PostCode:
        this.proximity.postcodeSelection.distance.distanceType = type;
        break;
      case ProximityTypeSelection.Points:
        this.proximity.points.distance.distanceType = type;
        break;
      default:
        this.proximity.pointOfInterest.distance.distanceType = type;
    }

    let ptype: ProximityDistance;
    switch (Number(this.proximity.typeSelection)) {
      case ProximityTypeSelection.PostCode:
        ptype = this.proximity.postcodeSelection.distance;
        break;
      case ProximityTypeSelection.Points:
        ptype = this.proximity.points.distance;
        break;
      default:
        ptype = this.proximity.pointOfInterest.distance;
    }

    const olddistanceValueType: string = ptype.olddistanceType;
    const newdistanceValueType: string = ptype.distanceType;
    ptype.distanceType = newdistanceValueType;
    if (ptype.distancevalue != null && olddistanceValueType !== newdistanceValueType) {
      const newDistance = this.generateNewDistance(ptype);
      // Note : Very Imp to maintain the old and new distance type of each control
      ptype.olddistanceType = ptype.distanceType;
      ptype.distancevalue = parseFloat(newDistance) || 0;
    } else {
      ptype.olddistanceType = ptype.distanceType;
    }
  }

  /**
   * Generate new distance
   * @param ptype ProximityDistance
   */
  generateNewDistance(ptype: ProximityDistance): any {
    const olddistanceValueType: string = ptype.olddistanceType;
    const newdistanceValueType: string = ptype.distanceType;
    const currentDistance = ptype.distancevalue;
    let newDistance = null;
    switch (newdistanceValueType) {
      case DistanceUnit.Meters:
        if (olddistanceValueType === DistanceUnit.KiloMeters) {
          newDistance = parseInt(this.distanceConverterService.fromKmToMeter(currentDistance).toString(), 10);
        } else if (olddistanceValueType === DistanceUnit.Miles) {
          newDistance = parseInt(this.distanceConverterService.fromMilesToMeter(currentDistance).toString(), 10);
        }
        break;
      case DistanceUnit.KiloMeters:
        if (olddistanceValueType === DistanceUnit.Meters) {
          newDistance = this.distanceConverterService.fromMeterToKm(currentDistance);
        } else if (olddistanceValueType === DistanceUnit.Miles) {
          newDistance = this.distanceConverterService.fromMilesToKm(currentDistance);
        }
        break;
      case DistanceUnit.Miles:
        if (olddistanceValueType === DistanceUnit.Meters) {
          newDistance = this.distanceConverterService.fromMeterToMiles(currentDistance);
        } else if (olddistanceValueType === DistanceUnit.KiloMeters) {
          newDistance = this.distanceConverterService.fromKmToMiles(currentDistance);
        }
        break;
      default:
        break;
    }
    return newDistance;
  }

  /**
   * @description Handles visibility of file upload / tag input for postcode selection.
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  postcodeSelectionChange(selectedValue): void {
    if (!selectedValue) {
      this.proximity.postcodeSelection.listUploadSelection = false;
      this.proximity.postcodeSelection.disablelistupload = 'true';
      this.proximity.postcodeSelection.listUpload = [];
    } else {
      this.proximity.postcodeSelection.listUploadSelection = true;
      this.proximity.postcodeSelection.disablelistupload = 'false';
      this.proximity.postcodeSelection.selectedModelValues = [];
    }
  }

  /**
   * @description  Handles visibility of file upload / tag input for points selection.
   * @author Amit Mahida
   * @param {*} selectedValue
   * @memberof ProximityBase
   */
  pointsSelectionChange(selectedValue): void {
    if (!selectedValue) {
      this.proximity.points.listUploadSelection = false;
      this.proximity.points.disablelistupload = 'true';
      this.proximity.points.listUpload = [];
    } else {
      this.proximity.points.listUploadSelection = true;
      this.proximity.points.disablelistupload = 'false';
      this.proximity.points.selectedModelValues = [];
    }
  }

  /**
   * @description Saves file locally to maintain file object after reopening proximity sidebar
   * @param {any} event
   * @memberof ProximityBase
   * @author Amit Mahida
   */
  savefilelocally(event) {
    this.fileInput = event.file[0];
    this.proximity.fileName = event.file[0].name;
    this.proximity.postcodeSelection.listUploadSelection = true;
    this.proximity.postcodeSelection.disablelistupload = 'false';
  }

  /**
   * @description  Saves file locally to maintain file object after reopening proximity for points
   * @author Amit Mahida
   * @param {*} event
   * @memberof ProximityBase
   */
  savePointsfilelocally(event) {
    this.pointsFileInput = event.file[0];
    this.proximity.pointsFileName = event.file[0].name;
    this.proximity.points.listUploadSelection = true;
    this.proximity.points.disablelistupload = 'false';
  }

  proximityPostcodeResCallback = (data: any, event, values: CellValues) => {
    if (data.status === 'OK') {
      this.setFileid(data.data, ProximityTypeSelection.PostCode);
      this.setModelValuesForPostcode();
      this.setSelectionId();
      // if no poi data and no points, close modal popup
      if (!(this.proximity.pointOfInterest.selectedModelValues.length || this.proximity.pointOfInterest.distance.distancevalue) && !(this.proximity.points.listUpload.length || this.proximity.points.selectedModelValues.length || this.proximity.points.file)) {
        this.closeComponent(event, values);
      }
    } else {
      this.logHelperService.logError(this.userBundle[this.CONNECTING]);
    }
  }

  proximityPointsResCallback = (data: any, event, values: CellValues) => {
    if (data.status === 'OK') {
      this.setFileid(data.data, ProximityTypeSelection.Points);
      this.setModelValuesForPoints();
      this.setSelectionId();
      // if no poi data and no postcode, close modal popup
      if (!(this.proximity.pointOfInterest.selectedModelValues.length || this.proximity.pointOfInterest.distance.distancevalue) && !(this.proximity.postcodeSelection.listUpload.length || this.proximity.postcodeSelection.selectedModelValues.length || this.proximity.postcodeSelection.file)) {
        this.closeComponent(event, values);
      }
    } else {
      this.logHelperService.logError(this.userBundle[this.CONNECTING]);
    }
  }

  /**
   * @description call serive to get selection id and send post code to backend
   * @author Nishit Parekh
   * @param {*} event in case of modal pop up it will send event and in geo mapper, it will set msg
   * @param {*} values in case of modal pop up, it will send values object, not needed for geo mpapper
   * @param {*} checkValidation check if validationForPostcode method needs to be considered
   * @param {*} close this willl decide weather to close modal pop up or filter window
   * @returns Promise
   * @memberof ProximityBase
   */
  async postCodeDataUpload(event, values: CellValues, checkValidation: boolean): Promise<boolean | any> {
    return new Promise((resolve) => {
      const proximityObjNew = {
        distance: parseFloat(this.proximity.postcodeSelection.distance.distancevalue.toString()),
        unit: this.proximity.postcodeSelection.distance.distanceType,
        include: this.proximity.postcodeSelection.include,
        postcode: [],
        action: 'postcode'
      };
      this.proximity.postcodeSelection.selectedModelValues.forEach((obj) => {
        proximityObjNew.postcode.push(obj.postcodeId);
      });

      if (!checkValidation || this.validationForPostcode()) {
        this.proximityService.getProximityBrickResponseWithoutFile(proximityObjNew,
          this.proximity.postcodeSelection.userSelectionId).subscribe((data: any) => {
            this.proximityPostcodeResCallback(data, event, values);
            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }

  /**
   * @description call serive to get selection id and send file uploaded to backend
   * @author Nishit Parekh
   * @param {*} event in case of modal pop up it will send event and in geo mapper, it will set msg
   * @param {*} values in case of modal pop up, it will send values object, not needed for geo mpapper
   * @param {*} checkValidation check if validationForPostcode method needs to be considered
   * @param {*} close this willl decide weather to close modal pop up or filter window
   * @returns Promise
   * @memberof ProximityBase
   */

  async postCodeFileUpload(event, values: CellValues, checkValidation: boolean): Promise<boolean | any> {
    return new Promise((resolve) => {
      const proximityObjNew = {
        distance: parseFloat(this.proximity.postcodeSelection.distance.distancevalue.toString()),
        unit: this.proximity.postcodeSelection.distance.distanceType,
        include: this.proximity.postcodeSelection.include,
        action: 'postcode'
      };
      if (!checkValidation || this.validationForPostcode()) {
        this.proximityService.getProximityBrickResponse(this.proximity.postcodeSelection.file,
          proximityObjNew, this.proximity.postcodeSelection.userSelectionId).subscribe((data: any) => {
            this.proximityPostcodeResCallback(data, event, values);
            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }

  /**
   * @description call serive to get selection id and send points to backend
   * @author Amit Mahida
   * @param {*} event
   * @param {*} values
   * @param {*} checkValidation
   * @param {*} close
   * @returns {(void | boolean)}
   * @memberof ProximityBase
   */
  async pointsDataUpload(event, values: CellValues, checkValidation: boolean): Promise<boolean | any> {
    return new Promise((resolve) => {
      const proximityObjNew = {
        distance: parseFloat(this.proximity.points.distance.distancevalue.toString()),
        unit: this.proximity.points.distance.distanceType,
        include: this.proximity.points.include,
        points: [],
        action: 'points'
      };
      proximityObjNew.points = this.proximity.points.selectedModelValues;

      if (!checkValidation || this.validationForPoints()) {
        this.proximityService.getProximityBrickResponseWithoutFile(proximityObjNew,
          this.proximity.points.userSelectionId).subscribe((data: any) => {
            this.proximityPointsResCallback(data, event, values);
            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }

  /**
   * @description call serive to get selection id and send file uploaded to backend for points
   * @author Amit Mahida
   * @param {*} event
   * @param {*} values
   * @param {*} checkValidation
   * @param {*} close
   * @returns {(void | boolean)}
   * @memberof ProximityBase
   */
  async pointsFileUpload(event, values: CellValues, checkValidation: boolean): Promise<boolean | any> {
    return new Promise((resolve) => {
      const proximityObjNew = {
        distance: parseFloat(this.proximity.points.distance.distancevalue.toString()),
        unit: this.proximity.points.distance.distanceType,
        include: this.proximity.points.include,
        action: 'points'
      };
      if (!checkValidation || this.validationForPoints()) {
        this.proximityService.getProximityBrickResponse(this.proximity.points.file,
          proximityObjNew, this.proximity.points.userSelectionId).subscribe((data: any) => {
            this.proximityPointsResCallback(data, event, values);
            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }

  setFileData() {
    if (this.fileInput) {
      this.proximity.postcodeSelection.file = this.fileInput;
    } else {
      this.proximity.postcodeSelection.file = this.proximity.file;
    }
  }

  setPointsFileData() {
    if (this.pointsFileInput) {
      this.proximity.points.file = this.pointsFileInput;
    } else {
      this.proximity.points.file = this.proximity.pointsFile;
    }
  }

  closeComponent(data?, values?): void {
    console.log(data, values);
  }

  addPoints() {
    if (this.proximity.points.latitude && this.proximity.points.longitude) {
      this.proximity.points.selectedModelValues.push({ lat: this.proximity.points.latitude, lon: this.proximity.points.longitude });
      this.proximity.points.latitude = '';
      this.proximity.points.longitude = '';
    } else {
      this.logHelperService.logError(this.userBundle['common.error.proximity.valueForBothPoints']);
    }
  }

  removePoint(index) {
    this.proximity.points.selectedModelValues.splice(index, 1);
  }

  getPointOfInterestTreeData() {
    this.proximityService.getPointOfInterestTreeData().subscribe((response: any) => {
      if (response && response.data) {
        this.poiData = _.sortBy(response.data, [function (poiData) { return poiData[0].poiCategoryName.toLowerCase(); }]);
        this.treeData = this.generatePOITreeData();
        this.restoreRelativeSelection();
        if (Number(this.proximity.typeSelection) === ProximityTypeSelection.POI && this.proximity.pointOfInterest.selectedModelValues.length > 0 && !this.proximity.pointOfInterest.selectedModelValues.hasOwnProperty('-99')) {
          this.showContent(Number(this.proximity.pointOfInterest.selectedAttributeTreeId));
        }
      }
    });
  }

  generatePOITreeData(): any[] {
    const returnedData = [];
    for (const category of this.poiData) {
      let iscategorySelected = false;
      category[1] = _.sortBy(category[1], [function (cat) { return cat[0].poiSubCategoryName.toLowerCase(); }]);
      const treeCat = {
        id: `C_${category[0].poiCategoryId}`,
        poiId: category[0].poiCategoryId,
        poiName: category[0].poiCategoryName,
        poi: [],
        relative: false
      };
      for (const subcategory of category[1]) {
        let isSubcategorySelected = false;
        subcategory[1] = _.sortBy(subcategory[1], [function (subcat) { return subcat.poiBrandName.toLowerCase(); }]);
        if (subcategory[0].poiSubCategoryId && subcategory[0].poiSubCategoryId !== 0) {
          const treeSubCat = {
            id: `S_${subcategory[0].poiSubCategoryId}`,
            poiId: subcategory[0].poiSubCategoryId,
            poiName: subcategory[0].poiSubCategoryName,
            poi: [],
            relative: false
          };
          for (const brand of subcategory[1]) {
            if (brand.poiBrandId && brand.poiBrandId !== 0) {
              const treebrand = {
                id: `B_${brand.poiBrandId}`,
                poiId: brand.poiBrandId,
                poiName: brand.poiBrandName,
                relative: false
              };

              const lastMatchingIndexAll = (_.findLastIndex(this.proximity.pointOfInterest.selectedModelValues, (obj) => {
                return (obj['poiId'] === treebrand.poiId && obj['poiName'] === treebrand.poiName
                  || obj['poiId'] === treeSubCat.poiId && obj['poiName'] === treeSubCat.poiName
                  || obj['poiId'] === treeCat.poiId && obj['poiName'] === treeCat.poiName);
              }));

              if (this.showSelected && lastMatchingIndexAll > -1) {
                isSubcategorySelected = true;
                this.nodeIds.push(treebrand.id);
                treeSubCat.poi.push(treebrand);
              } else if (!this.showSelected) {
                this.nodeIds.push(treebrand.id);
                treeSubCat.poi.push(treebrand);
              }
            }
          }

          const lastMatchingIndex = (_.findLastIndex(this.proximity.pointOfInterest.selectedModelValues, (obj) => {
            return (obj['poiId'] === treeSubCat.poiId && obj['poiName'] === treeSubCat.poiName
              || obj['poiId'] === treeCat.poiId && obj['poiName'] === treeCat.poiName);
          }));

          if (this.showSelected && (isSubcategorySelected || lastMatchingIndex > -1)) {
            iscategorySelected = true;
            this.nodeIds.push(treeSubCat.id);
            treeCat.poi.push(treeSubCat);
          } else if (!this.showSelected) {
            this.nodeIds.push(treeSubCat.id);
            treeCat.poi.push(treeSubCat);
          }
        }
      }

      const lastMatchingPoiIndex = (_.findLastIndex(this.proximity.pointOfInterest.selectedModelValues, (obj) => {
        return (obj['poiId'] === treeCat.poiId && obj['poiName'] === treeCat.poiName);
      }));

      if ((this.showSelected && (iscategorySelected || lastMatchingPoiIndex > -1)) || !this.showSelected) {
        this.nodeIds.push(treeCat.id);
        returnedData.push(treeCat);
      }
    }
    return returnedData;
  }

  initPOIRelative(index) {
    if (!this.proximity.pointOfInterest.poiAttributes[index].poiRelative) {
      this.proximity.pointOfInterest.poiAttributes[index].poiRelative = [];
    }
  }

  managePOIAttributes() {
    this.proximity.pointOfInterest.poiAttributes = [];
    if (this.proximity.pointOfInterest.selectedModelValues && this.proximity.pointOfInterest.selectedModelValues.length) {
      // Category
      this.proximity.pointOfInterest.poiAttributes.push({
        poiAttributeId: 1,
        poi: []
      });
      // Sub Category
      this.proximity.pointOfInterest.poiAttributes.push({
        poiAttributeId: 2,
        poi: []
      });
      // Brand
      this.proximity.pointOfInterest.poiAttributes.push({
        poiAttributeId: 3,
        poi: []
      });
      if (GLOBAL.localSolverEnabled) {
        this.initPOIRelative(0);
        this.initPOIRelative(1);
        this.initPOIRelative(2);
      }
      for (const selectedValue of this.proximity.pointOfInterest.selectedModelValues) {
        if (selectedValue.id.indexOf('C_') > -1) {
          // Category
          this.proximity.pointOfInterest.poiAttributes[0].poi.push(selectedValue.poiId);
          if (GLOBAL.localSolverEnabled && selectedValue.relative) {
            this.proximity.pointOfInterest.poiAttributes[0].poiRelative.push(selectedValue.poiId);
          }
        } else if (selectedValue.id.indexOf('S_') > -1) {
          // Sub Category
          this.proximity.pointOfInterest.poiAttributes[1].poi.push(selectedValue.poiId);
          if (GLOBAL.localSolverEnabled && selectedValue.relative) {
            this.proximity.pointOfInterest.poiAttributes[1].poiRelative.push(selectedValue.poiId);
          }
        } else {
          // Brand
          this.proximity.pointOfInterest.poiAttributes[2].poi.push(selectedValue.poiId);
          if (GLOBAL.localSolverEnabled && selectedValue.relative) {
            this.proximity.pointOfInterest.poiAttributes[2].poiRelative.push(selectedValue.poiId);
          }
        }
      }
    }

    for (let index = this.proximity.pointOfInterest.poiAttributes.length - 1; index >= 0; index--) {
      const poiAttr = this.proximity.pointOfInterest.poiAttributes[index];
      if (!poiAttr.poi.length) {
        this.proximity.pointOfInterest.poiAttributes.splice(index, 1);
      }
    }

  }

  /**
   * @description When parent node select, tree-component will return only last lavel childs. but, we need to select parent not a child.
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  selectActualNode(node) {
    if (node.realParent && node.realParent.isAllSelected) {
      this.selectActualNode(node.realParent);
    } else {
      if (!this.proximity.pointOfInterest.selectedModelValues.hasOwnProperty(node.data)) {
        this.proximity.pointOfInterest.selectedModelValues.push(node.data);
      }
      this.removeSelectedChieldNodesData(node.data, true);
    }
  }

  onSelectNode(node) {
    if (Number(this.proximity.pointOfInterest.selectedAttributeTreeId) !== 1) {
      return;
    }
    if (node.hasOwnProperty('node')) {
      node = node.node;
    }
    if (!this.showSelected) {
      this.selectActualNode(node);
    }
    if (this.proximity.pointOfInterest.selectedModelValues.length > 0) {
      this.proximity.pointOfInterest.selectedAttributeId = node.level;
      this.proximity.pointOfInterest.selectedAttribute = this.proximity.pointOfInterest.attributeData
        .filter((item: any) => item.poiAttributeId === node.level)[0];
    } else {
      this.proximity.pointOfInterest.selectedAttributeId = null;
      this.proximity.pointOfInterest.selectedAttribute = null;
      this.showSelected = false;
      if (this.treeViewComponent.searchText === '') {
        this.treeViewComponent.tree.treeModel.collapseAll();
      }
    }

    this.categoryAddRemoveTags();
    this.proximity.pointOfInterest.selectedModelValues = _.uniqBy(_.sortBy(this.proximity.pointOfInterest.selectedModelValues, [(selectedModelValue) => {
      return _.findIndex(this.nodeIds, nodeId => selectedModelValue.id === nodeId);
    }]) as TreeNode[], (sortedSelectedModelValue) => {
      return sortedSelectedModelValue.id;
    });
    this.managePOIAttributes();
  }

  onDeSelectNode(node) {
    if (Number(this.proximity.pointOfInterest.selectedAttributeTreeId) !== 1) {
      return;
    }
    if (node.hasOwnProperty('node')) {
      node = node.node;
    }
    const isParentSelected = this.checkIsParentSelected(node);
    if (isParentSelected) {
      this.autoSelectSiblingNode(node);
    }
    this.removeLeafParentAtDeselect(node, true);
    this.removeSelectedChieldNodesData(node.data, true);
    this.proximity.pointOfInterest.selectedModelValues = this.proximity.pointOfInterest.selectedModelValues.filter(item => item.id !== node.data.id);
    if (this.showSelected) {
      if (this.visibleNodesId.indexOf(node.id) === -1) {
        this.visibleNodesId.splice(this.visibleNodesId.indexOf(node.id), 1);
      }
    }
    if (this.proximity.pointOfInterest.selectedModelValues.length > 0) {
      this.proximity.pointOfInterest.selectedAttributeId = node.level;
      this.proximity.pointOfInterest.selectedAttribute = this.proximity.pointOfInterest.attributeData
        .filter((item: any) => item.poiAttributeId === node.level)[0];
    } else {
      this.proximity.pointOfInterest.selectedAttributeId = null;
      this.proximity.pointOfInterest.selectedAttribute = null;
      this.showSelected = false;
      if (this.treeViewComponent.searchText === '') {
        this.treeViewComponent.tree.treeModel.collapseAll();
      }
    }
    this.treeData = this.generatePOITreeData();
    this.restoreRelativeSelection();
    this.categoryAddRemoveTags();
    this.proximity.pointOfInterest.selectedModelValues = _.uniqBy(_.sortBy(this.proximity.pointOfInterest.selectedModelValues, [(selectedModelValue) => {
      return _.findIndex(this.nodeIds, nodeId => selectedModelValue.id === nodeId);
    }]) as TreeNode[], (sortedSelectedModelValue) => {
      return sortedSelectedModelValue.id;
    });
    this.managePOIAttributes();
  }

  /**
   * @description If parent node is selected, chiled node need to remove from selectedModelValues because, chield node by default selected if psrent is selected.(SM-8090)
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  removeSelectedChieldNodesData(nodeData, isSelf) {
    if (nodeData.poi) {
      nodeData.poi.forEach((chieldNode) => {
        this.removeSelectedChieldNodesData(chieldNode, false);
      });
    }
    if (!isSelf) {
      this.proximity.pointOfInterest.selectedModelValues = this.proximity.pointOfInterest.selectedModelValues.filter(item => item.id !== nodeData.id);
    }
  }

  /**
   * @description When parent is selected and deselect child node then remove all parent leaf node and deselected child node from selectedLeafNodeIds.
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  removeLeafParentAtDeselect(node, isSelf) {
    if (node.realParent) {
      if (this.treeViewComponent.tree.treeModel.selectedLeafNodeIds.hasOwnProperty(node.realParent.id)) {
        this.removeLeafParentAtDeselect(node.realParent, false);
        delete this.treeViewComponent.tree.treeModel.selectedLeafNodeIds[node.realParent.id];
      }
    }
    if (isSelf && this.treeViewComponent.tree.treeModel.selectedLeafNodeIds.hasOwnProperty(node.id)) {
      delete this.treeViewComponent.tree.treeModel.selectedLeafNodeIds[node.id];
    }
  }

  /**
   * @description When parent is selected and deselect child node then deSelect all parent node and select all child's sibling node.
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  autoSelectSiblingNode(node) {
    if (node.realParent) {
      const isParentSelected = this.checkIsParentSelected(node.realParent);
      if (isParentSelected) {
        this.autoSelectSiblingNode(node.realParent);
      }
      const nodeData = node.realParent.data;
      nodeData.poi.forEach((chieldNode: TreeNode) => {
        this.proximity.pointOfInterest.selectedModelValues.push(chieldNode);
      });
      this.proximity.pointOfInterest.selectedModelValues = this.proximity.pointOfInterest.selectedModelValues.filter(item => item.id !== node.realParent.data.id);
    }
  }

  /**
   * @description Focus next selected node.(SM-8090)
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  focusOnNextSelectedNode() {
    const viewport = this.treeViewComponent.tree.treeModel.virtualScroll.viewport;
    const selectedModelValues = this.proximity.pointOfInterest.selectedModelValues;
    if (selectedModelValues.length && this.currentTopSelectedNodeIndex < Number(selectedModelValues.length - 1)) {
      const node: TreeNode = this.treeViewComponent.tree.treeModel.getNodeById(selectedModelValues[this.currentTopSelectedNodeIndex + 1].id);
      let nodePosition = node.position;
      if (node.realParent) {
        this.expandParentNode(node);
      }
      this.treeViewComponent.tree.treeModel.setFocusedNode(node);
      if (this.showSelected && Object.keys(this.selectedValue).length > 0 && this.visibleNodesId.indexOf(node.id) !== -1) {
        this.visibleNodesId = _.sortBy(this.visibleNodesId, [(visibleNodeId) => {
          return _.findIndex(this.nodeIds, nodeId => visibleNodeId === nodeId);
        }]);
        nodePosition = ((this.visibleNodesId.indexOf(node.id) + 1) * 36) + 2;
      }
      let showSelected = 2;
      if (this.showSelected) {
        showSelected = 3;
      }
      viewport.scrollTop = nodePosition > viewport.clientHeight ?
        nodePosition - (((nodePosition - 2) / node.height) + (node.height * showSelected))
        : 0;
      this.currentTopSelectedNodeIndex++;
    }
  }

  /**
   * @description Focus previous selected node.(SM-8090)
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  focusOnPreviousSelectedNode() {
    const viewport = this.treeViewComponent.tree.treeModel.virtualScroll.viewport;
    const selectedModelValues = this.proximity.pointOfInterest.selectedModelValues;
    if (selectedModelValues.length && this.currentTopSelectedNodeIndex > 0) {
      const node: TreeNode = this.treeViewComponent.tree.treeModel.getNodeById(selectedModelValues[this.currentTopSelectedNodeIndex - 1].id);
      let nodePosition = node.position;
      if (node.realParent) {
        this.expandParentNode(node);
      }
      this.treeViewComponent.tree.treeModel.setFocusedNode(node);
      if (this.showSelected && Object.keys(this.selectedValue).length > 0) {
        this.visibleNodesId = _.sortBy(this.visibleNodesId, [(visibleNodeId) => {
          return _.findIndex(this.nodeIds, nodeId => visibleNodeId === nodeId);
        }]);
        nodePosition = ((this.visibleNodesId.indexOf(node.id) + 1) * 36) + 2;
      }
      let showSelected = 2;
      if (this.showSelected) {
        showSelected = 3;
      }
      viewport.scrollTop = nodePosition > viewport.clientHeight ?
        nodePosition - (((nodePosition - 2) / node.height) + (node.height * showSelected))
        : 0;
      this.currentTopSelectedNodeIndex--;
    }
  }

  /**
   * @description expand all parents node hirarchy of selected chield node to redirect next and previous selected node. (SM-8090)
   * @memberof ProximityBase
   * @author Nikunj Gadhiya
   */
  expandParentNode(node: TreeNode) {
    const parentNode = node.realParent;
    if (this.visibleNodesId.indexOf(node.id) === -1) {
      this.visibleNodesId.push(node.id);
    }
    if (parentNode.realParent) {
      this.expandParentNode(parentNode);
    }
    if (!parentNode.isExpanded) {
      parentNode.expand();
    }
  }

  onChangeProximityType(typeSelection) {
    this.proximity.typeSelection = _.cloneDeep(typeSelection);
    if (Object.keys(this.selectedValue).length > 0 && !this.selectedValue.hasOwnProperty('-99')) {
      this.selectedValue.typeSelection = _.cloneDeep(typeSelection);
      this.isPOITabLoaded = false;
      if (this.selectedValue != null && Object.keys(this.selectedValue).length > 0 && !this.selectedValue.hasOwnProperty('-99')) {
        this.setSelectedValues();
      }
      this.setProximityPoiTree();
    } else if (this.proximity.pointOfInterest && this.proximity.pointOfInterest.selectedModelValues && this.proximity.pointOfInterest.selectedModelValues.length > 0) {
      this.isPOITabLoaded = false;
      this.setProximityPoiTree();
    }
  }

  updateRelativeFilterRecursively(selectedValue, relative) {
    if (selectedValue.poi && selectedValue.poi.length) {
      for (const poi of selectedValue.poi) {
        poi.relative = relative;
        this.updateRelativeFilterRecursively(poi, relative);
      }
    }
  }

  relativeFiltersChange(node) {
    for (const selectedValue of this.proximity.pointOfInterest.selectedModelValues) {
      if (node.data.id === selectedValue.id) {
        selectedValue.relative = node.data.relative;
        this.updateRelativeFilterRecursively(selectedValue, node.data.relative);
      }
    }
    this.managePOIAttributes();
  }

  onShowSelected() {
    this.treeData = this.generatePOITreeData();
    this.restoreRelativeSelection();
  }

  restoreRelativeSelection() {
    if (this.proximity && this.proximity.pointOfInterest && this.proximity.pointOfInterest.selectedModelValues
      && this.proximity.pointOfInterest.selectedModelValues.length) {
      this.proximity.pointOfInterest.selectedModelValues.filter(s => s.relative).forEach((selected) => {
        const relativeObj = this.treeData.filter(f => f['id'] === selected.id);
        if (relativeObj && relativeObj.length) {
          relativeObj[0].relative = true;
          this.updateRelativeFilterRecursively(relativeObj[0], true);
        } else {
          let secondLevel = this.treeData.filter(f => f.poi && f.poi.length && f.poi.filter(c => c['id'] === selected.id).length);
          if (secondLevel && secondLevel.length) {
            secondLevel = secondLevel[0].poi.filter(s => s['id'] === selected.id);
            secondLevel[0].relative = true;
            this.updateRelativeFilterRecursively(secondLevel[0], true);
          } else {
            const thirdLevel = this.treeData.filter(f => f.poi && f.poi.length && f.poi.filter(d => d.poi && d.poi.length && d.poi.filter(e => e['id'] === selected.id).length).length);
            if (thirdLevel && thirdLevel.length) {
              thirdLevel[0].relative = true;
              this.updateRelativeFilterRecursively(thirdLevel[0], true);
            }
          }
        }
      });
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  trackByPoiType(index, item) {
    return item?.poiTypeId;
  }

  trackByPoiAttribute(index, item) {
    return item?.poiAttributeId;
  }

  trackById(index, item) {
    return item?.id;
  }
}
