<div class="bounceInRight animated workspace innerpage">
  <div [ngSwitch]="environmentId">
    <ng-template [ngSwitchCase]="environmentCountyId.UK">
      <app-commercial-uk #commercialUK></app-commercial-uk>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.UK2">
      <app-commercial-uk #commercialUK></app-commercial-uk>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.US">
      <app-commercial-us #commercialUS></app-commercial-us>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.AE">
      <app-commercial-ae #commercialAE></app-commercial-ae>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.Germany">
      <app-commercial-germany #commercialGermany></app-commercial-germany>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.Denmark">
      <app-commercial-denmark #commercialDenmark></app-commercial-denmark>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.HKAI">
      <app-commercial-hk #commercialHK></app-commercial-hk>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.HKCTS">
      <app-commercial-hk #commercialHK></app-commercial-hk>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.HKM">
      <app-commercial-hk #commercialHK></app-commercial-hk>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.Italy">
      <app-commercial-italy #commercialIT></app-commercial-italy>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.AU">
      <app-commercial-au #commercialAU></app-commercial-au>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.SG">
      <app-commercial-sg #commercialSG></app-commercial-sg>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.Belgium">
      <app-commercial-be #commercialBE></app-commercial-be>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.FI">
      <app-commercial-fi #commercialFi></app-commercial-fi>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.CN">
      <app-commercial-shanghai #commercialCN></app-commercial-shanghai>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.NL">
      <app-commercial-nl #commercialNL></app-commercial-nl>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.Norway">
      <app-commercial-norway #commercialNOR></app-commercial-norway>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.BR">
      <app-commercial-br #commercialBR></app-commercial-br>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.ES">
      <app-commercial-es #commercialES></app-commercial-es>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.CNSHA">
      <app-commercial-cnsha #commercialCNSHA></app-commercial-cnsha>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.CNGA">
      <app-commercial-cnga #commercialCnga></app-commercial-cnga>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.AT">
      <app-commercial-at #commercialAT *ngIf="uiControl.enableNewCommercialConfiguration"></app-commercial-at>
      <app-commercial-default #commercialDefault *ngIf="!uiControl.enableNewCommercialConfiguration"></app-commercial-default>
    </ng-template>
    <ng-template [ngSwitchCase]="environmentCountyId.PT">
      <app-commercial-pt #commercialPT *ngIf="uiControl.enableNewCommercialConfiguration"></app-commercial-pt>
      <app-commercial-default #commercialDefault *ngIf="!uiControl.enableNewCommercialConfiguration"></app-commercial-default>
    </ng-template>
    <ng-template [ngSwitchDefault]>
      <app-commercial-default #commercialDefault></app-commercial-default>
    </ng-template>
  </div>
</div>