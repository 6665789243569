<ng-container *ngIf="isValidWorkspace">
  <div *ngIf="isLoadedCampaign && loadedCampaignSummary.length > 0">
    <div *ngFor="let cs of loadedCampaignSummary; trackBy: trackByItem;">
      <div [ngClass]="['databar', cs.class ? cs.class : '']">
        <ul>
          <li *ngIf="cs.campaignDates" [title]="cs.campaignDates">
            <img src="assets/img/calendar.png" alt="">
            {{cs.campaignDates}}
          </li>
          <li *ngIf="cs.audienceRating" [title]="cs.audienceName" class="impressions">
            <img src="assets/img/audiance.png" alt="" /> {{cs.audienceRating}}
            <span *ngIf="cs.audienceName">
              ({{cs.audienceName | slice:0:18 }} {{cs.audienceName.length > 18 ? '...' : ''}})
            </span>
          </li>
          <li *ngIf="cs.audienceImpressions" [title]="cs.audienceName" class="impressions">
            <img src="assets/img/impressions.png" alt="" /> {{cs.audienceImpressions}}
            <span *ngIf="cs.audienceName">
              ({{cs.audienceName | slice:0:18 }} {{cs.audienceName.length > 18 ? '...' : ''}})
            </span>
          </li>
          <li *ngIf="cs.price" [title]="cs.price">
            <span class="currencySymbol">{{systemData.currencySymbol}}</span>
            {{cs.price}}
          </li>
          <li *ngIf="cs.frames" [title]="cs.frames">
            <img src="assets/img/frames.png" alt="" />
            {{cs.frames}}
          </li>
          <li *ngIf="cs.averageQoc" [title]="cs.averageQoc">
            <img src="assets/img/AverageQoc.png" alt="" />
            {{cs.averageQoc}}
          </li>
          <li *ngIf="cs.performanceRating" style="width: 8%;" [title]="cs.performanceRating">
            <img src="assets/img/tilde.png" alt="" />
            {{cs.performanceRating}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngFor="let cs of campaignSummary; trackBy: trackByIndex; let index = index">
    <div [ngClass]="['databar', cs.class ? cs.class : '']">
      <ul>
        <li *ngIf="cs.campaignDates" [title]="cs.campaignDates">
          <img src="assets/img/calendar.png" alt="">
          {{cs.campaignDates}}
        </li>
        <li *ngIf="cs.audienceRating" [title]="cs.audienceName" class="impressions">
          <img src="assets/img/audiance.png" alt="" /> {{cs.audienceRating}}
          <span *ngIf="cs.audienceName">({{cs.audienceName | slice:0:18 }}
            {{cs.audienceName.length > 18 ? '...' : ''}})</span>
        </li>
        <li *ngIf="cs.audienceImpressions" [title]="cs.audienceName" class="impressions">
          <img src="assets/img/impressions.png" alt="" /> {{cs.audienceImpressions}}
          <span *ngIf="cs.audienceName">({{cs.audienceName | slice:0:18 }} {{cs.audienceName.length > 18 ? '...' :
            ''}})</span>
        </li>
        <li *ngIf="cs.price">
          <span class="currencySymbol">{{systemData.currencySymbol}}</span> {{cs.price}}
        </li>
        <li *ngIf="cs.frames" [title]="cs.frames">
          <img src="assets/img/frames.png" alt="" /> {{cs.frames}}
        </li>
        <li *ngIf="cs.averageQoc" [title]="cs.averageQoc">
          <img src="assets/img/AverageQoc.png"alt="" /> {{cs.averageQoc}}
        </li>
        <li *ngIf="cs.performanceRating" [title]="cs.performanceRating">
          <img src="assets/img/tilde.png" alt="" /> {{cs.performanceRating}}
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<div
  *ngIf="(campaignSummary.length === 0 && !isLoadedCampaign) || (isLoadedCampaign && loadedCampaignSummary.length == 0 && campaignSummary.length === 0) || !isValidWorkspace">
  <div class="databar">
    <ul>
      <li>
        <img src="assets/img/calendar.png" alt="">
      </li>
      <li>
        <img src="assets/img/audiance.png" alt="">
      </li>
      <li>
        <span class="currencySymbol">{{systemData.currencySymbol}}</span>
      </li>
      <li>
        <img src="assets/img/frames.png" alt=""/>
      </li>
    </ul>
  </div>
</div>
