import { Injectable } from '@angular/core';
import { SharedService, DataShareService, StateService, LogHelperService, HistoryStackService, BrickBaseService } from '../core/services';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Campaign } from '../models/campaign';
import * as _ from 'lodash';
import { SystemFlags } from '../models/system-flags';
import { AppHeaderService } from '../../../../root/app-header/app-header.service';
import { ProximityTypeSelection, ThirdParty } from '../core/enum';
import { GLOBAL } from '../core/utils/app.constant';
import { CampaignDetailsService } from '../commercial/campaign-details/campaign-details.service';

@Injectable()
export class SearchService {
  public loadCampaignSub: Subject<object> = new Subject();

  public campaignDetailsChanged: Subject<Campaign> = new Subject();

  public cloneCampaignSub: Subject<Campaign> = new Subject();

  constructor(
    private sharedService: SharedService,
    private dataShareService: DataShareService,
    private stateService: StateService,
    private appHeaderService: AppHeaderService,
    private logHelper: LogHelperService,
    private historyStackService: HistoryStackService,
    private campaignDetailsService: CampaignDetailsService,
    private brickBaseService: BrickBaseService
  ) { }

  /**
   * @description Retrieves information about loaded campaign
   * @author Amit Mahida
   * @param {*} param
   * @param {string} serviceURL
   * @returns {Observable<any>}
   * @memberof SearchService
   */
  loadCampaign(param: any, serviceURL: string): Observable<any> {
    let params = {};
    if (param.action === 'loadApiRequest') {
      params = {
        action: param.action,
        smartApiRequestId: param.id
      };
    } else {
      params = {
        action: param.action,
        bricsCampaignId: param.id
      };
    }
    return this.sharedService.makeServerCall(params, serviceURL);
  }

  /**
   * @description Refreshes application state on server after reset event fired.
   * @author Amit Mahida
   * @param {string} serviceURL
   * @returns {Observable<any>}
   * @memberof SearchService
   */
  refreshCampaign(serviceURL: string): Observable<any> {
    const params = {
      action: 'refreshUI',
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID
    };
    return this.sharedService.makeServerCall(params, serviceURL);
  }

  /**
   * @description Emits load campaign event
   * @author Amit Mahida
   * @param {object} data
   * @memberof SearchService
   */
  emitLoadCampaign(data: object) {
    this.loadCampaignSub.next(data);
  }

  /**
   * @description Emits clone campaign event
   * @author Dhaval Patel
   * @param {object} data
   * @memberof SearchService
   */
  emitCloneCampaign(data: any) {
    this.cloneCampaignSub.next(data);
  }

  /**
   * @description sends campaign request while redirection to workspace
   * @author Amit Mahida
   * @param {*} param
   * @returns {Observable<any>}
   * @memberof SearchService
   */
  campaignRequest(param: any): Observable<any> {
    const params = {
      action: param.action,
      data: JSON.stringify(param.data),
      bricsCampaignId: 0
    };

    if (!_.isUndefined(param.bricsCampaignId)) {
      params.bricsCampaignId = param.bricsCampaignId;
    } else {
      delete params.bricsCampaignId;
    }

    return this.sharedService.makeServerCall(params, this.dataShareService.getServiceCallUrlByKey('CAMPAIGN_REQUEST_URL'));
  }

  getInitialConfig(selectedLocale: string): Observable<any> {
    const param = {
      action: 'getConfig'
    };
    if (selectedLocale) {
      param['selectedLocale'] = selectedLocale;
      param['action'] = 'changeLocale';
    }
    return this.sharedService.makeServerCall(param, GLOBAL.BRIC_CONFIG_URL);
  }

  updateLanguage(selectedLocale: string) {
    const param = {
      selectedLocale,
      action: 'getConfig'
    };
    const url = GLOBAL.HTTP_METHOD === 'GET' ? `${GLOBAL.SERVICE_BASE_URL}/data/locale/${selectedLocale}.json` : GLOBAL.BRIC_CONFIG_URL;
    return this.sharedService.makeServerCall(param, url);
  }

  /**
   * @description Makes server call to get brics master data.
   * @author Amit Mahida
   * @param {string} url
   * @returns {Observable<any>}
   * @memberof AppService
   */
  getBricsMasterData(url: string, selectedLocale: string): Observable<any> {
    const param = {
      action: 'getBricStructure'
    };
    if (selectedLocale) {
      param['selectedLocale'] = selectedLocale;
    }
    return this.sharedService.makeServerCall(param, url);
  }

  /**
   * @description Notifies campaign details change
   * @author Amit Mahida
   * @param {Campaign} updatedCampaign
   * @memberof SearchService
   */
  updateCampaignDetails(updatedCampaign: Campaign) {
    updatedCampaign = updatedCampaign ? updatedCampaign : new Campaign();
    this.campaignDetailsChanged.next(updatedCampaign);
  }

  /**
   * @description Returns true if campaign details changed
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof SearchService
   */
  isCampaignDetailsChanged(): boolean {
    const cachedCampaign: Campaign = this.stateService.getCampaign();
    const pageLoadCampaignState: Campaign = this.stateService.getInitialStateCampaign();

    if (JSON.stringify(cachedCampaign) !== JSON.stringify(pageLoadCampaignState)) {
      return true;
    }

    return false;
  }

  /**
   * @description This function will set false value for hidden elements SBRICS-1208
   * @author Amit Mahida
   * @param {Campaign} campaign
   * @returns {Campaign}
   * @memberof SearchService
   */
  setValueHiddenFileds(campaign: Campaign): Campaign {
    const tempCampaign = _.cloneDeep(campaign);

    if (this.dataShareService.hideUIElements('search.exclusion.agencyBlacklist') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.excludeAgencyBlacklist = false;
    }
    if (this.dataShareService.hideUIElements('search.exclusion.specialistBlacklist') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.excludeSpecialistBlacklist = false;
    }
    if (this.dataShareService.hideUIElements('search.exclusion.duplication') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.excludeDuplication = false;
    }
    if (this.dataShareService.hideUIElements('search.exclusion.juxta') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.excludeJuxta = false;
    }
    if (this.dataShareService.hideUIElements('search.exclusion.proximityProhibition') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.excludeProximityProhibition = false;
    }

    if (this.dataShareService.hideUIElements('search.exclusion.customFormats') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.excludeCustomFormats = false;
    }

    if (this.dataShareService.hideUIElements('search.exclusion.dummyPanels') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.excludeDummyPanels = false;
    }

    if (this.dataShareService.hideUIElements('search.exclusion.exclusivity') ||
      this.dataShareService.hideUIElements('search.exclusionHeading')) {
      tempCampaign.exclusivity = false;
      delete tempCampaign.exclusivityMask;
    }

    return tempCampaign;
  }

  /**
   * @description Generates proximity prohibition schema for request params
   * @author Amit Mahida
   * @returns Array<Object>[]
   * @memberof CampaignDetailsComponent
   */
  generateProximityprohabitionJSON(campaign: Campaign) {
    const responseArray = [];
    if (campaign.proximityProhibition) {
      campaign.proximityProhibition.forEach((groupObj) => {
        groupObj.proximityProhibitionType.forEach((prohibitionTypeObj) => {
          if (prohibitionTypeObj.distance != null && prohibitionTypeObj.distance !== 0) {
            let jsonObj = {
              prohibitionTypeId: prohibitionTypeObj.prohibitionTypeId,
              distance: prohibitionTypeObj.distance
            };
            responseArray.push(jsonObj);
          }
        });
      });
      return responseArray;
    }
  }

  public redirectFromSearch(pageName: string, campaign: Campaign): Promise<string> {
    const userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    const promise = new Promise((resolve, reject) => {
      if (GLOBAL.BRIC_CAMPAIGN_ID > 0) {
        if (this.isCampaignDetailsChanged()) {
          SystemFlags.forcefullyCallProcessBrics = true;
        } else {
          resolve(pageName);
          return promise;
        }
      }
      campaign = this.setValueHiddenFileds(campaign);

      if (!this.dataShareService.hideUIElements('search.campaignGroup')) {
        // If its visible then only below validation should be executed
        // Validation for Campaign Group and CampaignType field
        if (_.isUndefined(campaign.campaignTypeGroupId) || Number(campaign.campaignTypeGroupId) === -1) {
          this.logHelper.logError(userBundle['search.error.campaigngrouprequired']);
          reject(pageName);
          return promise;
        } else if (_.isUndefined(campaign.campaignTypeId) || Number(campaign.campaignTypeId) === -1) {
          this.logHelper.logError(userBundle['search.error.campaigntyperequired']);
          reject(pageName);
          return promise;
        }
      }

      const thirdParty = {};
      const campaignRequestParam: { action: string, data: any } = {
        action: 'initCampaign',
        data: _.clone(campaign)
      };

      if (GLOBAL.BRIC_CAMPAIGN_ID > 0) {
        campaignRequestParam['bricsCampaignId'] = GLOBAL.BRIC_CAMPAIGN_ID;
      }

      if (campaign.brand.brandId == null || campaign.brand.brandId === 0) {
        delete campaignRequestParam.data.brand;
      } else {
        campaignRequestParam.data['brandId'] = campaign.brand.brandId;
        delete campaignRequestParam.data.brand;
      }
      if (campaign.salesTeamId == null || campaign.salesTeamId === -1) {
        delete campaignRequestParam.data['salesTeamId'];
      }
      if (!_.isUndefined(campaign.advertiser) &&
        campaign.advertiser.advertiserId == null || campaign.advertiser.advertiserId === 0) {
        delete campaignRequestParam.data['advertiser'];
      } else {
        campaignRequestParam.data['advertiserId'] = campaign.advertiser.advertiserId;
        delete campaignRequestParam.data.advertiser;
      }

      if (campaign.productCategoryId === -1 || campaign.productCategoryId == null) {
        delete campaignRequestParam.data['productCategoryId'];
      }
      if (!_.isUndefined(campaign.specialist) &&
        campaign.specialist.specialistId != null && campaign.specialist.specialistId !== 0) {
        thirdParty[ThirdParty.specialist] = campaign.specialist.specialistId;
        delete campaignRequestParam.data['specialist'];
      } else {
        delete campaignRequestParam.data['specialist'];
      }
      if (!_.isUndefined(campaign.agency) && campaign.agency.agencyId != null && campaign.agency.agencyId !== 0) {
        thirdParty[ThirdParty.agency] = campaign.agency.agencyId;
        delete campaignRequestParam.data.agency;
      } else {
        delete campaignRequestParam.data.agency;
        delete campaignRequestParam.data.excludeAgencyBlacklist;
      }

      // Deleting it as it is not needed in request, discussed with Brandon
      delete campaignRequestParam.data['specialistInvoiceStatusCode'];
      delete campaignRequestParam.data['advertiserInvoiceStatusCode'];
      delete campaignRequestParam.data['agencyInvoiceStatusCode'];
      delete campaignRequestParam.data['bookingStatusId'];
      delete campaignRequestParam.data['isAddedFromModal'];
      if (!Object.keys(thirdParty).length) {
        delete campaignRequestParam.data.thirdParty;
      } else {
        campaignRequestParam.data.thirdParty = thirdParty;
      }
      // Fix for SBRICS-2845, Nishit, dt:13/09/2017
      let responseProximityprohabitionJSON = [];
      if (campaignRequestParam.data.excludeProximityProhibition) {
        responseProximityprohabitionJSON = this.generateProximityprohabitionJSON(campaign);
      }
      campaignRequestParam.data['proximityProhibition'] = responseProximityprohabitionJSON;
      // Case when user has not clicked on the Duplication Overlay, and excludeDuplication:true then set Advertizer as default value
      if (campaignRequestParam.data['advertiserId']) {
        if (campaignRequestParam.data['excludeDuplication'] && (!campaignRequestParam.data['duplicationAdvertisers'] || campaignRequestParam.data['duplicationAdvertisers'].length === 0)) {
          campaignRequestParam.data['duplicationAdvertisers'] = [];
          const objAdvertizer = {
            advertiserId: campaign.advertiser.advertiserId,
            advertiserName: campaign.advertiser.advertiserName,
            invoiceStatusCode: campaign.advertiser.invoiceStatusCode
          };
          campaignRequestParam.data['duplicationAdvertisers'].push(objAdvertizer);
        }
      }

      // Case 1: User select some value of Advertiser and if opens duplication modal and remove that value
      //        in that case we shouldn't send duplicationAdvertisers object so set emptyDuplication to true
      // Case 2: User select some value of Advertiser and redirects to workspace
      //        in that case we should send duplicationAdvertisers object so set emptyDuplication to by default false
      // SBRICS-1816 : 13/09/2016

      if (!campaignRequestParam.data.excludeDuplication || (campaignRequestParam.data['duplicationAdvertisers']
        && campaignRequestParam.data['duplicationAdvertisers'].length === 0)) {
        delete campaignRequestParam.data['duplicationAdvertisers'];
      }
      if (campaignRequestParam.data.productCategoryId === -1) {
        delete campaignRequestParam.data['productCategoryId'];
      }
      if (campaignRequestParam.data['proximityProhibition'].length === 0) {
        delete campaignRequestParam.data['excludeProximityProhibition'];
      }
      if (campaignRequestParam.data.campaignTypeGroupId === -1) {
        delete campaignRequestParam.data.campaignTypeGroupId;
      }
      if (campaignRequestParam.data.campaignTypeId === -1) {
        delete campaignRequestParam.data.campaignTypeId;
      }

      if (campaignRequestParam.data.id === 0) {
        delete campaignRequestParam.data.id;
      }

      this.campaignRequest(campaignRequestParam).subscribe((data) => {
        if (data.status === 'OK') {
          // This will be used with each and every request from next page. so required to be access everywhere
          if (GLOBAL.BRIC_CAMPAIGN_ID <= 0) {
            GLOBAL.BRIC_CAMPAIGN_ID = data.data.bricsCampaignId;
          }

          if (SystemFlags.isCloneCampaign && SystemFlags.isRangeSelectionMandatory) {
            this.cloneUploadBricks().then(() => {
              resolve(pageName);
              return promise;
            }, () => {
              reject(pageName);
              return promise;
            });
          } else {
            resolve(pageName);
            return promise;
          }
        } else {
          // Resetting
          GLOBAL.BRIC_CAMPAIGN_ID = 0;
          this.updateCampaignDetails(new Campaign());
          this.stateService.setCampaign(new Campaign());
          reject(pageName);
          return promise;
        }
      }, () => {
        reject(pageName);
        return promise;
      });

    });
    return promise;
  }

  /**
   * @description Reset Campaign if makeRefreshUICall is true
   * @author Shivani Patel
   * @param {boolean} [makeRefreshUICall=true] True to make 'refreshUI' call
   * @param {boolean} [makeConfigResetCall=false] True to get all the configs from backend after 'refreshUI' response
   * @param {boolean} [resetAll=false] True to reset everything mainly used from Commercial page
   * @param {boolean} [showInfoToaster=false] True to show info Toaster after getting response of 'refreshUI' call
   * @returns {(Promise<any> | void)}
   * @memberof SearchService
   */
  public resetCampaign(makeRefreshUICall = true, makeConfigResetCall = false, resetAll = false, showInfoToaster = false): Promise<any> | void {
    SystemFlags.reset();
    SystemFlags.resetCampaign = true;
    SystemFlags.isCloneCampaign = false;
    SystemFlags.isRangeSelectionMandatory = false;
    SystemFlags.splitted = false;
    this.dataShareService.enableGeoMapperTab(true);
    this.dataShareService.enableVisualPlannerTab(true);
    this.dataShareService.setLoadedCampaignFlag(false);
    this.campaignDetailsService.resetAllList();
    this.stateService.resetExceptSearch();
    if (resetAll) {
      this.stateService.resetSearch();
    }
    // disable result, commercial tab
    this.dataShareService.activateResultTab(false);
    // reset audit records
    this.appHeaderService.setAuditRecords([]);
    // hide lock message from header
    this.appHeaderService.changeLockedMessage(null);
    // hide info message from header
    this.appHeaderService.changeInfoMessage(null);

    this.stateService.setColumnHelper({});
    this.stateService.setWorkspaceObject('filter', this.stateService.getFreshFilterObj());
    this.stateService.setPCMObject('filter', this.stateService.getFreshFilterObj());
    this.stateService.setPCMObject('lastProductSaved', null);

    this.historyStackService.resetHistoryStack();

    this.stateService.setCampaign(new Campaign());
    this.stateService.setInitialStateCampaign(new Campaign());
    this.updateCampaignDetails(new Campaign());

    // To make initConfig call we have to set GLOBAL.BRIC_CAMPAIGN_ID to 0
    GLOBAL.BRIC_CAMPAIGN_ID = 0;

    if (makeRefreshUICall) {
      return new Promise((resolve, reject) => {
        const userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
        const url = this.dataShareService.getServiceCallUrlByKey('CLEAR_LOADED_CAMPAIGN');
        this.refreshCampaign(url).subscribe((data) => {
          if (data.status === 'OK' && showInfoToaster) {
            this.logHelper.logInfo(userBundle['search.info.clearCampaign']);
          }
          if (makeConfigResetCall) {
            this.appHeaderService.changeResetConfig({ getConfig: true, getBricStructure: true });
          }
          resolve(data);
        }, (reason) => {
          this.logHelper.logError(userBundle['search.error.gettingBrickList']);
          reject(reason);
        });
      });
    }
  }

  /**
   * @description clone feature : this method calls API to handle the file upload of clone campaign
   * @author Dhaval Patel
   * @param bricsData Bricks data structure
   */
  cloneUploadBricks() {
    return new Promise((resolve) => {
      const filterObj = this.stateService.getWorkspaceFilterObj();
      const selectionIdActionMap = {};
      const listBrickText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.List];
      const multiTargetBrickText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.MultiTarget];
      const proximityBrickText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Proximity];
      const objectiveBrickText = this.brickBaseService.brickReqJsonText[this.brickBaseService.brickID.Objective];
      if (filterObj && filterObj.rows && filterObj.rows.length > 0) {
        filterObj.rows.forEach((row) => {
          if (row.bric.bricid === this.brickBaseService.brickID.List ||
            row.bric.bricid === this.brickBaseService.brickID.MultiTarget ||
            row.bric.bricid === this.brickBaseService.brickID.Proximity ||
            row.bric.bricid === this.brickBaseService.brickID.Objective) {
            row.cells.forEach((cell) => {
              if (cell.requestJSON && cell.requestJSON[listBrickText] && cell.requestJSON[listBrickText].length) {
                cell.requestJSON[listBrickText].forEach((list) => {
                  selectionIdActionMap[list.userSelectionId] = 'panel';
                });
              } else if (cell.requestJSON && cell.requestJSON[multiTargetBrickText] && cell.requestJSON[multiTargetBrickText].length) {
                cell.requestJSON[multiTargetBrickText].forEach((mt) => {
                  selectionIdActionMap[mt.userSelectionId] = 'multitarget';
                });
              } else if (cell.requestJSON && cell.requestJSON[proximityBrickText]) {
                if (cell.requestJSON[proximityBrickText].userSelectionIds && cell.requestJSON[proximityBrickText].userSelectionIds.length) {
                  cell.requestJSON[proximityBrickText].userSelectionIds.forEach((userSelectionId) => {
                    if (cell.requestJSON[proximityBrickText].displayData && cell.requestJSON[proximityBrickText].displayData[userSelectionId]) {
                      if (cell.requestJSON[proximityBrickText].displayData[userSelectionId].proximityType === ProximityTypeSelection.Points) {
                        selectionIdActionMap[userSelectionId] = 'points';
                      } else if (cell.requestJSON[proximityBrickText].displayData[userSelectionId].proximityType === ProximityTypeSelection.POI) {
                        selectionIdActionMap[userSelectionId] = 'poi';
                      } else if (cell.requestJSON[proximityBrickText].displayData[userSelectionId].proximityType === ProximityTypeSelection.PostCode) {
                        selectionIdActionMap[userSelectionId] = 'postcode';
                      }
                    }
                  });
                }
              } else if (cell.requestJSON && cell.requestJSON[objectiveBrickText] && cell.requestJSON[objectiveBrickText].bricsData
                && cell.requestJSON[objectiveBrickText].bricsData.length) {
                cell.requestJSON[objectiveBrickText].bricsData.forEach((bd) => {
                  if (bd && bd[listBrickText] && bd[listBrickText].length) {
                    bd[listBrickText].forEach((list) => {
                      selectionIdActionMap[list.userSelectionId] = 'panel';
                    });
                  }
                });
              }
            });
          }
        });
        if (_.isEmpty(selectionIdActionMap)) {
          resolve(true);
        } else {
          this.cloneUserSelection({ selectionIdActionMap }).subscribe((response) => {
            if (response && response.status === 'OK' && response.data && response.data.oldNewUserSelectionIds) {
              const oldNewUserSelectionIds = response.data.oldNewUserSelectionIds;
              filterObj.rows.forEach((row) => {
                if (row.bric.bricid === this.brickBaseService.brickID.List ||
                  row.bric.bricid === this.brickBaseService.brickID.MultiTarget ||
                  row.bric.bricid === this.brickBaseService.brickID.Proximity ||
                  row.bric.bricid === this.brickBaseService.brickID.Objective) {
                  row.cells.forEach((cell) => {
                    if (cell.requestJSON && cell.requestJSON[listBrickText] && cell.requestJSON[listBrickText].length) {
                      cell.requestJSON[listBrickText].forEach((list, index) => {
                        if (oldNewUserSelectionIds[list.userSelectionId]) {
                          cell.requestJSON[listBrickText][index].userSelectionId = oldNewUserSelectionIds[list.userSelectionId];
                        }
                      });
                      if (cell.selected && cell.selected.listUpload && cell.selected.listUpload.fileList && cell.selected.listUpload.fileList.length) {
                        cell.selected.listUpload.fileList.forEach((file, i) => {
                          if (oldNewUserSelectionIds[file.userSelectionId]) {
                            cell.selected.listUpload.fileList[i].userSelectionId = oldNewUserSelectionIds[file.userSelectionId];
                          }
                        });
                      }
                      if (cell.selected && cell.selected.routeFrameArray && cell.selected.routeFrameArray.length) {
                        cell.selected.routeFrameArray.forEach((frame, i) => {
                          if (oldNewUserSelectionIds[frame.userSelectionId]) {
                            cell.selected.routeFrameArray[i].userSelectionId = oldNewUserSelectionIds[frame.userSelectionId];
                          }
                        });
                      }
                      if (cell.selected && cell.selected.routeFrameIds) {
                        if (cell.selected.routeFrameIds.include && cell.selected.routeFrameIds.include.length) {
                          cell.selected.routeFrameIds.include.forEach((frame, i) => {
                            if (oldNewUserSelectionIds[frame.userSelectionId]) {
                              cell.selected.routeFrameIds.include[i].userSelectionId = oldNewUserSelectionIds[frame.userSelectionId];
                            }
                          });
                        }
                        if (cell.selected.routeFrameIds.exclude && cell.selected.routeFrameIds.exclude.length) {
                          cell.selected.routeFrameIds.exclude.forEach((frame, i) => {
                            if (oldNewUserSelectionIds[frame.userSelectionId]) {
                              cell.selected.routeFrameIds.exclude[i].userSelectionId = oldNewUserSelectionIds[frame.userSelectionId];
                            }
                          });
                        }
                      }
                    } else if (cell.requestJSON && cell.requestJSON[multiTargetBrickText] && cell.requestJSON[multiTargetBrickText].length) {
                      cell.requestJSON[multiTargetBrickText].forEach((mt, index) => {
                        if (oldNewUserSelectionIds[mt.userSelectionId]) {
                          cell.requestJSON[multiTargetBrickText][index].userSelectionId = oldNewUserSelectionIds[mt.userSelectionId];
                        }
                      });
                      if (cell.selected && cell.selected.listUpload && cell.selected.listUpload.fileList && cell.selected.listUpload.fileList.length) {
                        cell.selected.listUpload.fileList.forEach((file, i) => {
                          if (oldNewUserSelectionIds[file.userSelectionId]) {
                            cell.selected.listUpload.fileList[i].userSelectionId = oldNewUserSelectionIds[file.userSelectionId];
                          }
                        });
                      }
                    } else if (cell.requestJSON && cell.requestJSON[proximityBrickText]) {
                      if (cell.requestJSON[proximityBrickText].userSelectionIds && cell.requestJSON[proximityBrickText].userSelectionIds.length) {
                        for (let index = 0; index < cell.requestJSON[proximityBrickText].userSelectionIds.length; index += 1) {
                          const oldId = cell.requestJSON[proximityBrickText].userSelectionIds[index];
                          const newId = oldNewUserSelectionIds[oldId];
                          if (oldId && newId) {
                            if (cell.requestJSON[proximityBrickText].displayData && cell.requestJSON[proximityBrickText].displayData[oldId]) {
                              cell.requestJSON[proximityBrickText].displayData[oldId].userSelectionId = oldNewUserSelectionIds[oldId];
                              cell.requestJSON[proximityBrickText].displayData[newId] = cell.requestJSON[proximityBrickText].displayData[oldId];
                              delete cell.requestJSON[proximityBrickText].displayData[oldId];
                            }
                            cell.requestJSON[proximityBrickText].userSelectionIds[index] = newId;
                          }
                        }
                      }
                      if (cell.selected && cell.selected.pointOfInterest && cell.selected.pointOfInterest.userSelectionId && oldNewUserSelectionIds[cell.selected.pointOfInterest.userSelectionId]) {
                        cell.selected.pointOfInterest.userSelectionId = oldNewUserSelectionIds[cell.selected.pointOfInterest.userSelectionId];
                      }
                      if (cell.selected && cell.selected.points && cell.selected.points.userSelectionId && oldNewUserSelectionIds[cell.selected.points.userSelectionId]) {
                        cell.selected.points.userSelectionId = oldNewUserSelectionIds[cell.selected.points.userSelectionId];
                      }
                      if (cell.selected && cell.selected.postcodeSelection && cell.selected.postcodeSelection.userSelectionId && oldNewUserSelectionIds[cell.selected.postcodeSelection.userSelectionId]) {
                        cell.selected.postcodeSelection.userSelectionId = oldNewUserSelectionIds[cell.selected.postcodeSelection.userSelectionId];
                      }
                    } else if (cell.requestJSON && cell.requestJSON[objectiveBrickText] && cell.requestJSON[objectiveBrickText].bricsData
                      && cell.requestJSON[objectiveBrickText].bricsData.length) {
                      cell.requestJSON[objectiveBrickText].bricsData.forEach((bd) => {
                        if (bd && bd[listBrickText] && bd[listBrickText].length) {
                          bd[listBrickText].forEach((list, index) => {
                            if (oldNewUserSelectionIds[list.userSelectionId]) {
                              bd[listBrickText][index].userSelectionId = oldNewUserSelectionIds[list.userSelectionId];
                            }
                          });
                          if (cell.selected && cell.selected.objectives && cell.selected.objectives.length) {
                            cell.selected.objectives.forEach((objective) => {
                              if (objective && objective.bric && objective.bric.bricid === this.brickBaseService.brickID.List && objective.cells && objective.cells.length) {
                                objective.cells.forEach((c) => {
                                  if (c.requestJSON && c.requestJSON[listBrickText] && c.requestJSON[listBrickText].length) {
                                    c.requestJSON[listBrickText].forEach((list, index) => {
                                      if (oldNewUserSelectionIds[list.userSelectionId]) {
                                        c.requestJSON[listBrickText][index].userSelectionId = oldNewUserSelectionIds[list.userSelectionId];
                                      }
                                    });
                                  }
                                  if (c.selected && c.selected.listUpload && c.selected.listUpload.fileList && c.selected.listUpload.fileList.length) {
                                    c.selected.listUpload.fileList.forEach((file, i) => {
                                      if (oldNewUserSelectionIds[file.userSelectionId]) {
                                        c.selected.listUpload.fileList[i].userSelectionId = oldNewUserSelectionIds[file.userSelectionId];
                                      }
                                    });
                                  }
                                  if (c.selected && c.selected.routeFrameArray && c.selected.routeFrameArray.length) {
                                    c.selected.routeFrameArray.forEach((frame, i) => {
                                      if (oldNewUserSelectionIds[frame.userSelectionId]) {
                                        c.selected.routeFrameArray[i].userSelectionId = oldNewUserSelectionIds[frame.userSelectionId];
                                      }
                                    });
                                  }
                                  if (c.selected && c.selected.routeFrameIds) {
                                    if (c.selected.routeFrameIds.include && c.selected.routeFrameIds.include.length) {
                                      c.selected.routeFrameIds.include.forEach((frame, i) => {
                                        if (oldNewUserSelectionIds[frame.userSelectionId]) {
                                          c.selected.routeFrameIds.include[i].userSelectionId = oldNewUserSelectionIds[frame.userSelectionId];
                                        }
                                      });
                                    }
                                    if (c.selected.routeFrameIds.exclude && c.selected.routeFrameIds.exclude.length) {
                                      c.selected.routeFrameIds.exclude.forEach((frame, i) => {
                                        if (oldNewUserSelectionIds[frame.userSelectionId]) {
                                          c.selected.routeFrameIds.exclude[i].userSelectionId = oldNewUserSelectionIds[frame.userSelectionId];
                                        }
                                      });
                                    }
                                  }
                                });
                              }
                            });
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
            this.stateService.setWorkspaceObject('filter', filterObj);
            resolve(true);
          }, () => {
            resolve(false);
          });
        }
      } else {
        resolve(false);
      }
    });
  }

  /**
   * @description clone user selection
   * @author Dhaval Patel
   * @param {*} param
   * @returns {Observable<any>}
   * @memberof SearchService
   */
  cloneUserSelection(data: any): Observable<any> {
    const params = {
      action: 'cloneUserSelection',
      data: JSON.stringify(data)
    };
    return this.sharedService.makeServerCall(params, this.dataShareService.getServiceCallUrlByKey('FILE_UPLOAD_URL'));
  }
}
