import { UiControl } from '../../models/initial-config.model';
import { Network, NetworkGroup, CellValues, UserModel } from '../../models';
import { DataShareService } from '../../core/services/data-share.service';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { SharedService } from '../../core/services';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';

export class PricingTagBase {

  /**
   * @description
   * @type {object}
   * @memberof PricingTag
   */
  userBundle: object;

  /**
   * @description
   * @type {UiControl}
   * @memberof PricingTagBase
   */
  uiControl: UiControl;

  /**
  * @description
  * @type {UserModel}
  * @memberof PricingTagBase
  */
  user: UserModel;

  /**
   * @description
   * @type {[]}
   * @memberof PricingTagBase
   */
  networkGroups: NetworkGroup[];

  /**
   * @description selected networks
   * @type {Network[]}
   * @memberof PricingTagBase
   */
  selectedNetworks: Network[] = [];

  /**
   * @description displays the filtered networks based on selected tab
   * @type {Network[]}
   * @memberof PricingTagBase
   */
  displayNetworks: Network[] = [];

  /**
   * @description selected network group from tab
   * @type {number}
   * @memberof PricingTagBase
   */
  selectedNetworkGroupId = 0;

  /**
   * @description list of warning networks
   * @memberof PricingTagBase
   */
  warningNetworks = [];

  pcmMode = false;

  isSelectedOptStack = false;
  checkAll = false;
  lookupColumnData;
  disableDropDown = false;
  networkAvailabilityFilter = false;
  networkUnmatchedFilter = false;

  isRateCard = false;
  modeVisible = false;
  disableSelectedRatecard = false;
  static selectionId = 28;
  resolveObjectBase;
  checkAllDisabled = false;

  constructor(
    private dataShareService: DataShareService,
    public brickBaseService: BrickBaseService,
    private sharedService: SharedService,
    private appHeaderService: AppHeaderService
  ) {

  }

  init(resolveObject: CellValues) {
    this.pcmMode = this.appHeaderService.enabledPCM;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.modeVisible = this.uiControl.openRateCardsEnabled;
    this.user = this.dataShareService.getUserModel();
    this.resolveObjectBase = resolveObject;
    this.isSelectedOptStack = this.uiControl.defaultOptionStack ? true : false;
    this.networkAvailabilityFilter = this.uiControl.defaultNetworkAvailabilityFilter ? true : false;
    this.networkUnmatchedFilter = this.uiControl.defaultNetworkUnmatchedFilter ? true : false;
    this.loadFromResolved(resolveObject);
    this.selectTab(0);
    this.lookupColumnData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.PricingTag, null);
    this.checkIfNetworkInputDisabled(resolveObject);
  }

  /**
   * Initialize values from resolved Object
   * @param resolveObject
   */

  loadFromResolved(resolveObject: CellValues) {
    const networkGroup = _.values(this.dataShareService.getInitialConfigByKey('networkGroup'));
    this.networkGroups = this.sharedService.getTagDataFromColumnConfig(this.brickBaseService.brickID.PricingTag, resolveObject.columnConfig, networkGroup);
    if (resolveObject.selectedValues && !resolveObject.selectedValues.hasOwnProperty('-99')) {
      const lookupColumn = this.dataShareService.getInitialConfigByKey('lookupColumn').find((e: any) => e.brickId === this.brickBaseService.brickID.PricingTag);
      const pricingTagSelectionId = lookupColumn.lookup[0].selectionId;
      const selectedNetworks = resolveObject.selectedValues[pricingTagSelectionId];

      this.loadSelectedGroup(selectedNetworks, resolveObject, networkGroup);
      this.loadFrameData(selectedNetworks, resolveObject);

      this.selectedNetworks = _.cloneDeep(this.sharedService.generateFrameDataforPricingTag(resolveObject.columnConfig, selectedNetworks));
      if (!(this.selectedNetworks && this.selectedNetworks[0] && this.selectedNetworks[0].openRateCard)) {
        const existSelectedNetworks = [];
        this.selectedNetworks.forEach((selectedNetwork) => {
          this.networkGroups[0].network.forEach((network) => {
            if (selectedNetwork.networkId === network.networkId) {
              network.networkDefaultQuantity = selectedNetwork.networkDefaultQuantity;
              network.noOfFramesEditable = selectedNetwork.noOfFramesEditable;
              network.optionStack = selectedNetwork.optionStack;
              if (this.uiControl.fixedPackagePriceEnabled) {
                network.fixedPackagePrice = selectedNetwork.fixedPackagePrice;
              }
              existSelectedNetworks.push(network);
            }
          });
        });
        this.selectedNetworks = existSelectedNetworks;
      }
      this.displayNetworks = _.cloneDeep(this.selectedNetworks);
      this.checkForCheckAll();
    }
  }

  /**
   * Initialize Groups from resolve object
   * @param selectedNetworks
   * @param resolveObject
   */
  loadSelectedGroup(selectedNetworks, resolveObject, ng) {
    const allSelectedNetworks = [];
    for (const network of selectedNetworks) {
      allSelectedNetworks.push(network.networkId);
    }
    if (resolveObject && resolveObject.productValidations && !this.pcmMode) {
      this.networkGroups = this.networkGroups.filter((networkGroup) => {
        return networkGroup.inUse
          && networkGroup.network
          && networkGroup.network.filter(network => allSelectedNetworks.includes(network.networkId)).length;
      });
      if (!resolveObject.isOptionalInProduct) {
        this.disableSelectedRatecard = true;
      }
      if (!(this.networkGroups && this.networkGroups.length)) {
        this.networkGroups = this.sharedService.getTagDataFromColumnConfig(this.brickBaseService.brickID.PricingTag,
          null, ng);
        this.networkGroups = this.networkGroups.filter((networkGroup) => {
          return networkGroup.inUse
            && networkGroup.network
            && networkGroup.network.filter(network => allSelectedNetworks.includes(network.networkId)).length;
        });
      }
    }
  }

  /**
   * Load frame data for networks from resolvedObject
   * @param selectedNetworks
   * @param resolveObject
   */
  loadFrameData(selectedNetworks, resolveObject) {
    if (resolveObject.columnConfig === null) {
      for (const netG of this.networkGroups) {
        for (const selectedNetwork of selectedNetworks) {
          const netIndex = netG.network.findIndex(net => net.networkId === selectedNetwork.networkId);
          if (netIndex > -1) {
            selectedNetwork.frameData = netG.network[netIndex].frameData;
          }
        }
      }
    }
  }

  selectTab(tabId: number) {
    this.selectedNetworkGroupId = tabId;
    this.filterDisplayNetworks();
    // const tagInput = document.querySelector('#networkInput');
    // console.log(tagInput);
    // tagInput.target.click();
    // .click();
  }

  /**
   * @description  updates networks to display for
   * @author Amit Mahida
   * @memberof PricingTagBase
   */
  filterDisplayNetworks() {
    this.displayNetworks = [];
    if (_.isArray(this.selectedNetworks)) {
      this.selectedNetworks.forEach((selectedNetwork) => {
        const selectedNetworkGroup: NetworkGroup[] = this.networkGroups.filter((networkGroup) => {
          return networkGroup.networkGroupId === this.selectedNetworkGroupId;
        });
        if (selectedNetworkGroup[0]
          && selectedNetworkGroup[0].network.findIndex(network => network.networkId === selectedNetwork.networkId) > -1) {
          this.displayNetworks.push(selectedNetwork);
        }
      });
    }
  }

  /**
   * @description Returns observable stream of networkdata for selected network group
   * @author Amit Mahida
   * @param {string} query
   * @returns {Observable<Network[]>}
   * @memberof PricingTagBase
   */
  loadNetworkData = (query: string): Observable<Network[]> => {
    const returnedData: Network[] = this.getNetworkData(query);
    return of(returnedData);
  }

  /**
   * @description Returns networkdata for selected network group
   * @author Amit Mahida
   * @param {string} query
   * @returns {Network[]}
   * @memberof PricingTagBasee4
   */
  getNetworkData(query: string): Network[] {
    let returnedData: Network[] = [];
    for (const networkGroup of this.networkGroups) {
      if (this.selectedNetworkGroupId === networkGroup.networkGroupId) {
        returnedData = networkGroup.network.filter((ele: Network) => {
          if (ele.networkName.toLowerCase().indexOf(query.toLowerCase()) !== -1 && ele.inUse) {
            if (this.uiControl.networkBricAvailabilityControls && !this.pcmMode) {
              if (this.networkAvailabilityFilter && this.networkUnmatchedFilter) {
                return !(ele.frameData[1] < ele.networkMinQuantity || ele.frameData[2] < ele.networkMinQuantity);
              } else if (!this.networkAvailabilityFilter && !this.networkUnmatchedFilter) {
                return true;
              } else if (this.networkAvailabilityFilter && !this.networkUnmatchedFilter) {
                return !(ele.frameData[1] < ele.networkMinQuantity);
              } else if (!this.networkAvailabilityFilter && this.networkUnmatchedFilter) {
                return !(ele.frameData[2] < ele.networkMinQuantity);
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            return false;
          }
        });
        break;
      }
    }
    if (this.uiControl.fixedPackagePriceEnabled) {
      returnedData.forEach(r => r.fixedPackagePrice = !_.isUndefined(r.networkMinQuantity));
    }
    return returnedData;
  }

  /**
   * @description checks if the network is warned network
   * @author Amit Mahida
   * @param {number} networkId
   * @returns {boolean}
   * @memberof PricingTagBase
   */
  isWarnedNetwork(networkId: number) {
    if (networkId) {
      this.networkGroups.forEach((value: NetworkGroup) => {
        if (typeof value.network !== 'undefined' && value.networkGroupId !== 0) {
          return value.network.find((inValue) => {
            if (typeof inValue.networkId !== 'undefined' && inValue.networkId === networkId) {
              return this.warningNetworks.indexOf(networkId) > -1;
            }
          });
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  }

  /**
   * @description removed selected network
   * @author Amit Mahida
   * @param {number} index
   * @memberof PricingTagBase
   */
  removeSelectedNetwork(network: Network) {
    const deleteIndex = this.selectedNetworks.findIndex(selectedNetwork => selectedNetwork === network);
    this.selectedNetworks.splice(deleteIndex, 1);
    this.filterDisplayNetworks();
  }

  /**
   * @description Handles option stack change event
   * @author Amit Mahida
   * @param {Network} network
   * @memberof PricingTagBase
   */
  onOptionStackChange(network: Network) {
    if (network.optionStack) {
      network.max = network.frameData[0];
    } else {
      network.max = network.frameData[1];
    }
    const index = this.selectedNetworks.findIndex(selectedNetwork => selectedNetwork === network);
    if (this.selectedNetworks[index].optionStack) {
      this.selectedNetworks[index].max = this.selectedNetworks[index].frameData[0];
    } else {
      this.selectedNetworks[index].max = this.selectedNetworks[index].frameData[1];
    }
    this.checkForCheckAll();
  }

  /**
   * @description Handles networkDefaultQuantity & optionStack on adding new Network
   * @author Amit Mahida
   * @param {Network} network
   * @memberof PricingTagBase
   */
  tagAdded(network: Network) {
    if (this.uiControl.openRateCardsEnabled && this.isRateCard) {
      this.displayNetworks = [];
      this.selectedNetworks = [network];
    }
    if (this.pcmMode) {
      network.noOfFramesEditable = true;
    }
    this.filterDisplayNetworks();
    const disNetIndex = this.displayNetworks.findIndex(disNetwork => disNetwork.networkId === network.networkId);
    if (this.isSelectedOptStack) {
      network.optionStack = true;
      this.displayNetworks[disNetIndex].optionStack = true;
    } else {
      network.optionStack = false;
      this.displayNetworks[disNetIndex].optionStack = false;
    }

    if (network.networkMinQuantity > network.networkDefaultQuantity) {
      network.networkDefaultQuantity = network.networkMinQuantity;
    }
    this.displayNetworks[disNetIndex].networkDefaultQuantity = network.networkDefaultQuantity;
    this.checkForCheckAll();
  }

  toggleSelection() {
    this.selectedNetworks.forEach((network) => {
      if (!this.checkIsDisabled(network)) {
        network.optionStack = this.checkAll;
      }
    });
    this.displayNetworks.forEach((network) => {
      if (!this.checkIsDisabled(network)) {
        network.optionStack = this.checkAll;
      }
    });
  }

  /**
   * @description makes the taginput disable if there's at least one non editable network
   * @author Amit Mahida
   * @param {CellValues} resolveObject
   * @memberof PricingTagBase
   */
  checkIfNetworkInputDisabled(resolveObject: CellValues) {
    if (this.selectedNetworks) {
      if (resolveObject.productValidations && !resolveObject.isOptionalInProduct) {
        this.disableDropDown = true;
      } else {
        this.disableDropDown = false;
      }
    }
  }

  /**
   * @description Set networkGroups for selected network mode
   * @author Dhaval Patel
   * @param {any} columnConfig
   * @memberof PricingTagBase
   */
  setNetworkGroup(columnConfig) {
    const networkGroup = _.values(this.dataShareService.getInitialConfigByKey('networkGroup'));
    if (this.isRateCard) {
      if (columnConfig && columnConfig.lookupData) {
        columnConfig.lookupData[PricingTagBase.selectionId] = columnConfig.rateCardLookupData;
      }
    }
    this.networkGroups = this.sharedService.getTagDataFromColumnConfig(this.brickBaseService.brickID.PricingTag,
      columnConfig, networkGroup);
    if (this.isRateCard && this.networkGroups && this.networkGroups[0]) {
      this.networkGroups[0].networkGroupName = this.userBundle['workspace.collection.rateCards.allSelection'] || 'All Ratecards';
    }
    this.selectTab(0);
  }

  /**
   * This method is used to update checkAll value when any optionStack is updated
   * @author Dhaval Patel
   */
  checkForCheckAll() {
    if (this.selectedNetworks && this.selectedNetworks.length) {
      const enabledNetworks = this.selectedNetworks.filter(network => !this.checkIsDisabled(network));
      const optionStackNetworks = enabledNetworks.filter(network => network.optionStack);
      if (optionStackNetworks.length && optionStackNetworks.length === enabledNetworks.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    } else {
      this.checkAll = false;
    }
  }

  /**
   * This method is used to check disbility of all selected networks
   * @author Dhaval Patel
   */
  isAllNetworkDisabled() {
    if (this.selectedNetworks && this.selectedNetworks.length) {
      const disabledNetworks = this.selectedNetworks.filter(network => this.checkIsDisabled(network));
      if (this.selectedNetworks.length === disabledNetworks.length) {
        this.checkAllDisabled = true;
      } else {
        this.checkAllDisabled = false;
      }
    }
  }

  /**
   * This will check selected network is disabled or not.
   * @param network selected network
   * @returns boolean
   */
  checkIsDisabled(network: Network) {
    if ((!this.isRateCard && !network.noOfFramesEditable && !this.pcmMode && this.resolveObjectBase.productValidations) ||
      (this.isRateCard && (!this.uiControl.openRateCardsEnabled || this.disableSelectedRatecard))) {
      return true;
    }
    return false;
  }

}
