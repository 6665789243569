declare let MarkerClusterer;
declare let MapLabel;

import {
  Component, EventEmitter, Input, Output, ElementRef, ViewChild, SimpleChange,
  OnInit, OnChanges, OnDestroy, ChangeDetectorRef, HostListener
} from '@angular/core';
import { ShapeCollection } from './map-shape-collection';
import { GeoMapService } from './geo-map.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { LogHelperService } from '../core/services/log-helper.service';
import { DataShareService } from '../core/services/data-share.service';
import { CampaignService } from '../core/services/campaign.service';
import { FilterDataService } from '../core/services/filter-data.service';
import { DistanceConverterService } from '../core/services/distance-converter.service';
import { NgbDropdown, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProposalComponent } from './proposal/proposal.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MapLabelCollection } from './map-label-collection';
import { MapSvgIcon, svgIconSettings } from './mapSvgIcon';
import { CartService } from './cart.service';
import { FrameFilterType, FrameStatus, MapViewBy, FurnitureStatus } from './status.enum';
import { GridOptions, ColDef } from 'ag-grid-community';
import { AgFurnitureComponent } from './ag-furniture/ag-furniture.component';
import { AgFrameComponent } from './ag-frame/ag-frame.component';
import { LoaderService } from '../core/services/loader.service';
import { SharedService } from './../core/services/shared.service';
import { BrickBaseService } from './../core/services/brick-base.service';
import { FolioImageComponent } from './folio-image/folio-image.component';
import { DatePipe } from '@angular/common';
import {
  InitialConfigModel, FurnitureModel,
  FrameModel, MapDataObservableModel, LookupCampaignResponseModel,
  LookupCampaignModel, GetCampaignResponseModel, BookingDetailsParam, ResponseModel, SaveFrameResponseModel, SystemFlags
} from '../models/index';
import {
  GoogleAnalyticsEvents, EventActionsMapFeatures,
  EventActionsCampaignFeatures, GoogleAnalyticsEventCategory,
  EventActionsCart
} from '../GoogleAnalytics/GoogleAnalyticsEvent';
import { LocaleData } from '../core/utils/LocaleData';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentId } from '../core/enum';
import { AgmSnazzyInfoWindow } from '@agm/snazzy-info-window';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-geo-map',
  templateUrl: './geo-map.component.html',
  styleUrls: ['./geo-map.component.css'],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  }
})
export class GeoMapComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * input - whether filter area is visible or not
   * @type {boolean}@memberof GeoMapComponent
   */
  @Input() isFilterAreaVisible: boolean;

  /**
   * input - whether filter bar is expanded or not
   * @type {boolean}@memberof GeoMapComponent
   */
  @Input() isFilterBarExpanded: boolean;

  /**
   * output event - for redirect to result page
   * @memberof GeoMapComponent
   */
  @Output() goToResultPage = new EventEmitter<any>();

  /**
   * application initial config file
   * @type {*}@memberof GeoMapComponent
   */
  @Input() initialConfig: InitialConfigModel;

  /**
   * Default map center lat position
   * @type {number}@memberof GeoMapComponent
   */
  lat = 39.2189341;

  /**
   * Default map center lng position
   * @type {number}@memberof GeoMapComponent
   */
  lng = -95.8933979;

  zoomLevel = 5;

  userData: any;

  googleMapOptions: google.maps.MapOptions = {
    center: new google.maps.LatLng(this.lat, this.lng),
    fullscreenControl: false,
    streetViewControl: true,
    clickableIcons: false,
    zoom: this.zoomLevel,
    mapTypeControl: false
  };

  /**
   * loaded Map data
   * @type Array<FurnitureModel>@memberof GeoMapComponent
   */
  assetsData: FurnitureModel[];

  /**
   * whether map is loaded or not
   * @type {Boolean}@memberof GeoMapComponent
   */
  isMapLoaded: boolean = false;

  /**
   * google map object
   * @type {google.maps.Map}@memberof GeoMapComponent
   */
  mapObj: google.maps.Map;

  /**
   * Selected assests data to display in frame selection info window
   * @type {Array<FurnitureModel>}@memberof GeoMapComponent
   */
  selectedAssetsData: FurnitureModel[];

  /**
   * temp Selected assests data to display in frame selection info window
   * @type {Array<any>}@memberof GeoMapComponent
   */
  tempSelectedAssetsData: FurnitureModel[];

  /**
   * In frame selection info window whether all frames all selected
   * @type {boolean}@memberof GeoMapComponent
   */
  allSelected = false;

  /**
   * minimum number of frames required to show All checkbox in frame info window
   * @type {number}@memberof GeoMapComponent
   */
  minFramesToShowAllSelect = 2;

  /**
   * whether cart is visible or not
   * @type {boolean}@memberof GeoMapComponent
   */
  isCartVisible = false;
  /**
   * width of cart
   * @type {number}@memberof GeoMapComponent
   */
  cartWidth = 350;

  /**
   * whether Legend is visible or not
   */
  isLegendVisible = false;

  /**
   * Drawing manager circle option
   * @type {*}@memberof GeoMapComponent
   */
  circleOptions: any = {
    fillColor: '#000000',
    fillOpacity: 0.7,
    // suppressUndo: true
  };

  /**
   * Whether to show shape properties in frame info window
   * @type {boolean}@memberof GeoMapComponent
   */
  isShapePropVisible = false;

  /**
   * shape collection object
   * @type {ShapeCollection}@memberof GeoMapComponent
   */
  shapeCollection: ShapeCollection;

  /**
   * Map Label Collection object
   * @type {MapLabelCollection}@memberof GeoMapComponent
   */
  labelCollection: MapLabelCollection;

  /**
   * map data subscription object
   * @type {Subscription}@memberof GeoMapComponent
   */
  mapDataSubscriber: Subscription;

  /**
   * array of markers
   * @type {google.maps.Marker[]}@memberof GeoMapComponent
   */
  markers: google.maps.Marker[] = [];

  /**
   * marker cluster object
   * @type {*}@memberof GeoMapComponent
   */
  markerCluster: any;

  /**
   * Frame selection info window object
   * @type {AgmSnazzyInfoWindow}@memberof GeoMapComponent
   */
  infoWindowObj: AgmSnazzyInfoWindow;

  /**
   * plot a POI to draw circle based on radius and unit Info window object
   * @type {AgmSnazzyInfoWindow} @memberof GeoMapComponent
   */
  circleInfoWindowObj: AgmSnazzyInfoWindow;

  /**
   * draw circle info window value object
   * @memberof GeoMapComponent
   */
  drawCircleValues = {
    radius: '',
    unit: '0',
    decimalPoints: 0
  };

  /**
   * Frame selection info window element
   * @type {AgmSnazzyInfoWindow}@memberof GeoMapComponent
   */
  @ViewChild('infoWindow') infoWindowElement: AgmSnazzyInfoWindow;

  /**
   * draw circle info window element
   * @type {AgmSnazzyInfoWindow} @memberof GeoMapComponent
   */
  @ViewChild('circleInfoWindow') circleInfoWindowElement: AgmSnazzyInfoWindow;

  /**
   * ngbModal options
   * @type {NgbModalOptions}@memberof GeoMapComponent
   */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'sm'
  };

  /**
   * Selected shape background color
   * @type {*}@memberof GeoMapComponent
   */
  public selectedShapeColor: any = '#127bdc';

  /**
   * Selected shape map label text
   * @type {string}@memberof GeoMapComponent
   */
  selectedShapeText = '';

  /**
   * Drawing Manager Object
   * @type {*}
   * @memberof GeoMapComponent
   */
  drawingManagerObj: google.maps.drawing.DrawingManager;

  /**
   * Active btn in Drawing controls
   */
  drawingControlActiveBtn = 1;

  legendData = [];

  // new markerIcon given by rimas on slack
  markerIcon = {
    0: 'default',
    1: 'large_screen',  // -> This is a billboard
    2: 'streettalk', // -> This is a kiosk
    3: 'railway',
    4: 'mall',
    5: 'supermarket',
    6: 'airport',
    7: 'street_furniture'
  };

  public selectedFrameFilter: FrameFilterType = FrameFilterType.all;
  public frameFilterType = FrameFilterType;

  public allFrameOnMap = 0;
  public availableFrameOnMap = 0;
  public selectedFrameOnMap = 0;
  // private frameFilterSubscriber: Subscription;

  public frameDetailObj = {
    frame: null,
    top: '0px',
    left: '0px'
  };

  /**
   * Ag-Grid Options Object
   */
  gridOptionsFrameSelection: GridOptions;

  /**
   * cart data subscriber object
   */
  cartDataSubscriber: Subscription;

  /**
   * is viewing cart data on map
   */
  isViewCartOnMap = false;

  /**
   * Whether to disable save btn on frame selection popup
   * @type {boolean}
   * @memberof GeoMapComponent
   */
  isDisbledSaveFrameBtn = true;

  /**
   * Whether to disable save btn on frame selection popup
   * @type {boolean}
   * @memberof GeoMapComponent
   */
  frameSelectionGridHeight = '200px';

  /**
   * Subscriber object for filter data change
   * @type {Subscription}
   * @memberof GeoMapComponent
   */
  filterDataSubscriber: Subscription;

  mapViewBy = MapViewBy;

  viewBy: MapViewBy = MapViewBy.furniture;

  placeHighlighterMarker = {
    position: [this.lat, this.lng],
    visible: false,
    icon: 'images/location_highlight.png'
  };

  /**
   * @description It is toggle flag which decides to display label on marker icon
   * @memberof GeoMapComponent
   */
  labelMarker = false;

  /**
   * @description It is toggle flag which decides to display color on marker icon
   * @memberof GeoMapComponent
   */
  colorMarker = true;

  /**
   * map setting ngbDropdown element. needed this to close dropdown manually i.e. when clicking out side of element
   * @type {NgbDropdown}
   * @memberof GeoMapComponent
   */
  @ViewChild(NgbDropdown) mapSetting: NgbDropdown;
  /**
   * Channel Container element. needed to check wheter click operation happend inside this element or not for manual dropdown close
   * @type {ElementRef}
   * @memberof FilterAreaComponent
   */
  @ViewChild('mapSettingContainer') mapSettingContainer: ElementRef;

  /**
   * whether other campaign slider is visible or not
   */
  isCampaignVisible = false;

  openedCampaigns: LookupCampaignModel[] = [];

  /**
   * marker cluster object
   * @type {*}@memberof GeoMapComponent
   */
  // openCampaignMarkers: Array<any> = [];

  /**
   * marker cluster object
   * @type {*}@memberof GeoMapComponent
   */
  openCampaignMarkerCluster = [];

  colorList: string[] = ['#935cf9', '#b20f50', '#e97333', '#fa60d8', '#35586a', '#ffd79b'];

  bookingDetailsParams: BookingDetailsParam = {};

  /**
   * Frame search object
   * @type {FrameModel} - searched frame selected object
   * @memberof GeoMapComponent
   */
  frameSearchObj: FrameModel = null;

  systemFlags = SystemFlags;

  /**
   * whether Indoor map slider is visible or not
   */
  isIndoorMapVisible = false;

  indoorMaps: any[] = [];
  indoorMapsDrp: any[] = [];

  activeIndoorMapId = null;

  indoorMapSettings = {
    maxZoom: 25,
    minZoom: 14,
    mapBaseUrl: ''
  };

  compactMarker = false;

  svgMarkers = {
    iconParam: {
      ...svgIconSettings.defaultOptions,
      fill: '#15cf4e'
    },
    colorCodes: {
      0: '#15cf4e',
      1: '#f09e00',
      2: '#f42134',
      '0_cart': '#02a5ef'
    },
  };
  mapSvgIcon: MapSvgIcon;
  iconLegendData: any[] = [];
  isDefaultMap = true;
  showMarker = true;
  restrictIndoorFramCartSelections = false;
  zoomScale = 22;
  indoorMapBounds: google.maps.LatLngBounds = null;
  indoorMapMaxZoom = null;
  locationInAlphabeticalOrder = false;
  /**
   * isMarkerSelected is used for draggable popup
   */
  isMarkerSelected = false;
  /**
   * To show callout/guiding box of popup
   */
  showGuideIMP = false;
  /**
   * To show callout/guiding box of indoormap dropdown
   */
  showGuideIM = false;
  /**
   * Configrable property to show guiding box
   */
  guidingIndoorMap = false;
  /**
   * Configrable property to enable zoom disablility feature
   */
  disableZoom = false;
  /**
   * Configrable property to enable draggable furniture box
   */
  draggableFurnitureSelectionBox = false;
  /**
   * indoor furniture count
   */
  indoorFurniture = 0;
  /**
   * outdoor furniture count
   */
  outdoorFurniture = 0;
  minIconSize = 15;
  environmentId = null;

  /**
  * ngx-slider options for Marker Icon Size
  */
  public sliderMarkerIconSizeoptions: Options = {
    floor: 10,
    ceil: 38,
    step: 1
  };

  /**
 * ngx-slider options for Marker Text Size
 */
  public sliderMarkerTextSizeoptions: Options = {
    floor: 10,
    ceil: 20,
    step: 1
  };

  sliderMarkerIconSizeSelectedValue: number = 38;
  sliderMarkerTextSizeSelectedValue: number = 14;
  /*
   * to toggle marker labels long or short as per SM-10108
   */
  shortenMarker = false;

  @ViewChild("placeSearch") public searchElementRef: ElementRef;

  constructor(private geoMapService: GeoMapService,
    private logHelper: LogHelperService,
    private dataShareService: DataShareService,
    private modalService: NgbModal,
    private distanceConverterService: DistanceConverterService,
    public cartService: CartService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private brickBaseService: BrickBaseService,
    private filterDataService: FilterDataService,
    private campaignService: CampaignService,
    private datePipe: DatePipe,
    private domSanitizer: DomSanitizer,
    private ref: ChangeDetectorRef) {
    this.mapSvgIcon = new MapSvgIcon();
    this.assetsData = [];
    this.selectedAssetsData = [];
    this.tempSelectedAssetsData = [];
    this.shapeCollection = new ShapeCollection(this.onSelectedShapeChange.bind(this));
    this.labelCollection = new MapLabelCollection();
  }
  // Agm Map variables
  agmDrawingMode = null;
  isInfoWindowOpen = false;
  isCircleInfoWindowOpen = false;
  // For SM-7386
  defaultFurnitureDetailsPosition: any = { x: -250, y: -150 };
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  /**
   * Angular lifecycle hook - will be Called once after component Initialize
   * @memberof GeoMapComponent
   */
  ngOnInit() {
    this.userData = this.dataShareService.getInitialConfigByKey('userData');
    this.lat = this.userData.latitude;
    this.lng = this.userData.longitude;
    this.zoomLevel = this.userData.zoomLevel;
    this.googleMapOptions.center = new google.maps.LatLng(this.lat, this.lng);
    this.googleMapOptions.zoom = this.zoomLevel;
    this.indoorMapSettings.mapBaseUrl = this.initialConfig.uiControl.indoorMapBaseUrl;
    this.restrictIndoorFramCartSelections = this.initialConfig.uiControl.restrictIndoorFramCartSelections;
    this.guidingIndoorMap = this.initialConfig.uiControl.guidingIndoorMap;
    this.draggableFurnitureSelectionBox = this.initialConfig.uiControl.draggableFurnitureSelectionBox;
    this.labelMarker = this.initialConfig.uiControl.defaultMarkerLabelVisibility;
    this.disableZoom = this.initialConfig.uiControl.disableZoom;
    this.environmentId = this.initialConfig.systemData.environmentId;
    this.locationInAlphabeticalOrder = this.initialConfig.uiControl.locationInAlphabeticalOrder;
    this.sliderMarkerTextSizeSelectedValue = this.initialConfig.uiControl.geoMarkerLabelDefaultTextSize ? this.initialConfig.uiControl.geoMarkerLabelDefaultTextSize : 14;
    this.sliderMarkerIconSizeSelectedValue = this.initialConfig.uiControl.geoMarkerDefaultIconSize ? this.initialConfig.uiControl.geoMarkerDefaultIconSize : 38;
    // ag-grid config for frame selection popup
    this.gridOptionsFrameSelection = <GridOptions>{
      suppressMovableColumns: true,
      suppressCellSelection: true,
      context: {
        componentParent: this
      },
      defaultColDef: {
        resizable: false
      },
      getRowHeight: (params): number => {
        if (params.data.frames.length > 2) {
          return (params.data.frames.length * 25);
        } else {
          return 50;
        }
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      columnDefs: this.getFrameSelectColumnDefs()
    };

    // Load legend data
    this.setLegendData();
    this.filterDataSubscription();
    this.geoMapService.shortenMarker = this.shortenMarker;

    this.mapDataSubscriber = this.geoMapService.mapData$
      .subscribe(this.mapDataSubscription);
    this.cartDataSubscriber = this.cartService.cartData$.subscribe(this.cartDataSubscription);
  }

  /**
 * Trigger on document click
 * this is needed for map setting ngbDropdown component to manually close on clicking out side of component
 * @param {any} $event
 * @memberof GeoMapComponent
 */
  onDocumentClick($event: MouseEvent): void {
    const dropdownEle: any = this.mapSetting;
    if (dropdownEle.isOpen()) {
      if ($event.button !== 2 && !dropdownEle._isEventFromToggle($event)
        && !!this.mapSettingContainer.nativeElement && !this.mapSettingContainer.nativeElement.contains($event.target)) {
        dropdownEle.close();
      }
    }
  }

  /**
   * Set the legend Data list
   */
  setLegendData() {
    const channelSelectionData = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Environment, null);
    const channelLookupData = this.sharedService.getLookupData(channelSelectionData.lookup[0].selectionId, null);
    this.legendData = _.cloneDeep(channelLookupData[0].data);
    for (const legendData of this.legendData) {
      legendData.selected = false;
      let icon = 'images/icons-assets/';
      if (this.markerIcon[legendData.gmIconId]) {
        icon += this.markerIcon[legendData.gmIconId];
      } else {
        icon += this.markerIcon[0];
      }
      icon = `${icon}.png`;
      legendData.icon = icon;
    }
  }

  /**
   * get ag-grid column config object for frame selection grid
   * @returns
   * @memberof GeoMapComponent
   */
  getFrameSelectColumnDefs(): ColDef[] {
    return [
      {
        headerName: this.initialConfig.userBundle['geoplanner.text.furniture'] || 'Furniture',
        field: this.shortenMarker ? 'furnitureName' : 'furnitureFullName',
        headerClass: 'left-pad-30',
        cellRendererFramework: AgFurnitureComponent
      },
      {
        headerName: this.initialConfig.userBundle['geoplanner.text.frames'] || 'Frames',
        field: 'frames',
        cellRendererFramework: AgFrameComponent
      }
    ];
  }

  /**
   * Angular lifecycle hook - will be Called after any input property changes
   * @memberof GeoMapComponent
   */
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add 'implements OnChanges' to the class.
    if (changes.isFilterAreaVisible && !changes.isFilterAreaVisible.firstChange
      && (changes.isFilterAreaVisible.currentValue !== changes.isFilterAreaVisible.previousValue)) {
      this.resizeMap();
    }
    // resize map on Filter bar collapse/Expand
    if (changes.isFilterBarExpanded && !changes.isFilterBarExpanded.firstChange
      && (changes.isFilterBarExpanded.currentValue !== changes.isFilterBarExpanded.previousValue)) {
      this.resizeMap();
    }
  }

  /**
   * Resize google map
   * @memberof GeoMapComponent
   */
  resizeMap(): void {
    setTimeout(() => {
      google.maps.event.trigger(this.mapObj, 'resize');
    }, 500);
  }

  /**
   * cart data subscription method
   * @memberof GeoMapComponent
   */
  cartDataSubscription = (): void => {
    this.calculateMapFrames();
  }

  /**
   * Subscription method for filter data change
   * @memberof GeoMapComponent
   */
  filterDataSubscription(): void {
    this.filterDataSubscriber = this.filterDataService.filterData$
      .subscribe(() => {
        this.filter_channelChange();
      });
  }

  /**
   * channel Data change from Filter data subscription
   * @memberof GeoMapComponent
   */
  filter_channelChange() {
    // Temporary hide legend and legend relation with map marker
    // this.legendData.map((channel) => channel.selected = false);
    // if (data && data.length > 0) {
    //     for (let i = 0; i < data.length; i++) {
    //         for (let j = 0; j < this.legendData.length; j++) {
    //             if (this.legendData[j].id == data[i].id) {
    //                 this.legendData[j].selected = true;
    //                 break;
    //             }
    //         }
    //     }
    // }
  }

  /**
   * frame filter subscription method
   * @memberof GeoMapComponent
   */
  // frameFilterSubscription = (frameFilter: FrameFilterType) => {
  //     this.selectedFrameFilter = frameFilter;
  //     if (this.assetsData.length > 0) {
  //         this.createMarkers();
  //     }
  // }

  onFrameFilterChange(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures,
      EventActionsMapFeatures.MapFilter, 'All, Available, selected filter on map');
    if (this.assetsData.length > 0) {
      this.createMarkers();
    }
  }

  calculateMapFrames(): void {
    this.allFrameOnMap = 0;
    this.availableFrameOnMap = 0;
    this.selectedFrameOnMap = 0;
    this.assetsData.forEach((furniture) => {
      this.allFrameOnMap += furniture.frames.length;
      furniture.frames.forEach((frame) => {
        if (frame.status === FrameStatus.available) {
          this.availableFrameOnMap += 1;
        }
        if (this.cartService.isFrameInCart(frame.frameId)) {
          this.selectedFrameOnMap += 1;
        }
      });
    });
  }

  onViewCartOnMapClick(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures, EventActionsMapFeatures.ToggleCart, 'Toggle View Cart on map');
    if (this.isViewCartOnMap) {
      this.filterDataService.restoreFilterData();
    } else {
      this.filterDataService.setBackupFilterData();
      this.campaignService.loadCartOnMap();
      if (this.environmentId === EnvironmentId.US && this.outdoorFurniture === 0) {
        this.setBlankMapStyle();
      }
    }
  }

   /**
   * this will set minor spaces between marker locations on geo map, 
   * if it has same latitude and longitude for multiple markers
   * @param {any} assetData
   * @memberof GeoMapComponent
   */
  addOffsetToSameCoordinates = (assetData: FurnitureModel[]) => {
    for (let index = 0; index < assetData.length; index++) {
          for (let j = 1; j < assetData.length; j++) {
            //check to see if 2 coordinates are same
            if(assetData[index].longitude === assetData[j].longitude &&
              assetData[index].latitude === assetData[j].latitude) {
                //update the position of the coincident marker by applying a small multipler to its coordinates
                assetData[index].latitude = assetData[index].latitude + (Math.random() - 0.05) / 2500
                assetData[index].longitude = assetData[index].longitude + (Math.random() - 0.05) / 2500
          }
          }
        }
        return assetData
  }

  /**
   * Map assests data subscription method
   * @memberof GeoMapComponent
   */
  mapDataSubscription = (data: MapDataObservableModel): void => {
    this.resetIndoorMap();
    this.resetGuide();
    this.assetsData = this.addOffsetToSameCoordinates(data.mapData) || [];
    this.indoorMaps = data.indoorMaps || [];
    this.manageIconProp();
    this.setUniqueIconDetails();
    this.getIndoorMapForDrp();
    this.countFurniture();
    this.checkZoomDisabilityByCount();
    this.cartService.setRequestJSON(data.requestJSON);
    this.cartService.setRequestFrameTimings(data.requestFrameTimings);
    this.cartService.setIsViewCartOnMap(data.isViewCartOnMap);
    this.isViewCartOnMap = data.isViewCartOnMap;
    if (this.environmentId === EnvironmentId.US && this.outdoorFurniture === 0 && this.isViewCartOnMap) {
      this.setBlankMapStyle();
    }
    this.frameSearchObj = null;
    this.calculateMapFrames();
    this.markers = [];
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
    }
    // close info windows
    if (this.circleInfoWindowObj) {
      this.isCircleInfoWindowOpen = false;
    }
    if (this.infoWindowObj) {
      this.isInfoWindowOpen = false;
      this.bookingDetailsParams = {};
    }
    this.hideFurniturePopup();
    this.createMarkers();
    if (this.assetsData.length > 0) {
      if (this.mapObj) {
        this.mapObj.setCenter({
          lat: this.assetsData[0].latitude,
          lng: this.assetsData[0].longitude
        });
      }
    } else if (this.openedCampaigns.length > 0) {
      if (this.mapObj && this.openedCampaigns[0].assetData.length > 0) {
        this.mapObj.setCenter({
          lat: this.openedCampaigns[0].assetData[0].latitude,
          lng: this.openedCampaigns[0].assetData[0].longitude
        });
      }
    }

    this.drawingControlActiveBtn = 1;
    setTimeout(() => {
      if (this.guidingIndoorMap && this.indoorMapsDrp && this.indoorMapsDrp.length > 0) {
        this.showGuideIM = true;
      }
    }, 100);
  }

  resetIndoorMap() {
    this.clearIndoorMap();
  }

  getIndoorMapForDrp() {
    if (this.locationInAlphabeticalOrder) {
      this.indoorMapsDrp = _.sortBy(_.cloneDeep(this.indoorMaps), 'mapName');
    } else {
      this.indoorMapsDrp = _.cloneDeep(this.indoorMaps);
    }
  }

  getMarkerIcon(asset: FurnitureModel): google.maps.Icon {
    const icon: google.maps.Icon = {
      url: asset.icon,
      origin: new google.maps.Point(0, 0),
      scaledSize: new google.maps.Size(this.sliderMarkerIconSizeSelectedValue, this.sliderMarkerIconSizeSelectedValue),
      anchor: new google.maps.Point(asset.width / 2, asset.height)
    };

    if (this.compactMarker) {
      icon.scaledSize = new google.maps.Size(24, 24);
    }

    if (asset.iconImage) {
      let dynamicWidth = asset.width;
      let dynamicHeight = asset.height;
      if (this.zoomScale && !isNaN(this.zoomScale)) {
        const ratio = this.zoomScale / 20;
        dynamicWidth = ((this.zoomScale * asset.width) / 13) * ratio;
        dynamicHeight = ((this.zoomScale * asset.height) / 13) * ratio;
      }
      icon.size = new google.maps.Size(dynamicWidth + svgIconSettings.extraSpaceForContainer, dynamicHeight + svgIconSettings.extraSpaceForContainer);
    }

    if (!this.showMarker) {
      icon.scaledSize = new google.maps.Size(10, 10);
    }

    icon.labelOrigin = new google.maps.Point(icon.anchor.x, icon.anchor.y + 6 + Math.round(this.sliderMarkerTextSizeSelectedValue / 4)); //setting the y-point near to 10
    return icon;
  }

  createMarkers() {
    this.markers = [];
    this.setAssetVisibility();
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
    }
    for (const curAsset of this.assetsData) {
      if (curAsset.visible) {
        this.setFrameIcon(curAsset);
        curAsset.width = this.sliderMarkerIconSizeSelectedValue;
        curAsset.height = this.sliderMarkerIconSizeSelectedValue;
        const markerTitle = this.shortenMarker ? curAsset.furnitureName?.toString() : curAsset.furnitureFullName?.toString();
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(curAsset.latitude, curAsset.longitude),
          icon: this.getMarkerIcon(curAsset),
          label: (this.labelMarker && markerTitle ? {
            fontWeight: 'bold',
            color: 'black',
            fontSize: this.sliderMarkerTextSizeSelectedValue.toString() + 'px',
            text: markerTitle,
          } : ''),
          title: (this.labelMarker ? '' : markerTitle),
          // animation: google.maps.Animation.DROP,

        });
        marker['furnitureId'] = curAsset.furnitureId;
        marker['frames'] = curAsset.frames;
        marker.addListener('click', (event) => {
          this.onMarkerClick(event, curAsset);
        });
        this.markers.push(marker);
      }
    }

    this.createMarkersOtherCampaigns();
    this.createMarkerClusters();
  }

  createMarkerClusters(): void {
    if (this.mapObj) {
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
        this.markerCluster = null;
      }
      // Cluster all the markers
      this.markerCluster = new MarkerClusterer(this.mapObj, this.markers, {
        imagePath: 'scripts/3rdParty/markerclusterer/images/m',
        maxZoom: this.assetsData.length > 0 ? 10 : -1,
        gridSize: this.assetsData.length > 0 ? 50 : -1
      });
      this.markerCluster.setCalculator(this.markerClusterCalculator);
    } else {
      setTimeout(() => {
        this.createMarkerClusters();
      }, 100);
    }
  }

  /**
   * Angular lifecycle hook - will be Called once  just before Angular destroys the directive/component
   * @memberof GeoMapComponent
   */
  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.mapDataSubscriber.unsubscribe();
    this.cartDataSubscriber.unsubscribe();
    this.filterDataSubscriber.unsubscribe();
    MapLabel = undefined;
  }

  /**
   * selected shape change callback
   * this will also trigger when clicking on shape
   * @param {any} isSelectedShapeClicked
   * @memberof GeoMapComponent
   */
  onSelectedShapeChange(isSelectedShapeClicked: boolean) {
    this.resetGuide();
    let assetsList: FurnitureModel[] = [];
    let iwPos: google.maps.LatLng;
    this.isShapePropVisible = true;
    if (this.shapeCollection.selectedShape) {
      if (this.shapeCollection.selectedShape.type === google.maps.drawing.OverlayType.POLYGON) {
        assetsList = this.getAssetsInsidePolygon(this.shapeCollection.selectedShape);

        // find center of polygon
        const bounds = new google.maps.LatLngBounds();
        this.shapeCollection.selectedShape.getPath().forEach((element) => { bounds.extend(element); });
        iwPos = bounds.getCenter();
      } else if (this.shapeCollection.selectedShape.type === google.maps.drawing.OverlayType.RECTANGLE) {
        assetsList = this.getPointsInsideRectangle(this.shapeCollection.selectedShape);
        iwPos = this.shapeCollection.selectedShape.bounds.getCenter();
        if (this.isMinZoomIndoorMap()) {
          iwPos = this.getIndoorMapCenter();
        }
      } else if (this.shapeCollection.selectedShape.type === google.maps.drawing.OverlayType.CIRCLE) {
        assetsList = this.getPointsInsideCircle(this.shapeCollection.selectedShape);
        iwPos = this.shapeCollection.selectedShape.center;
        if (this.isMinZoomIndoorMap()) {
          iwPos = this.getIndoorMapCenter();
        }
      }
      this.selectedShapeColor = this.shapeCollection.selectedShape.fillColor;
      if (this.labelCollection.exists(this.shapeCollection.selectedShape.id)) {
        this.labelCollection.setPosition(this.shapeCollection.selectedShape.id, iwPos);
        this.selectedShapeText = this.labelCollection.getText(this.shapeCollection.selectedShape.id);
      } else {
        this.selectedShapeText = '';
        this.labelCollection.add(this.selectedShapeText, iwPos, this.mapObj, this.shapeCollection.selectedShape.id);
      }
    }

    this.selectedAssetsData = [];
    this.allSelected = true;
    for (const obj of assetsList) {
      this.addAssetInSelectedList(obj);
    }
    this.verifyGuideIMPBulk(assetsList);
    this.tempSelectedAssetsData = _.cloneDeep(this.selectedAssetsData);
    this.setframeSelectionGridHeight();

    this.bookingDetailsParams = {};
    if (this.shapeCollection.selectedShape) {
      this.setDisableSaveFrameBtn();
      if (isSelectedShapeClicked && this.infoWindowObj) { // check info window is already open or not
        // do nothing as info window is already open
        this.infoWindowObj.latitude = iwPos.lat();
        this.infoWindowObj.longitude = iwPos.lng();
        this.ref.detectChanges();
      } else {
        if (this.draggableFurnitureSelectionBox) {
          this.initializeDraggablePopup();
        } else {
          this.infoWindowObj.latitude = iwPos.lat();
          this.infoWindowObj.longitude = iwPos.lng();
          this.isInfoWindowOpen = true;
          this.ref.detectChanges();
        }
        if (this.gridOptionsFrameSelection.api) {
          this.gridOptionsFrameSelection.api.sizeColumnsToFit();
        }
      }
    } else {
      this.isInfoWindowOpen = false;
    }
  }

  private getIndoorMapCenter(): google.maps.LatLng {
    const iwPos = this.indoorMapBounds.getCenter();
    const lat = iwPos.lat();
    const lng = iwPos.lng();
    return new google.maps.LatLng(lat - 0.0007, lng);
  }

  private isMinZoomIndoorMap(): boolean {
    return this.activeIndoorMapId && this.indoorMapBounds && this.indoorMapMaxZoom >= this.zoomScale;
  }

  addAssetInSelectedList(asset: FurnitureModel): void {
    const assets = _.clone(asset);
    assets.selected = true;
    assets.frames = assets.frames.filter((frame: FrameModel) => {
      return frame.visible;
    });
    let anyFrameAvailable = false;
    assets.frames.map((frame: FrameModel) => {
      if (frame.status === FrameStatus.available) {
        anyFrameAvailable = true;
      }
      frame.isDeletable = true;
      if (this.cartService.isFrameInCart(frame.frameId)) {
        frame.selected = true;
        frame.isDeletable = this.cartService.isFrameDeletable(frame.frameId);
      } else {
        frame.selected = false;
        if (frame.status === FrameStatus.available) {
          assets.selected = false;
          this.allSelected = false;
        }
      }
    });
    if (!this.isViewCartOnMap && !anyFrameAvailable) {
      assets.selected = false;
    }
    if (this.restrictIndoorFramCartSelections) {
      this.setDisabilityOfFurniture(assets);
    }
    this.selectedAssetsData.push(assets);
  }

  setDisabilityOfFurniture(assets: FurnitureModel) {
    if (!this.activeIndoorMapId && assets.mapId) {
      assets.disabled = true;
      if (Array.isArray(assets.frames)) {
        assets.frames.forEach((frame: FrameModel) => {
          frame.disabled = true;
        });
      }
    } else {
      assets.disabled = false;
      if (Array.isArray(assets.frames)) {
        assets.frames.forEach((frame: FrameModel) => {
          frame.disabled = false;
        });
      }
    }
  }

  setframeSelectionGridHeight(): void {
    this.frameSelectionGridHeight = '200px';
    let frameCount = 0;
    this.selectedAssetsData.forEach((asset: FurnitureModel) => {
      frameCount += asset.frames.length;
    });
    if (frameCount < 7) {
      if (frameCount > 1) {
        this.frameSelectionGridHeight = `${((frameCount * 25) + 30)}px`;
      } else {
        // minimum height
        this.frameSelectionGridHeight = '80px';
      }
    }
  }

  setAssetVisibility(): void {
    // set furniture visiblility based on legend selection
    const selectedLegends = this.legendData.filter(c => c.selected);
    if (selectedLegends.length > 0) {
      this.legendData.forEach((legend) => {
        if (legend.selected) {
          this.assetsData.forEach((asset: FurnitureModel) => {
            if (asset.legendId === legend.id) {
              asset.visible = true;
            }
          });
        } else {
          this.assetsData.forEach((asset: FurnitureModel) => {
            if (asset.legendId === legend.id) {
              asset.visible = false;
            }
          });
        }
      });
    } else {
      this.assetsData.forEach((asset: FurnitureModel) => {
        asset.visible = true;
      });
    }

    // set furniture visibility based on indoor map
    if (this.activeIndoorMapId) {
      this.assetsData.forEach((asset: FurnitureModel) => {
        if (asset.visible) {
          if (String(asset.mapId) !== String(this.activeIndoorMapId)) {
            asset.visible = false;
          }
        }
      });
    }

    if (this.environmentId === EnvironmentId.US && this.isViewCartOnMap && this.outdoorFurniture === 0 && !this.activeIndoorMapId) {
      this.assetsData.forEach((asset: FurnitureModel) => {
        if (asset.visible) {
          asset.visible = false;
        }
      });
    }

    // set furniture visibility based on all
    if (this.selectedFrameFilter === FrameFilterType.all) {
      this.assetsData.forEach((asset: FurnitureModel) => {
        if (asset.visible) {
          for (const frame of asset.frames) {
            frame.visible = true;
          }
        }
      });
    } else if (this.selectedFrameFilter === FrameFilterType.selected) {
      this.assetsData.forEach((asset: FurnitureModel) => {
        if (asset.visible) {
          let isAnyFrameInFurSelected = false;
          for (const frame of asset.frames) {
            if (this.cartService.isFrameInCart(frame.frameId)) {
              isAnyFrameInFurSelected = true;
              frame.visible = true;
            } else {
              frame.visible = false;
            }
          }
          if (!isAnyFrameInFurSelected) {
            asset.visible = false;
          }
        }
      });
    } else if (this.selectedFrameFilter === FrameFilterType.available) {
      this.assetsData.forEach((asset: FurnitureModel) => {
        if (asset.visible) {
          let isAnyFrameInFurAvailable = false;
          for (const frame of asset.frames) {
            if (frame.status === FrameStatus.available) { // && !this.cartService.isFrameInCart(asset.frames[i].frameId)
              isAnyFrameInFurAvailable = true;
              frame.visible = true;
            } else {
              frame.visible = false;
            }
          }
          if (!isAnyFrameInFurAvailable) {
            asset.visible = false;
          }
        }
      });
    }
  }

  /**
   * set marker icon for frame
   * @param asset - furniture data object
   */
  setFrameIcon(asset: FurnitureModel) {
    if (!this.showMarker) {
      asset.icon = this.getDotIcon();
      return;
    }
    if (asset.iconImage) {
      asset.icon = this.getSvgIcon(asset);
      return;
    }
    asset.icon = this.getPngIcon(asset);
  }

  getSvgIcon(asset: FurnitureModel, isForLegend = false) {
    this.svgMarkers.iconParam.fill = this.svgMarkers.colorCodes[this.getFrameIconColorPrefix(asset)];
    return this.mapSvgIcon.getIcon(asset, this.svgMarkers.iconParam, this.isAnyFrameSelected(asset), this.mapSvgIcon.fetchSVG(asset.iconImage), this.zoomScale, isForLegend);
  }

  getDotIcon() {
    return this.mapSvgIcon.getDotIcon();
  }

  getPngIcon(asset: FurnitureModel, isForLegend = false) {
    let icon = 'images/icons-assets/';
    if (asset.gmIconId && asset.gmIconId !== '') {
      icon += this.markerIcon[asset.gmIconId];
    } else {
      icon += this.markerIcon[0];
    }
    icon = `${icon}_${this.getFrameIconColorPrefix(asset)}`;
    if (!isForLegend) {
      icon += this.isAnyFrameSelected(asset) ? '_selected' : '';
    }

    icon += '.png';
    return icon;
  }

  getFrameIconColorPrefix(asset: FurnitureModel): string {
    let colorPrefix = '1';
    let furnitureStatus: FurnitureStatus = this.geoMapService.getFurnitureAvailabilityStatus(asset);
    if (furnitureStatus === FurnitureStatus.partialAvailable) {
      furnitureStatus = FurnitureStatus.available;
    }
    if (this.colorMarker) {
      const cartSelected: FrameModel[] = asset.frames.filter((fra: FrameModel) => {
        return this.cartService.isFrameSelectedInCart(fra.frameId);
      });
      let isSearchedFrame = false;
      if (this.frameSearchObj) {
        const frameSelected: FrameModel[] = asset.frames.filter((fra: FrameModel) => {
          return fra.frameId === this.frameSearchObj.frameId;
        });
        isSearchedFrame = frameSelected.length > 0;
      }
      if (cartSelected.length > 0 || isSearchedFrame) {
        colorPrefix = '0_cart';
      } else {
        colorPrefix = furnitureStatus.toString();
      }
    }

    return colorPrefix;
  }

  isAnyFrameSelected(asset: FurnitureModel): boolean {
    let isAnyFrameInFurSelected = false;
    for (const frame of asset.frames) {
      if (this.cartService.isFrameInCart(frame.frameId)) {
        isAnyFrameInFurSelected = true;
        break;
      }
    }
    return isAnyFrameInFurSelected;
  }

  /**
   * called when ng-ui/map is ready
   * @param {any} map - google map Object
   * @memberof GeoPlannerComponent
   */
  onMapReady(map) {
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(document.getElementById('result-button'));
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(document.getElementById('cart-button'));
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(document.getElementById('legend-button'));
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(document.getElementById('indoor-button'));
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(document.getElementById('campaign-button'));
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(document.getElementById('switchMarker-button'));
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('drawingControlBtnGrp'));
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('viewByBtnGrp'));
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('placeAutoSearch'));
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('frameSearch'));
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('selectedFrameFilterBtnGrp'));
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('viewCartmapbtn'));
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('indoormapDropdown'));
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('indoorMapGuide'));
    // map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('selectedAssets'))
    this.isMapLoaded = true;
    this.mapObj = map;

    // request email from salim and rachel, to hide google maps default POI from map
    if (this.environmentId === EnvironmentId.US && this.outdoorFurniture === 0 && this.isViewCartOnMap) {
      this.setBlankMapStyle();
    } else {
      this.setDefaultMapStyle();
    }

    // MapLabel library has dependency on google maps library
    // so it is needed to check maps library is loaded than load map label library
    if (typeof (MapLabel) === 'undefined') {
      // DOM: Create the script element
      const jsElm = document.createElement('script');
      // set the type attribute
      jsElm.type = 'application/javascript';
      // make the script element load file
      jsElm.src = 'scripts/3rdParty/js-map-label-gh-pages/src/maplabel.js';
      // finally insert the element to the body element in order to load the script
      document.body.appendChild(jsElm);
      const options: any = {
        drawingControl: false,
        drawingControlOptions: {
          drawingModes: ['marker', 'circle', 'polygon', 'rectangle']
        },
        circleOptions: this.circleOptions,
        polygonOptions: this.circleOptions,
        rectangleOptions: this.circleOptions,
        drawingMode: null
      };
      this.drawingManagerObj = new google.maps.drawing.DrawingManager(options);
      this.onDrawingManagerInit(this.drawingManagerObj);
      this.infoWindowInit(this.infoWindowElement);
      this.circleInfoWindowInit(this.circleInfoWindowElement);
    }
  }

  private setDefaultMapStyle(): any {
    this.isDefaultMap = true;
    if (this.mapObj) {
      this.mapObj.setOptions({
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'landscape',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'administrative',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      });
    }
  }

  private setBlankMapStyle(): any {
    this.isDefaultMap = false;
    if (this.mapObj) {
      this.mapObj.setOptions({
        styles: [
          {
            stylers: [{ visibility: 'off' }]
          }, {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [{ visibility: 'on' }, { color: '#fcfcfc' }]
          }, {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ visibility: 'on' }, { color: '#fcfcfc' }]
          }
        ]
      });
    }
  }

  onIdle() {
    this.zoomScale = this.mapObj && this.mapObj.getZoom();
    if (this.assetsData && this.assetsData.length > 0) {
      this.createMarkers();
    }
  }

  /**
   * called when clicking on map marker
   * @param {any} event - click event object
   * @memberOf GeoPlannerComponent
   */
  onMarkerClick(event, markerData: FurnitureModel): void {
    this.infoWindowInit(this.infoWindowElement);
    this.resetGuide();
    this.selectedAssetsData = [];
    this.allSelected = true;
    this.isShapePropVisible = false;
    this.addAssetInSelectedList(markerData);
    this.verifyGuideIMP(markerData);
    this.tempSelectedAssetsData = _.cloneDeep(this.selectedAssetsData);
    this.setframeSelectionGridHeight();
    this.setDisableSaveFrameBtn();
    this.bookingDetailsParams = {};
    if (this.infoWindowObj && this.isInfoWindowOpen) { // check info window is already open or not
      // do nothing as info window is already open
      this.lng = event.latLng.lng();
      this.lat = event.latLng.lat();
      this.infoWindowObj.latitude = event.latLng.lat();
      this.infoWindowObj.longitude = event.latLng.lng();
    } else {
      if (this.draggableFurnitureSelectionBox) {
        this.initializeDraggablePopup();
      } else {
        this.lng = event.latLng.lng();
        this.lat = event.latLng.lat();
        this.infoWindowObj.latitude = event.latLng.lat();
        this.infoWindowObj.longitude = event.latLng.lng();
        this.isInfoWindowOpen = true;
        this.ref.detectChanges();
      }
      if (this.gridOptionsFrameSelection.api) {
        this.gridOptionsFrameSelection.api.sizeColumnsToFit();
      }
    }
  }

  /**
   * This method is responsible to initialize the draggable popup
   * Draggable popup will be displayed at bottom left corner initialy
   */
  initializeDraggablePopup() {
    if (this.mapObj.controls[google.maps.ControlPosition.BOTTOM_LEFT].getLength() === 0) {
      this.isMarkerSelected = true;
      setTimeout(() => {
        const furniturePopupDiv = document.getElementById('draggablePopup');
        if (furniturePopupDiv) {
          this.mapObj.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(furniturePopupDiv);
        }
      }, 100);
    }
  }

  /**
   * Marker Clusterer image calculatin function
   * it can return from 1 to 5 and based on that cluster image will be shown
   * @param {any} markers - List of markers
   * @param {any} numStyles - number styles
   * @returns
   * @memberof GeoMapComponent
   */
  // @ts-ignore
  markerClusterCalculator(markers, numStyles) {
    const index = 1;
    let count = 0;
    const visibleMarkers = markers.filter(m => m.visible);
    // cluster counter show frames count not furniture count
    for (const marker of visibleMarkers) {
      const frames = marker.frames || [];
      for (const frame of frames) {
        if (frame.visible) {
          count++;
        }
      }
    }

    return {
      index,
      text: count,
    };
  }

  /**
   * called when frame selection info window initialize
   * @param {any} infoWindow
   * @memberof GeoMapComponent
   */
  infoWindowInit(infoWindow) {
    this.infoWindowObj = infoWindow;
  }

  /**
   * called when draw circle info window initialize
   *
   * @param {any} infoWindow
   * @memberof GeoMapComponent
   */
  circleInfoWindowInit(infoWindow) {
    this.circleInfoWindowObj = infoWindow;
  }

  /**
   * close frame selection info window
   * @memberof GeoMapComponent
   */
  closeInfoWindow = (id?: string) => {
    if (id === 'circle') {
      this.isCircleInfoWindowOpen = false;
    } else {
      this.bookingDetailsParams = {};
      if (this.infoWindowObj) {
        this.isInfoWindowOpen = false;
      }
    }
    this.hideFurniturePopup();
  }

  /**
   * @description Handles Esc keyup event
   * @author Nikunj Gadhiya
   * @memberof GeoMapComponent
   */
  @HostListener('document:keyup', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event && event.key === 'Escape') {
      this.closeInfoWindow();
    }
  }

  /**
   * triggers when clicking on map
   * @param {any} event
   * @memberof GeoMapComponent
   */
  // @ts-ignore
  onMapClick(event) {
    this.shapeCollection.clearSelection(false);
    this.isCartVisible = false;
    this.isLegendVisible = false;
    this.isCampaignVisible = false;
    this.isIndoorMapVisible = false;
    this.closeInfoWindow();
  }

  /**
   * called when drawing manager init
   * @param {any} dm
   * @memberof GeoMapComponent
   */
  onDrawingManagerInit(dm) {
    this.drawingManagerObj = dm;
    google.maps.event.addListener(dm, 'overlaycomplete', this.onOverlayComplete.bind(this));
    this.drawingManagerObj.setDrawingMode(null);
    this.drawingManagerObj.setMap(this.mapObj);
  }

  /**
   * call when overlay draw complete
   * @param {any} event - event object
   * @memberof GeoMapComponent
   */
  onOverlayComplete(event) {
    this.drawingControlActiveBtn = 1;
    this.drawingManagerObj.setDrawingMode(null);

    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures, EventActionsMapFeatures.DrawShape, 'Draw shape on map');
    if (event.type === google.maps.drawing.OverlayType.MARKER) {
      const pos = event.overlay.position;
      // dont draw marker on map
      event.overlay.setMap(null);

      // clear draw circle value object
      this.drawCircleValues.radius = '';
      this.drawCircleValues.unit = '0';
      this.drawCircleValues.decimalPoints = 0;

      // Open Draw Circle Detail Info window
      this.circleInfoWindowObj.latitude = pos.lat();
      this.circleInfoWindowObj.longitude = pos.lng();
      this.isCircleInfoWindowOpen = true;
    } else {
      this.shapeCollection.add(event.overlay, event.type);
    }
  }

  /**
   * In draw circle info window, unit type change call back
   * @memberof GeoMapComponent
   */
  onUnitChange() {
    if (this.drawCircleValues.unit === '0') {
      if (this.drawCircleValues.radius.indexOf('.') > 0) {
        this.drawCircleValues.radius = this.drawCircleValues.radius.split('.')[0];
      }
      this.drawCircleValues.decimalPoints = 0;
    } else {
      this.drawCircleValues.decimalPoints = 2;
    }
  }

  /**
   * draws a circle based on draw circle info window valus selection
   * @memberof GeoMapComponent
   */
  drawCircle() {
    // Draw Cirlcle on map
    const circleOptions = _.clone(this.circleOptions);
    circleOptions.map = this.mapObj;
    circleOptions.center = { lat: this.circleInfoWindowObj.latitude, lng: this.circleInfoWindowObj.longitude };
    let radius = parseInt(this.drawCircleValues.radius, 10);
    if (this.drawCircleValues.unit === '1') {
      radius = this.distanceConverterService.fromKmToMeter(parseInt(this.drawCircleValues.radius, 10));
    } else if (this.drawCircleValues.unit === '2') {
      radius = this.distanceConverterService.fromMilesToMeter(parseInt(this.drawCircleValues.radius, 10));
    } else if (this.drawCircleValues.unit === '3') {
      radius = this.distanceConverterService.fromFeetToMeter(parseInt(this.drawCircleValues.radius, 10));
    }
    circleOptions.radius = radius;
    const circle = new google.maps.Circle(circleOptions);
    this.shapeCollection.add(circle, google.maps.drawing.OverlayType.CIRCLE);
    this.isCircleInfoWindowOpen = false;
  }

  /**
   * Redirect to result page
   * @memberof GeoMapComponent
   */
  redirectToResultPage() {
    this.goToResultPage.emit();
  }

  /**
   * called when user search location in map
   * @param place - user entered location
   */
  placeChanged(place) {
    if (!place.geometry) { return; }

    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures, EventActionsMapFeatures.SearchLocation, 'Search location on map');
    if (place.geometry.viewport) {
      this.mapObj.fitBounds(place.geometry.viewport);
    } else if (place.geometry.location) {
      this.mapObj.setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
    }
    if (place.geometry.location) {
      this.placeHighlighterMarker.position = place.geometry.location;
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
    }
    this.placeHighlighterMarker.visible = true;
  }

  /**
   * called when user change Map view by
   */
  onViewByChange() {
    console.log(this.viewBy);
  }

  // @ts-ignore
  onPlaceInputChange(event) {
    this.placeHighlighterMarker.visible = false;
  }

  /**
   * called when user click on clear button of auto complete input in map
   */
  onClearAutoSearch() {
    const inputAutoEle: any = document.getElementById('inputAutoSearch');
    inputAutoEle.value = '';
    inputAutoEle.focus();
    this.placeHighlighterMarker.visible = false;
  }

  /**
   * Delete selected shape
   * @memberof GeoMapComponent
   */
  deleteShape() {
    if (this.shapeCollection.selectedShape) {
      this.labelCollection.delete(this.shapeCollection.selectedShape.id);
      this.shapeCollection.deleteSelected();
      this.hideFurniturePopup();
    }
  }

  /**
   * get the list of frames inside rectangle
   * @param {*} overlayObj
   * @returns
   * @memberof GeoMapComponent
   */
  getPointsInsideRectangle(overlayObj: any): FurnitureModel[] {
    const assetsList: FurnitureModel[] = [];
    const bounds = overlayObj.getBounds();
    for (const curAsset of this.assetsData) {
      if (curAsset.visible) {
        if (bounds.contains({ lat: curAsset.latitude, lng: curAsset.longitude })) {
          assetsList.push(curAsset);
        }
      }
    }
    return assetsList;
  }

  /**
   * get the list of frames inside circle
   * @param {*} overlayObj
   * @returns
   * @memberof GeoMapComponent
   */
  getPointsInsideCircle(overlayObj: any): FurnitureModel[] {
    const assetsList: FurnitureModel[] = [];
    const circleCenterPos: google.maps.LatLng = overlayObj.getCenter();
    const circleRadius: number = overlayObj.getRadius();
    for (const curAsset of this.assetsData) {
      if (curAsset.visible) {
        const assetLatLng = new google.maps.LatLng(curAsset.latitude, curAsset.longitude);
        if (google.maps.geometry && google.maps.geometry.spherical && google.maps.geometry.spherical.computeDistanceBetween(assetLatLng, circleCenterPos) <= circleRadius) {
          assetsList.push(curAsset);
        }
      }
    }
    return assetsList;
  }

  /**
   * get the list of frames inside polygon
   * @param {*} overlayObj
   * @returns
   * @memberof GeoMapComponent
   */
  getAssetsInsidePolygon(overlayObj: any): FurnitureModel[] {
    const assetsList: FurnitureModel[] = [];
    for (const curAsset of this.assetsData) {
      if (curAsset.visible) {
        const latLng = new google.maps.LatLng(curAsset.latitude, curAsset.longitude);
        if (google.maps.geometry.poly.containsLocation(latLng, overlayObj)) {
          assetsList.push(curAsset);
        }
      }
    }
    return assetsList;
  }

  setDisableSaveFrameBtn(): void {
    const str1 = JSON.stringify(this.selectedAssetsData);
    const str2 = JSON.stringify(this.tempSelectedAssetsData);
    this.isDisbledSaveFrameBtn = str1 === str2;
  }
  /**
   * in frame selection info window, select all checkbox change
   * @memberof GeoMapComponent
   */
  onSelectAllChange(): void {
    this.selectedAssetsData.forEach((asset: FurnitureModel) => {
      let anyFrameAvailable = false;
      asset.frames.forEach((frame: FrameModel) => {
        if (!frame.disabled) {
          if (this.isViewCartOnMap) {
            anyFrameAvailable = true;
            frame.selected = this.allSelected;
          } else {
            if (frame.status === FrameStatus.available) {
              anyFrameAvailable = true;
              frame.selected = this.allSelected;
            }
          }
        }
      });
      if (anyFrameAvailable) {
        asset.selected = this.allSelected;
      }
    });
    this.setDisableSaveFrameBtn();
  }

  /**
   * @description check/uncheck frames for same visual unit id
   * @author Amit Mahida
   * @param {number} vuId
   * @param {boolean} selected
   * @memberof GeoMapComponent
   */
  markSameVuFrame(vuId: number, selected: boolean) {
    this.assetsData.forEach((asset) => {
      asset.frames.forEach((frame) => {
        if (frame.visualUnitId === vuId) {
          frame.selected = selected;
        }
      });
      let isAllFrameInFurSelected = true;
      for (const frame of asset.frames) {
        if (this.isViewCartOnMap) {
          if (!frame.selected) {
            isAllFrameInFurSelected = false;
            break;
          }
        } else {
          if (!frame.selected && frame.status === FrameStatus.available) {
            isAllFrameInFurSelected = false;
            break;
          }
        }
      }
      asset.selected = isAllFrameInFurSelected;
    });
  }

  /**
   * In frame selection info window, furniture selection change
   * @memberof GeoMapComponent
   */
  onSelectFurnitureChange(asset: FurnitureModel): void {
    for (const frame of asset.frames) {
      if (this.isViewCartOnMap) {
        if (frame.isDeletable) {
          frame.selected = asset.selected;
          if (frame.visualUnitId) {
            this.markSameVuFrame(frame.visualUnitId, asset.selected);
          }
        }
      } else {
        if (frame.isDeletable && frame.status === FrameStatus.available) {
          frame.selected = asset.selected;
          if (frame.visualUnitId) {
            this.markSameVuFrame(frame.visualUnitId, asset.selected);
          }
        }
      }
    }

    const notSelectedAssets: FurnitureModel[] = this.selectedAssetsData.filter((asset1) => {
      return (!asset1.selected);
    });
    this.allSelected = notSelectedAssets.length > 0 ? false : true;
    this.setDisableSaveFrameBtn();
  }

  /**
   * In frame selection info window, frame selection change
   * @memberof GeoMapComponent
   */
  onSelectFrameChange(asset: FurnitureModel, frame: FrameModel): void {
    if (frame.visualUnitId) {
      this.markSameVuFrame(frame.visualUnitId, frame.selected);
    }
    let isAllFrameInFurSelected = true;
    for (const frame of asset.frames) {
      if (this.isViewCartOnMap) {
        if (!frame.selected) {
          isAllFrameInFurSelected = false;
          break;
        }
      } else {
        if (!frame.selected && frame.status === FrameStatus.available) {
          isAllFrameInFurSelected = false;
          break;
        }
      }
    }
    asset.selected = isAllFrameInFurSelected;
    this.setDisableSaveFrameBtn();
    // check all frames are selected
    const notSelectedAssets: FurnitureModel[] = this.selectedAssetsData.filter(asset2 => (!asset2.selected));
    this.allSelected = notSelectedAssets.length > 0 ? false : true;
  }

  /**
   * In frame selection info window, save selected frame
   * @memberof GeoMapComponent
   */
  saveSelectedFrames(): void {
    this.loaderService.show();
    // Show loader - needed timeout to start loader first
    // Needed: In large data set it looks browser hanged
    setTimeout(() => {

      if (SystemFlags.getBasketDataForGP) {
        const modalOptions: any = {
          backdrop: this.ngbModalOptions.backdrop,
          keyboard: this.ngbModalOptions.keyboard,
        };
        this.loaderService.hide();
        const modalRef = this.modalService.open(ConfirmationComponent, modalOptions);
        modalRef.componentInstance.resolveObject = {
          content: this.initialConfig.userBundle['geoplanner.confirm.redirection'],
          title: this.initialConfig.userBundle['result.confirmation.label'],
          ok: this.initialConfig.userBundle['common.ok'],
        };
        modalRef.result.then(() => {
          this.saveFrames();
          SystemFlags.getBasketDataForGP = false;
        }, (reason) => {
          console.log(reason);
        }).catch(() => {
          // nothing to be done on close
        });
      } else {
        this.loaderService.hide();
        this.saveFrames();
      }

    }, 10);
  }

  /**
   * In frame selection info window, save selected frame
   * @memberof GeoMapComponent
   */
  saveFrames(): void {
    this.bookingDetailsParams = {};
    const tempCartData: FrameModel[] = _.cloneDeep(this.cartService.cartData);

    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.Cart, EventActionsCart.SaveFrame, 'Save frame from frame selection');

    for (const asset of this.selectedAssetsData) {
      for (const frame of asset.frames) {
        const ind: boolean = this.cartService.isFrameInCart(frame.frameId);
        if (frame.selected) {
          if (!ind) {
            this.cartService.addFrame(frame, false);
          }
        } else {
          if (ind) {
            this.cartService.removeFrame(frame.frameId, null, null, false);
          }
        }
      }
    }

    this.cartService.saveFrame().subscribe((res: ResponseModel<SaveFrameResponseModel>) => {
      if (res.status === 'OK') {
        this.createMarkers();
        const saveMsg = this.initialConfig.userBundle['geoplanner.success.saveselectedframes'] || 'Selected frames saved.';
        this.logHelper.logSuccess(saveMsg);
        this.isInfoWindowOpen = false;
        this.hideFurniturePopup();
        if (res.data && res.data.basketData && res.data.basketData.length) {
          this.dataShareService.activateResultTab(true);
        }
      } else if (res.status === 'KO') {
        this.cartService.setCartData(_.cloneDeep(tempCartData));
        this.logHelper.logError(res.message);
        this.dataShareService.activateResultTab(false);
      }
    }, (err) => {
      this.cartService.setCartData(_.cloneDeep(tempCartData));
      this.logHelper.logError(err.message);
    });
  }

  /**
   * toggle cart display
   * @memberof GeoMapComponent
   */
  toggleCart(): void {
    this.isCartVisible = !this.isCartVisible;
    this.isLegendVisible = false;
    this.isCampaignVisible = false;
    this.isIndoorMapVisible = false;
  }

  /**
   * toggle cart display
   * @memberof GeoMapComponent
   */
  toggleLegend() {
    this.isLegendVisible = !this.isLegendVisible;
    this.isCartVisible = false;
    this.isCampaignVisible = false;
    this.isIndoorMapVisible = false;
  }

  changeMapStyle() {
    this.isDefaultMap = !this.isDefaultMap;
    if (this.isDefaultMap) {
      this.setDefaultMapStyle();
    } else {
      this.setBlankMapStyle();
    }
  }

  markerShowHideChanged() {
    this.showMarker = !this.showMarker;
    this.createMarkers();
  }

  setUniqueIconDetails() {
    const uniqueIconDetails = {};
    this.iconLegendData = [];
    for (const asset of this.assetsData) {
      if (asset.iconImage) {
        if (!uniqueIconDetails[`${asset.iconImageId}`]) {
          uniqueIconDetails[`${asset.iconImageId}`] = {
            icon: this.domSanitizer.bypassSecurityTrustUrl(this.getSvgIcon(asset, true)),
            iconType: 'svg'
          };
        }
        uniqueIconDetails[`${asset.iconImageId}`].subFamilyNames = this.getSubFamilyNames(uniqueIconDetails[`${asset.iconImageId}`].subFamilyNames, asset.subFamilyName);
      } else {
        if (!uniqueIconDetails[asset.gmIconId]) {
          uniqueIconDetails[asset.gmIconId] = {
            icon: this.getPngIcon(asset, true),
            iconType: 'png',
          };
        }
        uniqueIconDetails[`${asset.gmIconId}`].subFamilyNames = this.getSubFamilyNames(uniqueIconDetails[`${asset.gmIconId}`].subFamilyNames, asset.subFamilyName);
      }
    }
    Object.keys(uniqueIconDetails).forEach((key) => {
      this.iconLegendData.push(uniqueIconDetails[key]);
    });
    if (this.iconLegendData && this.iconLegendData.length) {
      this.iconLegendData.sort((a, b) => {
        if (a && a.subFamilyNames && a.subFamilyNames[0] && b && b.subFamilyNames && b.subFamilyNames[0]) {
          return a.subFamilyNames[0].localeCompare(b.subFamilyNames[0]);
        } else if (a && a.subFamilyNames && a.subFamilyNames[0]) {
          return a.subFamilyNames[0];
        } else if (b && b.subFamilyNames && b.subFamilyNames[0]) {
          return b.subFamilyNames[0];
        }
      });
      for (const legend of this.iconLegendData) {
        if (legend && Array.isArray(legend.subFamilyNames)) {
          legend.subFamilyNames.sort((a, b) => a.localeCompare(b));
        }
      }
    }
  }

  getSubFamilyNames(array: any[], subFamilyName: string) {
    if (array && array.length > 0) {
      if (array.indexOf(subFamilyName) === -1) {
        array.push(subFamilyName);
      }
    } else {
      array = [subFamilyName];
    }
    return array;
  }

  /**
   * Legend selection change
   * @memberof GeoMapComponent
   */
  onLegendSelectionChange(): void {
    this.createMarkers();
  }

  /**
   * open proposal modal popup
   * @memberof GeoMapComponent
   */
  openProposal(): void {
    const modalRef = this.modalService.open(ProposalComponent, this.ngbModalOptions);
    modalRef.componentInstance.resolveObject = {
      brickBgColor: '#0ABD62',
      SelectedValue: null,
      title: this.initialConfig.userBundle['common.proposal'],
      ok: this.initialConfig.userBundle['common.ok'],
      campaignTitle: this.initialConfig.userBundle['result.campaignTitle'],
      CCPHideFinanceEnabled: this.initialConfig.uiControl.CCPHideFinanceEnabled,
      CCPHideFinance: this.initialConfig.userBundle['common.CCPHideFinance'],
      successMsg: this.initialConfig.userBundle['result.showMessage.proposal']
    };
  }

  /**
   * Selected color change
   * @param {*} val
   * @memberof GeoMapComponent
   */
  public onColorChange(val: any): void {
    if (this.shapeCollection.selectedShape) {
      this.shapeCollection.selectedShape.setOptions({ fillColor: val });
    }
    this.selectedShapeColor = val;
  }

  /**
   * Shape text change
   * @memberof GeoMapComponent
   */
  public onShapeLabelChange(): void {
    this.labelCollection.setText(this.shapeCollection.selectedShape.id, this.selectedShapeText);
  }

  /**
   * Trigger when google maps drawing controls change
   * @memberof GeoMapComponent
   */
  public onDrawingControlActionChange(): void {
    switch (this.drawingControlActiveBtn) {
      case 1: // Drag shape
        this.drawingManagerObj.setDrawingMode(null);
        break;
      case 2: // Add POI
        this.drawingManagerObj.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
        break;
      case 3: // Draw Circle
        this.drawingManagerObj.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
        break;
      case 4: // Draw Polygon
        this.drawingManagerObj.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        break;
      case 5: // Draw Rectangle
        this.drawingManagerObj.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
        break;
      case 6: // Delete Selected Shape
        this.deleteShape();
        this.drawingControlActiveBtn = 1;
        break;
      default:
        break;
    }
  }

  /**
   * Show frame detail pop up
   * @param {any} $event - mouse event
   * @param {any} frame - frame for which details need to be shown
   * @memberof GeoMapComponent
   */
  public onShowFrameDetail($event: MouseEvent, frame: FrameModel): void {
    let modalHeight = 203; // 139 height, 64 header menu
    if (frame.availability) {
      modalHeight += 22;
    }
    const topPos = $event.clientY - $event.offsetY - modalHeight;
    const leftPos = $event.clientX - $event.offsetX - 115;
    this.frameDetailObj = {
      frame,
      top: `${topPos}px`,
      left: `${leftPos}px`
    };
  }

  /**
   * Hide frame detail pop up
   * @param {any} $event - mouse event object
   * @memberof GeoMapComponent
   */
  public onHideFrameDetail($event: MouseEvent): void {
    if (!$event['toElement'].classList.contains('framedetailclass')) {
      this.frameDetailObj = {
        frame: null,
        top: '0px',
        left: '0px'
      };
    }
  }

  onShowFurnitureImages(furniture: FurnitureModel): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.CampaignFeatures,
      EventActionsCampaignFeatures.FolioGallery, 'View frame\'s folio gallery images');
    const modalOptions: any = {
      keyboard: this.ngbModalOptions.keyboard,
      size: 'md animated slideInDown',
      windowClass: 'alignmodal'
    };
    const modalRef = this.modalService.open(FolioImageComponent, modalOptions);
    modalRef.componentInstance.furniture = furniture;
  }

  toggleLabelMarker(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures, EventActionsMapFeatures.MarkerLabel,
      'Enable/disable marker label');
    this.labelMarker = !this.labelMarker;
    this.createMarkers();
  }

  toggleColorMarker(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures,
      EventActionsMapFeatures.MarkerColor, 'Enable/disable marker color');
    this.colorMarker = !this.colorMarker;
    this.createMarkers();
  }

  toggleCompactMarker(): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures,
      EventActionsMapFeatures.CompactMarker, 'Enable/disable Compact marker');
    this.compactMarker = !this.compactMarker;
    this.createMarkers();
  }

  //#region - SM-1345 - Open other campaign to plot on map
  toggleCampaign(): void {
    this.isCampaignVisible = !this.isCampaignVisible;
    this.isCartVisible = false;
    this.isLegendVisible = false;
    this.isIndoorMapVisible = false;
  }

  createMarkersOtherCampaigns(): void {
    const uniqeFurnitureIds: number[] = [];
    const furnitureCounts: number[] = [];
    for (const openedCampaign of this.openedCampaigns) {
      if (openedCampaign.exclude) {
        continue;
      }
      const basketData: FurnitureModel[] = openedCampaign.assetData;
      for (const assetData of basketData) {
        const furIndex = uniqeFurnitureIds.indexOf(assetData.furnitureId);
        let furCount = 1;
        if (furIndex === -1) {
          uniqeFurnitureIds.push(assetData.furnitureId);
          furnitureCounts.push(1);
        } else {
          furnitureCounts[furIndex] += 1;
          furCount = furnitureCounts[furIndex];
        }
        const position = {
          x: 12,
          y: 20
        };
        position.x = 28;
        position.y = 11;
        switch (furCount) {
          case 2:
            position.x = 12;
            position.y = this.compactMarker ? 26 : 20;
            break;
          case 3:
            position.x = -4;
            position.y = this.compactMarker ? 11 : 6;
            break;
          // case 4:
          //     position.x = 26;
          //     position.y = -7;
          //     break;
          case 4:
            position.x = this.compactMarker ? 12 : 6;
            position.y = this.compactMarker ? -3 : -12;
            break;
        }

        if (assetData.iconImage) {
          const diffHeight = assetData.height - 25;
          const diffWidth = (assetData.width - 25) / 2;
          const extraSpace = 2;
          console.log(diffWidth);
          switch (furCount) {
            case 1:
              position.x = (assetData.width - diffWidth) + extraSpace;
              position.y = (assetData.height / 2) - diffHeight;
              break;
            case 2:
              position.x = (assetData.width / 2) - diffWidth;
              position.y = (assetData.height - diffHeight) + extraSpace;
              break;
            case 3:
              position.x = -(diffWidth + extraSpace);
              position.y = (assetData.height / 2) - diffHeight;
              break;
            case 4:
              position.x = (assetData.width / 2) - diffWidth;
              position.y = -(diffHeight + extraSpace);
              break;
          }
        }

        const marker = new google.maps.Marker({
          icon: {
            // tslint:disable-next-line:prefer-template
            path: 'M ' + position.x + ', ' + position.y + ' m -7, 0 a 7,7 0 1,0 14,0 a 7,7 0 1,0 -14,0',
            fillColor: openedCampaign.plotColor,
            fillOpacity: 1.0,
            strokeColor: '#000000',
            strokeWeight: 1,
            scale: 1,
            anchor: new google.maps.Point(12, 24),
            labelOrigin: new google.maps.Point(position.x, position.y)
          },
          label: {
            fontWeight: 'bold',
            color: '#FFFFFF',
            text: openedCampaign.brandName
              && openedCampaign.brandName.length > 0 ? openedCampaign.brandName[0] : '',
            fontSize: '10px'
          },
          position: new google.maps.LatLng(assetData.latitude, assetData.longitude),
          zIndex: 999
        });
        const furInMainAssetData: FurnitureModel[] = this.assetsData.filter((furn: FurnitureModel) => {
          return furn.furnitureId === assetData.furnitureId;
        });
        if (furInMainAssetData.length === 0) {
          assetData.isPlottedMarker = true;
          assetData.frames.forEach(fra => fra.visible = true);
          marker.addListener('click', (event) => {
            this.onMarkerClick(event, assetData);
          });
        } else {
          marker.setCursor('default');
        }
        this.markers.push(marker);
        // }
      }
      // this.openCampaignMarkers.push(curCamMarkers)
    }
  }

  // createMarkerClusterOtherCampaigns() {
  //     for (var i = 0; i < this.openCampaignMarkers.length; i++) {
  //         // Cluster all the markers
  //         var markerCluster = new MarkerClusterer(this.mapObj, this.openCampaignMarkers[i], {
  //             imagePath: 'scripts/3rdParty/markerclusterer/images/m',
  //             maxZoom: 10,
  //             gridSize: 50
  //         });
  //         markerCluster.setCalculator(this.markerClusterCalculatorOtherCampaigns);
  //         this.openCampaignMarkerCluster.push(markerCluster);
  //     }
  // }

  // /**
  //  * Marker Clusterer image calculatin function
  //  * it can return from 1 to 5 and based on that cluster image will be shown
  //  * @param {any} markers - List of markers
  //  * @param {any} numStyles - number styles
  //  * @returns
  //  * @memberof GeoMapComponent
  //  */
  // markerClusterCalculatorOtherCampaigns(markers, numStyles) {
  //     const index = 2;
  //     let count = 0;
  //     //const visibleMarkers = markers.filter((m) => m.visible);
  //     // cluster counter show frames count not furniture count
  //     for (let i = 0; i < markers.length; i++) {
  //         const frames = markers[i].frames || [];
  //         for (let j = 0; j < frames.length; j++) {
  //             //if (frames[j].visible) {
  //                 count++;
  //            //}
  //         }
  //     }
  //     // var count = visibleMarkers.length;
  //     return {
  //         text: count,
  //         index: index
  //     };
  // }

  /**
   * Load the campaign list using service call.
   * @param query queryParameter for http call - serched text
   * @return responsed campaign data
   */
  loadOtherCampaignList = (query: string): Observable<LookupCampaignModel[]> => {
    // Search text should be exact 8 character long - discuss with andrius on slack
    // it will be campaign reference id - which is 8 character long so
    // searchString: "coca" <reference, advertiser, brand, title/> - keeping it 3 or more character
    if (query && query.length >= 3) {
      GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.CampaignFeatures,
        EventActionsCampaignFeatures.LookupCampaign, 'Search other campaign for plot');
      return this.geoMapService.getSearchedCampaignList(query).map((data: LookupCampaignResponseModel) => {
        const returnArr: LookupCampaignModel[] = [];
        if (data && data.campaignLookup) {
          for (const campaignLookup of data.campaignLookup) {
            const obj: LookupCampaignModel = {
              campaignId: campaignLookup.campaignId,
              campaignLookupDisplay: campaignLookup.campaignLookupDisplay
            };
            returnArr.push(obj);
          }
        }
        return returnArr;
      });
    } else {
      return Observable.of([]);
    }
  }

  otherCampaignMatchingFn = (): boolean => {
    // data is already filtered by back-end so it will always return true
    // This is needed as we dont want to show searched text in autocomplete and
    // in this case component automatically filter-out data whose text doesn't match search text
    return true;
  }

  removeCampaignFromList(index: number): void {
    if (index != null) {
      this.openedCampaigns.splice(index, 1);
      this.createMarkers();
    }
  }

  toggleCampaignExclude(): void {
    this.createMarkers();
  }

  onAddInCampaignList(event: LookupCampaignModel) {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.CampaignFeatures,
      EventActionsCampaignFeatures.GetCampaign, 'Load other campaign for plot');
    event.exclude = false;
    this.geoMapService.loadSelectedCampaign(event.campaignId).subscribe((data: GetCampaignResponseModel) => {
      event.assetData = data.campaign.assetData;
      event.assetData.forEach((furn) => {
        furn.frames.forEach((frame) => {
          frame.status = FrameStatus.available;
        });
      });
      event.startDate = this.datePipe.transform(data.campaign.startDate, LocaleData.displayDateFormat);
      event.endDate = this.datePipe.transform(data.campaign.endDate, LocaleData.displayDateFormat);
      event.campaignStatusName = data.campaign.campaignStatusName;
      event.campaignReference = data.campaign.campaignReference;
      event.brandName = data.campaign.brandName;
      event.advertiserName = data.campaign.advertiserName;
      event.plotColor = this.getAvailablePlotColor();
      this.createMarkers();
    });
  }

  getAvailablePlotColor(): string {
    let retColor = this.colorList[0];
    if (this.openedCampaigns.length === 0) {
      retColor = this.colorList[0];
    } else {
      for (const color of this.colorList) {
        const campaign = this.openedCampaigns.filter((cam) => {
          return cam.plotColor === color;
        });
        if (campaign.length === 0) {
          retColor = color;
          break;
        }
      }
    }
    return retColor;
  }
  //#endregion - SM-1345 - Open other campaign to plot on map

  onViewBookingDetailClick(frame: FrameModel): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.CampaignFeatures,
      EventActionsCampaignFeatures.FrameBooking, 'View frame booking list - timeline');
    this.isCartVisible = false;
    this.isLegendVisible = false;
    this.isCampaignVisible = false;
    this.isIndoorMapVisible = false;
    const reqParam = {
      data: ''
    };
    const reqData = {};
    reqData[frame.frameId] = this.isViewCartOnMap ? [[frame.startDateReq, frame.endDateReq]] : this.cartService.requestFrameTimings;
    reqParam.data = JSON.stringify(reqData);

    this.bookingDetailsParams = {
      reqParam,
      frameCode: frame.frameName,

    };
  }

  //#region SM-1699 - Search Frame on Map
  /**
   * Search frame dropdown item text formatter
   * @memberof GeoMapComponent
   */
  searchFrameFormatter = (frame: FrameModel): string => {
    return frame && frame.frameName ? frame.frameName : this.initialConfig.userBundle['geoplanner.text.frame.notFound'] || 'Frame not found';
  }

  /**
   * Search Frame input text box display formatter
   * @memberof GeoMapComponent
   */
  searchFrameInputFormatter = (frame: FrameModel): string => frame.frameName;

  /**
   * Search Frame on map, Searching based on text entered in asset data
   * @memberof GeoMapComponent
   */
  searchFrameOnMap = (text$: Observable<string>): Observable<FrameModel[]> => {
    return text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map((term) => {
        const arr: FrameModel[] = [];
        if (term !== '') {
          for (const asset of this.assetsData) {
            for (const frame of asset.frames) {
              if (frame.frameName.toLowerCase().indexOf(term.toLowerCase()) > -1) {
                const obj: FrameModel = _.cloneDeep(frame);
                obj.latitude = asset.latitude;
                obj.longitude = asset.longitude;
                obj.furnitureId = asset.furnitureId;
                arr.push(obj);
              }
            }
          }
        } else {
          this.onClearFrameSearch();
        }
        if (arr && arr.length > 0) {
          return arr;
        }
        return [null];
      });
  }

  /**
   * on Select frame from Search dropdown list
   * @param {*} $event - selected item event object
   * @memberof GeoMapComponent
   */
  onSearchFrameSelect($event): void {
    GoogleAnalyticsEvents.send(GoogleAnalyticsEventCategory.MapFeatures, EventActionsMapFeatures.SearchFrame, 'Search frame on map');
    this.frameSearchObj = $event.item;
    if (this.frameSearchObj && this.frameSearchObj.furnitureId) {
      this.highlightMarker(this.frameSearchObj.furnitureId, true);
    }
  }

  /**
   * on clearing searched frame
   * @memberof GeoMapComponent
   */
  onClearFrameSearch(): void {
    if (this.frameSearchObj) {
      const furnId = this.frameSearchObj.furnitureId;
      this.frameSearchObj = null;
      this.highlightMarker(furnId, false);
    }
  }

  /**
   * highlight marker with furniture id on map
   * @param {number} furnitureId - marker furniture id
   * @param {boolean} selected - selected or not
   * @memberof GeoMapComponent
   */
  highlightMarker(furnitureId: number, selected: boolean) {
    // get furniture object from asset data
    const originalFurniture: FurnitureModel = this.assetsData.filter((furn: FurnitureModel) => {
      return furn.furnitureId === furnitureId;
    })[0];
    if (originalFurniture && selected) {
      let isIndoorMapFound = false;
      if (originalFurniture.mapId && Array.isArray(this.indoorMapsDrp)) {
        if (originalFurniture.mapId === this.activeIndoorMapId) {
          isIndoorMapFound = true;
        } else {
          for (const map of this.indoorMapsDrp) {
            if (map.mapId === originalFurniture.mapId) {
              isIndoorMapFound = true;
              this.activeIndoorMapId = originalFurniture.mapId;
              this.onIndoorMapDrpChange();
              this.setAssetVisibility();
              break;
            }
          }
        }
      }
      if (!isIndoorMapFound) {
        if (this.activeIndoorMapId) {
          this.clearIndoorMap();
          this.setAssetVisibility();
        }
        this.mapObj.setCenter({
          lat: originalFurniture.latitude,
          lng: originalFurniture.longitude
        });
        this.mapObj.setZoom(20);
      }
    }
    let marker = null;
    // get marker object
    for (const m of this.markers) {
      if (m['furnitureId'] === furnitureId) {
        marker = m;
        break;
      }
    }

    if (marker) { // if furniture available on map
      // set new frame icon based on cart selection
      this.setFrameIcon(originalFurniture);
      marker.setIcon(this.getMarkerIcon(originalFurniture));

      // animate marker
      if (selected) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(() => {
          marker.setAnimation(null);
        }, 3000);
      } else {
        marker.setAnimation(null);
      }
    }
  }
  //#endregion - SM-1699 - Search Frame on Map

  //#region - cart summary
  /**
   * Clicking frame in cart summary
   * @param {*} param
   * @memberof GeoMapComponent
   */
  onCartSummaryCellClick(param) {
    this.highlightMarker(param.node.data.furnitureId, param.node.data.selected_cart);
  }

  /**
   * Deleting frame From cart summary
   * @memberof GeoMapComponent
   */
  onDeleteFromCartSummary() {
    this.createMarkers();
    if (this.cartService.cartData.length === 0) {
      this.cartService.setIsViewCartOnMap(false);
      this.isViewCartOnMap = false;
    }
  }

  /**
   * on cart detail modal pop up close event handler
   * @memberof GeoMapComponent
   */
  onCartDetailClose(isDataChanged: boolean) {
    if (isDataChanged) {
      this.createMarkers();
    }
  }
  //#endregion - cart summary

  //#region - Indoor Maps
  toggleIndoorMap(): void {
    this.isIndoorMapVisible = !this.isIndoorMapVisible;
    this.isCartVisible = false;
    this.isLegendVisible = false;
    this.isCampaignVisible = false;
  }

  /**
   * This method will be called when indoor map dropdown value changed.
   * This method is responsible to load selected indoor map
   */
  onIndoorMapDrpChange(): void {
    this.closeGuideIM();
    this.closeGuideIMP();
    this.closeInfoWindow();
    this.setBlankMapStyle();
    this.checkZoomDisabilityByCount();
    const levelMaps = _.filter(this.indoorMaps, (map: any) => String(map.mapId) === String(this.activeIndoorMapId));
    if (levelMaps.length > 0) {
      this.mapObj.setOptions({
        restriction: null
      } as any);
      this.showMarker = true;
      this.loadIndoorMap(levelMaps[0], true);
    } else {
      this.clearIndoorMap();
    }
    this.createMarkers();
  }

  /**
   * This method is responsible to load the indoor map
   * First we find out the selected indoor map then using their cordinates and map folder
   * we call the generated API and get the indoor map tiles from S3 server
   */
  loadIndoorMap(levelMap: any, ignoreActiveMapIdValidation = false): void {
    this.onClearAutoSearch();
    if (!ignoreActiveMapIdValidation && String(this.activeIndoorMapId) === String(levelMap.mapId)) {
      return;
    }

    this.mapObj.overlayMapTypes.clear();
    const group = {};
    this.activeIndoorMapId = levelMap.mapId;

    const imageMapTypeOverlay = new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        const proj = this.mapObj.getProjection();
        const z2 = Math.pow(2, zoom);
        const tileXSize = 256 / z2;
        const tileYSize = 256 / z2;
        const tileBounds = new google.maps.LatLngBounds(
          proj.fromPointToLatLng(new google.maps.Point(coord.x * tileXSize, (coord.y + 1) * tileYSize)),
          proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * tileXSize, coord.y * tileYSize))
        );
        const y = coord.y;
        const x = coord.x >= 0 ? coord.x : z2 + coord.x;
        if (this.mapObj.getBounds().intersects(tileBounds) && (this.indoorMapSettings.minZoom <= zoom) && (zoom <= this.indoorMapSettings.maxZoom)) {
          return this.buildTileUrls(this.indoorMapSettings.mapBaseUrl, group, levelMap, zoom, x, y);
        }
      },
      tileSize: new google.maps.Size(256, 256),
      maxZoom: this.indoorMapSettings.maxZoom,
      minZoom: this.indoorMapSettings.minZoom,
      name: 'level.mapName',
      opacity: 1.0
    });

    this.mapObj.overlayMapTypes.push(imageMapTypeOverlay);
    if (levelMap.mapBounds) {
      const northEast = new google.maps.LatLng(levelMap.mapBounds.coordinates[0][1], levelMap.mapBounds.coordinates[0][0]);
      const southWest = new google.maps.LatLng(levelMap.mapBounds.coordinates[1][1], levelMap.mapBounds.coordinates[1][0]);
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(northEast);
      bounds.extend(southWest);
      this.indoorMapBounds = bounds;
      this.mapObj.fitBounds(bounds);
      this.mapObj.setZoom(18);
      this.indoorMapMaxZoom = this.mapObj.getZoom();
      this.mapObj.setOptions({
        restriction: {
          latLngBounds: bounds,
          strictBounds: false,
        }
      } as any);
    }
  }

  /**
   * This method will be called when user clear the indoor map
   */
  clearIndoorMap($event = null) {
    if (this.environmentId === EnvironmentId.US && this.outdoorFurniture === 0 && this.isViewCartOnMap) {
      this.setBlankMapStyle();
    } else {
      this.setDefaultMapStyle();
    }
    this.showMarker = true;
    this.activeIndoorMapId = null;
    this.indoorMapBounds = null;
    this.indoorMapMaxZoom = null;
    if (this.mapObj) {
      this.mapObj.overlayMapTypes.clear();
      this.mapObj.setOptions({
        restriction: null
      } as any);
    }
    if ($event) {
      $event.stopImmediatePropagation();
    }
  }

  /**
   * This method is used to build the tiles
   * This method loads tiles for indoor map
   */
  buildTileUrls(mapBaseUrl: string, data: any, mapObj: any, zoom: number, x: number, y: number): string {
    if (data.map_tiles_url) {
      const key = [mapObj.mapFolder, '/', zoom, '/', x, '/', y, '.png'].join('');
      return data.map_tiles_url[mapObj.mapCode][key];
    } else {
      return [mapBaseUrl, mapObj.mapFolder, '/', zoom, '/', x, '/', y, '.png'].join('');
    }
  }

  /**
   * This method is responsible to hide the draggable popup.
   */
  hideFurniturePopup() {
    this.isMarkerSelected = false;
    if (this.mapObj && this.mapObj.controls && this.mapObj.controls[google.maps.ControlPosition.BOTTOM_LEFT]) {
      this.mapObj.controls[google.maps.ControlPosition.BOTTOM_LEFT].clear();
    }
  }

  /**
   * This method will close the callout box of popup
   */
  closeGuideIMP() {
    this.showGuideIMP = false;
  }

  /**
   * This method will close the callout box of dropdown
   */
  closeGuideIM() {
    this.showGuideIM = false;
  }

  /**
   * This method will close the callout box
   */
  resetGuide() {
    this.closeGuideIMP();
    this.closeGuideIM();
  }

  /**
   * This method will verify that we have to show callout box to guide user or not
   * This method will be called when user click on marker
   * @param asset Furniture details
   */
  verifyGuideIMP(asset: FurnitureModel) {
    if (this.restrictIndoorFramCartSelections && this.guidingIndoorMap && asset && asset.mapId && this.indoorMapsDrp && this.indoorMapsDrp.length > 0 && !this.activeIndoorMapId) {
      this.showGuideIMP = true;
    }
  }

  /**
   * This method will verify that we have to show callout box to guide user or not
   * This method will be called when user select furniture using shapes
   * @param assetsList collection of furniture
  */
  verifyGuideIMPBulk(assetsList: FurnitureModel[]) {
    let show = true;
    if (assetsList && assetsList.length > 0) {
      for (const asset of assetsList) {
        if (!asset.mapId) {
          show = false;
        }
      }
    } else {
      show = false;
    }
    if (show && !this.activeIndoorMapId && this.guidingIndoorMap && this.restrictIndoorFramCartSelections) {
      this.showGuideIMP = true;
    }
  }

  /**
   * This method will be used to count the indoor map furniture and outdoor furniture
   */
  countFurniture() {
    if (this.assetsData && this.assetsData.length > 0) {
      for (const asset of this.assetsData) {
        if (asset.mapId) {
          this.indoorFurniture += 1;
        } else {
          this.outdoorFurniture += 1;
        }
      }
    } else {
      this.indoorFurniture = 0;
      this.outdoorFurniture = 0;
    }
  }

  /**
   * This method is used to check the zoom disbality using the count of indoor/outdoor furniture count
   */
  private checkZoomDisabilityByCount() {
    if (this.disableZoom) {
      if (this.indoorFurniture > 0 && this.outdoorFurniture === 0) {
        this.zoomDisability();
      } else {
        if (this.mapObj) {
          this.resetZoom();
        }
      }
    }
  }

  /**
   * This method used to check the zoom disability
   */
  private zoomDisability() {
    if (this.mapObj) {
      if (this.indoorMapsDrp && this.indoorMapsDrp.length > 0 && !this.activeIndoorMapId && this.mapObj && this.disableZoom) {
        this.mapObj.setOptions({ zoomControl: false, disableDoubleClickZoom: true });
      } else {
        this.resetZoom();
      }
    }
  }

  /**
   * This method enable the zooming feature.
   */
  private resetZoom() {
    this.mapObj.setOptions({ zoomControl: true, disableDoubleClickZoom: false });
  }

  /**
   * This method is responsible to manage the icon properties
   * width/height : If width/height is below the 15 then this implemetation will increased it to default value
   */
  private manageIconProp(): void {
    if (Array.isArray(this.assetsData)) {
      for (const asset of this.assetsData) {
        if (asset.width < this.minIconSize && asset.width !== 0) {
          const increasedPerc = (100 * this.minIconSize) / asset.width;
          asset.width = this.minIconSize;
          asset.height = (increasedPerc * asset.height) / 100;
        } else if (asset.height < this.minIconSize && asset.height !== 0) {
          const increasedPerc = (100 * this.minIconSize) / asset.height;
          asset.height = this.minIconSize;
          asset.width = (increasedPerc * asset.width) / 100;
        }
      }
    }
  }

  trackByItem(index, item) {
    return item;
  }

  trackByIndmap(index, indmap) {
    return indmap?.mapId;
  }

  trackByCampaignId(index, item) {
    return item?.campaignId;
  }

  updateMapMarkerIconTextSize() {
    this.createMarkers();
  }

  /**
   * Toggle marker labels to long and short
   */
  toggleMarkerLabels() {
    this.shortenMarker = !this.shortenMarker;
    this.geoMapService.shortenMarker = this.shortenMarker;
    this.gridOptionsFrameSelection.columnDefs = this.getFrameSelectColumnDefs();
    this.createMarkers();
  }

  getSettingDropDownHeight() {
    let dropdownheight: any = 132;
    if (this.initialConfig.uiControl.shortenFurnitureCode) {
      if (this.labelMarker && this.markers.length > 0) {
        dropdownheight = 332;
      } else if (this.markers.length > 0) {
        dropdownheight = 252;
      } else {
        dropdownheight = 165;
      }
    } else {
      if (this.labelMarker && this.markers.length > 0) {
        dropdownheight = 312;
      } else if (this.markers.length > 0) {
        dropdownheight = 222;
      }
    }
    return { 'height': dropdownheight + 'px' };
  }
}
