import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ListBase } from '../list-base';
import { DataShareService } from '../../../core/services/data-share.service';
import { SharedService } from '../../../core/services/shared.service';
import { ListFilterService } from '../list-filter.service';
import { LogHelperService } from '../../../core/services/log-helper.service';
import { FilterDataService } from '../../../core/services/filter-data.service';
import { BrickBaseService } from '../../../core/services/brick-base.service';
declare var _;

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.css']
})
export class ListFilterComponent extends ListBase implements OnInit {
  /**
   * This object is used to manipulate and tranfer data between
   * List filter component and filter area component.
   * @memberof ListFilterComponent
   */
  @Input() resolveObject;

  /**
   * Event object to close list filter sidebar.
   * @memberof ListFilterComponent
   */
  @Output() closeSidebar = new EventEmitter<string>();

  /**
   * Creates an instance of ListFilterComponent.
   * @param {DataShareService} dataShareService
   * @param {SharedService} sharedService
   * @param {ListFilterService} listFilterService
   * @param {LogHelperService} logHelperService
   * @param {FilterDataService} filterDataService
   * @param {BrickBaseService} brickBaseService
   * @memberof ListFilterComponent
   */
  constructor(
    dataShareService: DataShareService,
    sharedService: SharedService,
    logHelperService: LogHelperService,
    brickBaseService: BrickBaseService,
    listFilterService: ListFilterService,
    private filterDataService: FilterDataService,
  ) {
    super(dataShareService, sharedService, brickBaseService, logHelperService, listFilterService);
  }

  /**
   * @description angular life cycle hook - called on component init
   * @author Alkesh Shah
   * @memberof ListFilterComponent
   */
  ngOnInit(): void {
    this.resolveObject.title = this.initialConfig.userBundle['workspace.collection.bric.list.subHeading'];
    this.listData.fileNameLabel = this.initialConfig.userBundle['geoplanner.text.uploadFile'] || 'Upload File';
    const tempList = this.filterDataService.getList();
    this.init(tempList);
  }

  /**
   * @description callback method on close button click
   * @author Alkesh Shah
   * @memberof ListFilterComponent
   */
  close() {
    this.listData.listUpload.fileList = _.clone(this.cloneOfFileList);
    this.closeSidebar.emit();
  }

  /**
   * @description callback method on save button click
   * @author Alkesh Shah
   * @memberof ListFilterComponent
   */
  save() {
    const routeIdToSend = this.getRouteIdToSend();
    if (this.isValidSelection(routeIdToSend)) {
      this.sendUploadRequest(this.fileToSend, routeIdToSend, 0).then((responseMsg) => {
        if (responseMsg === '') {
          const tempList = this.filterDataService.getList();
          tempList.listUpload = this.listData.listUpload;
          tempList.routeFrameIds = this.listData.routeFrameIds;
          tempList.routeFrameArray = this.listData.routeFrameArray;
          this.filterDataService.setList(tempList);
          this.closeSidebar.emit();
        } else {
          this.logHelperService.logError(responseMsg);
        }
      }, (error) => {
        this.logHelperService.logError(error);
      });
    } else {
      this.logHelperService.logError(this.initialConfig.userBundle['workspace.error.list.validation.route']);
    }
  }
}
