<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved()">
  <div class="listmodal">
    <div class="tabs_container horizontal">
      <ul class="tabs-menu">
        <li [ngClass]="{'current': 0 == activeTabIndex}">
          <a (click)="activeTabIndex=0;" style="cursor: pointer;">
            <span class="text">{{initialConfig.userBundle['workspace.collection.list.title.listUpload']}}</span>
          </a>
        </li>
        <li [ngClass]="{'current': 1 == activeTabIndex}">
          <a (click)="activeTabIndex=1;" style="cursor: pointer;">
            <span class="text">{{initialConfig.userBundle['workspace.collection.list.title.routeID']}}</span>
          </a>
        </li>
      </ul>
      <div class="tab" [ngClass]="{'readOnly' : readOnlyModal}">
        <div id="tabList" class="tab-content list-popup "
          [ngStyle]="{'display': 0==activeTabIndex?'block':'none'}">
          <div class="scrolldiv">
            <div class="control_group">
              <app-file-upload [multipleFiles]="true" ngDefaultControl extension=".xls,.xlsx"
                [(fileName)]="listData.fileNameLabel" logerror="logerror(data)" [noOfFiles]="2"
                (savefilelocally)="saveFilelocally($event)"></app-file-upload>
            </div>
          </div>
          <div class="clear"></div>
          <span
            style="font-size: 15px;font-weight: 500;">{{initialConfig.userBundle['workspace.collection.list.title.selectedFile']}}:</span>
          <ul class="summaryContainer" style="margin-top:30px;height:auto;"
            *ngIf="listData.listUpload.fileList.length > 0">
            <li *ngFor="let file of listData.listUpload.fileList; trackBy: trackByIndex; let i = index;">
              <label style="width: 72% !important">
                {{((file.userSelectionName || file.name).length > 30) ? (file.userSelectionName ||
                file.name).substring(0, 30) + '...' : (file.userSelectionName || file.name)}}</label>
              <span style="width: 28%; float: right">
                <!-- swap added for SMI-135, Nishit -->
                <span *ngIf="initialConfig.uiControl['swapEnabled']" class="left">
                  <button type="button" name="swap" class="list-lock zoombutton animated bounceIn"
                    [ngClass]="{'unswap': !file.swap, 'swap': file.swap }" (click)="file.swap = !file.swap"></button>
                </span>
                <!-- hardAllocated added for SMI-395, Nishit -->
                <span *ngIf="initialConfig.uiControl['hardAllocationEnabled']">
                  <button type="button" name="hardAllocated" class="list-lock zoombutton animated bounceIn"
                    [ngClass]="{'unlockbutton': !file.hardAllocated, 'lockbutton': file.hardAllocated }"
                    title="{{{false: 'Lock', true: 'Unlock'}[file.hardAllocated ? file.hardAllocated : false]}}"
                    (click)="file.hardAllocated = !file.hardAllocated"></button>
                </span>
                <span>
                  <input type="radio" [(ngModel)]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                    class="radio small include" name="file-include-{{i}}" [value]="false" id="file-include-{{i}}" />
                  <label for="file-include-{{i}}">&nbsp;</label>
                  <input type="radio" [(ngModel)]="file.exclude" (ngModelChange)="fileStatusChanged($event, i)"
                    class="radio small exclude" name="file-exclude-{{i}}" [value]="true" id="file-exclude-{{i}}" />
                  <label for="file-exclude-{{i}}">&nbsp;</label>
                </span>
                <a class="download-btn" (click)="downloadListFile(file.userSelectionId, file.userSelectionName)"
                  *ngIf="file.userSelectionId" title="Click to Download {{file.userSelectionName}}" target="_parent">
                  <img [src]="'images/download.png'" alt="download"/>
                </a>
                <a class="download-btn" *ngIf="!file.userSelectionId">
                  <img [src]="'images/download.png'" style="opacity: 0.3"  alt="download"/>
                </a>
                <a class="right" (click)="removeFile(i)">
                  <img [src]="'images/sub_black_delete.png'" height="10px" width="10px"  alt="delete"/>
                </a>
              </span>
            </li>
          </ul>
        </div>
        <div id="tabFrameId" class="tab-content" [ngStyle]="{'display': 1==activeTabIndex?'block':'none'}">
          <app-lookup-view [lookupType]="lookups.lookup[0].lookupType" [selectionId]="lookups.lookup[0].selectionId"
            [placeholder]="lookups.lookup[0].displayName" [regexp]="lookups.lookup[0]['regExp']"
            [(ngModel)]="listData.routeFrameArray" [displayType]="1" name="routeFrameId">
          </app-lookup-view>
        </div>
      </div>
    </div>
  </div>
</app-sb-modal-wrapper>
