import { DataShareService } from '../../core/services/data-share.service';
import { CampaignDetailsService } from './campaign-details.service';
import { StateService } from '../../core/services/state.service';
import { LookupViewService } from '../../core/components/lookup-view/lookup-view.service';
import { SbSelectedItem, SbSelectedAgency, SbSelectedAdvertiser, SbSelectedBrand, SbSelectedSpecialist } from '../../models/sb-selected-item';
import {
  defaultAdvertiserSearchParams, RequestParams,
  defaultAgencySearchParams, Advertiser, Brand, Specialist,
  SubAgency, SalesTeam, AssignedTo, SystemFlags, Agency,
} from '../../models';
import { ProductCategoryMain, InitialConfigModel } from '../../models/initial-config.model';
import { CampaignDetails } from '../commercial.model';
import { ThirdParty } from '../../core/enum';
import * as _ from 'lodash';
import { LocaleData } from '../../core/utils/LocaleData';
import { CommercialService, MaskService } from '../../core/services';
import { defaultSubAgencySearchParams, defaultBrandSearchParams, defaultSpecialistSearchParams } from '../../models/request-params';
import { SelectConfig } from '../../core/components/sb-select/core/select-config';
import { NumberMaskModel } from '../../models/imask';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerHelper } from '../../core/components/ngb-datepicker/ngb-datepicker-helper';

export class CampaignDetailsBase {
  userBundle: any;
  systemData: any;
  uiControl: any;
  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsBase
   */
  public advertiserLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsBase
   */
  public brandLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsBase
   */
  public specialistLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsBase
   */
  public agencyLookupConfig: SelectConfig;

  /**
   * @description
   * @type {SelectConfig}
   * @memberof CampaignDetailsBase
   */
  public subAgencyLookupConfig: SelectConfig;

  /**
   * @description Used for percentage masking
   * @type {NumberMaskModel}
   * @memberof CampaignDetailsBase
   */
  percentageInputMask: NumberMaskModel;

  /**
   * @description Instance of mask service
   * @type {MaskService}
   * @memberof CampaignDetailsBase
   */
  maskService: MaskService = new MaskService();

  /**
   * @description - Campaign detail object
   * @type {CampaignDetails}
   * @memberof CampaignDetailsBase
   */
  public campaignDetails: CampaignDetails;
  /**
   * @description - display date format
   * @type {string}
   * @memberof CampaignDetailsBase
   */
  public displayDateFormat: string;

  public displayFromToDateFormat: string;

  /**
   * @description Initial Config
   * @type {InitialConfigModel}
   * @memberof CampaignDetailsBase
   */
  public initialConfig: InitialConfigModel;
  /**
   * @description - whether sub-agency is disable or not
   * @type {boolean}
   * @memberof CampaignDetailsBase
   */
  public subAgencydisable: boolean;
  /**
   * @description - Product category list
   * @type {ProductCategoryMain[]}
   * @memberof CampaignDetailsBase
   */
  productCategoryList: ProductCategoryMain[];
  /**
   * @description - Sales team data list
   * @type {SalesTeam[]}
   * @memberof CampaignDetailsBase
   */
  salesTeamList: SalesTeam[];
  /**
   * @description assign to data item list
   * @type {AssignedTo[]}
   * @memberof CampaignDetailsBase
   */
  assignedToList: AssignedTo[];

  userData: any;

  // #region - Advertiser autocomplete dropdown
  /**
   * @description - Allow advertiser reset
   * @type {boolean}
   * @memberof CampaignDetailsBase
   */
  public resetAdvertiserCampaignFlag: boolean = false;
  /**
   * @description advertiser list
   * @type {SbSelectedItem[]}
   * @memberof CampaignDetailsBase
   */
  public advertisersList: SbSelectedAdvertiser[];
  // #endregion - Advertiser

  // #region - Brnad autocomplete dropdown
  /**
   * @description - Allow brand reset
   * @type {boolean}
   * @memberof CampaignDetailsBase
   */
  public resetBrandCampaignFlag: boolean = false;
  /**
   * @description brand list
   * @type {SbSelectedItem[]}
   * @memberof CampaignDetailsBase
   */
  public brandsList: SbSelectedBrand[];
  // #endregion

  // #region - specialist autocomplet dropdown
  /**
   * @description - Allow specialist reset
   * @type {boolean}
   * @memberof CampaignDetailsBase
   */
  public resetSpeciallistCampaignFlag: boolean = false;
  /**
   * @description specialist list
   * @type {SbSelectedItem[]}
   * @memberof CampaignDetailsBase
   */
  public specialistsList: SbSelectedSpecialist[] = [];
  // #endregion

  // #region - Agency autocomplet dropdown
  /**
   * @description - Allow Agency reset
   * @type {boolean}
   * @memberof CampaignDetailsBase
   */
  public resetAgencyCampaignFlag: boolean = false;
  /**
   * @description Agency list
   * @type {SbSelectedItem[]}
   * @memberof CampaignDetailsBase
   */
  public agenciesList: SbSelectedAgency[] = [];
  /**
   * @description Agency lookup url
   * @type {string}
   * @memberof CampaignDetailsBase
   */
  public agencyLookupUrl: string;
  /**
   * @description Agency lookup search request params
   * @type {RequestParams}
   * @memberof CampaignDetailsBase
   */
  public agencySearchParams: RequestParams = defaultAgencySearchParams;
  // #endregion - Agency

  // #region - SubAgency autocomplet dropdown
  /**
   * @description - Allow SubAgency reset
   * @type {boolean}
   * @memberof CampaignDetailsBase
   */
  public resetSubAgencyCampaignFlag: boolean = false;
  /**
   * @description SubAgency list
   * @type {SbSelectedItem[]}
   * @memberof CampaignDetailsBase
   */
  public subAgenciesList: SbSelectedItem[] = [];
  /**
   * @description SubAgency lookup url
   * @type {string}
   * @memberof CampaignDetailsBase
   */
  public subAgencyLookupUrl: string;
  /**
   * @description SubAgency lookup search request params
   * @type {RequestParams}
   * @memberof CampaignDetailsBase
   */
  public subAgencySearchParams: RequestParams = defaultSubAgencySearchParams;
  // #endregion - SubAgency

  public selectUndefinedOptionValue = undefined;

  readonly DEFAULT_OPTION = 'common.lookup.defaultOption';

  /**
 * @description this will enable/disable commercial page fields for SSPUI campaigns
 * @memberof CampaignDetailsBase
 */
  ppCampaignReadOnlyFlag = (SystemFlags.isPPCampaign && !SystemFlags.readOnly);

  /**
   * Options is for prevent recalculation
   */
  options = {
    preventRecalculationChanged: true
  }

  /**
   * Creates an instance of CampaignDetailsBase.
   * @author Alkesh Shah
   * @param {DataShareService} dataShareService
   * @param {CampaignDetailsService} campaignDetailsService
   * @memberof CampaignDetailsBase
   */
  constructor(
    public dataShareService: DataShareService,
    public campaignDetailsService: CampaignDetailsService,
    public lookupViewService: LookupViewService,
    public commercialService?: CommercialService,
    public stateService?: StateService
  ) {
  }

  /**
   * @description call on component init to initialize data
   * @author Alkesh Shah
   * @param {CampaignDetails} campaignDetails
   * @memberof CampaignDetailsBase
   */
  public init(campaignDetails: CampaignDetails) {
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.percentageInputMask = this.maskService.currencyInputMask({ max: 100, min: 0, padFractionalZeros: false, scale: 2, normalizeZeros: false });

    this.campaignDetails = campaignDetails;
    this.userBundle = this.dataShareService.getInitialConfigByKey('userBundle');
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.displayDateFormat = LocaleData.displayDateFormat;
    this.displayFromToDateFormat = this.uiControl.showDayofWeekRangeBric ? `dddd, ${LocaleData.displayDateFormat}` : LocaleData.displayDateFormat;
    this.agencyLookupUrl = this.dataShareService.getServiceCallUrlByKey('LOOKUP_AGENCY_URL');
    this.subAgencyLookupUrl = this.dataShareService.getServiceCallUrlByKey('LOOKUP_SUB_AGENCY_URL');

    this.userData = this.dataShareService.getInitialConfigByKey('userData');

    /* Specially written in case when user has only selected Advertiser on Search and not the Brand,
     from backend we will not get the list of Advertisers for brands */
    if (this.isAdvertiserSelected()) {
      this.advertisersList = [];
      this.advertisersList.push({ ...this.campaignDetails.advertiser });
      this.campaignDetailsService.advertisersList = this.advertisersList;
    }
    if (this.campaignDetails.brand && this.campaignDetails.brand.organisations) {
      this.mapAdvertiserList(this.campaignDetails.brand.organisations);
    }
    /* Specially written in case when user has only selected Brand on Search and not the Advertiser,
     from backend we will not get the list of brands for advertiser */
    if (this.isBrandSelected()) {
      this.brandsList = [];
      this.brandsList.push({ ...this.campaignDetails.brand });
      this.campaignDetailsService.brandsList = this.brandsList;
    }
    if (this.campaignDetails.advertiser && this.campaignDetails.advertiser.brands) {
      this.mapBrandList(this.campaignDetails.advertiser.brands);
    }

    if (campaignDetails.thirdParty[ThirdParty.agency]) {
      if (Array.isArray(this.campaignDetails.discounts)) {
        this.campaignDetails.discounts.forEach((discount) => {
          discount.agencyCommissionPercent = 20;
        });
      }
      this.commercialService.notifyGrid(this.campaignDetails);
    } else {
      if (this.campaignDetails.discounts) {
        this.campaignDetails.discounts.forEach((discount) => {
          discount.agencyCommissionPercent = 0;
        });
      }
      this.commercialService.notifyGrid(this.campaignDetails);
    }
    // load campaign case - need to maintain received product category id
    const productCategoryId = campaignDetails.productCategoryId;
    this.populateProductCategory(campaignDetails);
    campaignDetails.productCategoryId = productCategoryId ? productCategoryId : campaignDetails.productCategoryId;
    this.assignedToList = this.dataShareService.getInitialConfigByKey('assignedTo');
    this.setActiveAssignedTo();
    if (SystemFlags.isLoadedCampaign && this.campaignDetails.assignedTo && !this.campaignDetails.assignedTo.inUse) {
      this.assignedToList.push(this.campaignDetails.assignedTo);
    }
    const salesTeamList = this.dataShareService.getInitialConfigByKey('salesTeam');
    this.salesTeamList = Array.isArray(salesTeamList) ? salesTeamList.filter(item => item.inUse === true) : [];
  }

  /**
   * @description To set the configuration for all lookup components
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setLookupConfig() {
    this.setAdvertiserConfig();
    this.setBrandConfig();
    this.setSpecialistConfig();
    this.setAgencyConfig();
    this.setSubAgencyConfig();
  }

  /**
   * @description To set the configuration for advertiser lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAdvertiserConfig() {
    this.advertiserLookupConfig = new SelectConfig({
      placeholder: this.userBundle[this.DEFAULT_OPTION],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_ADVERTISER_URL'),
      displayInvoiceCode: this.uiControl.invoiceClientStatusEnabled,
      extraParams: defaultAdvertiserSearchParams,
      searchKeyParamName: 'organisationName',
      idField: 'advertiserId',
      textField: 'advertiserName',
      searchInItemsOnly: this.isBrandSelected()
    });
  }

  /**
   * @description To set the configuration for brand lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setBrandConfig() {
    this.brandLookupConfig = new SelectConfig({
      placeholder: this.userBundle[this.DEFAULT_OPTION],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_BRAND_URL'),
      displayInvoiceCode: this.uiControl.invoiceClientStatusEnabled,
      extraParams: defaultBrandSearchParams,
      searchKeyParamName: 'brandName',
      idField: 'brandId',
      textField: 'brandName',
      searchInItemsOnly: this.isAdvertiserSelected()
    });
  }

  /**
   * @description To set the configuration for specialist lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setSpecialistConfig() {
    this.specialistLookupConfig = new SelectConfig({
      placeholder: this.userBundle[this.DEFAULT_OPTION],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_SPECIALIST_URL'),
      displayInvoiceCode: this.uiControl.invoiceClientStatusEnabled,
      extraParams: defaultSpecialistSearchParams,
      searchKeyParamName: 'organisationName',
      idField: 'specialistId',
      textField: 'specialistName',
      searchInItemsOnly: this.userData && this.userData.thirdParty && this.userData.thirdParty[ThirdParty.specialist]
    });
  }

  /**
   * @description To set the configuration for agency lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAgencyConfig() {
    this.agencyLookupConfig = new SelectConfig({
      placeholder: this.userBundle[this.DEFAULT_OPTION],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_AGENCY_URL'),
      displayInvoiceCode: this.uiControl.invoiceClientStatusEnabled,
      extraParams: defaultAgencySearchParams,
      searchKeyParamName: 'organisationName',
      idField: 'agencyId',
      textField: 'agencyName',
      searchInItemsOnly: this.userData && this.userData.thirdParty && this.userData.thirdParty[ThirdParty.agency]
    });
  }

  /**
   * @description To set the configuration for sub agency lookup component
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setSubAgencyConfig() {
    this.subAgencyLookupConfig = new SelectConfig({
      placeholder: this.userBundle[this.DEFAULT_OPTION],
      lookupUrl: this.dataShareService.getServiceCallUrlByKey('LOOKUP_SUB_AGENCY_URL'),
      displayInvoiceCode: false,
      extraParams: defaultSubAgencySearchParams,
      searchKeyParamName: 'organisationName',
      idField: 'subAgencyId',
      textField: 'subAgencyName'
    });
  }

  /**
   * Maps Brands to the object required to populate the drop down
   *
   * @param {*} brands
   * @memberof CampaignDetailsBase
   */
  mapBrandList(brands) {
    this.brandsList = brands.map(item => ({
      brandId: item.brandId,
      brandName: item.brandName || item.name , // in case of getHeader(for BE) name is coming
      source: item
    }));
    this.campaignDetailsService.brandsList = this.brandsList;
  }

  /**
   * Maps Advertiser to the object required to populate the drop down
   *
   * @param {*} organisations
   * @memberof CampaignDetailsBase
   */
  mapAdvertiserList(organisations) {
    this.advertisersList = organisations.map(item => ({
      advertiserId: item.organisationId,
      advertiserName: item.organisationName,
      source: item
    }));
    this.campaignDetailsService.advertisersList = this.advertisersList;
  }

  // #region - Advertiser - callback methods
  /**
   * @description callback method called on advertiser name select
   * @author Alkesh Shah, Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  onAdvertiserNameSelected(value: SbSelectedAdvertiser, isTitlePersisted = false): void {
    const searchObject = this.stateService.getCampaign();
    searchObject.duplicationAdvertisers = [];
    this.stateService.setSearchPageObject('duplicationData', []);
    if (value) {
      this.setAdvertiserFromSource(value);
      SystemFlags.advertiserChange = true;
      searchObject.excludeAdvertiserBlacklist = SystemFlags.isLoadedCampaign ? searchObject.excludeAdvertiserBlacklist : true;
      // Populate Brand List //
      if (value.source && value.source.brands) {
        this.mapBrandList(value.source.brands);
      }
      this.initBrandList();
      this.populateProductCategory(this.campaignDetails);
      this.updateBrandConfig();
      this.brandLookupConfig = _.clone(this.brandLookupConfig);
      this.stateService.setSearchPageObject('selectedText', value);
      if (!isTitlePersisted) {
        this.campaignDetails.title =
        this.campaignDetailsService.populateCampaignTitle(
          this.campaignDetails.advertiser,
          this.dataShareService.getInitialConfigByKey('userData').initials
          );
      }
      // Commented below flag to fixed issue: If we select brand by lookup call the Advertiser gets auto populated.
      // If we are selecting the same item from auto populated list of advertiser it will clear the advertiser, brand and productCategory
      // this.resetBrandCampaignFlag = !this.resetBrandCampaignFlag;
    } else {
      searchObject.excludeAdvertiserBlacklist = SystemFlags.isLoadedCampaign ? searchObject.excludeAdvertiserBlacklist : true;
    }
  }

  initBrandList() {
    if (this.brandsList && this.brandsList.length === 1) {
      this.setFirstBrandFromList();
    } else {
      // It should clear the selected brand when the user changes the advertiser and the brand which was already selected from previous Advertiser is not in the newly populated list.
      const exists = this.brandsList ? this.brandsList.find((brand) => brand.brandId === this.campaignDetails.brand.brandId) : false;
      if (!exists) {
        this.setBrandFromSource(new Brand());
      }
    }

    this.campaignDetailsService.brandsList = this.brandsList;
  }

  /**
   * @description - Callback method called on clear selected Advertiser
   * @author Alkesh Shah
   * @memberof CampaignDetailsBase
   */
  // tslint:disable-next-line:variable-name
  removeSelectedAdvertiserName(_event, isTitlePersisted = false, campaignDetails?: any) {
    campaignDetails = campaignDetails ? campaignDetails : this.campaignDetails;
    this.resetBrandCampaignFlag = !this.resetBrandCampaignFlag;
    this.stateService.setSearchPageObject('selectedText', {});
    if (this.commercialService) {
      this.commercialService.notifyGrid({ clearBrands: true });
    }
    this.clearBrandAdvertiserProductCategory(campaignDetails, isTitlePersisted);
  }

  /**
   * @description - Callback method called on getting lookup response of advertiser
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  getAdvertisersResponse(value: any): void {
    if (value.organisation && value.organisation.length) {
      this.mapAdvertiserList(value.organisation);
    }
  }
  // #endregion - Advertiser

  // #region - Brand - callback methods
  /**
   * @description - callback method called on brand name select
   * @author Alkesh Shah, Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  onBrandSelected(value: SbSelectedBrand, isTitlePersisted = false): void {
    const searchObject = this.stateService.getCampaign();
    searchObject.duplicationAdvertisers = [];
    this.stateService.setSearchPageObject('duplicationData', []);
    if (value) {
      this.setBrandFromSource(value);

      // Fill the Advertiser List //
      if (value.source && value.source.organisations) {
        this.mapAdvertiserList(value.source.organisations);
      }

      // If the Advertiser List has only one Advertiser, pre populate it//
      if (this.advertisersList.length === 1) {
        this.setFirstAdvertiserFromList();
      } else {
        const exists = this.advertisersList.find((advertiser) => advertiser.advertiserId === this.campaignDetails.advertiser.advertiserId);
        if (!exists) {
          this.setAdvertiserFromSource(new Advertiser());
        }
      }
      searchObject.excludeAdvertiserBlacklist = SystemFlags.isLoadedCampaign ? searchObject.excludeAdvertiserBlacklist : true;
      if (!isTitlePersisted) {
        this.campaignDetails.title = this.campaignDetailsService.populateCampaignTitle(
          this.campaignDetails.advertiser,
          this.dataShareService.getInitialConfigByKey('userData').initials
          );
      }
      searchObject.duplicationAdvertisers = [];
      this.populateProductCategory(this.campaignDetails);
      SystemFlags.advertiserChange = true;
      this.updateAdveriserConfig();
    }
  }

  /**
   * @description Gets the Product Categories based on Advertiser OR Brand Selection
   * @author Shreni Shah
   * @param {*} value
   * @memberof CampaignDetailsComponent
   */
  populateProductCategory(campaignDetails) {
    // Always give priority to Product Category at Advertiser Level //
    let productCategoryList = [];
    campaignDetails.productCategoryId = -1;
    if (this.isAdvertiserSelected() && this.advertisersList && this.advertisersList.length) {
      productCategoryList = this.initFilteredAdvertiser(productCategoryList);
    }
    if (productCategoryList.length === 0 && this.isBrandSelected() && this.brandsList && this.brandsList.length) {
      const filteredBrand = this.brandsList.filter(brand => brand.brandId === this.campaignDetails.brand.brandId);
      if (filteredBrand && filteredBrand[0] && filteredBrand[0].source && filteredBrand[0].source.productCategory) {
        if (filteredBrand[0].source.productCategory.length) {
          filteredBrand[0].source.productCategory.forEach((element) => {
            productCategoryList.push(element);
          });
        } else {
          productCategoryList = [filteredBrand[0].source.productCategory];
        }
      }
    }
    this.productCategoryList = this.campaignDetailsService.setProductCategoryList(productCategoryList);
    this.campaignDetailsService.productCategoryList = this.productCategoryList;
    this.populateSingleProductCategory(campaignDetails);
  }

  initFilteredAdvertiser(productCategoryList) {
    let productCatList = [];
    const filteredAdvertiser = this.advertisersList.filter(advertiser => advertiser.advertiserId === this.campaignDetails.advertiser.advertiserId);
    if (filteredAdvertiser && filteredAdvertiser[0] && filteredAdvertiser[0].source && filteredAdvertiser[0].source.productCategory) {
      if (filteredAdvertiser[0].source.productCategory.length) {
        filteredAdvertiser[0].source.productCategory.forEach((element) => {
          productCatList.push(element);
        });
      } else {
        productCatList = [filteredAdvertiser[0].source.productCategory];
      }
    }
    return productCatList;
  }

  populateSingleProductCategory(campaignDetails) {
    if (this.productCategoryList.length === 1 && this.productCategoryList[0].productCategory.length === 1) {
      campaignDetails.productCategoryId = this.productCategoryList[0].productCategory[0].productCategoryId;
    }
  }

  /**
   * @description - Callback method called on clear selected brand
   * @author Alkesh Shah
   * @memberof CampaignDetailsBase
   */
  // tslint:disable-next-line:variable-name
  removeSelectedBrand(_event: any, isTitlePersisted = false, campaignDetails?: any) {
    campaignDetails = campaignDetails ? campaignDetails : this.campaignDetails;
    this.clearBrandAdvertiserProductCategory(campaignDetails, isTitlePersisted);
  }

  clearBrandAdvertiserProductCategory(campaignDetails, isTitlePersisted = false) {
    campaignDetails.brand = new Brand();
    campaignDetails.advertiser = new Advertiser();
    this.populateProductCategory(campaignDetails);
    campaignDetails.productCategoryId = -1;
    this.brandsList = [];
    this.campaignDetailsService.brandsList = [];
    this.advertisersList = [];
    this.campaignDetailsService.advertisersList = [];
    if (!isTitlePersisted) {
      campaignDetails.title = this.campaignDetailsService.populateCampaignTitle(
      this.campaignDetails && this.campaignDetails.advertiser,
      this.userData.initials
      );
    }
    SystemFlags.advertiserChange = true;
    this.updateBrandConfig();
    this.updateAdveriserConfig();
    campaignDetails.excludeAdvertiserBlacklist = true;
    if ("externalReference" in campaignDetails) {
      campaignDetails.externalReference = "";
    }

  }

  /**
   * @description - Callback method called on getting lookup response of brand
   * @author Alkesh Shah, Shivani Patel
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  getBrandsResponse(value: any): void {
    if (value.brand && value.brand.length) {
      this.mapBrandList(value.brand);
    }
  }
  // #endregion - Brand

  // #region - Specialist - callback methods
  /**
   * @description - Called on specialist change
   * @author Alkesh Shah
   * @param {SbSelectedItem} [value] - null if specialist is cleared
   * @memberof CampaignDetailsBase
   */
  onSpecialistChange(value?: SbSelectedSpecialist) {
    const searchObject = this.stateService.getCampaign();
    this.campaignDetails.specialistPercentage = null;
    if (value) {
      this.setSpecialistFromSource(value);
      this.campaignDetailsService.populateSpecialistPercentage(this.campaignDetails);
    } else { // value not available means item is cleared
      this.setSpecialistFromSource(new Specialist());
      if (this.campaignDetails.thirdParty && this.campaignDetails.thirdParty[ThirdParty.specialist]) {
        delete this.campaignDetails.thirdParty[ThirdParty.specialist];
      }
    }
    searchObject.excludeSpecialistBlacklist = SystemFlags.isLoadedCampaign ? searchObject.excludeSpecialistBlacklist : true;
  }

  /**
   * @description - Callback method called after getting response of specialist lookup
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  getSpecialistsResponse(value: any): void {
    if (value.organisation && value.organisation.length) {
      this.specialistsList = value.organisation.map(item => ({
        specialistId: item.organisationId,
        specialistName: item.organisationName,
        source: item
      }));
    }
    this.campaignDetailsService.specialistsList = this.specialistsList;
  }
  // #endregion - Specialist

  // #region - Agency - callback methods
  /**
   * @description - Called on agency change
   * @author Alkesh Shah
   * @param {SbSelectedItem} [value] - null if agency is remvoed
   * @memberof CampaignDetailsBase
   */
  onAgencyChange(value?: SbSelectedAgency) {
    this.campaignDetails.agencyPercentage = null;
    const searchObject = this.stateService.getCampaign();
    if (value) {
      this.setAgencyFromSource(value);
      this.campaignDetailsService.populateAgencyPercentage(this.campaignDetails);
      searchObject.excludeAgencyBlacklist = SystemFlags.isLoadedCampaign ? searchObject.excludeAgencyBlacklist : true;
    } else {
      this.setAgencyFromSource(new Agency());
      if (this.campaignDetails.thirdParty && this.campaignDetails.thirdParty[ThirdParty.agency]) {
        delete this.campaignDetails.thirdParty[ThirdParty.agency];
      }
      searchObject.excludeAgencyBlacklist = SystemFlags.isLoadedCampaign ? searchObject.excludeAgencyBlacklist : true;
    }
  }

  /**
   * @description - Callback method called after getting response of agency lookup
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  getAgenciesResponse(value: any): void {
    if (value.organisation && value.organisation.length) {
      this.agenciesList = value.organisation.map(item => ({
        agencyId: item.organisationId,
        agencyName: item.organisationName,
        source: item
      }));
    }
    this.campaignDetailsService.agenciesList = this.agenciesList;
  }
  // #endregion - Agency

  // #region - SubAgency - callback methods
  /**
   * @description - Callback method called on subagency name select
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  onSubAgencyNameSelected(value: any): void {
    if (this.campaignDetails.subAgency) {
      this.campaignDetails.subAgency = value;
      this.campaignDetails.subAgency.invoiceStatusCode = value.source.invoiceStatusCode;
    }
  }

  /**
   * @description - Callback method called on remove selected sub agency
   * @author Alkesh Shah
   * @memberof CampaignDetailsBase
   */
  removeSelectedSubAgencyName(): void {
    this.campaignDetails.subAgency = new SubAgency();
  }

  /**
   * @description - Called after getting response of look up sub-agency
   * @author Alkesh Shah
   * @param {*} value
   * @memberof CampaignDetailsBase
   */
  getSubAgencyResponse(value: any): void {
    if (value.organisation && value.organisation.length) {
      this.subAgenciesList = value.organisation.map(item => ({
        subAgencyId: item.organisationId,
        subAgencyName: item.organisationName,
        source: item
      }));
    }
  }
  // #endregion - SubAgency

  /**
   * @description - set subAgency enable/disable
   * @author Alkesh Shah
   * @returns
   * @memberof CampaignDetailsBase
   */
  enableSubAgency(): void {
    this.subAgencydisable = true;

    if ((this.campaignDetails.assignedTo && this.campaignDetails.assignedTo.assignedToId > 0)
      && (this.campaignDetails.agency && this.campaignDetails.agency.agencyId > 0)) {
      if (this.campaignDetails.assignedTo.assignedToName.indexOf('REGIONAL') > -1) {
        this.subAgencydisable = false;
      } else {
        this.removeSelectedSubAgencyName();
      }
    }
  }

  /**
   * @description - checks the initialConfig uiControl to hide element or not
   * @author Alkesh Shah
   * @param {string} key - key to check
   * @returns {boolean}
   * @memberof CampaignDetailsBase
   */
  hideUIElements(key: string): boolean {
    return this.dataShareService.hideUIElements(key);
  }

  /**
   * @description Checks commercial uiControl to show/hide elements on commercial
   * @author Amit Mahida
   * @param {string} key
   * @returns {boolean}
   * @memberof CampaignDetailsBase
   */
  commercialHiddenElements(key: string): boolean {
    return this.commercialService.commercialHiddenElements(key);
  }

  /**
   * Sets Specialist and Agency based on the values got from the InitialConfig-->UserData-->ThirdParty Object
   * Common function used for both 1> External user 2> If the user has an access to specific organisations only
   * @author Shreni Shah
   * @returns Array<Object>[]
   * @memberof CampaignDetailsComponent
   */
  setSpecialistFromConfig(campaignDetails) {
    if (this.userData.thirdParty && this.userData.thirdParty[ThirdParty.specialist]) {
      // If multiple specialist populate the list//
      if (Array.isArray(this.userData.thirdParty[ThirdParty.specialist]) && this.userData.thirdParty[ThirdParty.specialist].length > 1) {
        this.userData.thirdParty[ThirdParty.specialist].forEach((value) => {
          this.specialistsList.push({
            specialistId: value.thirdPartyId,
            specialistName: value.thirdPartyName,
            source: value
          });
        });
      } else {
        // If single specialist, autopopulate and disable the control//
        this.userData.thirdParty[ThirdParty.specialist] = Array.isArray(this.userData.thirdParty[ThirdParty.specialist]) ?
          this.userData.thirdParty[ThirdParty.specialist][0] : this.userData.thirdParty[ThirdParty.specialist];
        campaignDetails.specialist = {
          specialistId: this.userData.thirdParty[ThirdParty.specialist].thirdPartyId,
          specialistName: this.userData.thirdParty[ThirdParty.specialist].thirdPartyName,
          invoiceStatusCode: this.userData.thirdParty[ThirdParty.specialist].invoiceStatusCode
        };
        campaignDetails.disableSpecialist = true;
      }
    }
  }

  pushToAgenciesList = (value) => {
    this.agenciesList.push({
      agencyId: value.thirdPartyId,
      agencyName: value.thirdPartyName,
      source: value
    });
  }

  setAgencyFromConfig(campaignDetails) {
    if (this.userData.thirdParty && this.userData.thirdParty[ThirdParty.agency]) {
      if (this.uiControl.allowDefaultMediaAgency) {
        this.userData.thirdParty[ThirdParty.agency].forEach(this.pushToAgenciesList);
        if (this.isDisabledAgencyForAllowDMA()) {
          campaignDetails.disableAgency = true;
        }
      } else {
        if (this.userData.thirdParty[ThirdParty.agency].length > 0) {
          this.userData.thirdParty[ThirdParty.agency].forEach(this.pushToAgenciesList);
        }
        if (!SystemFlags.isLoadedCampaign && this.userData.thirdParty[ThirdParty.agency].length === 1) {
          campaignDetails.agency = this.getOneThirdPartyAgency();
        }
        if (this.userData.thirdParty[ThirdParty.agency].length === 1) {
          campaignDetails.disableAgency = true;
        }
      }
      this.campaignDetailsService.populateAgencyPercentage(campaignDetails);
    }
  }

  /**
   * @description Get first third party agency
   */
  getOneThirdPartyAgency() {
    return {
      agencyId: this.userData.thirdParty[ThirdParty.agency][0].thirdPartyId,
      agencyName: this.userData.thirdParty[ThirdParty.agency][0].thirdPartyName,
      invoiceStatusCode: this.userData.thirdParty[ThirdParty.agency][0].invoiceStatusCode
    };
  }

  /**
   * @description To check that is disabled agency for allowed default media length
   */
  isDisabledAgencyForAllowDMA() {
    if (this.userData.thirdParty[ThirdParty.agency].length === 1 && this.userData.thirdParty[ThirdParty.agency][0].isDefault) {
      return true;
    }
    return false;
  }

  /**
   * @description Update the advertiser lookup configuration.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  updateAdveriserConfig() {
    this.advertiserLookupConfig.searchInItemsOnly = this.isBrandSelected();
    this.advertiserLookupConfig = _.clone(this.advertiserLookupConfig);
  }

  /**
   * @description Update the brand lookup configuration.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  updateBrandConfig() {
    this.brandLookupConfig.searchInItemsOnly = this.isAdvertiserSelected();
    this.brandLookupConfig = _.clone(this.brandLookupConfig);
  }

  /**
   * @description Is brand selected or not.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  isBrandSelected(): boolean {
    return this.campaignDetails && this.campaignDetails.brand && this.campaignDetails.brand.brandId > 0;
  }

  /**
   * @description Is brand selected or not.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  isAdvertiserSelected(): boolean {
    return this.campaignDetails && this.campaignDetails.advertiser && this.campaignDetails.advertiser.advertiserId > 0;
  }

  /**
   * @description Get first advertiser from advertisersList and set it to campaignDetails.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setFirstAdvertiserFromList(): void {
    if (Array.isArray(this.advertisersList) && this.advertisersList.length > 0) {
      this.setAdvertiserFromSource(this.advertisersList[0]);
    }
  }

  /**
   * @description Get first brand from brandList and set it to campaignDetails.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setFirstBrandFromList(): void {
    if (Array.isArray(this.brandsList) && this.brandsList.length > 0) {
      this.campaignDetails.brand.brandId = this.brandsList[0].brandId;
      this.campaignDetails.brand.brandName = this.brandsList[0].brandName;
      this.campaignDetails.brand.invoiceStatusCode = this.brandsList[0] && this.brandsList[0].source ?
        this.brandsList[0].source.invoiceStatusCode :
        null;
      this.campaignDetails.brand = _.clone(this.campaignDetails.brand);
    }
  }

  /**
   * @description set advertiser from selected source in campaignDetails.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAdvertiserFromSource(value: SbSelectedAdvertiser): void {
    if (value) {
      this.campaignDetails.advertiser.advertiserId = value.advertiserId;
      this.campaignDetails.advertiser.advertiserName = value.advertiserName;
      this.campaignDetails.advertiser.invoiceStatusCode = value.source ? value.source.invoiceStatusCode : value.invoiceStatusCode;
      this.campaignDetails.advertiser.organisationTypeId = value.source ? value.source.organisationTypeId : value.organisationTypeId;
      this.campaignDetails.advertiser.advertiserCode = value.source ? value.source.organisationCode : value.advertiserCode;
      this.campaignDetails.advertiser = _.clone(this.campaignDetails.advertiser);
    }
  }

  /**
   * @description Set brand from selected source in campaignDetails.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setBrandFromSource(value: SbSelectedBrand): void {
    if (value) {
      this.campaignDetails.brand.brandId = value.brandId;
      this.campaignDetails.brand.brandName = value.brandName;
      this.campaignDetails.brand.invoiceStatusCode = value.source ? value.source.invoiceStatusCode : null;
      this.campaignDetails.brand = _.clone(this.campaignDetails.brand);
    }
  }

  /**
   * @description Set specialist from selected source in campaignDetails.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setSpecialistFromSource(value: SbSelectedSpecialist): void {
    if (value) {
      this.campaignDetails.specialist.specialistId = value.specialistId;
      this.campaignDetails.specialist.specialistName = value.specialistName;
      this.campaignDetails.specialist.invoiceStatusCode = value.source ? value.source.invoiceStatusCode : null;
      this.campaignDetails.specialist = _.clone(this.campaignDetails.specialist);
    }
  }

  /**
   * @description Set agency from selected source in campaignDetails.
   * @author Dhaval Patel
   * @memberof CampaignDetailsBase
   */
  setAgencyFromSource(value: SbSelectedAgency): void {
    if (value) {
      this.campaignDetails.agency.agencyId = value.agencyId;
      this.campaignDetails.agency.agencyName = value.agencyName;
      this.campaignDetails.agency.invoiceStatusCode = value.source ? value.source.invoiceStatusCode : null;
      this.campaignDetails.agency = _.clone(this.campaignDetails.agency);
    }
  }

  /**
   * @description Manage the gross media value
   * @param key 'bookingList' and 'bookings', These two values are only allowed as key
   * @param notify If it is true then notify commercial grid
   * @author Dhaval Patel
   * @memberof CampaignDetailsBaseComponent
   */
  manageGrossMediaValueWithoutIndex(key: 'bookingList' | 'bookings' = 'bookingList', notify = true) {
    if (key && this.campaignDetails[key]) {
      for (let index = 0; index < this.campaignDetails[key].length; index++) {
        this.campaignDetailsService.manageGrossMediaValue(index, this.campaignDetails, key);
      }

      if (notify) {
        this.commercialService.notifyGrid(this.campaignDetails[key]);
      }
    }
  }

  manuallyTitleChanged() {
    SystemFlags.advertiserChange = false;
  }

  trackBySalesTeam(index, saleInfo) {
    return saleInfo?.salesTeamId;
  }

  trackByAssignedTo(index, assignedTo) {
    return assignedTo?.assignedToId;
  }

  trackByProductCategoryGroup(index, item) {
    return item?.productCategoryGroupId;
  }

  trackByProductCategory(index, item) {
    return item?.productCategoryId;
  }

  trackByCampaignType(index, item) {
    return item?.campaignTypeId;
  }

  trackByCampaignTypeGroup(index, item) {
    return item?.campaignTypeGroupId;
  }

  /**
   * @description set only active Assigned To options
   * @author Dhaval Patel
   * @memberof CampaignDetailsBaseComponent
   */
   setActiveAssignedTo() {
    // sm-8803 filtering list which has inUse set to true only
    this.assignedToList = this.assignedToList.filter(list => list['inUse']);
   }

   /**
   * @description prepare a startDate day object of ngb-datepicker
   * @author Kishan Patel
   * @param {NgbDateStruct} date - current date
   * @returns
   * @memberof CampaignDetailsBelgiumComponent
   */
  prepareStartDayObj(date: NgbDateStruct) {
    const obj = {
      isToday: false,
      isDisabled: false,
      isWeekday: false
    };

    const selecteddaye: any = NgbDatepickerHelper.convertDateStructToDate(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (selecteddaye < today) {
      obj.isDisabled = true;
    }
    obj.isToday = NgbDatepickerHelper.isEqual(today, date);
    return obj;
  }

  /**
   * @description cache specific days object if is not exists
   * @author Kishan Patel
   * @param {NgbDateStruct} date - current date object
   * @returns {string}
   * @memberof CampaignDetailsBelgiumComponent
   */
   cacheStartDateObj(date: NgbDateStruct, startDayObjCache): string {
    const daystring = NgbDatepickerHelper.getDateString(date);
    if (!startDayObjCache[daystring]) {
      startDayObjCache[daystring] = this.prepareStartDayObj(date);
    }
    return daystring;
  }

  /**
   * open start/end date datepicker on click
   * @param $event click event object
   * @param datePicker start/end date picker object
   */
   openDatePicker($event: Event, datePicker: NgbInputDatepicker, otherDatePicker: NgbInputDatepicker): void {
    otherDatePicker.close();
    $event.preventDefault();
    $event.stopPropagation();
    datePicker.toggle();
  }

  /**
   * close date picker on out-side click
   * @param event click event object
   * @param datePicker start/end date picker object
   */
   closeDatePickerOutsideClick(event: any, datePicker: NgbInputDatepicker): void {
    if (datePicker.isOpen()) {
      if (event.target.offsetParent == null && event.target.nodeName !== 'OPTION') {
        datePicker.close();
      } else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') {
        datePicker.close();
      }
    }
  }

  /**
   * @description Changes in Checkbox to Prevent Recalculation of CPT
   * @author Dhaval Patel
   * @memberof CampaignDetailsSgComponent
   */
  preventRecalculationChanged(notify: boolean = true, calculateCPTEmitter?: any) {
    this.campaignDetailsService.calculateCPT(this.campaignDetails, undefined, undefined, this.campaignDetails.bookingList[0].grossMediaValue, this.options);
    if (calculateCPTEmitter) {
      calculateCPTEmitter.emit();
    }
    if (notify) {
      this.commercialService.notifyGrid(this.campaignDetails);
    }
  }

}
