export class Network {
  networkId: number;
  networkName: string;
  networkDefaultQuantity: number;
  inUse: boolean;
  networkMinQuantity: number;
  optionStack?: boolean;
  frameData?: object;
  fixedPackagePrice?: boolean;
  noOfFramesEditable = true;
  max?: number;
  openRateCard?: boolean;
}

export class NetworkGroup {
  networkGroupId: number;
  networkGroupName: string;
  inUse: boolean;
  network: Network[];
}
