<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div class="modal_content" [ngClass]="{'readOnly' : readOnlyModal}">
    <div class="mediablocks" *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId;">
      <app-lookup-view [lookupType]="lookup.lookupType" [selectionId]="lookup.selectionId"
        [(ngModel)]="selected[lookup.selectionId]" [placeholder]="lookup.displayName"
        [bricId]="brickBaseService.brickID.Environment" [selectAll]="lookup.selectAll" [bricId]="lookups.brickId"
        [configLookup]="configLookup" [isRelativeFilterVisible]="isRelativeFilterVisible">
      </app-lookup-view>
    </div>
  </div>
</app-sb-modal-wrapper>
