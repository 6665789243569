export class RequiredCampaignFields {
  advertiser?: boolean = true;
  brand?: boolean = true;
  productCategoryId?: boolean = true;
  specialist?: boolean = false;
  internalAdvertiser?: boolean = false;
  salesTeamId?: boolean = true;
  agency?: boolean = false;
  subAgency?: boolean = false;
  title?: boolean = true;
  assignedTo?: boolean = false;
  campaignStartDate?: boolean = true;
  campaignEndDate?: boolean = false;
  pmpDealId?: boolean = true;
  campaignCPT?: boolean = true;
  floatingStartDate?: boolean = false;
  floatingEndDate?: boolean = false;
  salesPerson?: boolean = false;
  adminPerson?: boolean = false;
  externalReference = false;
}

export class RequiredBookingsFields {
  bookingStatusId?: boolean = false;
  adminPersonId?: boolean = true;
  salesPersonId?: boolean = true;
  blankOn?: boolean = false;
  blankBy?: boolean = false;

  constructor(bookingStatusId = false, adminPersonId = true, salesPersonId = true, blankOn = false, blankBy = false) {
    this.bookingStatusId = bookingStatusId;
    this.adminPersonId = adminPersonId;
    this.salesPersonId = salesPersonId;
    this.blankOn = blankOn;
    this.blankBy = blankBy;
  }
}

export class PopulateCommercialFields {
  campaignReference = true;
  externalReference = true;
  brand = true;
  advertiser = true;
  specialist = true;
  agency = true;
  grossMediaValue = true;
  subAgency = true;
  productCategoryId = true;
  smartBricsValue = true;
  netMediaValue = true;
  assignedToId = true;
  title = true;
  comments = true;
  reasonForChange = true;
  campaignStartDate = true;
  campaignEndDate = true;
  campaignCreateDate = true;
  freeOfCharge = true;
  salesTeamId = true;
  campaignStatusId = true;
  overshow = true;
  campaignReferences = false;
  pmpDealId = false;
  campaignCPT = false;
  mediaLength = false;
  sendProposal = false;
  product = false;
  buyerContactId = false;
  financeAmount = false;
  floatingStartDate = false;
  floatingEndDate = false;
  selectedCampaignReference = false;
  internalAdvertiserId = false;
  overs = false;
  pmpDiscount = false;
  dgNumber = false;
  opportunityLineId = false;
  invoiceLocked = false;
  readyForPosting = false;
  adminPerson = true;
  salesPerson = true;
  preventRecalculation = true;
  setPCFields() {
    this.pmpDealId = true;
    this.campaignCPT = true;
  }
}

export class PopulateBookingDetailFields {
  adminPersonId?: boolean = true;
  salesPersonId?: boolean = true;
  ctuPersonId?: boolean = true;
  invoiceTypeId?: boolean = true;
  barterId?: boolean = true;
  barterRate?: boolean = true;
  preBarterValue?: boolean = true;
  productionTypeId?: boolean = true;
  productCatalogueName?: boolean = true;
  productCatalogueId?: boolean = true;
  bookingStartDate?: boolean = true;
  bookingEndDate?: boolean = true;
  grossMediaValue?: boolean = true;
  smartBricsValue?: boolean = true;
  purchaseOrderNo?: boolean = true;
  buyerContactId?: boolean = true;
  netMediaValue?: boolean = true;
  mediaLength?: boolean = true;
  pmpdiscount?: boolean = false;
  impressions?: boolean = false;
  campaignCPT?: boolean = false;
  comments?: boolean = false;
  customerRef?: boolean = false;
  bookingReference?: boolean = false;
  invoiceTypeName?: boolean = false;
  bookingStatusId?: boolean = false;
  startDate?: boolean = false;
  endDate?: boolean = false;
  poNumber?: boolean = false;
  optionDroppingDate?: boolean = false;
  optionToRenew?: boolean = false;
  readOnlyOptionToRenew?: boolean = false;
  integrationStatusId?: boolean = false;
  financeAmount?: boolean = false;
  productionCost?: boolean = false;
  mountingCost?: boolean = false;
  discount?: boolean = false;
  blankingOption?: boolean = false;
  isCancellationPending?: boolean = false;
  impressionsRemaining?: boolean = false;
  impressionsPurchased?: boolean = false;
  grossMediaRemaining?: boolean = false;
  grossMediaPurchased?: boolean = false;

  setPICFields() {
    this.pmpdiscount = true;
    this.impressions = true;
    this.campaignCPT = true;
  }

  setPOORIFields() {
    this.poNumber = true;
    this.optionDroppingDate = true;
    this.optionToRenew = true;
    this.readOnlyOptionToRenew = true;
    this.integrationStatusId = true;
  }

  setPMDBFields() {
    this.productionCost = true;
    this.mountingCost = true;
    this.discount = true;
    this.blankingOption = true;
  }

  setCCBIBFields() {
    this.comments = true;
    this.customerRef = true;
    this.bookingReference = true;
    this.invoiceTypeName = true;
    this.bookingStatusId = true;
  }

  setBookingDateFieldsInverse() {
    this.bookingStartDate = false;
    this.bookingEndDate = false;
    this.startDate = true;
    this.endDate = true;
  }
}
