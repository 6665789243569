import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppNameEnum } from '../enum';
import nlBE from '@angular/common/locales/nl-BE';
import de from '@angular/common/locales/de';
import enGB from '@angular/common/locales/en-GB';
import en from '@angular/common/locales/en';
import enAU from '@angular/common/locales/en-AU';
import it from '@angular/common/locales/it';
import fi from '@angular/common/locales/fi';
import zhHantHK from '@angular/common/locales/zh-Hant-HK';
import zhHans from '@angular/common/locales/zh-Hans';
import enSG from '@angular/common/locales/en-SG';
import nl from '@angular/common/locales/nl';
import arAE from '@angular/common/locales/ar-AE';
import da from '@angular/common/locales/da';
import es from '@angular/common/locales/es';

import {
  InitialConfigModel, ServiceCalls, ColumnSummary, SystemFlags, User,
  UserModel, LookupColumn, ProductCatalogueHolder
} from '../../models';

import { LocaleData } from '../utils/LocaleData';
import { ColumnConfig } from '../../models/MapData.model';
import { BrickBaseService } from './brick-base.service';
import { isExist } from '../utils/isExist';
import { Brick } from '../../workspace/brick-model';
import { BricsMasterData, Category } from '../../models/bricsMasterData';
import { GLOBAL } from '../utils/app.constant';
import { CommercialButtonActions } from '../../models/request-params';
import { BookingLine } from '../../commercial/commercial.model';

@Injectable()
export class DataShareService {

  /**
  * It contains user Model of config.
  */
  userModel: UserModel;

  private rootScope = {};

  /**
   * It contains initial config data.
   */
  private initialConfig: InitialConfigModel;

  /**
   * It Contains Column Configuration received from map data call
   */
  // private columnConfig: any;

  /**
   * It contains Locale Object of user.
   */
  private locale: string;

  /**
  * It contains serviceCalls of config.
  */
  private serviceCalls: ServiceCalls;

  private columnSummary: ColumnSummary[] = [];

  private columnConfig: ColumnConfig[] = [];

  public languageChangedSub: Subject<boolean> = new Subject();
  public bookingListSub: Subject<BookingLine[]> = new Subject();

  constructor(
    private brickBaseService: BrickBaseService
  ) {

  }

  /**
   * @description It contains Application name.
   * value can be
   * GEO - for Geomapper
   * VIP - for Visual planner
   */
  public appName: AppNameEnum = AppNameEnum.geomapper;

  concertinaSelections = {};

  /**
   * @description It contains commercial config data
   */
  private commercialConfig;

  setCommercialConfig(config) {
    this.commercialConfig = config;
  }

  getCommercialConfig() {
    return this.commercialConfig;
  }

  /**
   * Set the configuraton to initial config.
   * @param config Configuration data
   */
  setInitialConfig(config: InitialConfigModel): void {
    this.initialConfig = config;
    SystemFlags.setSwitchBric(config.alternateDesign);
    this.serviceCalls = config.serviceCalls;
    for (const key in this.serviceCalls) {
      if (this.serviceCalls.hasOwnProperty(key)) {
        const element = this.serviceCalls[key];
        this.serviceCalls[key] = (typeof GLOBAL.SERVICE_BASE_URL !== 'undefined' ? GLOBAL.SERVICE_BASE_URL : '') + element;
      }
    }
    this.userModel = this.loadUserModel(config.userData);
    this.setLocale(config.userData.locale);

    this.initialConfig.uiControl.optionalBrics = [
      this.brickBaseService.brickID.List,
      this.brickBaseService.brickID.Network,
      this.brickBaseService.brickID.ProductCatalogue]; // SBRICS-1664

    GLOBAL.localSolverEnabled = this.initialConfig.uiControl.defaultAllocationEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.initialConfig.uiControl.defaultAllocationEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH;

    this.initialConfigSub.next(this.initialConfig);
  }

  /**
   * @description It contains Application name.
   * value can be
   * GEO - for Geomapper
   * VIP - for Visual planner
   */
  private bricsMasterData: BricsMasterData;

  getParamsForActionButtons(id): CommercialButtonActions {

    const actionButtons = {
      1: {
        title: '',
        action: 'saveCampaign',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        url: this.getServiceCallUrlByKey('CAMPAIGN_SAVE_URL'),
        userBundle: 'common.save',
        color: '#ef3f3b'
      },
      2: {
        title: '',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        requestStatusId: 8,
        action: 'requestBooking',
        url: this.getServiceCallUrlByKey('CAMPAIGN_REQUEST_URL'),
        userBundle: 'result.buttonLabel.requestBooking',
        color: '#FEA800'
      },
      3: {
        title: '',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        requestStatusId: 7,
        action: 'requestBooking',
        url: this.getServiceCallUrlByKey('CAMPAIGN_REQUEST_URL'),
        userBundle: 'result.buttonLabel.requestOption',
        color: '#333333'
      },
      4: {
        title: '',
        hideTitle: false,
        hideFinance: this.initialConfig.uiControl.CCPHideFinanceEnabled,
        action: 'getClientPortelURL',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        url: this.getServiceCallUrlByKey('CAMPAIGN_SAVE_URL'),
        userBundle: 'common.proposal',
        color: '#0abd62'
      },
      5: {
        title: '',
        hideFinance: this.initialConfig.uiControl.CCPHideFinanceEnabled,
        hideTitle: true,
        action: 'getClientPortelURL',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        url: this.getServiceCallUrlByKey('CAMPAIGN_SAVE_URL'),
        userBundle: 'result.buttonLabel.performance',
        color: '#0abd62'
      },
      6: {
        title: '',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        requestStatusId: 4,
        action: 'requestBooking',
        url: this.getServiceCallUrlByKey('CAMPAIGN_REQUEST_URL'),
        userBundle: 'result.buttonLabel.requestBooking',
        color: '#FEA800'
      },
      7: {
        title: '',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        requestStatusId: 2,
        action: 'requestBooking',
        url: this.getServiceCallUrlByKey('CAMPAIGN_REQUEST_URL'),
        userBundle: 'result.buttonLabel.requestOption',
        color: '#333333'
      },
      8: {
        title: '',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        requestStatusId: 8,
        action: 'requestBooking',
        url: this.getServiceCallUrlByKey('CAMPAIGN_REQUEST_URL'),
        userBundle: 'result.buttonLabel.requestBooking',
        color: '#FEA800'
      },
      9: {
        title: '',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        requestStatusId: 7,
        action: 'requestBooking',
        url: this.getServiceCallUrlByKey('CAMPAIGN_REQUEST_URL'),
        userBundle: 'result.buttonLabel.requestOption',
        color: '#333333'
      },
      10: {
        title: '',
        hideTitle: false,
        hideFinance: this.initialConfig.uiControl.CCPHideFinanceEnabled,
        action: 'generateCCP3Link',
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        url: this.getServiceCallUrlByKey('CAMPAIGN_SAVE_URL'),
        userBundle: 'result.buttonLabel.ccp3link',
        color: '#0abd62'
      },
    };

    return actionButtons[id];
  }

  initialConfigSub: Subject<any> = new Subject();
  loadedCampaignSub: Subject<any> = new Subject();
  activeResultTab: Subject<any> = new Subject();
  showVisualPlannerTab: Subject<any> = new Subject();
  showGeoMapperTab: Subject<any> = new Subject();
  isEnableGeoMapperTab: Subject<any> = new Subject();
  isEnableVisualPlannerTab: Subject<any> = new Subject();
  /**
   * @description Activate result and commercial tab if valid solution is available on workspace
   * @author Darshan Vachhani
   * @param {boolean} visible
   * @memberof DataShareService
   */
  activateResultTab(visible: boolean) {
    this.activeResultTab.next(visible);
  }

  /**
   * @description Hides and Shows the VisualPlannerTab
   * @author Shreni
   * @date 2020-04-01
   * @param {boolean} visible
   * @memberof DataShareService
   */
  activateVisualPlannerTab(visible: boolean) {
    this.showVisualPlannerTab.next(visible);
  }

  /**
   * @description Hides and shows the GeoMapper Tab
   * @author Shreni
   * @date 2020-04-01
   * @param {boolean} visible
   * @memberof DataShareService
   */
  activateGeoMapperTab(visible: boolean) {
    this.showGeoMapperTab.next(visible);
  }

  /**
   * @description Enable/Disable Geo Mapper tab
   * @author Dhaval Patel
   * @param {boolean} enable
   * @memberof DataShareService
   */
  enableGeoMapperTab(enable: boolean) {
    this.isEnableGeoMapperTab.next(enable);
  }

  /**
   * @description Enable/Disable Visual Planner tab
   * @author Dhaval Patel
   * @param {boolean} enable
   * @memberof DataShareService
   */
  enableVisualPlannerTab(enable: boolean) {
    this.isEnableVisualPlannerTab.next(enable);
  }

  // TODO: Need to find way to dynamically load locale file
  // DO NOT DELETE
  // setLocale(localeId: string): Promise<any> {
  //   localeId = localeId.replace(localeId.substring(3, 5), localeId.substring(3, 5).toUpperCase());
  //   if (localeId === 'de-DE') {
  //     localeId = 'en-US';
  //   }
  //   return new Promise((resolve, reject) => {
  //     System.import(`@angular/common/locales/${localeId}.js`).then(
  //       (module) => {
  //         registerLocaleData(module.default, localeId);
  //         resolve();
  //       },
  //       () => {
  //         System.import(`@angular/common/locales/${localeId.split('-')[0]}.js`).then((module) => {
  //           registerLocaleData(module.default, localeId);
  //           resolve();
  //         }, reject);
  //       }
  //     );
  //   });
  // }

  /**
   * Get the initial configuration.
   * @return Initial configuration.
   */
  getInitialConfig(): InitialConfigModel {
    return this.initialConfig;
  }

  /**
   * Set the configuraton to column config.
   * USED FOR GEOMAPPER ONLY, for others use state service
   * @param config Configuration data
   */
  setColumnConfig(config): void {
    this.columnConfig = [];
    if (Array.isArray(config)) {
      this.columnConfig = config;
    } else {
      this.columnConfig.push(config);
    }
  }

  /**
   * Set the Locale Object.
   * @param value any that define Locale.
   */
  setLocale(value: any): void {
    this.locale = value;
    LocaleData.locale = value;
    // set locale in angular - default is US so no need to do anything for US
    if (this.locale === 'de-de') { // germany
      registerLocaleData(de, 'en-US');
    } else if (this.locale === 'en-gb') { // UK
      registerLocaleData(enGB, 'en-US');
    } else if (this.locale === 'en-us') { // US
      registerLocaleData(en, 'en-US');
    } else if (this.locale === 'en-au') { // australia
      registerLocaleData(enAU, 'en-US');
    } else if (this.locale === 'nl-be') { // belgium
      registerLocaleData(nlBE, 'en-US');
    } else if (this.locale === 'it-it') { // Italy
      registerLocaleData(it, 'en-US');
    } else if (this.locale === 'zh-hk') { // Hongkong
      registerLocaleData(zhHantHK, 'en-US');
    } else if (this.locale === 'en-sg') { // singapore
      registerLocaleData(enSG, 'en-US');
    } else if (this.locale === 'zh-cn') { // china
      registerLocaleData(zhHans, 'en-US');
    } else if (this.locale === 'nl-nl') { // Netherland
      registerLocaleData(nl, 'en-US');
    } else if (this.locale === 'fi-fi') { // Finland
      registerLocaleData(fi, 'en-US');
    } else if (this.locale === 'ar-ae') { // Dubai
      registerLocaleData(arAE, 'en-US');
    } else if (this.locale === 'da-dk') { // Denmark
      registerLocaleData(da, 'en-US');
    } else if (this.locale === 'es-es') { // Spain
      registerLocaleData(es, 'en-US');
    } else if (this.locale === 'de-at') { // Austria
      registerLocaleData(de, 'en-US');
    }

    LocaleData.setLocaleDateFormat();
    LocaleData.setLocaleNumberFormat('en-US');
  }

  /**
   * Get the Locale object.
   */
  getLocale(): any {
    return this.locale;
  }

  /**
  * Get the user model.
  */
  getUserModel(): UserModel {
    return this.userModel;
  }

  /**
   * @description Returns properties from the initial config data based on given key
   * @author Amit Mahida
   * @param {(string | Array<any>)} key
   * @returns {*}
   * @memberof DataShareService
   */
  getInitialConfigByKey(key: string | any[]): any {
    if (key instanceof Array) {
      const arrInitialConfig: any[] = [];
      key.forEach((obj) => {
        if (typeof obj === 'string') {
          const data = this.initialConfig[obj];
          arrInitialConfig.push(data);
        }
      });
      return arrInitialConfig;
    } else {
      return this.initialConfig[key];
    }
  }

  /**
   * @description Returns service call url from initial config data based on provided key
   * @author Amit Mahida
   * @param {string} key
   * @returns {(string | null)}
   * @memberof DataShareService
   */
  getServiceCallUrlByKey(key: string): string | null {
    if (typeof this.serviceCalls !== 'undefined') {
      return this.serviceCalls[key];
    }
    return null;
  }

  setRootScopeByKey(key: string, val: any) {
    this.rootScope[key] = val;
  }

  getRootScopeByKey(key) {
    return this.rootScope[key];
  }

  /**
   * @description loads user model.
   * @author Amit Mahida
   * @private
   * @param {*} userdata
   * @returns {UserModel}
   * @memberof DataShareService
   */
  private loadUserModel(userdata: any): UserModel {
    const user = new User().user;
    if (userdata && userdata.tokens) {
      if (userdata.tokens.length > 0) {
        userdata.tokens.forEach((token) => {
          switch (token) {
            case 'CAMPAIGN_TYPE':
              user.searchTabAccess.campaignTypeAccess = true;
              break;
            case 'PCM_USER':
              user.workspaceTabAccess.pcmMode = true;
              user.pcmTabAccess.tabAccess = true;
              break;
            case 'OPEN_REQUEST_READONLY':
              user.searchTabAccess.openRequestReadOnly = true;
              user.searchTabAccess.openRequest = true;
              break;
            case 'OPEN_REQUEST':
              user.searchTabAccess.openRequest = true;
              break;
            case 'OPEN_BOOKING_READONLY':
              user.searchTabAccess.openBookingReadOnly = true;
              user.searchTabAccess.openBooking = true;
              break;
            case 'OPEN_BOOKING':
              user.searchTabAccess.openBooking = true;
              break;
            case 'OPEN_SEARCH':
              user.searchTabAccess.openSearch = true;
              break;
            case 'CREATE_PROPOSAL':
              // Result Page
              user.resultTabAccess.createProposal = true;
              user.searchTabAccess.openRequestReadOnly = false;
              user.searchTabAccess.openRequest = true;
              user.searchTabAccess.openBookingReadOnly = false;
              user.searchTabAccess.openBooking = true;
              break;
            case 'SAVE_SEARCH':
              // Result page
              user.resultTabAccess.saveSearch = true;
              break;
            case 'REQUEST_CONFIRMATION':
              // Result page Request Booking
              user.resultTabAccess.requestConfirmation = true;
              break;
            case 'REQUEST_OPTION':
              // Result page
              user.resultTabAccess.requestOption = true;
              break;
            case 'DOWNLOAD_EXPORT':
              // Work Space
              user.workspaceTabAccess.downloadExport = true;
              break;
            case 'COMMERCIAL':
              user.commercialTabAccess.tabAccess = true;
              break;
            case 'CANCEL_BOOKING':
              // Commercial Tab
              user.commercialTabAccess.cancelBooking = true;
              break;
            case 'CONFIRMED_STATUS':
              user.commercialTabAccess.confirmedStatus = true;
              break;
            case 'UPDATE_BOOKING':
              user.commercialTabAccess.updateBooking = true;
              break;
            case 'UPDATE_OPTION_BOOKING':
              user.commercialTabAccess.updateOptionBooking = true;
              break;
            case 'UPDATE_CONFIRM_BOOKING':
              user.commercialTabAccess.updateConfirmBooking = true;
              break;
            case 'OPTIONED_STATUS':
              // Work Space
              user.commercialTabAccess.optionStatus = true;
              break;
            case 'ADV_AVAIL_EXP':
              user.workspaceTabAccess.downloadAdvanceAvailability = true;
              break;
            case 'ALLOCATE_ALL_FRAMES':
              user.workspaceTabAccess.allocateAllFrames = true;
              break;
            case 'EDIT_NETWORK_AVAILABILITY_FILTER':
              user.workspaceTabAccess.editNetworkAvailabilityFilter = true;
              break;
            case 'EDIT_NETWORK_UNMATCHED_FILTER':
              user.workspaceTabAccess.editNetworkUnmatchedFilter = true;
              break;
            case 'SECONDARY_AUDIENCE_WEIGHT':
              user.workspaceTabAccess.secondaryAudienceWeight = true;
              break;
            case 'RESHUFFLE_BOOKING':
              user.workspaceTabAccess.reshuffleBooking = true;
              break;
            case 'ORDERLINE_STATUS_ADJUSTMENT':
              // token for SM-2467
              user.commercialTabAccess.changePending = true;
              break;
            case 'DAILYPAPER_BOOKING':
              user.commercialTabAccess.dailyPaperBooking = true;
              break;
            case 'ALLOW_HIDE_IMPRESSION_CCP_EDIT':
              user.commercialTabAccess.allowHideImpressionCCPEdit = true;
              break;
            case 'ALLOW_HIDE_FINANCIAL_CCP_EDIT':
              user.commercialTabAccess.allowHideFinancialCCPEdit = true;
              break;
            case 'DG_LOCK_COLUMN_ACCESS':
              user.commercialTabAccess.dgLockAccess = true;
              break;
            case 'PREFERRED_LANGUAGE':
              user.searchTabAccess.updateLanguage = true;
              break;
          }
        });
      }
    }
    return user;
  }

  /**
  * @description Sets brics master data information
  * @author Amit Mahida
  * @param {Object} data
  * @memberof DataShareService
  */
  setBricsMasterData(data: any): void {
    const categories: Category[] = data.category.map((category) => {
      const brics: Brick[] = category.brics.map((bric) => {
        return new Brick(
          bric.bricid,
          true,
          bric.bricname,
          bric.color,
          bric.isprimary,
          true,
          undefined,
          undefined,
          bric.audienceCategoryGroupId,
          category.categoryid,
          category.name,
          undefined,
          undefined,
          undefined,
          bric.optionStack,
          bric.hide,
          undefined,
          bric.isObjective,
        );
      });
      return new Category(brics, category.categoryid, category.name);
    });
    this.bricsMasterData = new BricsMasterData(categories);
  }

  /**
   * @description Return brics master data
   * @author Amit Mahida
   * @returns {Object}
   * @memberof DataShareService
   */
  getBricsMasterData(): any {
    return this.bricsMasterData;
  }

  /**
   * @description Sets the isLoadedCampaign flag
   * @author Nishit Parekh
   * @param {*} val
   * @memberof DataShareService
   */
  setLoadedCampaignFlag(val) {
    this.loadedCampaignSub.next(val);
  }

  getColumnSummary(colIndex = 0): ColumnSummary {
    return this.columnSummary[colIndex];
  }

  /**
   *
   * @param colIndex
   * USED FOR GEOMAPPER ONLY, for others use state service
   */
  getColumnConfig(colIndex = 0): ColumnConfig {
    return this.columnConfig[colIndex];
  }

  /**
   * @description get selectionIds form lookupcolumn
   * @author Darshan Vachhani
   * @param {*} lookupColumns
   * @param {*} brickId
   * @returns {any[]} array of selectionIds
   * @memberof DataShareService
   */
  getSelectionIdsForCell(lookupColumns: LookupColumn[], brickId): any[] {
    const selectionIds = [];
    if (lookupColumns.length) {
      const lookupCell = lookupColumns.filter(ele => ele.brickId === brickId)[0];
      if (lookupCell && lookupCell.lookup && lookupCell.lookup.length) {
        lookupCell.lookup.forEach((element) => {
          selectionIds.push(element.selectionId);
          if (element.hasOwnProperty('selectionIds')) {
            element['selectionIds'].forEach((id) => {
              selectionIds.push(id);
            });
          }
        });
      }
    }
    return selectionIds;
  }

  /**
   * @description Returns true if the key element has to be hidden on UI
   * @author Shivani Patel
   * @param {string} key
   * @returns
   * @memberof DataShareService
   */
  hideUIElements(key: string) {
    if (this.initialConfig) {
      return isExist(this.initialConfig.uiControl.hiddenElements, key);
    }
    return false;
  }

  /**
   * Method used to set the ProductCatalogueHolder Object after adding/editting the product from the PCM Mode
   *
   * @param {ProductCatalogueHolder} productCatelogueHolder
   * @memberof DataShareService
   */
  setProductCatalogueHolder(productCatelogueHolder: ProductCatalogueHolder) {
    this.initialConfig.productCatalogueHolder = productCatelogueHolder;
  }

  getdefaultWeekDay(): Object[] {
    const defaultWeekDay = [];
    const userBundle = this.getInitialConfigByKey('userBundle');
    for (let i = 0; i <= 6; i++) {
      if (userBundle[`workspace.pcm.defaultweekday.${i}`]) {
        const defaultWeekDayObject = {
          dayId: i,
          dayName: userBundle[`workspace.pcm.defaultweekday.${i}`]
        };
        defaultWeekDay.push(defaultWeekDayObject);
      }
    }
    return defaultWeekDay;
  }

  languageChanged(isLanguageChanged: boolean) {
    this.languageChangedSub.next(isLanguageChanged);
  }

  updateBookingList(bookingList: BookingLine[]) {
    this.bookingListSub.next(bookingList);
  }
}
