
import { Advertiser } from '../models/advertiser';
import { Brand } from '../models/brand';
import { Specialist } from '../models/specialist';
import { Agency } from '../models/agency';
import { SubAgency } from '../models/subagency';
import { AssignedTo } from '../models/assigned-to';
import { UserModel } from '../models/user';
import { UiControl } from '../models/initial-config.model';
import { BuyerContact } from '../models/buyerContact';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AdminPerson } from '../models/adminPerson';
import { SalesPerson } from '../models/salesPerson';

export class CommercialConfig {
  user: UserModel;
  dontUpdateCommercialCall: boolean;
  bookingStatus: number[];
  uiControl: UiControl;
  campaignStatus: number;
}

export class BookingLine {
  bookingEndDate: string; // This is used in bookingList array where we have booking grid
  bookingLineNumber: number;
  bookingStartDate: string;  // This is used in bookingList array where we have booking grid
  containsDigital: boolean;
  containsPaper: boolean;
  grossMediaValue: number;
  hideMediaControl: boolean;
  impressions: number;
  invoiceTypeId: number;
  invoiceTypeName: string;
  lineNumber: number;
  locked: boolean;
  netMediaValue: number;
  optionDroppingDate: string;
  optionToRenew: boolean;
  readOnlyOptionToRenew: boolean;
  smartBricsValue: number;
  systemReverted: boolean;
  uniqueFrames: number;
  version: number;
  adminPersonId: number;
  adminPersonName: string;
  barterId: number;
  barterInvoiceStatusCode: string;
  barterName: string;
  barterRate: number;
  bookingReference: string;
  bookingStatusId: number;
  buyerContactId: number;
  buyerContactName: string;
  comments: string;
  ctuPersonId: number;
  ctuPersonName: string;
  customerRef: string;
  integrationMessage: string;
  integrationStatusId: number;
  integrationSuccess: false;
  mediaLength: number | string;
  poNumber: string;
  postBarterValue: number;
  preBarterValue: number;
  productionTypeId = -1;
  salesPersonId: number;
  salesPersonName: string;
  blankingOption: string;
  blankOn: any;
  blankBy: any;
  hideBlanking: boolean;
  productCatalogueId?: number;
  productCatalogueName?: string;
  startDate?: string; // This is just used in old bookings array where we don't have booking grid
  endDate?: string; // This is just used in old bookings array where we don't have booking grid
  impressionsRemaining: number;
  impressionsPurchased: number;
  pmpdiscount = 0;
  pmpdiscountDisplay = '';
  campaignCPT = 0;
  grossMediaRemaining?: number;
  grossMediaPurchased: number;
  productionCost?: number;
  mountingCost?: number;
  discount?: number;
  financeAmount?: number;
  purchaseOrderNo?: number;
  skipIntegration?: boolean;
  previousBookingStatusId?: number;
  isCancellationPending?: boolean;
  idCtuPerson?: number;
  noBlanking?: boolean;
  blankOnTermination?: boolean;
}

export class InternalAdvertiser {
  advertiserId = 0;
  advertiserName = '';
  organisationCode?: number;
  organisationId?: number;
  organisationName?: string;
  organisationTypeId?: number;
}

export class StatusCategory {
  calcTechFee: boolean;
  defaultDiscountAmount: number;
  editable: boolean;
  idStatusCategory: number;
  inUse: boolean;
  statusCategoryCode: string;
  statusCategoryName: string;
}

export class Discount {
  actualStart: string;
  dayMask: number;
  discount: number;
  fixed: boolean;
  frame: string;
  frameId: number;
  impressions: number;
  impressionsPurchased: number;
  impressionsRemaining: number;
  innovate: boolean;
  invoiceTypeId: number;
  net: number;
  rateCard: number;
  smartBricsValue: number;
  statusCategoryId: number | string;
  week: string;
  weekDays: string;
  zuluPeriod: number;
  locked: boolean;
  netAfterDiscount: number;
  bookingStatusId?: number;
  campaignStatus?: number;
  originalRateCard?: number;
  agencyCommission?: number;
  agencyCommissionPercent?: number;
  netAfterAgencyCommission?: number;
  negotiationTypeId?: number | string;
  costPerFrame?: number;
  numberFrames?: number;
  brandId?: number;
  brandName?: string;
  statusCategory?: number;
  overrideDiscounts?: boolean | string;
  mediaDiscount1?: number;
  mediaDiscount2?: number;
  discountStafRabDefault?: number;
  mediaDiscount1Default?: number;
  mediaDiscount2Default?: number;
}

export class CampaignDetails {
  campaignCreateDate = '';
  campaignEndDate = '';
  campaignReference: string;
  campaignStatusId: number;
  campaignRequestRaisedOn: string;
  campaignStartDate = '';
  grossMediaValue: number;
  locked: boolean;
  netMediaValue: number;
  smartBricsValue: number;
  thirdParty: object;
  uiControl: UiControl;
  bookings: BookingLine[];
  bookingStatus: object[];
  bookingList: BookingLine[];
  optionDroppingDate = '';
  advertiser?: Advertiser = new Advertiser();
  brand?: Brand = new Brand();
  specialist?: Specialist = new Specialist();
  specialistPercentage?: number = null;
  agency?: Agency = new Agency();
  agencyPercentage?: number = null;
  productCategoryId = -1;
  subAgency?: SubAgency = new SubAgency();
  internalAdvertiser?: InternalAdvertiser = new InternalAdvertiser();
  assignedTo?: AssignedTo = new AssignedTo();
  assignedToId?: number;
  title?: string;
  comments?: string;
  overs?: string;
  salesTeamId = -1;
  adminPersonId = -1;
  adminPersonName?: string;
  salesPersonId = -1;
  salesPersonName?: string;
  reasonForChange?: ReasonForChangeResult;
  freeOfCharge?: ReasonForChangeResult;
  externalReference?: string;
  financeAmount?: number;
  pmpDealId = '';
  campaignCPT?: number;
  pmpDiscount?: number;
  statusCategory?: StatusCategory[];
  discounts?: Discount[];
  discountsV2?: Discount[];
  floatingStartDate?: NgbDateStruct | Date;
  floatingEndDate?: NgbDateStruct | Date;
  campaignReferenceList?: CampaignReference[];
  disableSpecialist?: boolean;
  disableAgency?: boolean;
  calculateAtUI?: boolean;
  overshow?: boolean;
  mediaLength?: number | string;
  buyerContact?: BuyerContact = new BuyerContact();
  adminPerson?: AdminPerson = new AdminPerson();
  salesPerson?: SalesPerson = new SalesPerson();
  organisationContact: object;
  competitionArray: object[];
  campaignTypeId: number;
  product?: string;
  sendProposal?: boolean;
  selectedCampaignReference?: any[];
  campaignReferences?: any[];
  bookingStatusName?: string;
  dgNumber: string;
  opportunityLineId: string;
  bookingDetails?: any[];
  discountGridData?: any;
  gridLines?: any;
  updatedStatus?: any;
  invoiceLocked?: boolean;
  readyForPosting?: boolean;
  preventRecalculation?: boolean;
}
export class ReasonForChangeResolveParam {
  reasonForChange: string;
  status: number;
  isDropDown: boolean;
}

export class ReasonForChangeResult {
  auditReasonCode: string;
  auditReasonId: number;
  auditReasonName: string;
  specifyOther: boolean;
  comments?: string;
  freeText?: string;
  comment?: string;
}

export class FreeOFChargeResult {
  auditReasonCode: string;
  auditReasonId: number;
  auditReasonName: string;
  specifyOther: boolean;
  comments: string;
  freeOfCharge?: ReasonForChangeResult;
  freeText?: string;
}

export class CampaignReference {
  campaignReference: string;
  campaignReferenceDisplay: string;
  dgNumber: string;
  checked?: boolean;

}

export class HistoryROC {
  data = '';
  title = '';
}
