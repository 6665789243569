import { DataShareService } from '../../core/services/data-share.service';
import { TagGroupModel, InitialConfigModel, TagModel } from '../../models';
import { Observable ,  of } from 'rxjs';
import { TagCommon } from './tag-common';
import { DynamicKeyObjectData } from '../../models/Dynamic.model';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../workspace/objective/objective.service';
import { BrickBaseService } from '../../core/services';

export class TagBase {
  /**
   * It contains initial config data.
   */
  public initialConfig: InitialConfigModel;

  pcmMode: boolean;

  /**
   * It contains tags data.
   */
  public tags: any = {
    allTabSelection: {},
    tabeverClicked: false,
    tab: null
  };
  /**
   * It contains tagGroup data.
   */
  public tagGroups: DynamicKeyObjectData<TagGroupModel>;
  /**
   * It contains tag group keys data.
   */
  public tagGroupKeys: string[] = [];
  /**
   * It contains autocomplete items list.
   */
  public autocompleteItems: TagModel[];
  /**
   * It contains search label string.
   */
  public searchForTagText: string;
  /**
   * It contains search title text.
   */
  public searchForText: string;

  /**
   * Creates an instance of TagBase.
   * @author Alkesh Shah
   * @param {DataShareService} dataShareService
   * @memberof TagBase
   */
  constructor(
    private dataShareService: DataShareService,
    private appHeaderService: AppHeaderService,
    private objectiveService: ObjectiveService,
    private brickBaseService: BrickBaseService
  ) {
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.pcmMode = this.appHeaderService.enabledPCM;
  }

  /**
   * @description initialize object data
   * @author Alkesh Shah
   * @param {*} tagGroupsData - tag group data
   * @param {*} selectedValues - selected values
   * @memberof TagBase
   */
  init(tagGroupsData: DynamicKeyObjectData<TagGroupModel>, selectedValues) {
    this.tagGroups = new TagCommon().addExcludeFlagKey(tagGroupsData);
    this.tagGroupKeys = Object.keys(this.tagGroups);
    this.tags.tab = this.tagGroupKeys[0];

    // restore to previous state
    this.searchForText = this.initialConfig.userBundle['geoplanner.text.searchFor'] || 'Search for';
    this.searchForTagText = this.tagGroups[this.tagGroupKeys[0]].tagGroupName;
    this.tags.allTabSelection = selectedValues;

    if (this.appHeaderService.objectiveMode && Object.keys(this.tags.allTabSelection).length === 0) {
      const result: any = this.objectiveService.getSelectedForCollectionBrick(this.tagGroups, this.brickBaseService.brickID.Tag as number);
      this.tagGroups = result.lookups;
      if (Object.keys(result.selected).length) {
        this.tags = result.selected;
      }
    }

    this.autocompleteItems = this.getRoadSide('');
  }

  /**
   * @description To select/highlight selected parent.
   * @author Alkesh Shah
   * @param {string} id Index of selected tab.
   * @memberof TagBase
   */
  selectTabChange(id: string): void {
    this.tags.tabeverClicked = true;
    this.tags.tab = parseInt(id, 10);
    this.searchForTagText = this.tagGroups[id].tagGroupName;
    this.autocompleteItems = this.getRoadSide('');
  }

  /**
   * @description To filter tags data.
   * @author Alkesh Shah
   * @param {string} query Query string to be searched.
   * @returns {TagModel[]}
   * @memberof TagBase
   */
  getRoadSide(query: string): TagModel[] {
    let returnedData: TagModel[] = [];
    for (const tgKey of this.tagGroupKeys) {
      const tgValue: TagGroupModel = this.tagGroups[tgKey];
      if (parseInt(tgKey, 10) === parseInt(this.tags.tab, 10)) {
        returnedData = tgValue.tag.filter((ele) => {
          return ele.tagName.toLowerCase().indexOf(query.toLowerCase()) !== -1 && ele.inUse;
        });
        break;
      }
    }
    return returnedData;
  }

  /**
   * @description To load road side tags data.
   * @param query Query string to get data.
   * @memberof TagBase
   */
  loadRoadSide = (query: string): Observable<TagModel[]> => {
    const returnedData: TagModel[] = this.getRoadSide(query);
    return of(returnedData);
  }

  /**
   * @description To remove selected tag data.
   * @author Alkesh Shah
   * @param {*} index Index of item to be removed.
   * @memberof TagBase
   */
  removeTagsData(index): void {
    if (index != null) {
      this.tags.allTabSelection[this.tags.tab].splice(index, 1);
    }
  }

  /**
   * @description To check if tag data is selected or not.
   * @author Alkesh Shah
   * @param {*} tagId ID of the particular tag.
   * @returns {boolean}
   * @memberof TagBase
   */
  isTagDataSelected(tagId): boolean {
    if (this.tags.allTabSelection[tagId] && this.tags.allTabSelection[tagId].length > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description validate the selections
   * @author Alkesh Shah
   * @returns {boolean} - valid selection or not
   * @memberof TagBase
   */
  isValidSelection(): boolean {
    let isValid = false;
    if (Object.keys(this.tags.allTabSelection).length >= 1) {
      const selectionKeys = Object.keys(this.tags.allTabSelection);
      for (const key of selectionKeys) {
        const element = this.tags.allTabSelection[key];
        if (element.length > 0) {
          isValid = true;
          break;
        }
      }
    }
    return isValid;
  }

  trackByItem(index, item) {
    return item;
  }

  trackByIndex(index, item) {
    return index;
  }

}
