<app-sb-modal-wrapper [title]="resolveObject.brick.bricname" [titleBackgroundColor]="resolveObject.brick.color"
  [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div id="ProximityBrick">
    <div class="btn-group">
      <input type="radio" name="radio_groups" [checked]="proximity.typeSelection == proximityTypeSelection.PostCode"
        value="0" id="check1" checked>
      <label for="check1" (click)="onChangeProximityType(proximityTypeSelection.PostCode);"
        class="">{{userBundle['workspace.collection.bric.proximity.postcode']}}</label>
      <input type="radio" name="radio_groups" [checked]="proximity.typeSelection == proximityTypeSelection.POI"
        value="1" id="check2">
      <label for="check2" (click)="onChangeProximityType(proximityTypeSelection.POI);"
        class="">{{userBundle['workspace.collection.bric.proximity.poi']}}</label>
      <input type="radio" name="radio_groups" [checked]="proximity.typeSelection == proximityTypeSelection.Points"
        value="2" id="check3">
      <label for="check3" (click)="onChangeProximityType(proximityTypeSelection.Points);"
        class="">{{userBundle['workspace.collection.bric.proximity.points'] || 'Points'}}</label>
    </div>
    <!--Common header Ends-->

    <div [hidden]="proximity.typeSelection != proximityTypeSelection.PostCode" class="postcode"
      [ngClass]="{'readOnly' : readOnlyModal }">
      <div class="control_group">
        <input type="radio" id="sectorSelection" name="listuploadSelection"
          [checked]="proximity.postcodeSelection.listUploadSelection == false" class="radio small" value="false"
          (change)="postcodeSelectionChange(false)" />
        <label for="sectorSelection"
          class="proximitylabel">{{userBundle['workspace.collection.bric.proximity.postcode']}}
        </label>

        <div class="modal-autocomplete" [class.disabled]="proximity.postcodeSelection.disablelistupload == 'false'">
          <tag-input id="postcode-tag-input" class="proximity-bricks"
            [secondaryPlaceholder]="userBundle['common.aadTag']" placeholder="{{userBundle['common.aadTag']}}"
            [(ngModel)]="proximity.postcodeSelection.selectedModelValues" [identifyBy]="'postcode'"
            [displayBy]="'postcode'" [lyFromAutocomplete]="true" [minimumTextLength]="2" [maxItems]='100'
            (onAdd)="categoryAddRemoveTags()" (onRemove)="categoryAddRemoveTags()" [maintainSearchText]="false">
            <tag-input-dropdown [minimumTextLength]="3" [identifyBy]="'postcode'" [displayBy]="'postcode'"
              [autocompleteObservable]="loadPostCode" [appendToBody]="false" [matchingFn]="postCodeMatchingFn">
            </tag-input-dropdown>
          </tag-input>
        </div>

      </div>
      <div class="control_group">
        <input type="radio" id="listupload" [checked]="proximity.postcodeSelection.listUploadSelection == true"
          class="radio small" name="listuploadSelection" value="true" (change)="postcodeSelectionChange(true)" />
        <label for="listupload"
          class="proximitylabel">{{userBundle['workspace.collection.bric.proximity.postcodeList']}}</label>
        <app-file-upload ngDefaultControl extension=".xls,.xlsx" [(fileName)]="proximity.fileName"
          (savefilelocally)="savefilelocally($event)"></app-file-upload>
      </div>
      <div class="control_group">
        <label>{{userBundle['common.distance']}} </label>
        <input class="textinput form_control" id="postcodeDistance" autocomplete="off"
          [(ngModel)]="proximity.postcodeSelection.distance.distancevalue" type="text" [imask]="currencyInputMask"
          [unmask]="maskService.unmask.typed" />
        <div class="btn-group">
          <input type="radio" name="radio_groups_pd"
            [checked]="proximity.postcodeSelection.distance.distanceType == distanceUnit.Meters" value="0"
            id="check_units_pd1" (change)="onChangeDistanceType(distanceUnit.Meters, proximityTypeSelection.PostCode)">
          <label for="check_units_pd1" class="">{{userBundle['common.metres']}}</label>
          <input type="radio" name="radio_groups_pd"
            [checked]="proximity.postcodeSelection.distance.distanceType == distanceUnit.KiloMeters" value="1"
            id="check_units_pd2"
            (change)="onChangeDistanceType(distanceUnit.KiloMeters, proximityTypeSelection.PostCode)">
          <label for="check_units_pd2" class="">{{userBundle['common.kilometres']}}</label>
          <input type="radio" name="radio_groups_pd"
            [checked]="proximity.postcodeSelection.distance.distanceType == distanceUnit.Miles" value="2"
            id="check_units_pd3" (change)="onChangeDistanceType(distanceUnit.Miles, proximityTypeSelection.PostCode)">
          <label for="check_units_pd3" class="">{{userBundle['common.miles']}}</label>
        </div>
      </div>
      <div class="control_group_header">
        <div class="btn-group" style="float:left; width:100%;">
          <input type="radio" name="radio_groups_poi" [(ngModel)]="proximity.postcodeSelection.include" value="true"
            id="check11_poi">
          <label for="check11_poi" class="">{{userBundle['common.include']}}</label>
          <input type="radio" name="radio_groups_poi" [(ngModel)]="proximity.postcodeSelection.include" value="false"
            id="check22_poi">
          <label for="check22_poi" class="">{{userBundle['common.exclude']}}</label>
        </div>
      </div>
    </div>
    <!--Postcode section Ends-->

    <div [hidden]="proximity.typeSelection != proximityTypeSelection.POI" class="pointOfInterest"
      [ngClass]="{'readOnly' : readOnlyModal }">
      <div class="control_group">
        <label for="data-source"
          class="proximitylabel">{{userBundle['workspace.collection.bric.proximity.poi.dataSource']}}</label>
        <select id="data-source" class="form_control" [(ngModel)]="proximity.pointOfInterest.selectedDatasource">
          <option *ngFor="let item of proximity.pointOfInterest.datasource; trackBy: trackByPoiType;" [value]="item">{{item.poiTypeName}}</option>
        </select>
        <ng-container *ngIf="!uiControl.proximityPOITree">
          <label for="attribute"
            class="proximitylabel">{{userBundle['workspace.collection.bric.proximity.poi.attribute']}}
          </label>
          <select id="attribute" class="form_control" [(ngModel)]="proximity.pointOfInterest.selectedAttributeId"
            (change)="resetOptions()">
            <option *ngFor="let item of proximity.pointOfInterest.attributeData; trackBy: trackByPoiAttribute;" [value]="item.poiAttributeId">
              {{item.poiAttributeName}}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="uiControl.proximityPOITree">
          <label for="attribute"
            class="proximitylabel">{{userBundle['workspace.collection.bric.proximity.poi.attribute']}}
          </label>
          <select id="attribute" class="form_control" [(ngModel)]="proximity.pointOfInterest.selectedAttributeTreeId"
            (change)="resetOptionsTree()">
            <option *ngFor="let item of proximity.pointOfInterest.attributeTreeData; trackBy: trackById;" [value]="item.id">
              {{item.name}}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="proximity.typeSelection.toString() == '1' && uiControl.proximityPOITree && proximity.pointOfInterest.selectedAttributeTreeId == 1">
          <label for="value1"
            class="left proximitylabel">{{userBundle['workspace.collection.bric.proximity.poi.value']}}
          </label><br>
          <div class="form_control poi-tree-cnt tree-view-proximity">
            <app-tree-view id="poiTree" [data]="treeData" [options]="treeOptions" [lazySearch]="true"  [searchMinlength]="3" [isSearchCaseSensitive]="true"
              [templateProps]="templateProps" (onSelect)="onSelectNode($event)" (onDeSelect)="onDeSelectNode($event)" (selectedRelativeFiltersChange)="relativeFiltersChange($event)">
            </app-tree-view>
          </div>
        </ng-container>

        <ng-container
          *ngIf="proximity.typeSelection.toString() == '1' && uiControl.proximityPOITree && proximity.pointOfInterest.selectedAttributeTreeId == 1 && uiControl.poiEnhancedTree">
          <div class="row control_group" *ngIf="this.proximity.pointOfInterest.selectedModelValues.length > 0">
            <div class="pull-left">
              <ng-container *ngIf="objectKeys(this.selectedValue).length > 0">
                <label style="width:auto">{{userBundle['workspace.proximity.button.showSelected'] || 'Show Selected'}}</label>
                <label class="switchAllowFrames">
                  <input type="checkbox" [(ngModel)]="showSelected" (change)="onShowSelected()">
                  <span class="sliderAllowFrames round"></span>
                </label>
              </ng-container>
            </div>
            <div class="pull-right">
              <!-- <a class="download-btn" (click)="focusOnPreviousSelectedNode()" title="{{userBundle['workspace.proximity.button.previous']}}">
                  <img [src]="'images/arrow_lefts.png'" alt="{{userBundle['workspace.proximity.button.previous'] || 'Previous'}}"/>
              </a>
              <a class="download-btn" (click)="focusOnNextSelectedNode()" title="{{userBundle['workspace.proximity.button.next']}}">
                <img [src]="'images/arrow_rights.png'" alt="{{userBundle['workspace.proximity.button.next'] || 'Next'}}" />
              </a> -->
              <button type="button" class="btn btn-default" (click)="resetOptionsTree()">
                {{userBundle['workspace.proximity.button.deselectAll'] || 'Deselect All'}}
              </button>
            </div>
          </div>
        </ng-container>
        <div class="modal-autocomplete"
          *ngIf="!uiControl.proximityPOITree || (uiControl.proximityPOITree && proximity.pointOfInterest.selectedAttributeTreeId == 2)">
          <label for="value1"
            class="left proximitylabel">{{userBundle['workspace.collection.bric.proximity.poi.value']}}
          </label>
          <tag-input class="proximity-bricks" [secondaryPlaceholder]="userBundle['common.aadTag']"
            placeholder="{{userBundle['common.aadTag']}}" [(ngModel)]="proximity.pointOfInterest.selectedModelValues"
            [identifyBy]="'poiName'" [displayBy]="'poiName'" [onlyFromAutocomplete]="true" [maxItems]='100'
            (onAdd)="categoryAddRemoveTags()" (onRemove)="categoryAddRemoveTags()"
            [maintainSearchText]="false">
            <tag-input-dropdown [minimumTextLength]="2" [identifyBy]="'poiName'" [displayBy]="'poiName'"
              [autocompleteObservable]="loadAttributeData" [appendToBody]="false">
            </tag-input-dropdown>
          </tag-input>
        </div>
      </div>

      <div class="col-lg-push-4" style="position:relative;">
        <input type="radio" class="radio small" name="rdFrame" id="rbt-frame"
          [disabled]="proximity.pointOfInterest.radioButtons.disabled" value="0"
          [checked]="proximity.pointOfInterest.radioButtons.selection == 0"
          (change)="proximity.pointOfInterest.radioButtons.selection = 0">
        <label class="left" for="rbt-frame">{{userBundle['workspace.collection.bric.proximity.poi.all']}}
        </label>
        <input type="radio" name="rdFrame" class="radio small" id="rbt-listUpload"
          [disabled]="proximity.pointOfInterest.radioButtons.disabled" value="1"
          [checked]="proximity.pointOfInterest.radioButtons.selection == 1"
          (change)="proximity.pointOfInterest.radioButtons.selection = 1">
        <label class="left" for="rbt-listUpload">{{userBundle['workspace.collection.bric.proximity.poi.any']}}
        </label>
      </div>

      <div class="control_group">
        <label>{{userBundle['common.distance']}} </label>
        <input class="textinput form_control" autocomplete="off"
          [(ngModel)]="proximity.pointOfInterest.distance.distancevalue" type="text" [imask]="currencyInputMask"
          [unmask]="maskService.unmask.typed" />
        <div class="btn-group">
          <input type="radio" name="radio_groups_units"
            [checked]="proximity.pointOfInterest.distance.distanceType == distanceUnit.Meters" value="0"
            id="check_units_1" (change)="onChangeDistanceType(distanceUnit.Meters, proximityTypeSelection.POI)">
          <label for="check_units_1" class="">{{userBundle['common.metres']}}</label>
          <input type="radio" name="radio_groups_units"
            [checked]="proximity.pointOfInterest.distance.distanceType == distanceUnit.KiloMeters" value="1"
            id="check_units_2" (change)="onChangeDistanceType(distanceUnit.KiloMeters, proximityTypeSelection.POI)">
          <label for="check_units_2" class="">{{userBundle['common.kilometres']}}</label>
          <input type="radio" name="radio_groups_units"
            [checked]="proximity.pointOfInterest.distance.distanceType == distanceUnit.Miles" value="2"
            id="check_units_3" (change)="onChangeDistanceType(distanceUnit.Miles, proximityTypeSelection.POI)">
          <label for="check_units_3" class="">{{userBundle['common.miles']}}</label>
        </div>
      </div>
      <div class="control_group_header">
        <div class="btn-group" style="float:left; width:100%;">
          <input type="radio" name="radio_groups_include" [(ngModel)]="proximity.pointOfInterest.include" value="true"
            id="check11">
          <label for="check11" class="">{{userBundle['common.include']}}</label>
          <input type="radio" name="radio_groups_include" [(ngModel)]="proximity.pointOfInterest.include" value="false"
            id="check22">
          <label for="check22" class="">{{userBundle['common.exclude']}}</label>
        </div>
      </div>
    </div>

    <!--Point Of Interest section Ends-->

    <div [hidden]="proximity.typeSelection != proximityTypeSelection.Points" class="points"
      [ngClass]="{'readOnly' : readOnlyModal }">
      <div class="control_group">
        <input type="radio" id="sectorSelectionPoints" name="pointsListuploadSelection"
          [checked]="proximity.points.listUploadSelection == false" class="radio small" value="false"
          (change)="pointsSelectionChange(false)" />
        <label for="sectorSelectionPoints"
          class="proximitylabel">{{userBundle['workspace.collection.bric.proximity.points'] || 'Points'}}
        </label>

        <input class="form_control" [(ngModel)]="proximity.points.latitude" type="text"
          [placeholder]="userBundle['geoplanner.text.latitude']" style="width: 20%;" [imask]="pointsLatInputMask"
          [unmask]="maskService.unmask.typed" [disabled]="proximity.points.listUploadSelection == true" />
        <input class="form_control" [(ngModel)]="proximity.points.longitude" type="text"
          [placeholder]="userBundle['geoplanner.text.longitude']" style="width: 20%;" [imask]="pointsLngInputMask"
          [unmask]="maskService.unmask.typed" [disabled]="proximity.points.listUploadSelection == true" />
        <div class="proximity-points-add" (click)="addPoints()"><em class="fa fa-plus"></em></div>
        <div class="proximity-points-container" *ngIf="proximity.points.selectedModelValues.length > 0">
          <div class="list">
            <span class="proximity-points-chip"
              *ngFor="let point of proximity.points.selectedModelValues; trackBy: trackByIndex; let i = index">
              {{point.lat}} : {{point.lon}}
              <em (click)="removePoint(i)" class="fa fa-times"></em>
            </span>
          </div>
        </div>
      </div>
      <div class="control_group">
        <input type="radio" id="pointsListupload" [checked]="proximity.points.listUploadSelection == true"
          class="radio small" name="pointsListuploadSelection" value="true" (change)="pointsSelectionChange(true)" />
        <label for="pointsListupload" class="proximitylabel">
          {{userBundle['workspace.collection.bric.proximity.postcodeList']}}
        </label>
        <app-file-upload ngDefaultControl extension=".xls,.xlsx" [(fileName)]="proximity.pointsFileName"
          (savefilelocally)="savePointsfilelocally($event)"></app-file-upload>
      </div>
      <div class="control_group">
        <label for="pointsDistance">{{userBundle['common.distance']}} </label>
        <input class="textinput form_control" id="pointsDistance" autocomplete="off"
          [(ngModel)]="proximity.points.distance.distancevalue" type="text" [imask]="currencyInputMask"
          [unmask]="maskService.unmask.typed" />
        <div class="btn-group">
          <input type="radio" name="radio_groups_po"
            [checked]="proximity.points.distance.distanceType == distanceUnit.Meters" value="0" id="check_units_po1"
            (change)="onChangeDistanceType(distanceUnit.Meters, proximityTypeSelection.Points)">
          <label for="check_units_po1" class="">{{userBundle['common.metres']}}</label>
          <input type="radio" name="radio_groups_po"
            [checked]="proximity.points.distance.distanceType == distanceUnit.KiloMeters" value="1" id="check_units_po2"
            (change)="onChangeDistanceType(distanceUnit.KiloMeters, proximityTypeSelection.Points)">
          <label for="check_units_po2" class="">{{userBundle['common.kilometres']}}</label>
          <input type="radio" name="radio_groups_po"
            [checked]="proximity.points.distance.distanceType == distanceUnit.Miles" value="2" id="check_units_po3"
            (change)="onChangeDistanceType(distanceUnit.Miles, proximityTypeSelection.Points)">
          <label for="check_units_po3" class="">{{userBundle['common.miles']}}</label>
        </div>
      </div>
      <div class="control_group_header">
        <div class="btn-group" style="float:left; width:100%;">
          <input type="radio" name="radio_groups_points" [(ngModel)]="proximity.points.include" value="true"
            id="check11_point">
          <label for="check11_point" class="">{{userBundle['common.include']}}</label>
          <input type="radio" name="radio_groups_points" [(ngModel)]="proximity.points.include" value="false"
            id="check22_point">
          <label for="check22_point" class="">{{userBundle['common.exclude']}}</label>
        </div>
      </div>
    </div>
    <!--Points section Ends-->
  </div>
</app-sb-modal-wrapper>
