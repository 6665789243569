import { InitialConfigModel, SystemFlags } from '../models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CampaignDetails, CommercialConfig, FreeOFChargeResult } from './commercial.model';
import { StateService } from '../../app/core/services/state.service';
import { CampaignGroupTypeList, ThirdParty, CampaignStatus } from '../core/enum';
import { LogHelperService, DataShareService, CommercialService } from '../core/services';
import { CommercialButtonService } from './commercial-buttons/commercial-buttons.service';
import { NgbDatepickerHelper } from '../core/components/ngb-datepicker/ngb-datepicker-helper';
import { RequiredCampaignFields, RequiredBookingsFields, PopulateCommercialFields, PopulateBookingDetailFields } from '../models/RequiredFields';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { Helper } from '../search/campaign-details/helper';
import { GLOBAL } from '../core/utils/app.constant';
import { CampaignDetailsService } from './campaign-details/campaign-details.service';

export class CommercialBase {
  /**
   * @description Initial Config
   * @type {InitialConfigModel}
   * @memberof CommercialBase
   */
  initialConfig: InitialConfigModel;

  /**
   * @description store Commercial Config
   * @type {CommercialConfig}
   * @memberof CommercialBase
   */
  commercialConfig: CommercialConfig = new CommercialConfig();

  /**
   * @description campaign detail store
   * @type {CampaignDetails}
   * @memberof CommercialBase
   */
  campaignDetails: CampaignDetails = new CampaignDetails();

  bookingStatusId = null;

  /**
   * @description helper is used for some common functionality
   * @private
   * @type {Helper}
   * @memberof CommercialBase
   */
  private helper: Helper = new Helper();

  constructor(
    protected stateService: StateService,
    protected logHelperService: LogHelperService,
    protected commercialButtonService: CommercialButtonService,
    protected dataShareService: DataShareService,
    protected commercialService: CommercialService,
    protected datePipe: DatePipe,
    protected campaignDetailsService: CampaignDetailsService,
  ) { }

  /**
   * Base method to validate the fields of campaign detail page
   * @param fields Override the RequiredCampaignFields (Country specific)
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  validateCommonFields = (fields = {}) => {
    const campaignDetails = this.campaignDetails;
    let message = null;
    const searchPageObject = this.stateService.getCampaign();
    let requiredFields: RequiredCampaignFields = new RequiredCampaignFields();
    requiredFields = Object.assign(requiredFields, fields);

    if (requiredFields.advertiser && campaignDetails.advertiser.advertiserId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.advertiser'];
    } else if (requiredFields.brand && campaignDetails.brand.brandId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.brand'];
    } else if (requiredFields.productCategoryId && campaignDetails.productCategoryId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.productCategory'];
    } else if (requiredFields.specialist && campaignDetails.specialist.specialistId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.specialist'];
    } else if (requiredFields.internalAdvertiser && !campaignDetails.internalAdvertiser.advertiserId) {
      message = this.initialConfig.userBundle['commercial.validation.internalAdvertiser'];
    } else if (requiredFields.salesTeamId && campaignDetails.salesTeamId <= 0) {
      message = this.initialConfig.userBundle['commercial.validation.salesTeam'];
    } else if (requiredFields.agency && !campaignDetails.agency.agencyId) {
      message = this.initialConfig.userBundle['commercial.validation.agency'];
    } else if (requiredFields.subAgency && !campaignDetails.subAgency.subAgencyId) {
      message = this.initialConfig.userBundle['commercial.validation.subAgency'];
    } else if (requiredFields.title && (!campaignDetails.title || campaignDetails.title.trim() === '')) {
      message = this.initialConfig.userBundle['commercial.validation.title'];
    } else if (requiredFields.assignedTo && !this.hideUIElements('commercial.booking.assignedTo') && (campaignDetails.assignedTo === undefined || !(campaignDetails.assignedTo && campaignDetails.assignedTo.assignedToId))) {
      message = this.initialConfig.userBundle['commercial.validation.assignTo'];
    } else if (requiredFields.campaignStartDate && !campaignDetails.campaignStartDate) {
      message = this.initialConfig.userBundle['commercial.validation.campaignStartDate'];
    } else if (requiredFields.campaignEndDate && !this.campaignDetails.campaignEndDate) {
      message = this.initialConfig.userBundle['commercial.validation.campaignEndDate'];
    } else if (requiredFields.pmpDealId && (Number(searchPageObject.campaignTypeGroupId) === CampaignGroupTypeList.PMP &&
      (!this.campaignDetails.pmpDealId || this.campaignDetails.pmpDealId.trim() === ''))) {
      message = this.initialConfig.userBundle['commercial.validation.pmpDealId'];
    } else if (requiredFields.campaignCPT && (Number(searchPageObject.campaignTypeGroupId) === CampaignGroupTypeList.PMP && !this.campaignDetails.campaignCPT)) {
      message = this.initialConfig.userBundle['commercial.validation.campaignCPT'] || 'Please Enter Campaign CPT';
    } else if (requiredFields.floatingStartDate && !this.campaignDetails.floatingStartDate) {
      message = this.initialConfig.userBundle['commercial.validation.floatingStartDate'] || 'Please Enter Floating Start Date';
    } else if (requiredFields.floatingEndDate && !this.campaignDetails.floatingEndDate) {
      message = this.initialConfig.userBundle['commercial.validation.floatingEndDate'] || 'Please Enter Floating End Date';
    } else if (requiredFields.adminPerson && !this.campaignDetails.adminPerson.adminPersonId) {
      message = this.initialConfig.userBundle['commercial.validation.adminPerson'] || 'Please Enter BU Admin';
    } else if (requiredFields.salesPerson && !this.campaignDetails.salesPerson.salesPersonId) {
      message = this.initialConfig.userBundle['commercial.validation.salesPerson'] || 'Please Enter Sales Person';
    } else if (requiredFields.externalReference && !this.campaignDetails.externalReference) {
      message = this.initialConfig.userBundle['commercial.validation.externalReference'] || 'Please Enter Mosaic Reference';
    }
    return message;
  }

  /**
   * Base method to validate the booking details/ booking lines
   * @param booking booking detail of booking list
   * @param fields Override the RequiredBookingsFields (Country specific)
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  validateBookingFields = (booking, fields = {}) => {
    let message = null;
    let requiredFields: RequiredBookingsFields = new RequiredBookingsFields();
    requiredFields = Object.assign(requiredFields, fields);

    if (requiredFields.bookingStatusId && !this.hideColumns('status') && (_.isUndefined(booking.bookingStatusId) || booking.bookingStatusId === 0 || booking.bookingStatusId == null)) {
      message = this.initialConfig.userBundle['commercial.validation.bookingStatus'];
    } else if (requiredFields.adminPersonId && !this.hideColumns('admin_person') && !booking.adminPersonId) {
      message = this.initialConfig.userBundle['commercial.validation.adminPersonId'];
    } else if (requiredFields.salesPersonId && !this.hideColumns('sales_person') && !booking.salesPersonId) {
      message = this.initialConfig.userBundle['commercial.validation.salesPersonId'];
    } else if (requiredFields.blankOn && (booking.blankingOption === 'blankOn' && !booking.blankOn)) {
      message = this.initialConfig.userBundle['commercial.validation.blankOnDate'];
    } else if (requiredFields.blankOn && (booking.blankingOption === 'blankBy' && !booking.blankBy)) {
      message = this.initialConfig.userBundle['commercial.validation.blankByValue'];
    }
    return message;
  }

  /**
   * Base method to validate the media length
   * @param booking booking detail of booking list
   * @param status campaign status
   * @param notify If it is true then notify booking grid othrwise no action required
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  validateMediaLength(booking, status, notify = false) {
    const mediaLengthErrorMessage = this.getMediaLengthErrorMessage();
    const mediaLengthZeroErrorMessage = this.getMediaLengthZeroErrorMessage();
    let message = null;
    const statuses = this.commercialConfig.uiControl.mediaLengthRequired;
    if (Array.isArray(statuses) && statuses.indexOf(status) > -1) {
      this.bookingStatusId = status;
      this.campaignDetails.updatedStatus = status;
      if (!booking.mediaLength) {
        message = mediaLengthErrorMessage;
        if (notify) {
          this.commercialService.notifyGrid(this.campaignDetails);
        }
      } else if (Number(booking.mediaLength) === 0) {
        message = mediaLengthZeroErrorMessage;
        if (notify) {
          this.commercialService.notifyGrid(this.campaignDetails);
        }
      }
    }
    return message;
  }

  /**
   * Base method to check filled details are valid or not
   * @param message If it is not blank string then show warning othrwise allow to do action
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  isValid(message) {
    if (message && message !== '') {
      this.logHelperService.logError(message);
      return false;
    } else if (!this.commercialButtonService.isProductsValidAgainstRestrictions(this.campaignDetails)) {
      return false;
    }
    return true;
  }

  /**
   * Base method to populate camapaign parameter in commercial page.
   * It will be used to send in request body when user save campaign details.
   * @param status Campaign Status
   * @param campaignDetails Campaign Details
   * @param fields Override the PopulateCommercialFields (Country specific)
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateBookCommercialCampaignParam(status, campaignDetails, fields = {}) {
    let populate: PopulateCommercialFields = this.getCommercialFields();
    populate = Object.assign(populate, fields);
    let temp: any = this.populatePrimaryFieldsForCampaign(status, populate, campaignDetails);

    if (populate.campaignReferences && campaignDetails.campaignReferences) {
      temp.campaignReferences = [];
      // tslint:disable-next-line:prefer-for-of
      for (const campaignReference of campaignDetails.campaignReferences) {
        temp.campaignReferences.push(campaignReference);
      }
    }

    if (populate.pmpDealId && campaignDetails.pmpDealId) {
      temp.pmpDealId = campaignDetails.pmpDealId.trim();
    }
    if (populate.campaignCPT && campaignDetails.campaignCPT) {
      temp.campaignCPT = campaignDetails.campaignCPT;
    }
    if (populate.preventRecalculation) {
      temp.preventRecalculation = campaignDetails.preventRecalculation === true ? true : false;
    }

    if (populate.mediaLength && campaignDetails.mediaLength) {
      temp.mediaLength = parseFloat(campaignDetails.mediaLength);
    }
    if (populate.buyerContactId && campaignDetails.buyerContact.buyerContactId) {
      temp.buyerContactId = campaignDetails.buyerContact.buyerContactId;
    }

    if (populate.internalAdvertiserId && campaignDetails.internalAdvertiser.advertiserId != null
      && campaignDetails.internalAdvertiser.advertiserId !== ''
      && campaignDetails.internalAdvertiser.advertiserId !== 0) {
      temp.internalAdvertiserId = campaignDetails.internalAdvertiser.advertiserId;
    }

    const commonLogicFields = ['pmpDealId', 'campaignCPT', 'sendProposal', 'product', 'financeAmount',
      'selectedCampaignReference', 'overs', 'pmpDiscount', 'dgNumber', 'opportunityLineId', 'campaignReference',
      'title', 'comments'];
    commonLogicFields.forEach(field => {
      if (populate[field] && campaignDetails[field]) {
        temp[field] = campaignDetails[field];
      }
    });

    if (populate.invoiceLocked) {
      temp['invoiceLocked'] = campaignDetails.invoiceLocked === true ? true : false;
    }

    if (populate.readyForPosting) {
      temp['readyForPosting'] = campaignDetails.readyForPosting === true ? true : false;
    }

    if (populate.salesPerson && campaignDetails.salesPerson.salesPersonId) {
      temp['salesPersonId'] = campaignDetails.salesPerson.salesPersonId;
      temp['salesPersonName'] = campaignDetails.salesPerson.salesPersonName;
    }

    if (populate.adminPerson && campaignDetails.adminPerson.adminPersonId) {
      temp['adminPersonId'] = campaignDetails.adminPerson.adminPersonId;
      temp['adminPersonName'] = campaignDetails.adminPerson.adminPersonName;
    }

    const thirdParty = this.populateThirdPartyParam(campaignDetails, populate);
    if (thirdParty) {
      temp.thirdParty = thirdParty;
    }

    temp = Object.assign(temp, this.populateDateFields(populate, campaignDetails));

    return temp;
  }

  /**
   * To populate Third party parameters in campaign details.
   * @param campaignDetails Campaign Details
   * @param populate PopulateCommercialFields
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateThirdPartyParam(campaignDetails, populate: PopulateCommercialFields) {
    const thirdParty = {};
    if (populate.specialist && campaignDetails.specialist.specialistId !== 0
      && campaignDetails.specialist.specialistId != null && campaignDetails.specialist.specialistId !== '') {
      let commission = 0;
      if (campaignDetails.specialistPercentage !== 'NaN'
        && campaignDetails.specialistPercentage !== ''
        && campaignDetails.specialistPercentage != null) {
        commission = campaignDetails.specialistPercentage;
      }

      thirdParty[ThirdParty.specialist] = {
        commission,
        thirdPartyId: campaignDetails.specialist.specialistId
      };
    }

    if (populate.agency && campaignDetails.agency.agencyId !== 0 && campaignDetails.agency.agencyId != null && campaignDetails.agency.agencyId !== '') {

      let commission = 0;
      if (campaignDetails.agencyPercentage !== 'NaN' && campaignDetails.agencyPercentage !== ''
        && campaignDetails.agencyPercentage != null) {
        commission = campaignDetails.agencyPercentage;
      }

      thirdParty[ThirdParty.agency] = {
        commission,
        thirdPartyId: campaignDetails.agency.agencyId
      };
    }

    if (populate.subAgency && campaignDetails.subAgency.subAgencyId) {
      thirdParty[ThirdParty.subAgency] = {
        thirdPartyId: campaignDetails.subAgency.subAgencyId,
      };
    }

    if (thirdParty && (this.helper.getPropertyList(thirdParty).length !== 0)) {
      return thirdParty;
    }
    return null;
  }

  /**
   * Base method to populate booking list
   * @param campaignDetails Campaign Details
   * @param key either 'bookingList' or 'bookings'
   * @param populate PopulateBookingDetailFields
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateBookingDetailsServiceObject(campaignDetails: CampaignDetails, key: 'bookingList' | 'bookings', populate = {}) {
    const result = [];
    if (campaignDetails[key]) {
      campaignDetails[key].forEach((object) => {
        let bookingDetailObject = this.populateSingleBookingDetails(object, populate);
        bookingDetailObject = this.commercialService.addExtraParamsInBookingLine(bookingDetailObject, object);
        result.push(bookingDetailObject);
      });
    }
    return result;
  }

  /**
   * Populate booking detail for each booking list
   * @param object Booking detail
   * @param fields Override the PopulateBookingDetailFields (Country specific)
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateSingleBookingDetails(object: any, fields = {}) {
    let populate: PopulateBookingDetailFields = this.getBookingDetailFields();
    populate = Object.assign(populate, fields);
    let bookingDetailObject: any = this.populatePrimaryBookingFields(populate, object);

    if (populate.integrationStatusId && typeof (object.integrationStatusId) !== 'undefined') {
      bookingDetailObject.integrationStatusId = object.integrationStatusId;
    }
    const commonLogicFields = ['startDate', 'endDate', 'pmpdiscount', 'impressions', 'campaignCPT', 'comments',
      'customerRef', 'bookingReference', 'invoiceTypeName', 'bookingStatusId', 'poNumber', 'optionDroppingDate',
      'optionToRenew', 'readOnlyOptionToRenew', 'financeAmount'];
    commonLogicFields.forEach(field => {
      if (populate[field] && object[field]) {
        bookingDetailObject[field] = object[field];
      }
    });
    bookingDetailObject = Object.assign(bookingDetailObject, this.populateCostBookingFields(populate, object));
    bookingDetailObject = Object.assign(bookingDetailObject, this.populateExtraBookingFields(populate, object));
    bookingDetailObject = this.commercialService.addExtraParamsInBookingLine(bookingDetailObject, object);
    return bookingDetailObject;
  }

  /**
   * Populate primary/mostly-used fields for campaign
   * @param status Campaign Status
   * @param populate PopulateCommercialFields
   * @param campaignDetails Campaign Details
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populatePrimaryFieldsForCampaign(status, populate: PopulateCommercialFields, campaignDetails) {
    const temp: any = this.populateConditionalFieldsForCampaign(status, populate, campaignDetails);

    if (populate.externalReference && campaignDetails.externalReference && campaignDetails.externalReference !== '') {
      temp.externalReference = campaignDetails.externalReference;
    }

    if (populate.brand && campaignDetails.brand.brandId) {
      temp.brandId = Number(campaignDetails.brand.brandId);
    }

    if (populate.advertiser && campaignDetails.advertiser.advertiserId) {
      temp.advertiserId = Number(campaignDetails.advertiser.advertiserId);
    }

    if (populate.grossMediaValue) {
      temp.grossMediaValue = this.getGrossMediaValue(campaignDetails);
    }

    if (populate.productCategoryId && campaignDetails.productCategoryId > 0) {
      temp.productCategoryId = campaignDetails.productCategoryId;
    }

    if (populate.smartBricsValue && campaignDetails.smartBricsValue
      && !this.initialConfig.uiControl.hidePrice) { // && !this.InitialConfig.uiControl.hidePrice) {
      temp.smartBricsValue = campaignDetails.smartBricsValue;
    }

    if (populate.salesTeamId && campaignDetails.salesTeamId) {
      temp.salesTeamId = Number(campaignDetails.salesTeamId);
    }

    if (populate.overshow && this.commercialConfig.uiControl.showOvershowToggle) {
      temp.overshow = campaignDetails.overshow;
    }

    return temp;
  }

  /**
   * Populate Conditional Fields For Campaign
   * @param status Campaign Status
   * @param populate PopulateCommercialFields
   * @param campaignDetails Campaign Details
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateConditionalFieldsForCampaign(status, populate: PopulateCommercialFields, campaignDetails) {
    const temp: any = this.populateRCAndFCFields(populate, campaignDetails);
    if (populate.netMediaValue && campaignDetails.netMediaValue != null
      && campaignDetails.netMediaValue !== ''
      && !isNaN(campaignDetails.netMediaValue)) {
      temp.netMediaValue = this.getNetMediaValue(campaignDetails);
    }

    if (populate.assignedToId && campaignDetails.assignedTo
      && !this.hideUIElements('commercial.booking.assignedTo')) {
      temp.assignedToId = campaignDetails.assignedTo.assignedToId;
    }

    if (populate.campaignStatusId) {
      if (status) {
        temp.campaignStatusId = status;
      } else {
        temp.campaignStatusId = campaignDetails.campaignStatusId;
      }
    }
    return temp;
  }

  /**
   * populate Reason For Change And Reason For Change Fields
   * @param populate PopulateCommercialFields
   * @param campaignDetails Campaign Details
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateRCAndFCFields(populate: PopulateCommercialFields, campaignDetails) {
    const temp: any = {};
    if (populate.reasonForChange && campaignDetails.reasonForChange) {
      temp.auditReasonId = campaignDetails.reasonForChange.auditReasonId;

      if (campaignDetails.reasonForChange.specifyOther) {
        temp.auditReasonText = campaignDetails.reasonForChange.freeText;
      }
    }

    if (populate.freeOfCharge && campaignDetails.freeOfCharge) {
      temp.auditReasonId = campaignDetails.freeOfCharge.auditReasonId;

      if (campaignDetails.freeOfCharge.specifyOther) {
        temp.auditReasonText = campaignDetails.freeOfCharge.comment;
      }
    }
    return temp;
  }

  /**
   * Populate primary/mostly-used fields for booking detail
   * @param populate PopulateBookingDetailFields
   * @param object booking detail
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populatePrimaryBookingFields(populate: PopulateBookingDetailFields, object) {
    const bookingDetailObject: any = this.populateConditionalBookingFields(populate, object);
    if (populate.adminPersonId && object.adminPersonId) {
      bookingDetailObject.adminPersonId = Number(object.adminPersonId);
    }
    if (populate.salesPersonId && object.salesPersonId) {
      bookingDetailObject.salesPersonId = Number(object.salesPersonId);
    }
    if (populate.ctuPersonId && object.ctuPersonId && !this.hideUIElements('common.ctuPersonName')) {
      bookingDetailObject.ctuPersonId = Number(object.ctuPersonId);
    }
    if (populate.barterId && object.barterId && !this.hideUIElements('common.barterName')) {
      bookingDetailObject.barterId = Number(object.barterId); // SBRICS-1351, Nishit
    }
    if (populate.barterRate && object.barterRate && !this.hideUIElements('common.barter.commission')) {
      bookingDetailObject.barterRate = object.barterRate;
    }
    if (populate.preBarterValue && object.preBarterValue && !this.hideUIElements('common.barter.preValue')) {
      bookingDetailObject.preBarterValue = Number(object.preBarterValue);
    }
    const commonLogicFields = ['invoiceTypeId', 'productCatalogueName', 'productCatalogueId', 'bookingStartDate', 'bookingEndDate',
      'smartBricsValue', 'purchaseOrderNo', 'buyerContactId'];
    commonLogicFields.forEach(f => {
      if (populate[f] && object[f]) {
        bookingDetailObject[f] = object[f];
      }
    });
    return bookingDetailObject;
  }

  /**
   * Populate Conditional Booking Fields
   * @param populate PopulateBookingDetailFields
   * @param object booking detail
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateConditionalBookingFields(populate: PopulateBookingDetailFields, object) {
    const bookingDetailObject: any = {};
    if (populate.grossMediaValue) {
      if (object.grossMediaValue != null && object.grossMediaValue !== 0 && !isNaN(object.grossMediaValue)) {
        object.grossMediaValue = Number(object.grossMediaValue);
        bookingDetailObject.grossMediaValue = object.grossMediaValue;
      } else {
        bookingDetailObject.grossMediaValue = 0;
      }
    }

    if (populate.netMediaValue && object.netMediaValue != null && object.netMediaValue !== 0 && !isNaN(object.netMediaValue)) {
      object.netMediaValue = Number(object.netMediaValue);
      bookingDetailObject.netMediaValue = object.netMediaValue;
    }
    if (populate.productionTypeId && object.productionTypeId && object.productionTypeId !== -1) {
      bookingDetailObject.productionTypeId = Number(object.productionTypeId);
    }
    if (populate.mediaLength && object.mediaLength && !object.hideMediaControl) {
      bookingDetailObject.mediaLength = Number(object.mediaLength);
    }
    return bookingDetailObject;
  }

  /**
   * Populate Cost Booking Fields of booking list
   * @param populate PopulateBookingDetailFields
   * @param object booking detail
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateCostBookingFields(populate: PopulateBookingDetailFields, object) {
    const bookingDetailObject: any = this.populateBlankingOptionFields(populate, object);
    if (populate.productionCost && object.productionCost && !this.commercialService.commercialHiddenElements('commercial.booking.productionValue')) {
      bookingDetailObject.productionCost = Number(object.productionCost);
    }
    if (populate.mountingCost && object.mountingCost && !this.commercialService.commercialHiddenElements('commercial.booking.handlingValue')) {
      bookingDetailObject.mountingCost = Number(object.mountingCost);
    }
    if (populate.discount && object.discount && !this.commercialService.commercialHiddenElements('commercial.booking.discountValue')) {
      bookingDetailObject.discount = Number(object.discount);
    }
    return bookingDetailObject;
  }

  /**
   * Populate Blanking Option Fields of booking list
   * @param populate PopulateBookingDetailFields
   * @param object booking detail
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateBlankingOptionFields(populate: PopulateBookingDetailFields, object) {
    const bookingDetailObject: any = {};
    if (populate.blankingOption && object.blankingOption && !object.hideBlanking) {
      switch (object.blankingOption) {
        case 'noBlanking':
          bookingDetailObject[object.blankingOption] = object[object.blankingOption];
          break;
        case 'blankOnTermination':
          bookingDetailObject[object.blankingOption] = object[object.blankingOption];
          break;
        case 'blankOn':
          // Shreni , SBRICS : 439 //
          if (typeof object.blankOn !== 'undefined' && object.blankOn !== '') {
            const dt = new Date(object.blankOn);
            bookingDetailObject[object.blankingOption] = this.datePipe.transform(dt, GLOBAL.DATE_PARSE_FORMAT);
          }
          break;
        case 'blankBy':
          if (typeof object.blankBy !== 'undefined' && object.blankBy !== '') {
            const dt = new Date(object.blankBy);
            bookingDetailObject[object.blankingOption] = this.datePipe.transform(dt, GLOBAL.DATE_PARSE_FORMAT);
          }
          break;
      }
    }
    return bookingDetailObject;
  }

  /**
   * Populate extra fields of booking details
   * @param populate PopulateBookingDetailFields
   * @param object Booking detail
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateExtraBookingFields(populate, object) {
    const bookingDetailObject: any = {};
    if (populate.isCancellationPending && object.hasOwnProperty('isCancellationPending')) {
      bookingDetailObject.isCancellationPending = object.isCancellationPending;
    }
    if (populate.impressionsRemaining && object.impressionsRemaining) {
      bookingDetailObject.impressionsRemaining = Number(object.impressionsRemaining);
    }
    if (populate.impressionsPurchased && object.impressionsPurchased) {
      bookingDetailObject.impressionsPurchased = Number(object.impressionsPurchased);
    }
    if (populate.grossMediaRemaining && object.grossMediaRemaining) {
      bookingDetailObject.grossMediaRemaining = object.grossMediaRemaining;
    }
    if (populate.grossMediaPurchased && object.grossMediaPurchased) {
      bookingDetailObject.grossMediaPurchased = object.grossMediaPurchased;
    }
    return bookingDetailObject;
  }

  /**
   * Populate date fields
   * @param populate PopulateCommercialFields
   * @param campaignDetails Campaign Details
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  populateDateFields(populate: PopulateCommercialFields, campaignDetails) {
    const temp: any = {};
    if (populate.campaignStartDate && campaignDetails.campaignStartDate) {
      temp.campaignStartDate = this.transformDate(campaignDetails.campaignStartDate);
    }
    if (populate.campaignEndDate && campaignDetails.campaignEndDate) {
      temp.campaignEndDate = this.transformDate(campaignDetails.campaignEndDate);
    }
    if (populate.campaignCreateDate && campaignDetails.campaignCreateDate) {
      temp.campaignCreateDate = this.transformDate(new Date(campaignDetails.campaignCreateDate));
    }
    if (populate.floatingStartDate && campaignDetails.floatingStartDate) {
      temp.floatingStartDate = this.transformDate(NgbDatepickerHelper.convertDateStructToDate(campaignDetails.floatingStartDate as NgbDateStruct));
    }
    if (populate.floatingEndDate && campaignDetails.floatingEndDate) {
      temp.floatingEndDate = this.transformDate(NgbDatepickerHelper.convertDateStructToDate(campaignDetails.floatingEndDate as NgbDateStruct));
    }
    return temp;
  }

  /**
   * To get gross media value
   * @param campaignDetails Campaign Details
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getGrossMediaValue(campaignDetails) {
    if (campaignDetails.grossMediaValue != null
      && campaignDetails.grossMediaValue !== ''
      && !isNaN(campaignDetails.grossMediaValue)) {
      if ((typeof campaignDetails.grossMediaValue === 'string'
        || campaignDetails.grossMediaValue instanceof String)) {
        campaignDetails.grossMediaValue = parseFloat(campaignDetails.grossMediaValue);
      }
      return campaignDetails.grossMediaValue;
    }
    return 0;
  }

  /**
   * Get Net media value
   * @param campaignDetails Campaign Details
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getNetMediaValue(campaignDetails) {
    if ((typeof campaignDetails.netMediaValue === 'string'
      || campaignDetails.netMediaValue instanceof String)) {
      campaignDetails.netMediaValue = parseFloat(campaignDetails.netMediaValue);
    }
    return campaignDetails.netMediaValue;
  }

  /**
   * Get Commercial fields
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getCommercialFields() {
    return new PopulateCommercialFields();
  }

  /**
   * Get booking details fields
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getBookingDetailFields() {
    return new PopulateBookingDetailFields();
  }

  /**
   * To hide UI elements
   * @param key Key
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  hideUIElements(key) {
    return this.dataShareService.hideUIElements(key);
  }

  /**
   * To hide columns
   * @param key Key
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  hideColumns(key: string): boolean {
    let hide = false;

    if (this.commercialConfig.uiControl && this.commercialConfig.uiControl.hiddenColumns) {
      hide = this.commercialConfig.uiControl.hiddenColumns.indexOf(key) !== -1;
    }

    return hide;
  }

  /**
   * To transform date
   * @param date Date
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  transformDate(date) {
    return this.datePipe.transform(date, GLOBAL.DATE_PARSE_FORMAT);
  }

  /**
   * Get Media Length Error Message
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getMediaLengthErrorMessage() {
    return this.initialConfig.userBundle['commercial.error.book.mediaLength'] || 'Please Enter MediaLength';
  }

  /**
   * Get Media Length Zero Error Message
   * @memberof CommercialBase
   * @author Dhaval Patel
   */
  getMediaLengthZeroErrorMessage() {
    return this.initialConfig.userBundle['commercial.error.book.mediaLengthZero'] || 'zero second is not allowed';
  }

  setDefaultAgencySpecialistPerc(agencyPercentage: number = null, specialistPercentage: number = null) {

    if (agencyPercentage == null) {
      agencyPercentage = SystemFlags.isPPCampaign &&
        this.initialConfig.uiControl.hasOwnProperty('defaultSSPUIThirdPartyAgencyCommission') ?
        this.initialConfig.uiControl.defaultSSPUIThirdPartyAgencyCommission : this.initialConfig.uiControl.defaultThirdPartyAgencyCommission;

    }

    if (specialistPercentage == null) {
      specialistPercentage = SystemFlags.isPPCampaign &&
        this.initialConfig.uiControl.hasOwnProperty('defaultSSPUIThirdPartySpecialistCommission') ?
        this.initialConfig.uiControl.defaultSSPUIThirdPartySpecialistCommission : this.initialConfig.uiControl.defaultThirdPartySpecialistCommission;

    }

    this.campaignDetailsService.setDefaultAgencyPercentage(agencyPercentage);
    this.campaignDetailsService.setDefaultSpecialistPercentage(specialistPercentage);
  }

  /**
   * Need to trigger ROChange OR ROCancel in below scenario
   * @param status Status
   */
  handleROC(status, createRequestJSONForCommercial) {
    this.commercialButtonService.openReasonForChangeOverlay(
      this.commercialButtonService.populateDataParamReasonForChange(status, this.campaignDetails, this.commercialConfig.uiControl))
      .then((result: any) => {
        if (result) {
          this.campaignDetails = this.commercialButtonService.reasonForChangeOverlayThen(this.campaignDetails, result, status);

          const param = {
            bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
            data: JSON.stringify(createRequestJSONForCommercial(status))
          };
          this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
        }
      }, (reason) => {
        console.log(reason);
      });
  }

  /**
   * Confirm Campaign
   */
  confirmCampaign(createRequestJSONForCommercial) {
    this.commercialButtonService.openFreeOFChargeOverlay().then((result: FreeOFChargeResult) => {
      if (result) {
        this.campaignDetails.freeOfCharge = result.freeOfCharge;
        const comment = this.getComment(result);
        result.freeOfCharge.comment = comment;
        this.campaignDetails.comments = (this.campaignDetails.comments ?
          (`${this.campaignDetails.comments}\n\r${comment}`) : comment);
        const campaignStatus = CampaignStatus.confirm || this.campaignDetails.campaignStatusId;
        const param = {
          bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
          data: JSON.stringify(createRequestJSONForCommercial(campaignStatus))
        };
        this.commercialButtonService.validateCommercialScreenServiceRequest(param, this.campaignDetails.campaignStatusId);
      }
    });
  }

  /**
   * TO get the comment for campaign details from response
   * @param result Response of confirmed campaign
   */
  getComment(result) {
    if (result.freeOfCharge && result.freeOfCharge.specifyOther) {
      return (this.commercialButtonService.startsWith(result.freeText, 'FOC - ') ? '' : 'FOC - ') + result.freeText;
    }
    return (this.commercialButtonService.startsWith(result.freeOfCharge.auditReasonName, 'FOC - ') ? '' : 'FOC - ')
      + result.freeOfCharge.auditReasonName;
  }

  validateServiceRequest(status, createRequestJSONForCommercial) {
    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      data: JSON.stringify(createRequestJSONForCommercial(status))
    };
    this.commercialButtonService.validateCommercialScreenServiceRequest(param, status);
  }

  /*
    Need to trigger ROChange OR ROCancel in below scenario [SM-5283]
    1. It should be loaded campaign and there should be data in auditType(to populate drop downs) in Initial Config
    2. Trigger ROCancel OR
    3. Trigger ROChange
  */
  isROC(status) {
    if (SystemFlags.isLoadedCampaign && (this.initialConfig.auditType && this.initialConfig.auditType.length > 0) &&
      (this.commercialButtonService.roCancel(this.campaignDetails, this.commercialConfig.uiControl, status) ||
        this.commercialButtonService.roChange(this.campaignDetails, this.commercialConfig.uiControl, 'bookingList', status))) {
      return true;
    }
    return false;
  }

  isConfirm(status) {
    if ((this.commercialConfig.uiControl.tradingEnabled ?
      this.campaignDetails.grossMediaValue === 0 && this.commercialConfig.uiControl.enableFOC : false) &&
      status === CampaignStatus.confirm) {
      return true;
    }
    return false;
  }

  trackByIndex(index, item) {
    return index;
  }

  updateCommercialInfoForCPT(campaigndata) {
    const promise = new Promise((resolve, reject) => {
      const data = {
        data: JSON.stringify(campaigndata),
        bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
        redirection: true
      };
      this.commercialService.updateCommercialInfo(data).subscribe((res) => {
        if (String(res.status) === 'OK') {
          resolve(true);
          return promise;
        }
      }, () => {
        reject();
        return promise;
      });
    });
    return promise;
  }
}
