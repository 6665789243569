import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { DndDropEvent, DndDragImageOffsetFunction } from 'ngx-drag-drop';
import { Router } from '@angular/router';
import { SharedService } from './../../core/services/shared.service';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { LogHelperService } from './../../core/services/log-helper.service';
import { WorkspaceService } from './../../core/services/workspace.service';
import { SearchService } from '../../../../../geoplanner/src/app/search';
import { WorkspaceActionButtonService } from '../workspace.action.button.service';
import { SbModalPopupService } from './../../core/components/sb-modal-popup/sb-modal-popup.service';
import { AddProductComponent, EnableDisableProductComponent } from '../modal-windows/index';
import { CellValues, Row, Cell } from './../../models/workspace/index';
import { CellPosition, ExpandDirection } from '../../models/workspace/cell-position';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from './../../core/services/state.service';
import { DataShareService } from '../../core/services/data-share.service';
import { HistoryStackService } from '../../core/services/history-stack.service';
import { CellAttributeService } from './../../core/services/cell-attributes.service';
import { PopulateSelected } from '../../workspace/populate-selected';
import { WorkspaceBase } from '../workspace-base';
import { HistoryStackData } from '../../models/workspace/historyStackData';
import { PcmService } from '../../core/services/pcm.service';
import * as _ from 'lodash';
import { GLOBAL } from '../../core/utils/app.constant';
import { ColumnConfigs } from '../../models/MapData.model';
import { ProductCatalogue } from '../../models/productCatalogue';
import { ObjectiveModeForEnum, ObjectiveService } from '../objective/objective.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-pcm',
  templateUrl: './pcm.component.html',
  styleUrls: ['./pcm.component.css', './../workspace-pcm.css'],
  providers: [SbModalPopupService],
  encapsulation: ViewEncapsulation.None
})
export class PcmComponent extends WorkspaceBase implements OnInit, OnDestroy {

  lastProductSaved: ProductCatalogue;

  /**
   * @description returns true if product is in edit mode
   * @memberof PcmComponent
   */
  editProductMode = false;

  subscriptions: Subscription[] = [];

  /**
   * Creates an instance of WorkspaceComponent.
   * @author Amit Mahida
   * @memberof WorkspaceComponent
   */
  constructor(
    private sbModalPopupService: SbModalPopupService,
    public brickBaseService: BrickBaseService,
    public dataShareService: DataShareService,
    public stateService: StateService,
    public logHelperService: LogHelperService,
    public workspaceActionButtonService: WorkspaceActionButtonService,
    public workspaceService: WorkspaceService,
    public historyStackService: HistoryStackService,
    private router: Router,
    public appHeaderService: AppHeaderService,
    private searchService: SearchService,
    public pcmService: PcmService,
    private cellAttributeService: CellAttributeService,
    private sharedService: SharedService,
    private objectiveService: ObjectiveService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(brickBaseService, dataShareService, stateService, appHeaderService);
    this.subscriptions.push(this.dataShareService.languageChangedSub.subscribe((result) => {
      if (result) {
        this.stateService.setPCMObject('filter', this.filter);
        this.ngOnInit();
        this.filter = this.stateService.getPCMFilterObj();
        this.changeDetectorRef.detectChanges();
      }
    }));
  }

  ngOnInit() {
    this.onInit();
    this.appHeaderService.changePCMMode(true);
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.backupFilter = this.stateService.clone(this.filter);
  }

  /**
   * @description - callback method call on navigating to other page
   * @author Alkesh Shah
   * @returns {boolean}
   * @memberof WorkspaceComponent
   */
  // commenting below code, when we addrequired brics in worksapce,
  // go to pcm and again come back to workspace, it gave console error
  // beforeNavigate(): boolean {
  //   if (GLOBAL.BRIC_CAMPAIGN_ID >= 0 && !SystemFlags.incorrectSolution) {
  //     const stateChangeCondition = ((this.backupFilter.rows.length > 0 || this.filter.rows.length > 0) &&
  //       (JSON.stringify(this.backupFilter.rows) !== JSON.stringify(this.filter.rows)));
  //     if (!SystemFlags.getBasketDataForGP) {
  //       SystemFlags.getBasketDataForGP = stateChangeCondition ? true : false;
  //     }
  //     if (!SystemFlags.getBasketDataForVP) {
  //       SystemFlags.getBasketDataForVP = stateChangeCondition ? true : false;
  //     }
  //   } else {
  //     SystemFlags.getBasketDataForGP = false;
  //     SystemFlags.getBasketDataForVP = false;
  //   }
  //   return true;
  // }

  disableObjectiveBrick = (e, cell: any) => {
    cell.selected.disableObjective = !cell.selected.disableObjective;
    e.stopPropagation();
  }

  onDrop(event: DndDropEvent, rowIndex?: number, colIndex?: number) {
    if (!event.data) {
      this.expandDirection = ExpandDirection.None;
      this.showRemoveBar = false;
      return;
    }
    if (event.dropEffect === 'copy') {
      this.sendGAEvent(event.data.bricid);
      const cellPosition: CellPosition = this.filter.dropBrick(event.data, rowIndex, colIndex, this.expandDirection, true);
      if (cellPosition.rowIndex !== -1) {
        try {
          if (!this.filter.isMandatoryBrick(event.data.bricid) && !this.filter.isMandatoryBricksAvailable(cellPosition.cellIndex)
            && this.brickBaseService.bypassBricks.indexOf(event.data.bricid) === -1
            && (cellPosition.cellIndex === 0 || !this.filter.willColumnExpand(cellPosition.cellIndex))) {
            this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
            return;
          }
          const cellValues: CellValues = new CellValues();
          cellValues.brick = { ...event.data, ...cellPosition };
          this.openModal(cellValues);
        } catch (e) {
          this.logHelperService.logError(e);
        }
      } else {
        this.logHelperService.logError(cellPosition.note);
      }
    } else if (event.dropEffect === 'move') {
      this.filter.moveBrick(event.data, rowIndex, colIndex, this.expandDirection);
    }
    this.expandDirection = ExpandDirection.None;
    this.showRemoveBar = false;
  }

  openModal(cellValues: CellValues) {
    switch (cellValues.brick.bricid) {
      // case this.brickBaseService.brickID.Budget:
      case this.brickBaseService.brickID.Pattern:
      case this.brickBaseService.brickID.Media:
      case this.brickBaseService.brickID.Environment:
      case this.brickBaseService.brickID.ProductCatalogue:
      case this.brickBaseService.brickID.Distribution:
      case this.brickBaseService.brickID.Network:
      case this.brickBaseService.brickID.Objective:
      case this.brickBaseService.brickID.MultiTarget:
        this.openBricModalWindow(cellValues, false);
        break;
      default:
        this.openBricModalWindow(cellValues, false, true);
        break;
    }
  }

  /**
   * Redo last undone step
   */
  redoLastUndoneStep() {
    const oldItem = this.historyStackService.popFromUndoneHistoryStackPCM(this.stateService.getFreshFilterObj());
    if (oldItem) {
      // Push the item into Undo stack
      // Used when we do Undo then again Redo (eg: To push into Undo stack from Redo stack)
      this.historyStackService.pushInHistoryStackRedoPCM(new HistoryStackData(this.filter));
      this.workspaceService.setDefaultPanning();

      // Set current workspace item to poped workspace item from Redo Stack
      this.filter = oldItem;
      this.stateService.setPCMObject('filter', this.filter);

      // VJ: SM-5601, When a product is loaded and user is performing undo, its reinitializes productCatalogue and disabling edit mode
      this.filter.filterProductBuild();
    }
  }

  /**
   * Undo last step
   */
  undoLastStep() {
    const oldItem = this.historyStackService.popFromHistoryStackPCM(this.stateService.getFreshFilterObj());
    if (oldItem) {
      this.historyStackService.pushInUndoneHistoryStackPCM(new HistoryStackData(this.filter));
      this.workspaceService.setDefaultPanning();

      // Set current workspace item to previous workspace item
      this.filter = oldItem;

      if (this.filter.getMarkSelectedCount() > 0) {
        this.filter.removeMarkSelected();
      }
      this.stateService.setPCMObject('filter', this.filter);
      // After completing undo process need to send request to the backend
      // VJ: SM-5601, When a product is loaded and user is performing undo, its reinitializes productCatalogue and disabling edit mode
      this.filter.filterProductBuild();
    }
  }

  /**
   * @description call back function - called on dbl click on bric
   * @author Alkesh Shah
   * @param {Row} row - row object of
   * @param {Cell} cell
   * @memberof WorkspaceComponent
   */
  onBrickDblClick(row: Row, cell: Cell): void {
    const cellValues: CellValues = new CellValues();
    cellValues.brick = _.clone(row.bric);
    cellValues.brick.cellIndex = cell.cellIndex;
    cellValues.brick.rowIndex = cell.rowIndex;
    cellValues.selectedValues = _.cloneDeep(cell.selected);
    this.openBricModalWindow(cellValues, true);
  }

  /**
   * @description open bric modal popup
   * @author  Alkesh Shah, Amit Mahida
   * @param {CellValues} values
   * @param {boolean} [isEditMode=false]
   * @param {boolean} [allSelection=false]
   * @returns
   * @memberof PcmComponent
   */
  openBricModalWindow(values: CellValues, isEditMode = false, allSelection = false) {

    // if its drop bric case and need to populate with All Selection
    if (allSelection) {
      this.setValuesAndAddBric(values);
      return;
    }
    const cellComponent = this.getComponent(values.brick.bricid);
    const modalOptions: NgbModalOptions = this.getComponentModalOptions(values.brick.bricid);
    const columnConfig = this.stateService.getPCMObject('columnConfig');
    values.columnConfig = this.assignColumnConfig(columnConfig, values.brick.cellIndex, values.brick.bricid);

    // SM-9122 - here, used defaultAllocationEngine as reshufflingEngine from initConfig based on selected media type.
    const mediaBrickRow: Row = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Media));
    let mediaBrick: Cell;
    const mediaTypes = this.dataShareService.getInitialConfigByKey('mediaType');
    if (mediaBrickRow) {
      const inUseByResizable: number = mediaBrickRow.getOverlappingCell(values.brick.cellIndex);
      mediaBrick = inUseByResizable === -1 ? mediaBrickRow.cells[values.brick.cellIndex] : mediaBrickRow.cells[inUseByResizable];
      if (mediaBrick && mediaBrick.selected && mediaBrick.selected.selectedMedia) {
        const selectedMediaType = _.filter(mediaTypes, (mediaType) => {
          return mediaType.mediaTypeId.toString() === mediaBrick.selected.selectedMedia.toString();
        });
        if (selectedMediaType.length > 0) {
          values.reshufflingEngine = selectedMediaType[0].defaultAllocationEngine;
        }
      }
    }

    if (!this.checkIfBricAllowedToOpen(values, values.columnConfig)) {
      return;
    }
    if (values.brick.bricid === this.brickBaseService.brickID.Objective) {
      this.appHeaderService.changePCMMode(false);
      this.objectiveService.objectiveModeFor = ObjectiveModeForEnum.pcm;
      this.objectiveService.isEditMode = isEditMode;
      this.stateService.setObjectiveObject('currentObjectiveData', values);
      this.appHeaderService.changeObjectiveMode(true);
      this.stateService.setPCMObject('filter', this.filter);
      this.router.navigate(['objective']);
      return;
    }
    if (this.brickBaseService.floorCeilingBrics.indexOf(values.brick.bricid) >= 0) {
      values.sot = this.filter.getSOTForPCM(values.brick.cellIndex, values.brick.bricid);
      values.sot.mediaOnlyPaper = this.filter.checkIfColumnHasOnlyPaper(values.brick.cellIndex);
      if (values.selectedValues && values.sot.sotIncrement) {
        values.selectedValues.sotFloor = values.sot.sotFloor;
        values.selectedValues.sotCeiling = values.sot.sotCeiling;
      }
      values.selectedValues.sotIncrement = values.sot.sotIncrement;
    }

    this.sbModalPopupService.open(cellComponent, values, modalOptions).result.then((result: CellValues) => {
      if (values.brick.bricid === this.brickBaseService.brickID.Network) {
        this.filter.addNetworkData(result);
      } else {
        this.filter.addBrick(result, true, isEditMode);
      }
    }, () => {
      // on cancel seletion
    });
  }

  /**
   * @description This event will be triggered whenever user will click on any brick
   * @author Vijay Sutaria
   * @param {Cell} cell
   * @memberof WorkspaceComponent
   */
  onBrickClick(cell: Cell) {
    if (this.allowExplode) {
      this.allowExplode = this.filter.explodeCell(cell, true);
    } else if (this.allowCustomDelete && !cell.isEmpty && !cell.isLocked) {
      cell.markSelected = !cell.markSelected;
    }
  }

  /**
   * @description
   * @author Vijay Sutaria
   * @param {*} buttonType
   * @memberof WorkspaceComponent
   */
  toggleButtons(buttonType) {
    this.filter.removeMarkSelected();
    switch (buttonType) {
      case 'explode':
        this.allowExplode = !this.allowExplode;
        this.allowSplit = false;
        this.allowCustomDelete = false;
        break;
      case 'multiDelete':
        this.allowSplit = false;
        this.allowExplode = false;
        this.allowCustomDelete = !this.allowCustomDelete;
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  backToWorkspace() {
    this.stateService.setPCMObject('filter', this.filter);
    this.appHeaderService.changePCMMode(false);
    this.router.navigate(['workspace']);
  }

  /**
   * @description Handles remove event of row/cell
   * @author Amit Mahida
   * @param {DndDropEvent} event
   * @memberof WorkspaceComponent
   */
  onRemoveBrick(event: DndDropEvent) {
    this.historyStackService.pushInHistoryStackPCM(new HistoryStackData(this.filter));

    switch (event.type) {
      case 'row':
        this.filter.removeRow(event.data.index);
        break;
      case 'cell':
        if (this.filter.getMarkSelectedCount() || this.allowCustomDelete) {
          this.filter.removeMultipleBric();
        } else {
          this.filter.removeBric(event.data.rowIndex, event.data.cellIndex);
        }
        break;

      default:
        break;
    }
    this.allowCustomDelete = false;
    this.showRemoveBar = false;
  }

  /**
   * @description Applies drag effect on bricks
   * @author Amit Mahida
   * @type {DndDragImageOffsetFunction}
   * @memberof WorkspaceComponent
   */
  // tslint:disable-next-line: variable-name
  calDragImageOffset: DndDragImageOffsetFunction = (_event: DragEvent, dragImage: HTMLElement) => {
    const dragImageComputedStyle = window.getComputedStyle(dragImage);
    const width = parseFloat(dragImageComputedStyle.width);
    const height = parseFloat(dragImageComputedStyle.height);
    const paddingTop = parseFloat(dragImageComputedStyle.paddingTop) || 15;
    const paddingLeft = parseFloat(dragImageComputedStyle.paddingLeft) || 0;
    const borderTop = parseFloat(dragImageComputedStyle.borderTopWidth) || 0;
    const borderLeft: any = parseFloat(dragImageComputedStyle.borderLeftWidth) || 0;
    return {
      x: paddingLeft + borderLeft + (width / 2),
      y: paddingTop + borderTop + (height / 4)
    };
  }

  /**
   * @description Sets expand direction on draging over left or right of an existing cell
   * @author Amit Mahida
   * @param {Cell} cell
   * @memberof WorkspaceComponent
   */
  setExpandDirection(cell: Cell) {
    if (cell.highlightLeft) {
      this.expandDirection = ExpandDirection.Left;
    } else if (cell.highlightRight) {
      this.expandDirection = ExpandDirection.Right;
    } else {
      this.expandDirection = ExpandDirection.None;
    }
  }

  /**
   * @description Set All selection values for bric which are allowed for all selection
   * @author Nishit Parekh
   * @param {CellValues} values
   * @memberof PcmComponent
   */
  setValuesAndAddBric(values: CellValues) {

    const populateSelected = new PopulateSelected();

    switch (values.brick.bricid) {
      case this.brickBaseService.brickID.Incharge:
        values.selectedValues = populateSelected.getInchargeSelectedValue({});
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.Tag:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = { selectionCriteriaTag: [[{ selectionId: -99 }, { '-99': -99 }]] };
        break;
      case this.brickBaseService.brickID.Audience:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = {
          selectionAudience: {
            ...values.selectedValues,
            ...{ audienceCategoryGroupId: values.brick.audienceCategoryGroupId }
          }
        };
        break;
      case this.brickBaseService.brickID.SecondaryAudience:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = {
          secondaryAudience: {
            ...values.selectedValues,
            ...{ audienceCategoryGroupId: values.brick.audienceCategoryGroupId }
          }
        };
        break;
      case this.brickBaseService.brickID.Budget:
        values.selectedValues = populateSelected.getSelectionBudgetSelectedValue({});
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.Volume:
        values.selectedValues = populateSelected.getSelectionVolumeSelectedValue({});
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.Format:
      case this.brickBaseService.brickID.Location:
      case this.brickBaseService.brickID.SOT:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(values.brick.bricid, values.selectedValues);
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.Proximity:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = { selectionProximity: { userSelectionIds: [-99] } };
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.Frame:
        values.selectedValues = populateSelected.getFrameSelectedValue({});
        values.requestJson = this.cellAttributeService.getBrickRequestJSON(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.List:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = { selectionFrameList: [{ userSelectionId: -99 }] };
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.Channel:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = { selectionCriteriaProduct: [[{ selectionId: -99 }, { '-99': -99 }]] };
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      // case this.brickBaseService.brickID.SOT:
      //   values.selectedValues = GLOBAL.allSelection;
      //   values.requestJson = this.cellAttributeService.getBrickRequestJSON(values.brick.bricid, values.selectedValues);
      //   values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
      //   break;
      case this.brickBaseService.brickID.PricingTag:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = { selectionCriteriaNetwork: [[{ selectionId: 28 }, { '-99': -99 }]] };
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.AllAudience:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = { secondaryAudiences: GLOBAL.allSelection };
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.PrimaryAudience:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = {
          selectionPrimaryAudiences: {}
        };
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      case this.brickBaseService.brickID.FrameQuality:
        values.selectedValues = GLOBAL.allSelection;
        values.requestJson = { selectionFrameQuality: GLOBAL.allSelection };
        values.toolTipText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
        break;
      default:
        break;
    }
    values.displayText = this.cellAttributeService.getDisplayText(values.brick.bricid, values.selectedValues);
    this.filter.addBrick(values);
  }

  /**
   * Method to open the Save Modal For Adding/Editting the new Product
   *
   * @memberof PcmComponent
   */
  addNewProduct() {
    if (!this.filter.isMandatoryBricksAvailable()) {
      this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
      return;
    }
    const modalOptions: NgbModalOptions = {
      windowClass: 'modal-backdrop',
      size: 'sm'
    };
    this.sbModalPopupService.open(AddProductComponent, this.filter.productCatalogue, modalOptions).result.then((result) => {
      this.filter.generateSOTBrickRequestJSON();
      this.filter.generateBrickRequestJSON();
      let productCatalogue = new ProductCatalogue();
      productCatalogue = Object.assign(productCatalogue, result);
      productCatalogue.bricsData = this.filter.brickRequestJSON;

      if (productCatalogue.hasOwnProperty('idProductCatalogue')) {
        delete productCatalogue.idProductCatalogue;
      }
      const params = {
        action: 'saveProductCatalogue',
        productCatalogue: JSON.stringify(productCatalogue)
      };

      this.makeCallForProduct(params, !this.filter.productEditMode).then(() => {
        if (this.filter.productEditMode) {
          const productToDisable = this.stateService.getPCMObject('productToDisable');
          if (productToDisable) {
            productToDisable.inUse = false;
          }
          const paramsForLoadedProduct = {
            action: 'saveProductCatalogue',
            productCatalogue: JSON.stringify(productToDisable)
          };
          this.makeCallForProduct(paramsForLoadedProduct).then(() => {
            this.refreshProductList();
            this.stateService.setPCMObject('productToDisable', null);
          }, (error) => {
            console.log(error);
          });
        } else {
          this.refreshProductList();
        }
      }, (error) => {
        console.log(error);
      });
    }, () => {
      // On Cancel
    });
  }

  /**
   * Makes server call to add/edit product
   *
   * @param {*} params
   * @param showSuccessMessage set this flag to true if want to display success message (SM-4018)
   * @returns
   * @memberof PcmComponent
   */
  makeCallForProduct(params, showSuccessMessage = true) {
    return new Promise((resolve, reject) => {
      this.workspaceService.addProduct(params, this.dataShareService.getServiceCallUrlByKey('PCM_ADD_PRODUCT')).subscribe((data) => {
        if (data.status === 'OK') {
          const successMessage = params.inUse ?
            this.userBundle['workspace.pcm.disable.success'] :
            this.userBundle['workspace.pcm.enable.success'];

          if (showSuccessMessage) {
            this.logHelperService.logInfo(data.message || successMessage);
          }
          resolve(data.message || successMessage);
        } else if (data.status === 'KO') {
          this.onKO(data, params, reject);
        }
      }, (errorRes) => this.onErrorCall(errorRes, params, reject));
    });
  }

  onKO(data, params, reject) {
    const errorMessage = params.inUse ?
      this.userBundle['workspace.pcm.disable.failer'] :
      this.userBundle['workspace.pcm.enable.failer'];
    this.logHelperService.logError(data.message || errorMessage);
    reject();
  }

  onErrorCall(errorRes, params, reject) {
    const errorMessage = params.inUse ?
      this.userBundle['workspace.pcm.disable.failer'] :
      this.userBundle['workspace.pcm.enable.failer'];
    this.logHelperService.logError(this.userBundle['workspace.pcm.add.failer'] || errorMessage || JSON.stringify(errorRes));
    reject();
  }
  /**
   * Makes initial config call and updates the initialConfig.ProductCatalogueHolder to get
   * the newly added products
   *
   * @memberof PcmComponent
   */
  refreshProductList() {
    this.searchService.getInitialConfig(null).subscribe((response: any) => {
      if (response.status === 'OK') {
        const productCatalogueHolder = response.data.productCatalogueHolder;
        const products: ProductCatalogue[] = this.dataShareService.getInitialConfigByKey('productCatalogueHolder').productCatalogue;
        if (products && products.length) {
          this.lastProductSaved = productCatalogueHolder.productCatalogue.filter((product) => {
            return products.findIndex(oldProd => oldProd.idProductCatalogue === product.idProductCatalogue) === -1;
          })[0];
        }
        this.stateService.setPCMObject('lastProductSaved', this.lastProductSaved);
        this.dataShareService.setProductCatalogueHolder(productCatalogueHolder);
        this.clearWorkspace(false);
      }
    }, (error: Error) => {
      console.log(error);
    });
  }
  /**
   * Method to enable/disable product
   *
   * @memberof PcmComponent
   */
  disableEnableProduct() {
    const confirmationMessage =
      this.filter.productCatalogue.inUse ?
        this.filter.initialConfig.userBundle['workspace.pcm.disable.confirm'] :
        this.filter.initialConfig.userBundle['workspace.pcm.enable.confirm'];

    const modalOptions: NgbModalOptions = {
      windowClass: 'modal-backdrop',
      size: 'sm'
    };
    this.sbModalPopupService.open(EnableDisableProductComponent, confirmationMessage, modalOptions).result.then((message) => {
      if (message.toString().toLowerCase() === 'ok') {
        this.filter.productCatalogue.inUse = !this.filter.productCatalogue.inUse;
        const params = {
          action: 'saveProductCatalogue',
          productCatalogue: JSON.stringify(this.filter.productCatalogue)
        };
        this.makeCallForProduct(params).then(() => {
          this.refreshProductList();
        }, (error) => {
          console.log(error);
        });
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  /*
  * @description export All Product
  * @author Kishan Patel
  * @memberof PcmComponent
  */
  exportAllProducts() {
    const columnConfig = this.stateService.getWorkspaceObject('columnConfig');
    const productCatalogueHolder = this.dataShareService.getInitialConfigByKey('productCatalogueHolder');
    this.pcmService.prepareProductDataForExport(productCatalogueHolder,
      columnConfig, this.stateService).then((productDataToExport) => {
        this.pcmService.exportProducts(productDataToExport, this.userBundle);
      });
  }

  checkIfBricAllowedToOpen(values: CellValues, columnConfig) {
    let allowedToOpen = true;

    if (values.brick.bricid === this.brickBaseService.brickID.Tag) {
      const tagGroupData = this.sharedService.getTagDataFromColumnConfig(this.brickBaseService.brickID.Tag,
        columnConfig, _.cloneDeep(this.dataShareService.getInitialConfigByKey('tagGroup')));
      if (tagGroupData && tagGroupData.length) {
        values.tagGroupData = tagGroupData;
      } else {
        this.logHelperService.logError(this.userBundle['workspace.error.invalidBricks']);
        allowedToOpen = false;
      }
    }
    return allowedToOpen;
  }

  /**
   * @description Decides which conumn config for which index
   * @summary for SM-4161
   * @author Nishit Parekh
   * @param {*} columnConfig available column config
   * @param {*} cellIndex, cell index requesting column config
   * @returns column config for index
   * @memberof WorkspaceComponent
   */
  assignColumnConfig(columnConfig: ColumnConfigs, cellIndex: number, brickId: number) {
    let columnConfigAtIndex = null;
    const brickAllowedForLastConfig = [this.brickBaseService.brickID.Location, this.brickBaseService.brickID.Tag, this.brickBaseService.brickID.Format];

    // if columnConfig available use it from that index
    if (Object.keys(columnConfig).length && columnConfig[cellIndex]) {
      columnConfigAtIndex = columnConfig[cellIndex];
      // if columnConfig unavailable at that index, check for previous index,
    } else if (brickAllowedForLastConfig.indexOf(brickId) > -1) {
      if (columnConfig[cellIndex - 1]) {
        columnConfigAtIndex = columnConfig[cellIndex - 1];
      } else if (Object.keys(columnConfig).length) {
        columnConfigAtIndex = columnConfig[Object.keys(columnConfig).length - 1];
      }
    }
    return columnConfigAtIndex;
  }

}
