<div ngDraggable [handle]="myHandle" [bounds]="bodyEle">
  <div #myHandle class="modal-header switchBRICmodal-header{{alternateDesign}}"
    [style.backgroundColor]="titleBackgroundColor">
    <h3 #myHandle class="modal-title">{{title}}</h3>
    <button type="button" [hidden]="!showCloseButton" id="{{titleBackgroundColor}}_close" class="modal_close" aria-label="Close" (click)="close('dismiss')">x</button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <span [hidden]="isButtonHideUi">
      <button type="button" id="{{titleBackgroundColor}}_save" class="btn btn-primary" [disabled]="readOnlyModal" (click)="save()">{{saveButton}}</button>
      <button *ngIf="isIgnoreButtonEnabled" type="button" id="{{titleBackgroundColor}}_ignore" class="btn btn-primary" [disabled]="readOnlyModal" (click)="close('cancel')">{{ignoreButton}}</button>
    </span>
    <ng-content select="[additionalFooter]"></ng-content>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
