import * as _ from 'lodash';

export enum sortType {
  string = 1,
  number = 2,
  daterange = 3
}

export interface ISortColumn {
  colId: string;
  order: string;
  type: sortType;
  index?: number;
}

export class AgCustomSort {
  public sortColumns: ISortColumn[] = [];
  private numericFields: string[] = [];
  private dateRangeFields: string[] = [];
  private selectFields: string[] = [];
  private rowData = [];
  private groupingField = '';
  /**
   * Allow sorting on 1 column only.
   * belgium grid - sorting is done on single column, multi column sorting is not allowed
   * @private
   * @type {boolean}
   * @memberof AgCustomSort
   */
  private isSingleColumnSorting = false;

  constructor(numericFields, dateRangeFields, selectFields, groupingField = '', isSingleColumnSorting = false) {
    this.numericFields = numericFields;
    this.dateRangeFields = dateRangeFields;
    this.selectFields = selectFields;
    this.groupingField = groupingField;
    this.isSingleColumnSorting = isSingleColumnSorting;
  }

  public sort(colId, order, rowData): any[] {
    this.rowData = rowData;
    const sortColumn: ISortColumn = this.getSortColumn(colId);
    if (sortColumn) {
      if (order === '') {
        this.sortColumns.splice(sortColumn.index, 1);
      } else {
        this.sortColumns[sortColumn.index].order = order;
      }
    } else {
      if (this.isSingleColumnSorting) {
        this.sortColumns = [];
      }
      this.sortColumns.push({
        colId,
        order,
        type: this.getSortType(colId)
      });
    }

    if (this.sortColumns.length === 0 && this.groupingField) {
      this.sortColumns.push({
        colId: this.groupingField,
        order: 'asc',
        type: this.getSortType(this.groupingField)
      });
    }
    if (this.sortColumns.length > 0) {
      this.sorting();
    }
    return this.rowData;
  }

  public getSortType(colId): sortType {
    let type: sortType = sortType.string;
    if (this.numericFields.indexOf(colId) > -1) {
      type = sortType.number;
    } else if (this.dateRangeFields.indexOf(colId) > -1) {
      type = sortType.daterange;
    }
    return type;
  }

  public getSortColumn(colId): ISortColumn {
    let sortColumn: ISortColumn = null;
    for (let i = 0; i < this.sortColumns.length; i++) {
      if (this.sortColumns[i].colId === colId) {
        sortColumn = this.sortColumns[i];
        sortColumn.index = i;
        break;
      }
    }
    return sortColumn;
  }

  private sorting() {
    if (this.groupingField) {
      const parentRows = this.rowData.filter((row) => {
        return row.isParent;
      });
      parentRows.sort(this.sortComparator(this.sortColumns));
      const tempData = [];
      for (const parentRow of parentRows) {
        const childRows = this.rowData.filter((row) => {
          return (row[this.groupingField] === parentRow[this.groupingField] && !row.isParent);
        });
        childRows.sort(this.sortComparator(this.sortColumns));
        tempData.push(parentRow, ...childRows);
      }
      this.rowData = _.clone(tempData);
    } else {
      this.rowData.sort(this.sortComparator(this.sortColumns));
      this.rowData = _.clone(this.rowData);
    }
  }

  private sortComparator(columns: ISortColumn[]) {
    return (x, y) => {
      let colIndex = 0;
      for (let i = 0; i < columns.length; i++) {
        if (x[columns[i].colId] !== y[columns[i].colId]) {
          colIndex = i;
          break;
        }
      }
      let colId = columns[colIndex].colId;
      if (this.selectFields.indexOf(colId) > -1) {
        colId += '_sort';
      }
      switch (columns[colIndex].type) {
        case sortType.number:
          return this.numericComparator(x, y, colId, columns[colIndex].order);
        case sortType.daterange:
          return this.dateRangeComparator(x, y, colId, columns[colIndex].order);
        default:
          return this.stringComparator(x, y, colId, columns[colIndex].order);
      }
    };
  }

  private numericComparator = (x, y, colId, order) => {
    const val1 = x[colId] ? parseFloat(x[colId]) : 0;
    const val2 = y[colId] ? parseFloat(y[colId]) : 0;
    if (order === 'asc') {
      return val1 - val2;
    } else {
      return val2 - val1;
    }
  }

  private stringComparator = (x, y, colId, order) => {
    x[colId] = x[colId] ? String(x[colId]) : '';
    y[colId] = y[colId] ? String(y[colId]) : '';
    if (order === 'asc') {
      return x[colId].localeCompare(y[colId]);
    } else {
      return y[colId].localeCompare(x[colId]);
    }
  }

  private dateRangeComparator = (x, y, colId, order) => {
    let aDate = x[colId].split('-')[0].trim();
    let bDate = y[colId].split('-')[0].trim();
    const aDateParts = aDate.split('/');
    // Convert into MM/DD/YYYY
    const aDatePartsString = `${aDateParts[1]}/${aDateParts[0]}/${aDateParts[2]}`;
    aDate = new Date(aDatePartsString);

    const bDateParts = bDate.split('/');
    const bDatePartsString = `${bDateParts[1]}/${bDateParts[0]}/${bDateParts[2]}`;
    bDate = new Date(bDatePartsString);
    let dateDifference = 0;
    if (order === 'asc') {
      dateDifference = aDate - bDate;
    } else {
      dateDifference = bDate - aDate;
    }
    return dateDifference >= 1 ? 1 : dateDifference < 0 ? -1 : 0;
  }
}
