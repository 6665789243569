<div class="search_expand" *ngIf="!hideHeader">
  <div *ngIf="lazySearch" class="lead pull-left m-0 input-group" style="margin: 0;line-height: 30px;width:65%;">
    <input type="text" class="form-control" autocomplete="off" id="filter" #filter
      [placeholder]="templateProps.searchPlaceHolder">
    <span class="input-group-btn">
      <button class="btn btn-default prox-search-btn" [disabled]="filter?.value?.length < 2" type="button"
        (click)="filterNodes(filter.value)">Search</button>
      <button class="btn btn-default prox-search-btn" type="button"
        (click)="filter.value = ''; filterNodes(filter.value)">Reset</button>
    </span>
  </div>
  <p *ngIf="!lazySearch" class="lead pull-left m-0" style="margin: 0;line-height: 30px;width:65%;">
    <input class="search form-control" autocomplete="off" id="filter" #filter (keyup)="filterNodes(filter.value)"
      [placeholder]="templateProps.searchPlaceHolder" />
  </p>
  <button class="btn btn-default pull-right" (click)="expandAll()">{{userBundle['button.expandAll'] || 'Expand
    All'}}</button>
</div>
<div class="tree-view-wrapper relative-filters-wrapper">
  <label class="small" *ngIf="templateProps.allowCustomCheckBoxEvents && !templateProps.checkboxIconEnabled">
    <input type="checkbox" name="allChecked" class="checkbox small" [(ngModel)]="allChecked"
      (change)="onToggleSelectAll()">
    <span class="select_all">{{userBundle['workspace.collection.bric.secondaryAudience.selectAll'] ?
      userBundle['workspace.collection.bric.secondaryAudience.selectAll'] : 'Select All' }}</span>
  </label>
  <div class="product" *ngIf="templateProps.noDataAvailableText && !isDataAvailable">
    {{templateProps.noDataAvailableText}}</div>
  <tree-root [(state)]="state" [nodes]="data" #tree [options]="options" (deselect)="onDeSelectItem($event)"
    (select)="onSelectItem($event)" (updateData)="onUpdateData()" (initialized)="onTreeViewInitialized()">
    <ng-template #treeNodeTemplate let-node let-index="index">
      <label (click)="handleLabelClick(node)"
        [ngStyle]="{'font-weight': node.data['isGroup']? 'bold': 'normal', 'color':  templateProps.checkboxIconEnabled && !node.isSelected ? '#c6c6c6' : '' }"
        class="node-label">
        <span *ngIf="templateProps.checkboxIconEnabled">
          <em [ngClass]="node.isSelected ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></em>
        </span>
        <input type="radio" id="node.data[templateProps.displayId]" name="products"
          *ngIf="templateProps.displayRadioButton && !node.data['isGroup']" />
        <span *ngIf="templateProps.showId && !node.data['isGroup']"
          [ngClass]="{'disabled-product': !node.data['inUse']}">
          {{node.data[templateProps.displayId]}} - </span>
        <span
          [ngClass]="{'disabled-product': node.data.hasOwnProperty('inUse') && !node.data.inUse && !node.data['isGroup']}">{{node.data[templateProps.displayName]}}
        </span>
      </label>
      <div class="node-right-icons" *ngIf="templateProps?.localSolverEnabled">
        <div *ngIf="node.children && node.children.length && isAllChildSelected(node) && !node.isPartiallySelected"
          class="filter-lv3-label" [title]="userBundle['workspace.bric.relativeFilter']">
          <label for="filter-tag-{{node.data[templateProps.displayId]}}">
            <input type="checkbox" [(ngModel)]="node.data.relative" class="checkbox"
              id="filter-tag-{{node.data[templateProps.displayId]}}" (change)="toggleRelativeChildren(node)" />
            <em class="relativeFilterPercentage">%</em>
          </label>
        </div>
        <div *ngIf="node.isLeaf && node.isSelected" class="filter-lv3-label"
          [title]="userBundle['workspace.bric.relativeFilter']">
          <label for="filter-tag-{{node.data[templateProps.displayId]}}">
            <input type="checkbox" [(ngModel)]="node.data.relative" class="checkbox"
              id="filter-tag-{{node.data[templateProps.displayId]}}" (change)="changeRelativeFilter(node)" />
            <em class="relativeFilterPercentage">%</em>
          </label>
        </div>
      </div>
      <div class="node-right-icons" *ngIf="templateProps.checkboxIconEnabled">
        <div class="filter-lv3-label" [title]="userBundle['workspace.bric.relativeFilter']">
          <label for="filter-tag-{{node.data[templateProps.displayId]}}">
            <ng-container [ngSwitch]="node.data['shape']">
              <div [id]="'btn-icon-change'+node.data[templateProps.displayId]" (click)="updateShapeModel(node)"
                #mapSettingContainer class="form-control_geo dropdown">
                <span class="pull-left">
                  <span *ngSwitchCase="'circle'">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      [attr.fill]="node.isSelected ? (node.data['mixedColored'] ? 'url(#solids)' : node.data['colorCode']): '#c6c6c6'"
                      width="15" height="15" viewBox="0 0 20 20">
                      <defs>
                        <linearGradient id="solids" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style="stop-color:#ff0303;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#db1a1a;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#f708bb;stop-opacity:1" />
                          <stop offset="100%" style="stop-color:#f708bb;stop-opacity:1" />
                        </linearGradient>
                      </defs>
                      <g id="circle" transform="translate(-6 -6)">
                        <g id="Group_1" data-name="Group 1" transform="translate(6 6)">
                          <path id="Path_1" data-name="Path 1" d="M16,958.362a10,10,0,1,0,10,10A10,10,0,0,0,16,958.362Z"
                            transform="translate(-6 -958.362)" />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span *ngSwitchCase="'diamond'">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      [attr.fill]="node.isSelected ? (node.data['mixedColored'] ? 'url(#solids)' : node.data['colorCode']): '#c6c6c6'"
                      width="20" height="20" viewBox="0 0 20 20">
                      <defs>
                        <linearGradient id="solids" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style="stop-color:#ff0303;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#db1a1a;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#f708bb;stop-opacity:1" />
                          <stop offset="100%" style="stop-color:#f708bb;stop-opacity:1" />
                        </linearGradient>
                      </defs>
                      <g id="Diamond" transform="translate(-5.556 -5.556)">
                        <g id="Group_2" data-name="Group 2" transform="translate(5.556 5.556)">
                          <rect id="Rectangle_1" data-name="Rectangle 1" width="14.142" height="14.142"
                            transform="translate(0 10) rotate(-45)" />
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span *ngSwitchCase="'heart'">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      [attr.fill]="node.isSelected ? (node.data['mixedColored'] ? 'url(#solids)' : node.data['colorCode']): '#c6c6c6'"
                      width="20" height="17.292" viewBox="0 0 20 17.292">
                      <defs>
                        <linearGradient id="solids" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style="stop-color:#ff0303;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#db1a1a;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#f708bb;stop-opacity:1" />
                          <stop offset="100%" style="stop-color:#f708bb;stop-opacity:1" />
                        </linearGradient>
                      </defs>
                      <g id="Heart" transform="translate(-2.499 -8.929)">
                        <path id="Path_2" data-name="Path 2"
                          d="M19.627,9.748a5.64,5.64,0,0,0-7.088,1.274A5.65,5.65,0,0,0,2.5,14.527a5.526,5.526,0,0,0,.263,1.741c.924,4.37,9.623,9.953,9.623,9.953s8.8-5.406,9.814-9.756a5.568,5.568,0,0,0,.3-1.737,5.657,5.657,0,0,0-2.871-4.981Z" />
                      </g>
                    </svg>
                  </span>
                  <span *ngSwitchCase="'plus'">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      [attr.fill]="node.isSelected ? (node.data['mixedColored'] ? 'url(#solids)' : node.data['colorCode']): '#c6c6c6'"
                      width="20" height="20" viewBox="0 0 20 20">
                      <defs>
                        <linearGradient id="solids" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style="stop-color:#ff0303;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#db1a1a;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#f708bb;stop-opacity:1" />
                          <stop offset="100%" style="stop-color:#f708bb;stop-opacity:1" />
                        </linearGradient>
                      </defs>
                      <g id="Plus" transform="translate(-0.005 -0.002)">
                        <path id="Path_3" data-name="Path 3" d="M20,7.7H12.3V0h-4.6V7.7H0v4.6h7.7V20h4.6V12.3H20Z" />
                      </g>
                    </svg>
                  </span>
                  <span *ngSwitchCase="'polygons'">
                    <svg id="polygons" xmlns="http://www.w3.org/2000/svg"
                      [attr.fill]="node.isSelected ? (node.data['mixedColored'] ? 'url(#solids)' : node.data['colorCode']): '#c6c6c6'"
                      width="20" height="19.017" viewBox="0 0 20 19.017">
                      <defs>
                        <linearGradient id="solids" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style="stop-color:#ff0303;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#db1a1a;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#f708bb;stop-opacity:1" />
                          <stop offset="100%" style="stop-color:#f708bb;stop-opacity:1" />
                        </linearGradient>
                      </defs>
                      <g id="Group_3" data-name="Group 3">
                        <path id="Path_4" data-name="Path 4" d="M9.985,0,20,7.25,16.16,19.017H3.81L0,7.25Z"
                          fill-rule="evenodd" />
                      </g>
                    </svg>

                  </span>
                  <span *ngSwitchCase="'triangle'">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      [attr.fill]="node.isSelected ? (node.data['mixedColored'] ? 'url(#solids)' : node.data['colorCode']): '#c6c6c6'"
                      width="20" height="16.674" viewBox="0 0 20 16.674">
                      <defs>
                        <linearGradient id="solids" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style="stop-color:#ff0303;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#db1a1a;stop-opacity:1" />
                          <stop offset="25%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#b7f50d;stop-opacity:1" />
                          <stop offset="50%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#1221f3;stop-opacity:1" />
                          <stop offset="75%" style="stop-color:#f708bb;stop-opacity:1" />
                          <stop offset="100%" style="stop-color:#f708bb;stop-opacity:1" />
                        </linearGradient>
                      </defs>
                      <g id="triangle" transform="translate(-9.766 -16.457)">
                        <g id="Group_4" data-name="Group 4" transform="translate(9.766 16.457)">
                          <path id="Path_5" data-name="Path 5"
                            d="M19.766,968.819l5.006,8.346,4.994,8.328h-20l4.994-8.327Z"
                            transform="translate(-9.766 -968.819)" fill-rule="evenodd" />
                        </g>
                      </g>
                    </svg>
                  </span>
                </span>
              </div>
            </ng-container>
          </label>
        </div>
      </div>
    </ng-template>
  </tree-root>
</div>