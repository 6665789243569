import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { VolumeBase } from '../volume-base';
import {
  DataShareService,
  CellAttributeService,
  BrickBaseService,
  MaskService,
  LogHelperService,
  WorkspaceService
} from '../../../core/services';
import { CellValues } from '../../../models/workspace';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { NumberMaskModel } from '../../../models/imask';
import { GLOBAL } from '../../../core/utils/app.constant';

@Component({
  selector: 'app-volume-modal',
  templateUrl: './volume-modal.component.html',
  styleUrls: ['./volume-modal.component.css']
})
export class VolumeModalComponent extends VolumeBase implements OnInit {

  /**
   * @description Holds selected values from this modal
   * @type {Brick}
   * @memberof VolumeComponent
   */
  @Input() resolveObject: CellValues;

  /**
   * @description As we are using masking here the blank or not valid input data always considered as 0. And we have to allow 0 as an input.
   * So, to detect empty string i.e. "" for validation we need to use reference
   * @type {ElementRef}
   * @memberof VolumeModalComponent
   */
  @ViewChild('sotFloor') sotFloorElementRef: ElementRef;

  /**
   * @description As we are using masking here the blank or not valid input data always considered as 0. And we have to allow 0 as an input.
   * So, to detect empty string i.e. "" for validation we need to use reference
   * @type {ElementRef}
   * @memberof VolumeModalComponent
   */
  @ViewChild('sotCeiling') sotCeilingElementRef: ElementRef;

  readOnlyModal = false;
  public global = GLOBAL;
  maskService: MaskService = new MaskService();

  /**
   * @description Mask input config for Currency
   * @type {NumberMaskModel}
   * @memberof VolumeModalComponent
   */
  currencyInputMask: NumberMaskModel;

  /**
   * @description Mask input config for Percentage
   * @type {NumberMaskModel}
   * @memberof VolumeModalComponent
   */
  percentageInputMask: NumberMaskModel;

  /**
   * @description Mask input config for tolerance field, max 100 - 0 decimal
   * @type {NumberMaskModel}
   * @memberof VolumeModalComponent
   */
  numberInputMask: NumberMaskModel;

  /**
   * @description Mask input config for tolerance Percentage field
   * @type {NumberMaskModel}
   * @memberof VolumeModalComponent
   */
  percentageToleranceInputMask: NumberMaskModel;

  /**
   * @description For sot floor & ceiling
   * @type {NumberMaskModel}
   * @memberof VolumeModalComponent
   */
  percentageInputMaskForSOT: NumberMaskModel;

  disableFloorAndCeiling = false;
  localSolverEnabled = false;

  constructor(
    dataShareService: DataShareService,
    public brickBaseService: BrickBaseService,
    private cellAttributeService: CellAttributeService,
    public appHeaderService: AppHeaderService,
    public workspaceService: WorkspaceService,
    public logHelperService: LogHelperService
  ) {
    super(dataShareService, appHeaderService, brickBaseService, workspaceService, logHelperService);
  }

  ngOnInit() {
    this.init();
    this.initializeMask();
    if (this.resolveObject.selectedValues && !this.resolveObject.selectedValues.hasOwnProperty('-99')) {
      this.resolveObject.selectedValues.impressions = this.resolveObject.selectedValues.impressions === 0 ? this.resolveObject.selectedValues.impressions = null : this.resolveObject.selectedValues.impressions;
      this.ratingImpression = { ...this.ratingImpression, ...this.resolveObject.selectedValues };
      if (this.resolveObject.sot) {
        this.ratingImpression.sotFloor = this.resolveObject.sot.sotFloor != undefined && this.resolveObject.sot.sotFloor != null ? parseFloat(this.resolveObject.sot.sotFloor.toString()) : this.ratingImpression.sotFloor;
        this.ratingImpression.sotCeiling = this.resolveObject.sot.sotCeiling != undefined && this.resolveObject.sot.sotCeiling != null ? parseFloat(this.resolveObject.sot.sotCeiling.toString()) : this.ratingImpression.sotCeiling;
        this.ratingImpression.sotIncrement = this.resolveObject.sot.sotIncrement != undefined && this.resolveObject.sot.sotIncrement != null ? this.resolveObject.sot.sotIncrement : this.ratingImpression.sotIncrement;
        this.disableFloorAndCeiling = this.resolveObject.sot.disableFloorAndCeiling;
      }
    } else {
      if (this.resolveObject.selectedValues && this.resolveObject.sot) {
        this.ratingImpression.sotFloor = this.resolveObject.sot.sotFloor != undefined && this.resolveObject.sot.sotFloor != null ? parseFloat(this.resolveObject.sot.sotFloor.toString()) : this.ratingImpression.sotFloor;
        this.ratingImpression.sotCeiling = this.resolveObject.sot.sotCeiling != undefined && this.resolveObject.sot.sotCeiling != null ? parseFloat(this.resolveObject.sot.sotCeiling.toString()) : this.ratingImpression.sotCeiling;
        this.ratingImpression.sotIncrement = this.resolveObject.sot.sotIncrement != undefined && this.resolveObject.sot.sotIncrement != null ? this.resolveObject.sot.sotIncrement : this.ratingImpression.sotIncrement;
        this.disableFloorAndCeiling = this.resolveObject.sot.disableFloorAndCeiling;
      }
      this.resolveObject.selectedValues = null;
    }
    this.initializeIfNotPCMMode();
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.previousValues = Object.assign({},this.ratingImpression);
  }

  initializeMask() {
    this.currencyInputMask = this.maskService.currencyInputMask({
      max: 999999999999999999999,
      scale: 0
    });
    this.percentageInputMask = this.maskService.currencyInputMask({
      max: 100,
      min: 0
    });
    this.numberInputMask = this.maskService.currencyInputMask({ max: 999999999999999999999, scale: 0, min: 0 });
    this.percentageInputMaskForSOT = this.maskService.currencyInputMask({
      max: 100,
      min: 0
    });

    this.percentageToleranceInputMask = this.maskService.currencyInputMask({
      min: this.uiControl.toleranceMin ? this.uiControl.toleranceMin : 0,
      max: 100,
      scale: 0
    });
  }

  initializeIfNotPCMMode() {
    if (!this.pcmMode) {
      this.initRatingImpression();

      this.ratingImpression.tolerance = this.ratingImpression.tolerance || this.dataShareService.getInitialConfig().uiControl.defaultTolerance; // SM-5016
      this.ratingImpression.boostedTarget = this.ratingImpression.boostedTarget || false;
      this.ratingImpression.relative = this.ratingImpression.relative || false;

      /* setFloorAndCeling method should be called if both the values are blank for normal mode
       or when the Product is used with Budget as the OptionalBric and Budget brick is dragged for the first time */
      if ((!this.ratingImpression.sotFloor && !this.ratingImpression.sotCeiling) ||
        (((this.productValidations && this.productValidations.sotFloor) || (this.productValidations && this.productValidations.sotCeiling)) && !this.resolveObject.isEditMode) ||
        (this.resolveObject.sot && this.resolveObject.sot.sotFloor && this.resolveObject.sot.sotCeiling)
      ) {
        this.setFloorAndCeling();
      }
    }
    this.setDefaultGreenTolerance();
    this.setDefaultOrangeTolerance();
    this.readOnlyModal = this.resolveObject.readOnlyModal;
    this.localSolverEnabled = this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_LS || this.resolveObject.reshufflingEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH || this.appHeaderService.objectiveMode;
  }

  initRatingImpression() {
    this.ratingImpression.impressions = this.ratingImpression.impressions || this.ratingImpression.allocated;

    let allocateAllFrames = true;
    if (this.ratingImpression.hasOwnProperty('allocateAllFrames')) {
      allocateAllFrames = this.ratingImpression.allocateAllFrames;
    } else if (this.uiControl.hasOwnProperty('defaultAllocateAllFrames')) {
      allocateAllFrames = this.uiControl.defaultAllocateAllFrames;
    }
    this.ratingImpression.allocateAllFrames = allocateAllFrames;

    if (this.productValidations && this.productValidations.allocateAllFrames) {
      this.ratingImpression.allocateAllFrames = this.productValidations.allocateAllFrames;
    }

    if (this.productValidations && !this.resolveObject.isEditMode) {
      if (this.productValidations.sotFloor) {
        this.ratingImpression.sotFloor = this.productValidations.sotFloor;
      }
      if (this.productValidations.sotCeiling) {
        this.ratingImpression.sotCeiling = this.productValidations.sotCeiling;
      }
      // added for SM-4749 , modify for SM-5424
      if (this.productValidations.impressions) {
        this.ratingImpression.impressions = this.productValidations.impressions;
      }
    }

    if(this.productValidations && this.productValidations.sotIncrement){
      this.ratingImpression.sotIncrement = this.productValidations.sotIncrement;
    }
  }

  calculateFloorAndCeiling() {
    if (!this.pcmMode && this.resolveObject.sot && !this.resolveObject.sot.mediaOnlyPaper) {
      this.setFloorAndCeling();
    }
  }

  /**
   * @description Handles modal dismiss
   * @author Amit Mahida
   * @param {*} event
   * @memberof VolumeComponent
   */
  onModalClosed(event) {
    let isModalDataValid = !this.pcmMode ? !!this.ratingImpression.impressions : (!!this.ratingImpression.impressions ||
        !!this.ratingImpression.minImpressions ||  !!this.ratingImpression.maxImpressions ||
        !!this.ratingImpression.sotFloor || !!this.ratingImpression.sotCeiling);
    if (event.reason === 'escape' && (JSON.stringify(this.previousValues) !== JSON.stringify(this.ratingImpression))) {
      if (window.confirm(this.userBundle['common.modal.close'] || 'Are you sure to discard the changes?')) {
        event.activeModal.dismiss('dismiss');
      }
    } else {
      event.activeModal.dismiss('dismiss');
    }
  }

  /**
   * @description Handles modal save
   * @author Amit Mahida
   * @param {*} event
   * @memberof VolumeComponent
   */
  onModalSaved(event) {
    if (!this.validateVolumeInputs()) {
      return;
    }
    const values: CellValues = new CellValues();
    values.brick = this.resolveObject.brick;
    values.selectedValues = this.ratingImpression;

    if (this.uiControl.volumeRangeValidationEnabled) {
      if (this.sotCeilingElementRef && this.sotCeilingElementRef.nativeElement.value === '') {
        values.selectedValues.sotCeiling = null;
      }
      if (this.sotFloorElementRef && this.sotFloorElementRef.nativeElement.value === '') {
        values.selectedValues.sotFloor = null;
      }
    } else {
      delete values.selectedValues.sotFloor;
      delete values.selectedValues.sotCeiling;
    }

    if (!this.uiControl.toleranceEnabled) {
      delete values.selectedValues.tolerance;
    }

    values.displayText = this.cellAttributeService.getDisplayText(this.brickBaseService.brickID.Volume, values.selectedValues);
    values.requestJson = this.cellAttributeService.getBrickRequestJSON(this.brickBaseService.brickID.Volume, values.selectedValues);
    values.toolTipText = this.cellAttributeService.getToolTip(this.brickBaseService.brickID.Volume, values.selectedValues);
    event.activeModal.close(values);
    this.previousValues = Object.assign({},this.resolveObject.selectedValues);
  }

  setFloorAndCeling() {
    if (this.uiControl.autoCalculateFloorAndCeiling) {
      this.ratingImpression.sotFloor = this.calculateFloor();
      this.ratingImpression.sotCeiling = this.calculateCeiling();
    }
  }

  /**
   * @description calculates floor value
   * @author Nishit Parekh
   * @returns calculated floor value
   * @memberof VolumeComponent
   */
  calculateFloor() {
    if (this.uiControl.autoCalculateFloorAndCeiling) {
      if (this.resolveObject.sot && this.resolveObject.sot.sotFloor >= 0) {
        this.disableFloorAndCeiling = this.resolveObject.sot.disableFloorAndCeiling;
        return Number(this.resolveObject.sot.sotFloor);
      } else if ((this.resolveObject.columnSummary && Object.keys(this.resolveObject.columnSummary).length) && (!this.resolveObject.sot.mediaOnlyPaper || this.pcmMode)) {
        const floorCeilingPrecision = this.uiControl.floorCeilingPrecision
          ? this.uiControl.floorCeilingPrecision : 2;
        return this.workspaceService.computeFloorFromFormula(floorCeilingPrecision,
          this.ratingImpression.impressions, this.uiControl.sotSwing, this.productValidations, this.resolveObject.columnSummary, 'audienceImpressions');
      } else if (this.resolveObject.sot && this.resolveObject.sot.mediaOnlyPaper) {
        return this.ratingImpression.sotFloor == null ? 1 : Number(this.ratingImpression.sotFloor);
      }
      return null;
    }
    return null;
  }

  /**
   * @description calculates ceiling value
   * @author Nishit Parekh
   * @returns ceiling value
   * @memberof VolumeComponent
   */
  calculateCeiling() {
    /* CEILING = MIN(FLOOR + 2 * SOT Swing, MAX value specified in PCM (if PCM used) )*/
    if (this.uiControl.autoCalculateFloorAndCeiling) {
      if (this.resolveObject.sot && this.resolveObject.sot.sotCeiling && this.ratingImpression.sotFloor >= 0) {
        this.disableFloorAndCeiling = this.resolveObject.sot.disableFloorAndCeiling;
        return Number(this.resolveObject.sot.sotCeiling);
      } else if (this.ratingImpression.sotFloor && !this.resolveObject.sot.mediaOnlyPaper) {
        const floorCeilingPrecision = this.uiControl.floorCeilingPrecision ? this.uiControl.floorCeilingPrecision : 2;
        const sotSwing = this.uiControl.sotSwing;
        return this.workspaceService.computeCeilingFromFormula(floorCeilingPrecision, sotSwing, this.ratingImpression.sotFloor, this.productValidations);
      } else if (this.resolveObject.sot.mediaOnlyPaper) {
        return this.resolveObject.sot.sotCeiling == null ? 100 : Number(this.resolveObject.sot.sotCeiling);
      }
      return null;
    }
    return null;
  }

  updateAllocation(event) {
    if (this.productValidations && this.productValidations.maxImpressions
      && this.resolveObject.columnSummaryWithoutBrick.audienceImpressions
      && this.resolveObject.columnSummaryWithoutBrick.audienceImpressions > this.productValidations.maxImpressions
    ) {
      this.ratingImpression.allocateAllFrames = false;
      this.logHelperService.logError(this.userBundle['volume.error.allocationIsGreaterThanMax'] || 'Allocation would be greater than max');
      event.preventDefault();
    }
  }

  onChangeRelative(relative) {
    if (relative && this.ratingImpression.impressions > 100) {
      this.ratingImpression.impressions = null;
    }

    if (!relative && this.ratingImpression.impressions) {
      this.ratingImpression.impressions = Number.parseInt(this.ratingImpression.impressions.toString(), 10);
    }

    this.ratingImpression.allocated = null;
  }

  setDefaultGreenTolerance() {
    if (this.ratingImpression.greenTolerance === null || this.ratingImpression.greenTolerance === undefined) {
      this.ratingImpression.greenTolerance = this.uiControl.greenToleranceDefault || null;
    }
  }

  setDefaultOrangeTolerance() {
    if (this.ratingImpression.orangeTolerance === null || this.ratingImpression.orangeTolerance === undefined) {
      this.ratingImpression.orangeTolerance = this.uiControl.orangeToleranceDefault || null;
    }
  }
}
