import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { DndDropEvent } from '../../../../../../scripts/3rdParty/ngx-drag-drop-custom';
import { Router } from '@angular/router';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import {
  CellValues,
  Row, Cell,
  DateRange,
  ColumnSummary,
  Filter,
} from '../../models/workspace/index';
import { LogHelperService } from '../../core/services/log-helper.service';
import { WorkspaceService } from '../../core/services/workspace.service';
import { WorkspaceActionButtonService } from '../workspace.action.button.service';
import { SbModalPopupService } from '../../core/components/sb-modal-popup/sb-modal-popup.service';
import { CellPosition, ExpandDirection } from '../../models/workspace/cell-position';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '../../core/services/state.service';
import { SystemFlags } from '../../models';
import { DataShareService } from '../../core/services/data-share.service';
import * as _ from 'lodash';
import { HistoryStackService } from '../../core/services/history-stack.service';
import { WorkspaceBase } from '../workspace-base';
import { HistoryStackData } from '../../models/workspace/historyStackData';
import { PcmService } from '../../core/services';
import { Subscription } from 'rxjs';
import { Category } from '../../models/bricsMasterData';
import { Brick } from '../brick-model';
import { ObjectiveService, ObjectiveModeForEnum } from './objective.service';
import { GLOBAL } from '../../core/utils/app.constant';
import { ReshuffleConfirmComponent, ReshuffleDetailLSComponent, ReshuffleDetailLSResultComponent, ReshuffleProcessComponent } from '../modal-windows';
import { ReshuffleService } from '../reshuffle.service';

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.css', '../workspace-pcm.css'],
  providers: [SbModalPopupService],
  encapsulation: ViewEncapsulation.None
})
export class ObjectiveComponent extends WorkspaceBase implements OnInit, OnDestroy {
  @ViewChild('zoomReset') zoomReset;

  public global = GLOBAL;
  private subscriptions: Subscription[] = [];

  private currentObjectiveData: CellValues = this.stateService.getObjectiveObject('currentObjectiveData');

  allowLimitResuffleScope = false;

  lsProcessCompleted = false;

  kpiData;
  showLsMessages = false;

  /**
   *Creates an instance of ObjectiveComponent.
   * @author Amit Mahida
   * @memberof ObjectiveComponent
   */
  constructor(
    private sbModalPopupService: SbModalPopupService,
    public brickBaseService: BrickBaseService,
    public dataShareService: DataShareService,
    public stateService: StateService,
    public logHelperService: LogHelperService,
    private reshuffleService: ReshuffleService,
    public workspaceActionButtonService: WorkspaceActionButtonService,
    public workspaceService: WorkspaceService,
    public historyStackService: HistoryStackService,
    private router: Router,
    private pcmService: PcmService,
    public appHeaderService: AppHeaderService,
    private objectiveService: ObjectiveService
  ) {
    super(brickBaseService, dataShareService, stateService, appHeaderService);
  }

  ngOnInit() {
    this.onInit();
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.systemData = this.dataShareService.getInitialConfigByKey('systemData');
    this.masterData = _.cloneDeep(this.dataShareService.getBricsMasterData().category);
    this.masterData.forEach((category) => {
      category.brics = category.brics.filter(cell => cell.isObjective);
      if (!category.brics.length) {
        category.hide = true;
      }
    });

    if (this.currentObjectiveData) {
      if (this.currentObjectiveData.selectedValues) {
        this.filter.rows = this.currentObjectiveData.selectedValues.objectives;

        const { cellIndex: objectiveCellIndex, rowIndex: objectiveRowIndex } = this.currentObjectiveData.brick;
        const workspaceFilter: Filter = this.objectiveService.objectiveModeFor === ObjectiveModeForEnum.pcm ? this.stateService.getPCMFilterObj() : this.stateService.getWorkspaceObject('filter');
        const objectiveCell = workspaceFilter.rows[objectiveRowIndex].cells[objectiveCellIndex];

        const objectiveMeasure = this.workspaceService.getObjectiveMeasuresOfCell(objectiveCell, workspaceFilter.objectiveMeasuresData);

        if (objectiveMeasure) {
          this.filter.objectiveMeasuresData = objectiveMeasure.selectionObjectives.bricsData;
          this.filter.mergeObjectiveMeasures(this.filter.objectiveMeasuresData);
        }

        this.readOnlyModal = Boolean(SystemFlags.readOnly
          || SystemFlags.readOnlyWorkspace
          || (this.objectiveService.objectiveModeFor !== ObjectiveModeForEnum.pcm && this.currentObjectiveData.selectedValues.disableObjective)
          );
      } else {
        this.filter.rows = [];
      }
      if (this.currentObjectiveData.brick) {
        const userData = this.dataShareService.getInitialConfigByKey('userData');
        if (this.uiControl.defaultAllocationEngine === GLOBAL.RESHUFFLE_ENGINE.RESHUFFLE_ENGINE_VIOOH &&
          this.uiControl.reshuffleScopingForObjectiveWorkspaceEnabled
          && userData.tokens.indexOf('RESHUFFLE_SCOPE_IN_THE_OBJECTIVE_WORKSPACE') > -1
          && this.objectiveService.objectiveModeFor === ObjectiveModeForEnum.workspace) {
          this.allowLimitResuffleScope = true;
        }
      }
    }

    this.subscriptions.push(this.appHeaderService.backToWorkspace.subscribe((redirect: boolean) => {
      if (redirect) {
        this.cancelObjective();
      }
    }));
  }

  /**
   * @description - callback method call on navigating to other page
   * @author Alkesh Shah
   * @returns {boolean}
   * @memberof ObjectiveComponent
   */
  beforeNavigate(): boolean {
    this.appHeaderService.changeObjectiveMode(false);
    return true;
  }

  /**
   * @description Handes drop brick event on workspace
   * @author Amit Mahida
   * @param {DndDropEvent} event
   * @param {number} [rowIndex]
   * @param {number} [colIndex]
   * @param {boolean} [isGhost=false]
   * @returns void
   * @memberof ObjectiveComponent
   */
  onDrop(event: DndDropEvent, rowIndex?: number, colIndex?: number, isGhost = false) {
    if (this.filter.checkIfCellExistsInColumn(event.data.cellIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true) !== -1) {
      this.logHelperService.logError(this.userBundle['common.error.notallowed']);
      return true;
    }

    switch (event.dropEffect) {
      case 'copy':
        if (event.data) {
          this.sendGAEvent(event.data.bricid);
          const cellPosition: CellPosition = this.filter.dropBrick(event.data, rowIndex, colIndex, this.expandDirection, false, isGhost);

          if (cellPosition.rowIndex !== -1) {
            try {
              const cellValues: CellValues = new CellValues();
              cellValues.brick = { ...event.data, ...cellPosition };
              this.openBricModalWindow(cellValues);
            } catch (e) {
              this.logHelperService.logError(e);
            }
          } else {
            this.logHelperService.logError(cellPosition.note);
          }
        }
        break;
      case 'move':
        if (event.data && rowIndex !== null && rowIndex !== undefined) {
          this.sendGAEvent(this.filter.rows[rowIndex].bric.bricid);
          this.filter.moveBrick(event.data, rowIndex, colIndex, this.expandDirection);
        } else {
          this.logHelperService.logInfo(this.userBundle['common.error.notallowed']);
        }
        break;
      default:
        break;
    }
    this.expandDirection = ExpandDirection.None;
    this.enableAllMasterBrics();
    this.showRemoveBar = false;
  }

  /**
   * @description Redo last undone step
   * @author Amit Mahida
   * @returns
   * @memberof ObjectiveComponent
   */
  redoLastUndoneStep() {
    if (this.filter.isPastColumnHidden) {
      this.logHelperService.logError(this.userBundle['worksapce.error.hidden']);
      return;
    }

    const oldItem = this.historyStackService.popFromUndoneHistoryStackObjective(this.stateService.getFreshFilterObj());

    if (oldItem) {
      // Push the item into Undo stack
      // Used when we do Undo then again Redo (eg: To push into Undo stack from Redo stack)
      this.historyStackService.pushInHistoryStackRedoObjective(new HistoryStackData(this.filter));

      // Set current workspace item to poped workspace item from Redo Stack
      this.filter = oldItem;
      this.stateService.setObjectiveObject('filter', this.stateService.clone(this.filter));

      if (this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.ProductCatalogue))) {
        this.filter.reIndexingProductDetails();
      } else {
        this.filter.productDetails = [];
        this.stateService.setObjectiveObject('productDetails', this.filter.productDetails);
      }

      if (!this.filter.rows.length) {
        this.filter.columnSummaryWithoutBrick = [];
      }
    }
  }

  /**
   * @description Undo last step
   * @author Amit Mahida
   * @returns
   * @memberof ObjectiveComponent
   */
  undoLastStep() {
    if (this.filter.isPastColumnHidden) {
      this.logHelperService.logError(this.userBundle['worksapce.error.hidden']);
      return;
    }
    const oldItem = this.historyStackService.popFromHistoryStackObjective(this.stateService.getFreshFilterObj());

    if (oldItem) {
      this.historyStackService.pushInUndoneHistoryStackObjective(new HistoryStackData(this.filter));
      this.workspaceService.setDefaultPanning();

      // Set current workspace item to previous workspace item
      this.filter = oldItem;

      if (this.filter.getMarkSelectedCount() > 0) {
        this.filter.removeMarkSelected();
      }
      this.stateService.setObjectiveObject('filter', this.stateService.clone(this.filter));

      if (this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.ProductCatalogue))) {
        this.filter.reIndexingProductDetails();
      } else {
        this.filter.productDetails = [];
        this.stateService.setObjectiveObject('productDetails', this.filter.productDetails);
      }

      if (!this.filter.rows.length) {
        this.filter.columnSummaryWithoutBrick = [];
      }

      this.filter.displayBricksInLeftPanel();
    }
  }

  /**
   * @description call back function - called on dbl click on bric
   * @author Alkesh Shah
   * @param {Row} row - row object of
   * @param {Cell} cell
   * @memberof ObjectiveComponent
   */
  onBrickDblClick(row: Row, cell: Cell): void {
    // if bric is mandaroty dont allow to open
    if (!this.checkIfBricallowedToOpen(cell, Number(row.bric.bricid))) {
      return;
    }
    const cellValues: CellValues = new CellValues();
    cellValues.brick = _.clone(row.bric);
    cellValues.brick.cellIndex = cell.cellIndex;
    cellValues.brick.rowIndex = cell.rowIndex;
    cellValues.brick.editable = cell.isEditable;
    cellValues.selectedValues = _.cloneDeep(cell.selected);
    this.openBricModalWindow(cellValues, true);
  }

  /**
   * @description open bric modal popup
   * @author Alkesh Shah
   * @param {CellValues} values - cell values to use to open modal window
   * @package {boolean} isEditMode - Send this flag to true if user is editing any existing brick
   * @memberof ObjectiveComponent
   */
  openBricModalWindow(values: CellValues, isEditMode = false) {
    const cellComponent = this.getComponent(values.brick.bricid);
    const modalOptions: NgbModalOptions = this.getComponentModalOptions(values.brick.bricid);
    values.columnConfig = this.currentObjectiveData.columnConfig;
    const columnSummary = this.stateService.getAllColumnSummary();
    this.convertRowsInFilterFromObjectToRow();
    if (this.brickBaseService.floorCeilingBrics.indexOf(values.brick.bricid) >= 0) {
      values.columnSummary = SystemFlags.incorrectSolution ? null : this.filter.getColumnSummary(columnSummary, values.brick.bricid, values.brick.cellIndex) as ColumnSummary;
      values.sot = this.filter.getSOTValue(values.brick.cellIndex, values.brick.bricid);
      values.sot.mediaOnlyPaper = this.filter.checkIfColumnHasOnlyPaper(values.brick.cellIndex);
      if(values.selectedValues && !values.selectedValues.hasOwnProperty('-99')){
        values.selectedValues.sotFloor = values.sot.sotFloor;
        values.selectedValues.sotCeiling = values.sot.sotCeiling;
        values.selectedValues.sotIncrement = values.sot.sotIncrement;
      }
    } else if (this.filter.isQuantityBric(values.brick.bricid)) {
      values.columnSummary = SystemFlags.incorrectSolution ? null : this.filter.getColumnSummary(columnSummary, values.brick.bricid, values.brick.cellIndex) as ColumnSummary;
    } else {
      values.columnSummary = columnSummary ? columnSummary[values.brick.cellIndex] : null;
    }
    // Validate SOT Brick - For Product having Floor/Ceiling Values in Budget/Volumne BRIC
    if (this.brickBaseService.brickID.SOT === values.brick.bricid && this.filter.productDetails && this.filter.productDetails.length) {
      const validations = this.filter.getSOTRestrictionDetails(values.brick);
      if (validations) {
        values.sotRestriction = validations;
      }
    }

    values.readOnlyModal = this.readOnlyModal;

    const colIndexToCheck = this.filter.checkIfCellExistsInColumn(values.brick.cellIndex,
      Number(this.brickBaseService.brickID.ProductCatalogue), true);
    values.isOptionalInProduct = this.pcmService.isOptionalInProduct(colIndexToCheck, this.filter.productDetails,
      Number(this.brickBaseService.brickID.PricingTag));
    const product = this.filter.getProductHelperIfProductExists(colIndexToCheck);
    values.productValidations = product && product.validation[values.brick.cellIndex] ? product.validation[values.brick.cellIndex][values.brick.bricid] : null;
    values.isEditMode = isEditMode;
    if (values.productValidations && (values.productValidations.hasOwnProperty('-99') || _.isEmpty(values.productValidations))) {
      values.productValidations = {};
    }
    if (this.filter.isQuantityBric(values.brick.bricid) && values.columnSummary && !isEditMode) {
      values.selectedValues = this.filter.getQuantityBricSelectedValue(
        values.brick.rowIndex,
        values.brick.cellIndex,
        values.brick.bricid,
      );
    }

    this.sbModalPopupService.open(cellComponent, values, modalOptions).result.then((result: CellValues) => {
      if (values.brick.bricid === this.brickBaseService.brickID.Network) {
        this.filter.addNetworkData(result);
      } else if (values.brick.bricid === this.brickBaseService.brickID.Incharge) {
        const previousBricksDates: DateRange[] = this.workspaceService.getDatesFormAllIncharge(this.stateService.getWorkspaceFilterObj().rows);
        const data = this.workspaceService.allDatesProcessing(result.selectedValues, previousBricksDates, values.brick, this.filter.rows);
        if (data.bricksToPush && data.bricksToPush.length > 0) {
          this.filter.addMultipleRangeBric(data, result);
        } else {
          this.filter.addBrick(result, true, isEditMode);
        }
      } else {
        if (values.brick.bricid === this.brickBaseService.brickID.Media) {
          // When media bric value is Paper, Need to update floor ceiling values for volume/budget bric
          this.filter.updateFloorAndCeilingValues(result.brick.cellIndex, result.selectedValues.selectedMedia === '0');
        }
        this.filter.addBrick(result, true, isEditMode);
      }
    }, () => {
      this.filter.isMandatoryBricksAvailable();
    });
  }

  /**
   * @description This event will be triggered whenever user will click on any brick
   * @author Vijay Sutaria
   * @param {Cell} cell
   * @memberof ObjectiveComponent
   */
  onBrickClick(cell: Cell) {
    if (this.allowExplode) {
      this.allowExplode = this.filter.explodeCell(cell);
    } else if (this.allowCustomDelete) {
      if (!cell.isEmpty && !cell.isLocked && !cell.isMandatoryForProduct && cell.isEditable) {
        cell.markSelected = !cell.markSelected;
      }
    }
  }

  /**
   * @description Handles multiple events on footer buttons like explode & multidelete
   * @author Vijay Sutaria
   * @param {string} buttonType
   * @memberof ObjectiveComponent
   */
  toggleButtons(buttonType: string) {
    this.filter.removeMarkSelected();
    switch (buttonType) {
      case 'explode':
        this.allowExplode = !this.allowExplode;
        this.allowCustomDelete = false;
        break;
      case 'multiDelete':
        this.allowExplode = false;
        this.allowCustomDelete = !this.allowCustomDelete;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }

  /**
   * @description checkAvaibility Dashboard
   * @author Kishan Patel
   * @memberof ObjectiveComponent
   */
  checkAvaibility() {
    if (this.workspaceActionButtonService.checkAvaibility()) {
      this.router.navigate(['/result']);
    }
  }

  /**
    * @description Disables master brick on left sidebar
    * @author Amit Mahida
    * @param {number} cellIndex
    * @memberof ObjectiveComponent
    */
  disableMasterBrics = (cellIndex: number) => {
    if (this.filter.productDetails.length) {
      if (this.filter.checkIfCellExistsInColumn(cellIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true) !== -1) {
        cellIndex = this.filter.checkIfCellExistsInColumn(cellIndex, Number(this.brickBaseService.brickID.ProductCatalogue), true);
        const productDetail = this.filter.getProductHelperIfProductExists(cellIndex);
        if (productDetail) {
          this.masterData.forEach((category) => {
            category.brics.forEach((bric) => {
              bric.disabled = productDetail.optional[cellIndex]
                && productDetail.optional[cellIndex].indexOf(bric.bricid) > -1
                ? false : true;
            });
          });
        }
      }
    }
  }

  /**
   * @description Handles remove event of row/cell
   * @author Amit Mahida
   * @param {DndDropEvent} event
   * @memberof ObjectiveComponent
   */
  onRemoveBrick(event: DndDropEvent) {
    this.historyStackService.pushInHistoryStackObjective(new HistoryStackData(this.filter));
    switch (event.type) {
      case 'row':
        this.filter.removeRow(event.data.index);
        break;
      case 'cell':
        if (this.filter.getMarkSelectedCount() || this.allowCustomDelete) {
          this.filter.removeMultipleBric();
        } else {
          this.filter.removeBric(event.data.rowIndex, event.data.cellIndex);
        }
        break;

      default:
        break;
    }
    this.allowCustomDelete = false;
    this.enableAllMasterBrics();
    this.showRemoveBar = false;
  }

  /**
   * @description Enables master brick on left sidebar
   * @author Amit Mahida
   * @memberof ObjectiveComponent
   */
  enableAllMasterBrics = () => {
    this.masterData.forEach((category) => {
      category.brics.forEach((bric) => {
        bric.disabled = false;
      });
    });
  }

  /**
   * @description Enable product mode
   * @author Amit Mahida
   * @memberof ObjectiveComponent
   */
  enableProductMode() {
    this.appHeaderService.changePCMMode(true);
    this.router.navigate(['pcm']);
  }

  /**
   * @description it will decide to allow open bric modal popup
   * @author Nishit Parekh
   * @param {Cell} cell
   * @param {number} bricId
   * @returns boolean value
   * @memberof ObjectiveComponent
   */
  checkIfBricallowedToOpen(cell: Cell, bricId: number) {
    let allowed = true;

    // Volume of any Product is allowed to open
    if ((cell.isMandatoryForProduct && this.brickBaseService.PCM_EDITABLE_BRICS.indexOf(bricId) === -1) || cell.isEmpty) {
      allowed = false;
    }
    // if we try to open Incharge bric and it has pattern birc below it, it should not allow to open
    if (bricId === this.brickBaseService.brickID.Incharge && this.filter.rows[cell.rowIndex + 1] &&
      this.filter.rows[cell.rowIndex + 1].bric.bricid === this.brickBaseService.brickID.Pattern) {
      const resized = this.filter.rows[cell.rowIndex].getOverlappingCell(cell.cellIndex);
      let startIndex = null;
      if (resized === -1) {
        startIndex = cell.cellIndex;
      } else {
        startIndex = resized;
      }
      // case when incharge bric is stretched then check for pattern bric below each resixed cell
      for (let index = startIndex; index < startIndex + cell.cellWidth; index++) {
        const patternBric = this.filter.rows[cell.rowIndex + 1].cells[index];
        if (patternBric.isEmpty && !patternBric.isHidden) {
          allowed = true;
        } else {
          this.logHelperService.logError(this.userBundle['workspace.error.pattern.noEditable']);
          allowed = false;
          break;
        }
      }
    }
    // if we try to open pattern and it is not valid than it should not be open
    if (bricId === this.brickBaseService.brickID.Pattern && !cell.isValid) {
      allowed = false;
      this.logHelperService.logError(this.userBundle['workspace.error.missingRequiredBricks']);
    }
    return allowed;
  }

  ghostCellHighlight(isHighlight = false) {
    this.highlightGhost = isHighlight;
  }

  /**
   * @description Handles drag brick over cell
   * @author Amit Mahida
   * @param {DragEvent} $event
   * @param {HTMLDivElement} cellElement
   * @param {number} bricid
   * @param {Cell} cell
   * @memberof ObjectiveComponent
   */
  dragOverCell($event: DragEvent, cellElement: HTMLDivElement, bricid: number, cell: Cell) {
    cell.highlight($event, this.draggedBricHighlight, cellElement, bricid);
    this.setExpandDirection(cell);
  }

  /**
   * @description Hide Volumne Budget and Product Catagloue brick for Filler Campaign
   * @author Shreni
   * @date 2020-04-01
   * @memberof ObjectiveComponent
   */
  handleBricsForFillerCampaign() {
    // Disable Volume, Budget, Product BRICS in Case of Filler Campaign//
    this.masterData.forEach((category: Category) => {
      category.brics.forEach((brick: Brick) => {
        if ([
          this.brickBaseService.brickID.Volume,
          this.brickBaseService.brickID.ProductCatalogue,
          this.brickBaseService.brickID.Budget].indexOf(brick.bricid) !== -1) {
          brick.hide = true;
        }
      });
      const hiddenBrics = category.brics.filter(bric => bric.hide === true);
      if (hiddenBrics.length === category.brics.length) {
        category.hide = true;
      }
    });
  }

  /**
   * @description Validates objective workspace before save
   * @author Amit Mahida
   * @returns {boolean}
   * @memberof ObjectiveComponent
   */
  validateObjectiveData(): boolean {
    if (!this.filter.rows.length) {
      this.logHelperService.logError(this.userBundle['objective.error.save.noBricsToSave'] || 'No BRICS to save');
      return false;
    }

    const totalColumns = this.filter.getMaxCellIndex() + 1;
    for (let colIndex = 0; colIndex < totalColumns; colIndex++) {
      const existingBricIds = this.filter.getNonEmptyBricsOfColumn(colIndex);
      const existingQuantityBricIds = existingBricIds.filter(bricId => this.filter.isQuantityBric(bricId));
      if (!existingQuantityBricIds.length) {
        this.logHelperService.logError(this.userBundle['objective.error.save.noQuantityInColumn'] || 'Each column should have at least one quantity bric');
        return false;
      }
    }
    return true;
  }

  backToNormalMode() {
    this.appHeaderService.objectiveMode = false;
    this.convertRowsInFilterFromObjectToRow();
    this.historyStackService.historyStackObjective.resetAll();
    this.historyStackService.undonehistoryStackObjective.resetAll();

    switch (this.objectiveService.objectiveModeFor) {
      case ObjectiveModeForEnum.workspace:
        this.router.navigate(['workspace']);
        break;
      case ObjectiveModeForEnum.pcm:
        this.appHeaderService.enabledPCM = true;
        this.router.navigate(['pcm']);
        break;
    }
  }

  cancelObjective() {
    this.filter = this.stateService.clone(this.backupFilter);
    this.backToNormalMode();
  }

  /**
   * This methos is responsible to revert workspace changes to previous state
   */
  revertWorkspaceStructure() {
    const filter = this.stateService.clone(this.backupFilter);
    this.stateService.setWorkspaceObject('filter', filter);
    filter.generateBrickRequestJSON();
  }

  saveObjective() {
    if (!this.validateObjectiveData()) {
      return;
    }
    this.convertRowsInFilterFromObjectToRow();
    // this.appHeaderService.objectiveMode = false;
    this.objectiveService.updateWorkspaceOnSave(this.filter);
    SystemFlags.processSolutionAfterObjectiveSave = true;
    this.backToNormalMode();
  }

  /**
   * This method is added to convert the rows and cells to Row and Cell class objects
   * SM-10792
   */
  convertRowsInFilterFromObjectToRow() {
    let row: Row = new Row();
    let cell: Cell = new Cell();
    const rowList: Row[] = [];
    let cellList: Cell[] = [];
    this.filter.rows.forEach((rowObj: any) => {
      cellList = [];
      row = new Row();
      for (var prop in rowObj) {
        if (prop === 'cells' && rowObj[prop].length > 0) {
          for (let i=0;i<rowObj[prop].length;i++) {
            cell = new Cell();
            for (var cellProp in rowObj[prop][i]) {
              cell[cellProp] = rowObj[prop][i][cellProp];
            }
            cellList.push(_.cloneDeep(cell));
          }
          row['cells'] = _.cloneDeep(cellList);
          cellList = [];
        } else {
          row[prop] = rowObj[prop];
        }
      }
      rowList.push(_.cloneDeep(row));
      row = null;
    });
    this.filter.rows = _.cloneDeep(rowList);
  }

  /**
   * This methos will get name of selected product format from Format brick and Objective brick
   * @param columnIndex column Index of column structure
   * @returns string
   */
   private getSelectedProductFormat(columnIndex: number) {
    const formateBricRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Format));
    const objectiveBricRow = this.filter.getRowByBrickId(Number(this.brickBaseService.brickID.Objective));
    let selectedProductFormats = '';
    if (objectiveBricRow && objectiveBricRow.cells && objectiveBricRow.cells[columnIndex].selected && objectiveBricRow.cells[columnIndex].selected.objectives) {
      const formatRow: Row = objectiveBricRow.cells[columnIndex].selected.objectives.find((row: Row) => {
        return row['bric'].bricid === Number(this.brickBaseService.brickID.Format);
      });
      selectedProductFormats = this.joinSelectedProductFormat(columnIndex, formatRow, selectedProductFormats);
    }
    if (formateBricRow) {
      selectedProductFormats = this.joinSelectedProductFormat(columnIndex, formateBricRow, selectedProductFormats);
    }
    return selectedProductFormats;
  }

  /**
   * This method will join the name of each selected product format
   * @param columnIndex column Index of column structure
   * @param formateBricRow Format brick row
   * @param selectedProductFormats string
   * @returns string
   */
  private joinSelectedProductFormat(columnIndex: number, formateBricRow, selectedProductFormats) {
    if (formateBricRow && formateBricRow.cells[columnIndex] && formateBricRow.cells[columnIndex].selected) {
      Object.keys(formateBricRow.cells[columnIndex].selected).forEach((key) => {
        const selectedFormats = _.map(formateBricRow.cells[columnIndex].selected[key], 'name').filter(Boolean).join(', ');
        let selectedProductFormatsLocal = selectedProductFormats;
        if (selectedFormats && selectedProductFormats) {
          selectedProductFormatsLocal = `${selectedFormats}, ${selectedProductFormats}`;
        } else if (selectedFormats) {
          selectedProductFormatsLocal = selectedFormats;
        }
        selectedProductFormats = selectedProductFormatsLocal;
      });
    }
    return selectedProductFormats;
  }

  /**
   * @description Handles reshuffling
   * @author Dhaval Patel
   * @memberof ObjectiveComponent
   */
   async onReshufflingClick() {
    if (!this.validateObjectiveData()) {
      return;
    }
    const columnIndex = this.currentObjectiveData.brick.cellIndex;
    if (GLOBAL.localSolverEnabled) {
      this.objectiveService.updateWorkspaceOnSave(this.filter);
      const workspaceFlter = this.stateService.getWorkspaceFilterObj();
      workspaceFlter.generateBrickRequestJSON();
      const data = {
        columnIndex,
        bricsData: workspaceFlter.brickRequestJSON
      }
      this.reshuffleService.fetchParentBookingsReshuffle(data).subscribe((res) => {
        if (res.status === 'KO') {
          this.revertWorkspaceStructure();
          this.logHelperService.logError(res.message);
        } else {
          if (res.message !== '') {
            this.logHelperService.logError(res.message);
          }
          this.openLSReshuffleDetail(columnIndex, res);
        }
      }, error => {
        this.revertWorkspaceStructure();
        this.logHelperService.logError(error?.error?.message);
      });
    }
  }

  /**
   * This method renders the resuffle popup
   * @param columnIndex column index where objective brick is dropped in workspace
   * @param res Response of get reshuffle API
   */
  openLSReshuffleDetail(columnIndex: number, res: any) {
    const modalOptions: NgbModalOptions = {
      windowClass: 'size-xl',
      backdrop: 'static'
    };
    const resolveObject = {
      columnIndex,
      ...res.data && { data: res.data },
      selectedProducts: this.getSelectedProductFormat(columnIndex)
    };
    if (this.uiControl.reshuffleScopingForObjectiveWorkspaceEnabled) {
      let excludeLabelItem;
      let reshuffleScopeIndex = -1;
      if (res.data.reshuffleScopeLookUp && res.data.reshuffleScopeLookUp.length) {
        res.data.reshuffleScopeLookUp.forEach((element, index) => {
          if (element.selectionId === 38 && element.lookupType === 38) {
            excludeLabelItem = {
              id: -1,
              name: element.displayName,
              inUse: true
            };
            reshuffleScopeIndex = index;
          } else {
            return element.lookupType = 2;
          }
        });
      }
      if (reshuffleScopeIndex != -1) {
        res.data.reshuffleScopeLookUp.splice(reshuffleScopeIndex, 1)
      }
      resolveObject['reshuffleCandidates'] = res.data.reshuffleCandidates;
      resolveObject['reshuffleScopeLookUp'] = res.data.reshuffleScopeLookUp;
      resolveObject['reshuffleScopeLookUpData'] = res.data.reshuffleScopeLookUpData;
      resolveObject['reshuffleScopeLimitCriteria'] = res.data.reshuffleScopeLimitCriteria['38'];
      resolveObject['dummySelectOption'] = excludeLabelItem;
    }
    this.sbModalPopupService.open(ReshuffleDetailLSComponent, resolveObject, modalOptions).result.then((result) => {
      const finalData = [];
      result.rowData.forEach((element) => {
        finalData.push({
          columnIndex: element.columnIndex,
          campaignId: element.campaignId,
          locked: element.locked || element.lockedAtUI,
          filler: element.filler,
          reshuffleColumns: element.reshuffleColumns
        });
      });
      this.lsProcessCompleted = false;
      this.reshuffleService.executeReshuffleForSingleCampaign(columnIndex, finalData, result.reshuffleScopeUserSelection).subscribe((res) => {
        this.lsProcessCompleted = true;
        this.kpiData = null;
        this.showLsMessages = false;
        if (res.status === 'KO') {
          this.revertWorkspaceStructure();
          this.logHelperService.logError(res.message);
        } else {
          const resolveResultObject = {
            columnIndex,
            data: res.data
          };
          this.sbModalPopupService.open(ReshuffleDetailLSResultComponent, resolveResultObject, modalOptions).result.then(
            (status) => {
              switch (status) {
                case 'save':
                  this.saveOrCancelReshuffle(true);
                  break;
                case 'cancel':
                  this.saveOrCancelReshuffle(false);
                  break;
              }
            }, (reason) => {
              if (reason === 'timeout') {
                const timeOutModalOptions: NgbModalOptions = {
                  windowClass: 'size-sm',
                  backdrop: 'static'
                };
                const timeOutResolveObject = {
                  message: this.userBundle['reshuffle.timeout.message'] || 'Your reshuffle request has timed out. If you wish to complete this action please launch the reshuffle process again',
                };
                this.sbModalPopupService.open(ReshuffleConfirmComponent, timeOutResolveObject, timeOutModalOptions).result.then(() => {
                  this.saveOrCancelReshuffle(false);
                }, () => {
                  this.saveOrCancelReshuffle(false);
                });
              }
            });
        }
      });
    }, () => {
      this.saveOrCancelReshuffle(false);
    });
  }

  /**
   * Method to save or reject the reshuffling
   * @param save Save action or cancel action
   */
  saveOrCancelReshuffle(save: boolean) {
    this.reshuffleService.saveOrCancelReshuffle(save).subscribe((res) => {
      if (res && res.status && res.status.toLowerCase() === 'ok') {
        if (res.data && save) {
          this.logHelperService.logInfo(this.userBundle['reshuffle.text.success'] || 'Reshuffle completed successfully');
          this.filter.processBricksCallback(res);
          this.backToNormalMode();
        } else if (!save) {
          this.logHelperService.logInfo(res.message);
          this.revertWorkspaceStructure();
        }
      } else {
        this.logHelperService.logInfo(res.message || this.userBundle['reshuffle.text.error'] || 'Reshuffle failed');
      }
    }, (res) => {
      if (res.error.status === 'KO') {
        this.logHelperService.logError(res.error.message);
      }
    });
  }
}
