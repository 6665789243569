<div [ngClass]="{remove: showRemoveBar}" [dndDropzone]="removeDropZone" (dndDrop)="onRemoveBrick($event)"
    dndDragoverClass="highlight-div-panning" id="removeBar">
    <div class="trash" [style.display]="showRemoveBar ? 'block' : 'none'">
        <span>{{userBundle['workspace.label.remove']}}</span>
    </div>
</div>

<div class="switchBRICdragging{{alternateDesign}}" style="display:none"></div>

<div class="sidebar bounceInLeft animated" [ngClass]="{'readOnly' : readOnlyModal}">
    <dl class="accordion">
        <ng-container *ngFor="let data of masterData; trackBy: trackByCategoryId; index as i">
            <dt class="cat_name" [hidden]="data.hide">
                <a href="javascript:void(0)" class="title"
                    [ngClass]="{'down': data.isAccordionDown, '': !data.isAccordionDown}"
                    (click)="data.isAccordionDown = !data.isAccordionDown">
                    <span class="icons animated" [style.background-color]="data.brics[0]?.color"></span>
                    <span class="text" [style.color]="data.brics[0]?.color">{{data.name}}</span>
                </a>
            </dt>
            <dd [ngClass]="{'down': data.isAccordionDown, '': !data.isAccordionDown}" [hidden]="data.hide">

                <div class="brick_container"
                    *ngFor="let bric of data.brics; trackBy: trackByBricId; index as brickIndex"
                    [hidden]="bric.hide && !bric.optionalForProduct">
                    <div class="brick peg" id="abc{{bric.bricid}}" [style.backgroundColor]="bric.color"
                        [dndDraggable]="bric" [dndType]="'master'" [dndEffectAllowed]="draggableMaster.effectAllowed"
                        [dndDragImageOffsetFunction]="calDragImageOffset"
                        [dndDisableIf]="draggableMaster.disable || readOnlyModal"
                        (dndStart)="onMasterBricDragStart(bric)" (dndEnd)="onDragEnd()"
                        dndDraggingClass="switchBRICdragging{{alternateDesign}}"
                        [ngClass]="{'brick_disabled' : bric.disabled}">
                        <img alt="brick" src="{{'assets/img/brick'+alternateDesign+'.png'}}" />
                        <div dndDragImageRef [style.backgroundColor]="bric.color"></div>
                        <div class="panel-line ng-scope" *ngIf="alternateDesign==1"></div>
                    </div>
                    <div class="bricktext">{{bric.bricname}}</div>
                </div>
            </dd>
        </ng-container>
    </dl>
</div>


<div class="workspace bounceInRight animated">
    <div class="workspace_block">
        <div class="workspace_block_2 animated zoomIn" id="workspace">
            <div id="divPanning" dndDropzone [ngClass]="{'highlight-div-panning': highlightDivPanning}"
                (dndDrop)="onDrop($event)" (dragover)="highlightWorkspace()" (dragleave)="highlightDivPanning=false;">
                <div class="panhandler-wrap" appPanZoom [disablePan]="disablePanning" [zoomIn]="zoomIn"
                    [zoomOut]="zoomOut" [zoomReset]="zoomReset" [refContainer]="panZoomContainer">

                    <div id="divZoom" #panZoomContainer>
                        <ng-container *ngFor="let row of filter.rows; trackBy: trackByRowIndex; index as rowIndex">
                            <div class="rows dropRow"
                                [style.width]="(CELLWIDTH * row.cells.length) + rowWidthDelta + 'px'"
                                [dndDraggable]="row" [dndEffectAllowed]="draggableRow.effectAllowed" dndDropzone
                                [dndType]="'row'" (dndStart)="onBricDragStart(row.bric, null)" (dndEnd)="onDndEnd()"
                                (dndDrop)="onDrop($event)" (dragover)="row.highlight(draggedBricHighlight)"
                                (dragleave)="row.removeHighlight()"
                                [ngClass]="{'row_hidden': row.isHidden, denmarkBRIC: alternateDesign == 2, 'disableCursor': row.disableCursorHighlight, 'lvl-over': row.highlightRow }"
                                [dndDisableIf]="readOnlyModal">

                                <div class="slot flat_brick switchBRIC_image{{alternateDesign}}" #cellElement
                                    *ngFor="let cell of row.cells; trackBy: trackByCellIndex; index as colIndex"
                                    [hidden]="cell.isHidden" appResizable [disableResize]="cell.isLocked || !cell.isEditable || cell.isMandatoryForProduct || cell.isEmpty
                  || row.bric.bricid === brickBaseService.brickID.Audience
                  || row.bric.bricid === brickBaseService.brickID.SecondaryAudience
                  || row.bric.bricid === brickBaseService.brickID.PrimaryAudience
                  || readOnlyModal" (resizing)="onCellExpanding()" (resizeStop)="onCellExpanded($event,cell)"
                                    [minResizeWidth]="CELLWIDTH" [maxResizeWidth]="(row.cells.length - colIndex)"
                                    [actualCellWidth]="cell.cellWidth" (resizeStart)="onCellExpandBegin()"
                                    [style.width]="(cell.cellWidth * CELLWIDTH) + 'px'"
                                    [style.backgroundColor]="row.bric.color"
                                    [style.color]="row.bric.bricid === brickBaseService.brickID.Proximity ? '#545454' :'#ffffff'"
                                    dndDraggingClass="switchBRICdragging{{alternateDesign}}" [ngClass]="{'unUsed':!cell.isHidden && cell.isEmpty, 'denmarkBRIC': alternateDesign == 2,
                  'brick_disabled': cell.isLocked || !cell.isEditable || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1),
                  'explodeicon':allowExplode,
                  'lvl-over': cell.highlightCell,
                  'lr-drop-target-before-brick': cell.highlightLeft,
                  'lr-drop-target-after-brick': cell.highlightRight,
                  'brick_error': !cell.isValid }" [dndDisableIf]="cell.isLocked || !cell.isEditable"
                                    (dragover)="dragOverCell($event, cellElement, row.bric.bricid, cell);"
                                    (dragleave)="cell.removeHighlight()" dndDropzone [dndType]="'cell'"
                                    (dblclick)="onBrickDblClick(row,cell)" [dndDraggable]="cell"
                                    [dndDisableDragIf]="cell.isLocked || !cell.isEditable || cell.isEmpty || isResizing || readOnlyModal || (cell.isMandatoryForProduct && brickBaseService.PCM_EDITABLE_BRICS.indexOf(row.bric.bricid) == -1 && row.bric.bricid != brickBaseService.brickID.ProductCatalogue)"
                                    [dndEffectAllowed]="'move'" [dndDragImageOffsetFunction]="calDragImageOffset"
                                    (dndDrop)="onDrop($event, rowIndex, colIndex)" (click)="onBrickClick(cell)"
                                    (dndStart)="onBricDragStart(row.bric, cell.cellIndex)"
                                    [attr.data-col-index]="colIndex" [attr.data-row-index]="rowIndex"
                                    (mouseover)="disableMasterBrics(cell.cellIndex)"
                                    (mouseleave)="enableAllMasterBrics()" (dndEnd)="onDndEnd()"
                                    id="abc{{row.bric.bricid}}">

                                    <div dndDragImageRef [style.backgroundColor]="row.bric.color"></div>
                                    <div class="panel-line ng-scope" *ngIf="alternateDesign==1"></div>
                                    <div [innerHTML]='cell.displayText' appMasterTooltip [tooltipName]="'customtooltip'"
                                        [tooltipScope]="1"
                                        [ngClass]="{'frame-display-text': brickBaseService.brickID.Frame === row.bric.bricid}">
                                    </div>

                                    <em *ngIf="!cell.isEmpty && !cell.isHidden && [1, 3, 8, 27].includes(row.bric.bricid) && global.localSolverEnabled"
                                        class="img-circle trafficLight"
                                        [ngClass]="{'bg-red': cell.selected.status === TOLERANCE_STATUSES.RED, 'bg-orange': cell.selected.status === TOLERANCE_STATUSES.ORANGE, 'bg-green': cell.selected.status === TOLERANCE_STATUSES.GREEN}">
                                        &nbsp;
                                    </em>
                                    <span
                                        [ngClass]="{'unUsed':cell.isEmpty,'used':!cell.isEmpty, 'selectedbrick': cell.markSelected }"
                                        class="resize">
                                        <em class="halficonbtn appResizableHandle"
                                            [ngClass]="{'disable-expand': cell.isLocked || !cell.isEditable || cell.isMandatoryForProduct}">&nbsp;</em>
                                    </span>
                                    <img class="info_icon" src="../../../../../../assets/img/information.png"
                                        appMasterTooltip [tooltipName]="'warningtooltip'" alt="information"
                                        *ngIf="cell.showWarningIcon === true" [tooltipScope]="1" />
                                    <!-- tolerance warning -->
                                    <span
                                        *ngIf="filter.toleranceLimit.cellAddress.indexOf((rowIndex+','+colIndex)) > -1"
                                        appMasterTooltip [tooltipName]="'tolerancetooltip'" [tooltipScope]="1">
                                        <em class="fa fa-warning icontruefalse iconwarning" aria-hidden="true"></em>
                                        <div class="tolerancetooltip" style="word-break: break-word;">
                                            {{userBundle['workspace.toleranceLimit.warning']}}</div>
                                    </span>
                                    <!-- End tolerance warning -->
                                    <div *ngIf="cell.toolTipText" class="customtooltip">
                                        <div [innerHTML]='cell.toolTipText'></div>
                                    </div>
                                    <em
                                        [ngClass]="{'fa fa-clone icontruefalse' : cell.selected['allocateAllFrames'], 'allocateAllFrames': cell.selected['allocateAllFrames'] && global.localSolverEnabled}"></em>
                                    <em *ngIf="!cell.isEmpty && !cell.isHidden && [1, 3, 8, 27].includes(row.bric.bricid) && global.localSolverEnabled"
                                        class="img-circle trafficLight"
                                        [ngClass]="{'bg-red': cell.selected.status === TOLERANCE_STATUSES.RED, 'bg-orange': cell.selected.status === TOLERANCE_STATUSES.ORANGE, 'bg-green': cell.selected.status === TOLERANCE_STATUSES.GREEN}">
                                        &nbsp;
                                    </em>
                                    <div class="priority"
                                        *ngIf="global.localSolverEnabled && !uiControl.hideObjectivePriority && cell.selected.priority !== null && cell.selected.priority !== undefined && [1, 3, 8].includes(row.bric.bricid)">
                                        {{cell.selected.priority}}
                                    </div>
                                    <div *ngIf="cell.warningText" class="warningtooltip">
                                        <div [innerHTML]='cell.warningText'></div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- Ghost Row start -->
                        <div class="rows droprow ghostrow" *ngIf="filter.rows.length">
                            <div class="flat_brick slot unUsed"
                                *ngFor="let n of [].constructor(filter.rows[0].cells.length + 1); trackBy: trackByIndex; index as cellIndex;"
                                (dragover)="ghostCellHighlight(true)" (dragleave)="ghostCellHighlight()" dndDropzone
                                [style.width]="(CELLWIDTH) + 'px'" (dndDrop)="onDrop($event, null, cellIndex, true)"
                                dndDragoverClass="lvl-over">
                            </div>
                        </div>
                        <!-- Ghost Row end -->
                    </div>
                </div>
            </div>
        </div>
        <div style="clear: both;"></div>
    </div>
    <div class="zoom">
        <div class="left">
            <a href="javascript:void(0)" title="Back To Workspace" class="zoombutton closebutton animated bounceIn"
                style="opacity: 1" (click)="backToNormalMode()"></a>
            <a id="zoomIn" #zoomIn href="javascript:void(0)" #zoomIn
                title="{{userBundle['workspace.button.tooltip.zoomIn']}}" class="zoombutton in animated bounceIn"
                [ngClass]="{'readOnly' : readOnlyModal}"></a>
            <a id="zoomReset" #zoomReset href="javascript:void(0)"
                title="{{userBundle['workspace.button.tooltip.defaultZoom']}}"
                class="zoombutton default animated bounceIn" [ngClass]="{'readOnly' : readOnlyModal}"></a>
            <a id="zoomOut" #zoomOut href="javascript:void(0)" #zoomOut
                title="{{userBundle['workspace.button.tooltip.zoomOut']}}" class="zoombutton out animated bounceIn"
                [ngClass]="{'readOnly' : readOnlyModal}"></a>
            <button type="button" class="zoombutton multidelete animated bounceIn"
                [ngClass]="{'active': allowCustomDelete, 'readOnly' : readOnlyModal}"
                title="{{userBundle['workspace.button.tooltip.multipleDelete']}}"
                (click)="toggleButtons('multiDelete')"></button>
            <a id="btnUndo" appNoDblClick href="javascript:void(0)"
                title="{{userBundle['workspace.button.tooltip.undo']}}" class="zoombutton undobutton animated bounceIn"
                (click)="undoLastStep()" [ngStyle]="{'cursor': filter.isPastColumnHidden ? 'not-allowed' : 'pointer'}"
                [ngClass]="{'readOnly' : readOnlyModal}"></a>
            <a id="btnRedo" appNoDblClick href="javascript:void(0)"
                title="{{userBundle['workspace.button.tooltip.redo']}}" class="zoombutton redobutton animated bounceIn"
                (click)="redoLastUndoneStep()"
                [ngStyle]="{'cursor': filter.isPastColumnHidden ? 'not-allowed' : 'pointer'}"
                [ngClass]="{'readOnly' : readOnlyModal}"></a>
            <button type="button" class="zoombutton explodebutton animated bounceIn"
                [ngClass]="{'active': allowExplode, 'readOnly' : readOnlyModal}" id="btnExplode"
                (click)="toggleButtons('explode')" title="{{userBundle['workspace.button.tooltip.explode']}}"></button>
            <a id="btnClear" href="javascript:void(0)" [title]="userBundle['workspace.button.tooltip.wiper']"
                class="zoombutton clearbutton animated bounceIn" (click)="clearWorkspace()"
                [ngStyle]="{'cursor': filter.isPastColumnHidden ? 'not-allowed' : 'pointer'}"
                [ngClass]="{'readOnly' : readOnlyModal}"></a>
        </div>
    </div>
    <div class="redbutton">
        <div class="right">
            <a href="javascript:void(0)" *ngIf="allowLimitResuffleScope" (click)="onReshufflingClick()" class="savebutton minimize animated bounceIn"
                [ngClass]="{'readOnly' : readOnlyModal}"></a>
            <a href="javascript:void(0)" (click)="saveObjective()" class="savebutton save animated bounceIn"
                [ngClass]="{'readOnly' : readOnlyModal}"></a>
        </div>
    </div>
</div>
