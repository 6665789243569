<app-sb-modal-wrapper [title]="userBundle['workspace.collection.networks.heading']" [readOnlyModal]="readOnlyModal"
  [titleBackgroundColor]="resolveObject.brick.color" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
  <div [ngClass]="{'readOnly' : readOnlyModal}">
    <div class="control_group network-type-switch" *ngIf="modeVisible && !disableDropDown">
          <label style="width: auto; padding-right: 10px;">{{ userBundle['workspace.collection.networks.network'] || 'Network' }}
          </label>
          <label class="switchAllowFrames">
              <input
                  [checked]="isRateCard"
                  (click)="modeChanged($event)"
                  type="checkbox" role='button'>
              <span class="sliderAllowFrames round"></span>
          </label>
          <label style="width: auto; padding-left: 10px;">{{ userBundle['workspace.collection.networks.rateCard'] || 'Rate Card' }}
          </label>
    </div>
    <div class="tabs_container horizontal">
      <ul class="tabs-menu">
        <li *ngFor="let networkGroup of networkGroups; trackBy: trackByNetworkGroup;" (click)="selectTab(networkGroup.networkGroupId)"
          [ngClass]="{current: selectedNetworkGroupId === networkGroup.networkGroupId}"
          id="tab-{{networkGroup.networkGroupId}}">
          <span class="text">{{networkGroup.networkGroupName}}</span>
        </li>
      </ul>
      <div class="tab">
        <div class="tab-content" *ngIf="!isRateCard">
          <input *ngIf="disableDropDown" type="text" class="search-disabled"
            minlength="1" type="text" disabled
            id="networkInput" placeholder="Add Tag" aria-label="Add Tag">
          <tag-input *ngIf="!disableDropDown" [inputId]="'networkInput'" [(ngModel)]="selectedNetworks" [identifyBy]="'networkId'"
            [displayBy]="'networkName'" [onlyFromAutocomplete]="true" [placeholder]="userBundle['common.aadTag']"
            [secondaryPlaceholder]="userBundle['common.aadTag']" [showDropdownIfEmpty]="true" (onAdd)="tagAdded($event)"
            [disable]="disableDropDown">
            <tag-input-dropdown [showDropdownIfEmpty]="true" [identifyBy]="'networkId'"
              [autocompleteObservable]="loadNetworkData" [visibleMenuAfterItemAdd]="true" [minimumTextLength]="0"
              [displayBy]="'networkName'" [appendToBody]="false"
              >
              <ng-template let-item="item" let-index="index">
                <span class="network-name">{{item.networkName}}</span>
                <span>&nbsp;&nbsp;
                  <img src="images/network-0.png" alt="network-0" />&nbsp;&nbsp;{{item.frameData[0]}}</span>
                <span>&nbsp;&nbsp;
                  <img src="images/network-1.png" alt="network-1" />&nbsp;&nbsp;{{item.frameData[1]}}</span>
                <span>&nbsp;&nbsp;
                  <img src="images/network-2.png" alt="network-2" />&nbsp;&nbsp;{{item.frameData[2]}}</span>
                <span *ngIf="uiControl.pricingTagMinimumEnabled">&nbsp;&nbsp;
                  <img src="images/network-3.png"  alt="network-3"/>&nbsp;&nbsp;{{item.networkMinQuantity}}</span>
              </ng-template>
            </tag-input-dropdown>
          </tag-input>

          <ul class="summaryContainer">
            <li *ngFor="let item of displayNetworks; let i = index; trackBy:trackByFn"
              [ngClass]="{'disable-network': !item.noOfFramesEditable && !pcmMode && resolveObject.productValidations}">
              <label>{{item.networkName}}</label>
              <span>
                <span class="fixPriceCheckBox" [ngClass]="{'fixedPackagePrice': item.fixedPackagePrice}" *ngIf="uiControl.fixedPackagePriceEnabled"
                (click)="item.fixedPackagePrice = !item.fixedPackagePrice">{{userBundle['workspace.network.fixedPricePackage'] || 'Fixed Price Package'}}</span>
                <span *ngIf="isWarnedNetwork(item.networkId)">&nbsp;&nbsp;
                  <img class="warning_icon_workspace" src="assets/img/icon-warning-red.png"  alt="warning"/>
                </span>

                <span *ngIf="resolveObject.brick?.optionStack">&nbsp;&nbsp;
                  <input id="optionStack{{i}}" class="option-stack" type="checkbox" tabindex="-1" (change)="onOptionStackChange(item)"
                    [(ngModel)]="item.optionStack" />&nbsp;&nbsp;
                  <label class="option-stack" for="optionStack{{i}}">
                    <div class="option-stack-container" [title]="userBundle['workspace.collection.networks.tooltip']">
                      <span class="fa fa-circle-thin"></span>
                      <span class="fa fa-circle-thin"></span>
                    </div>
                  </label>
                </span>

                <span *ngFor="let frame of item.frameData; trackBy: trackByIndex; let index = index">
                  <span *ngIf="index < 3">
                    &nbsp;&nbsp;
                    <img [src]="'images/network-' + index + '.png'"  alt="network" />&nbsp;&nbsp;{{frame}}
                  </span>
                </span>
                &nbsp;&nbsp;
                <span *ngIf="pcmMode">
                  | &nbsp;
                  <span>
                    <input type="text" [(ngModel)]="item.networkDefaultQuantity" class="net-qty-input"
                      [appOnlyNumber]="true" [minValue]="0" [maxValue]="item.frameData[0]"
                      [disabled]="!pcmMode && !item.noOfFramesEditable" />
                  </span>
                </span>
                <span *ngIf="!uiControl.pricingTagMinimumEnabled && !pcmMode">
                  | &nbsp;
                  <span>
                    <input *ngIf="item.optionStack || uiControl.reshuffleEnabled" type="text"
                      [(ngModel)]="item.networkDefaultQuantity" class="net-qty-input" [appOnlyNumber]="true"
                      [minValue]="0" [maxValue]="item.frameData[0]" [disabled]="!pcmMode && !item.noOfFramesEditable" />
                    <input *ngIf="!item.optionStack && !uiControl.reshuffleEnabled" type="text"
                      [(ngModel)]="item.networkDefaultQuantity" class="net-qty-input" [appOnlyNumber]="true"
                      [minValue]="0" [maxValue]="item.frameData[1]" [disabled]="!pcmMode && !item.noOfFramesEditable" />
                  </span>
                </span>
                <span *ngIf="uiControl.pricingTagMinimumEnabled && !pcmMode">
                  <span>
                    <img [src]="'images/network-3.png'"  alt="network-3"/>&nbsp;&nbsp;{{item.networkMinQuantity}}
                  </span>
                  &nbsp; | &nbsp;
                  <span>
                    <input type="text" *ngIf="item.frameData[2] >= item.networkMinQuantity"
                      [(ngModel)]="item.networkDefaultQuantity" class="net-qty-input" [appOnlyNumber]="true"
                      [minValue]="item.networkMinQuantity" [maxValue]="9999"
                      [disabled]="!pcmMode && !item.noOfFramesEditable" />
                    <input type="text" *ngIf="item.frameData[2] < item.networkMinQuantity"
                      [(ngModel)]="item.networkDefaultQuantity" class="net-qty-input" [appOnlyNumber]="true"
                      [minValue]="item.frameData[2]" [maxValue]="item.networkMinQuantity"
                      [disabled]="!pcmMode && !item.noOfFramesEditable" />
                  </span>
                </span>
              </span>
              <span *ngIf="pcmMode">
                <label for="noOfFramesEditable-{{i}}" tooltip style="display:inline; position:relative; top: 0px;"
                  [title]="userBundle['workspace.network.isNoOfFramesEditable'] || 'No of frames editable'">
                  <em class="fa fa-edit"></em>
                  <!-- <span class="customtooltip tooltip-overite" style="left: -156px;width: 150px;">
                    {{userBundle['workspace.network.isNoOfFramesEditable'] || 'No of frames editable'}}
                  </span> -->
                </label>
                <input type="checkbox" name="noOfFramesEditable-{{i}}" [(ngModel)]="item.noOfFramesEditable"
                  id="noOfFramesEditable-{{i}}">
              </span>
              <span class="right">
                <img src="images/sub_black_delete.png" (click)="removeSelectedNetwork(item)"
                  height="10px" width="10px" class="right"  alt="sub_black_delete"/>
              </span>
            </li>
          </ul>
        </div>
        <div class="tab-content" *ngIf="isRateCard">
          <input *ngIf="disableDropDown || !uiControl.openRateCardsEnabled; else elseTagInput" type="text" class="search-disabled" minlength="1" type="text" disabled
            id="networkInput" placeholder="Add Tag" aria-label="Add Tag">
          <ng-template #elseTagInput>
            <tag-input [inputId]="'networkInput'" [(ngModel)]="selectedNetworks"
            [identifyBy]="'networkId'" [displayBy]="'networkName'" [onlyFromAutocomplete]="true"
            [placeholder]="userBundle['common.aadTag']" [secondaryPlaceholder]="userBundle['common.aadTag']"
            [showDropdownIfEmpty]="true" (onAdd)="tagAdded($event)" [disable]="disableDropDown">
            <tag-input-dropdown [showDropdownIfEmpty]="true" [identifyBy]="'networkId'"
              [autocompleteObservable]="loadNetworkData" [visibleMenuAfterItemAdd]="false" [minimumTextLength]="0"
              [displayBy]="'networkName'" [appendToBody]="false" inputClass="form-control"
              [maintainSearchText]="true">
              <ng-template let-item="item" let-index="index">
                <span class="network-name">{{item.networkName}}</span>
              </ng-template>
            </tag-input-dropdown>
          </tag-input>
          </ng-template>

          <ul class="summaryContainer">
            <li *ngFor="let item of displayNetworks; let i = index; trackBy:trackByItem"
              [ngClass]="{'disable-network': (!item.noOfFramesEditable && !pcmMode && resolveObject.productValidations) || !uiControl.openRateCardsEnabled || disableSelectedRatecard}">
              <label>{{item.networkName}}</label>
              <span>
                <span class="fixPriceCheckBox" [ngClass]="{'fixedPackagePrice': item.fixedPackagePrice}"
                (click)="item.fixedPackagePrice = !item.fixedPackagePrice">{{userBundle['workspace.rateCard.fixedPrice'] || 'FIXED PRICE'}}</span>

                <span *ngIf="resolveObject.brick?.optionStack">&nbsp;&nbsp;
                  <input id="optionStack{{i}}" class="option-stack" type="checkbox" tabindex="-1" (change)="onOptionStackChange(item)"
                    [(ngModel)]="item.optionStack" />&nbsp;&nbsp;
                  <label class="option-stack" for="optionStack{{i}}">
                    <div class="option-stack-container" [title]="userBundle['workspace.collection.networks.tooltip']">
                      <span class="fa fa-circle-thin"></span>
                      <span class="fa fa-circle-thin"></span>
                    </div>
                  </label>
                </span>

                <span>
                  <span>
                    &nbsp;&nbsp;
                    <img [src]="'images/ratecard.png'"  alt="ratecard"/>&nbsp;&nbsp;{{ item?.networkDefaultQuantity }}
                  </span>
                </span>
              </span>
              <span class="right">
                <img src="../../../../../../../images/sub_black_delete.png" (click)="removeSelectedNetwork(item)"
                  height="10px" width="10px" class="right" alt="sub_black_delete" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div footer class="right bottom-right" [ngStyle]="{'width': pcmMode ? '20%' : ''}">
    <!-- <span class="customtooltip tooltip-overite"> {{InitialConfig.userBundle['workspace.collection.networks.tooltip']}}</span> -->
    <ng-container *ngIf="uiControl.networkBricAvailabilityControls && !pcmMode">
      <div class="control_group hide-network-switch" style="margin: 0px 0px;">
        <label style="width: fit-content;">{{userBundle['workspace.collection.networks.hideUnavailable'] ?
          userBundle['workspace.collection.networks.hideUnavailable'] : 'Hide Unavailable' }} : </label>
        <label class="switchAllowFrames">
          <input type="checkbox" id="networkAvailabilityFilter" name="networkAvailabilityFilter"
            [disabled]="!user.workspaceTabAccess.editNetworkAvailabilityFilter" [(ngModel)]="networkAvailabilityFilter">
          <span class="sliderAllowFrames round"></span>
        </label>
      </div>
      <div class="control_group hide-network-switch" style="margin: 0px 0px;">
        <label style="width: fit-content;">{{userBundle['workspace.collection.networks.hideUnmatched'] ?
          userBundle['workspace.collection.networks.hideUnmatched'] : 'Hide Unmatched' }} : </label>
        <label class="switchAllowFrames">
          <input type="checkbox" id="networkUnmatchedFilter" name="networkUnmatchedFilter"
            [disabled]="!user.workspaceTabAccess.editNetworkUnmatchedFilter" [(ngModel)]="networkUnmatchedFilter">
          <span class="sliderAllowFrames round"></span>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="resolveObject.brick?.optionStack && !(isRateCard && !uiControl.openRateCardsEnabled)">
      <div>
        <input id="checkAll" type="checkbox" name="checkAll" (change)="toggleSelection()" [(ngModel)]="checkAll" [disabled]="checkAllDisabled">
        <label for="checkAll" class="label-checkAll" *ngIf="!checkAll">
          {{userBundle['workspace.collection.networks.checkAll']}}
        </label>
        <label for="checkAll" class="label-checkAll" *ngIf="checkAll">
          {{userBundle['workspace.collection.networks.uncheckAll']}}
        </label>
      </div>
      <div>
        <input type="checkbox" id="isSelectedOptStack" name="isSelectedOptStack" [(ngModel)]="isSelectedOptStack"
          [disabled]="checkAllDisabled">
        <label for="isSelectedOptStack" class="label-optStack">
          <span class="fa fa-circle-thin" style="font-size: 16px;"></span>
          <span class="fa fa-circle-thin" style="font-size: 16px;margin-left: -5px"></span>
        </label>
      </div>
    </ng-container>
  </div>
</app-sb-modal-wrapper>
