<app-sb-modal-wrapper [titleBackgroundColor]="titleColor" class="reshuffle_modal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)" saveButton="{{userBundle['reshuffle.text.launch'] || 'Launch Allocation'}}">
  <div style="height: 200px; display: flex;" *ngIf="(uiControl.reshuffleScopingEnabled || uiControl.reshuffleScopingForObjectiveWorkspaceEnabled) && isReshuffleScopeAvailable">
    <div class="child control_group search_control_group exclude-dropdown">
      <select id="excludeList" class="form_control chzn-container" [(ngModel)]="exclusionValue">
        <option value="" [disabled]="true" selected>{{dummySelectOption.name}}</option>
        <option *ngFor="let item of excludeOptions;" [value]="item.id">
          {{item.name}}
        </option>
      </select>
    </div>
    <div *ngFor="let lookup of lookups.lookup; trackBy: trackBySelectionId; let i = index" class="child" style="width: auto">
      <div id="tab-{{i}}" class="tab-content reshuffle-container" style="display: flex;">
        <app-lookup-view [lookupType]="lookup.lookupType" [selectionId]="lookup.selectionId"
          [isObjectLookup]="true" [reshuffleLookupData]="lookup.lookupData"
          [(ngModel)]="selected[lookup.selectionId]" [placeholder]="lookup.displayName" [selectAll]="lookup.selectAll"
          [regexp]="lookup.regExp" [displaycode]="lookup.displayCode" [isRelativeFilterVisible]="false">
        </app-lookup-view>
      </div>
    </div>
  </div>
  <span *ngIf="resolveObject.message" class="forceReRunMsg alert alert-danger header_warning"
  style="margin-left: 10px;">{{resolveObject.message}}</span>
  <div class="tabs_container grid-container">
    <div class="tab">
      <ag-grid-angular style="width: 100%; margin-bottom: 15px;" [enableBrowserTooltips]="true" class="ag-theme-fresh reshuffle-detail" [gridOptions]="campaignGridOptions" [rowData]="rowData" [columnDefs]="campaignColumnDefs" [suppressDragLeaveHidesColumns]="true">
      </ag-grid-angular>
      <div class="col_select_menu">
          <div ngbDropdown class="d-inline-block" placement="left" [autoClose]="false" #toggleColumnContainer>
            <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              <em class="fa fa-bars"></em>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop_container">
                <div class="checkbox clearBoth" (click)="downloadExcel()">
                    <label class="center-block" style="cursor: pointer">Excel Export</label>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
    <div *ngIf="this.resolveObject.selectedProducts" footer style="float: right;">
        <ng-container>
          <div class="control_group" style="margin: 0px 0px;">
            <label class="labelDisplayHideBooking">{{userBundle['workspace.collection.reshuffle.displayHideHiddenBooking'] ?
              userBundle['workspace.reshuffle.displayHideHiddenBooking'] : 'HIDE HIDDEN BOOKINGS' }} : </label>
            <label class="switchAllowFrames">
              <input type="checkbox" id="displayHideHiddenBooking" name="displayHideHiddenBooking"
              [(ngModel)]="hideBookings" (change)="onHideBookings()">
              <span class="sliderAllowFrames round"></span>
            </label>
          </div>
        </ng-container>
      </div>
      <span additionalFooter>
        <input type="file" (change)="onFileChange($event)" name="file-upload-reshuffle" id="reshuffle-file-upload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <label for="reshuffle-file-upload" class="btn btn-primary">Upload a File</label>
        <span style="font-size: 18px; margin-left: 5px;"> {{ selectedFileName }}
          <a *ngIf="selectedFileName" class="file-remove" (click)="clearSelectedFile()">
            <img [src]="'images/sub_black_delete.png'" height="10px" width="10px"  alt="delete"/>
          </a>
        </span>
      </span>
</app-sb-modal-wrapper>
