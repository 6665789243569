<app-summary-bar (filterAreaToggle)="toggleFilterArea()"></app-summary-bar>

<app-filter-bar [isFilterAreaVisible]="isFilterAreaVisible" [initialConfig]="initialConfig" (filterBarToggle)="onFilterBarToggle($event)"></app-filter-bar>

<aside class="sidebar-left_geo" aria-label="filterarea">
    <app-filter-area [initialConfig]="initialConfig" (loadBrickDetail)="loadBrickDetailComponent($event)" (hideFilterArea)="toggleFilterArea()"></app-filter-area>
</aside>

<aside class="second-level-sidebar_geo sidebar-left_geo" aria-label="detail-sidebar" [ngClass]="{'large-sidebar_geo':isLargeDetailSidebar}" [ngStyle]="{'left': secondSliderLeft}">
    <ng-template appBricHost></ng-template>
</aside>

<section class="content-wrapper_geo" [ngStyle]="{'padding-top': isFilterBarExpanded? '114px':'80px'}">
    <app-geo-map [initialConfig]="initialConfig" [isFilterAreaVisible]="isFilterAreaVisible" [isFilterBarExpanded]="isFilterBarExpanded" (goToResultPage)="redirectToResultPage()"></app-geo-map>
</section>