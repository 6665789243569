import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { RequestJsonService } from './request-json.service';
import { DataShareService } from './../core/services/data-share.service';
import { BrickBaseService } from '../core/services/brick-base.service';
import { FrameStatus, FurnitureStatus, FrameFilterType } from './status.enum';
import { LogHelperService } from '../core/services/log-helper.service';
import { AppNameEnum } from '../core/enum';
import {
  ResponseModel, MapDataModel, FurnitureModel,
  LookupCampaignResponseModel, GetCampaignResponseModel,
  MapDataObservableModel, VipProcessFilterModel, FrameModel
} from '../models/index';
import { getFormData } from '../core/utils/formData';
import { GLOBAL } from '../core/utils/app.constant';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class GeoMapService {

  /**
   * Request JSON Used to get result data
   * @private
   * @type {*}@memberof GeoMapService
   */
  private requestJSON: any = [];
  /**
   * map data object
   * @private
   * @type {FurnitureModel[]}@memberof GeoMapService
   */
  private mapData: FurnitureModel[] = [];

  /**
   * map data source observable subject
   * @public
   * @memberof GeoMapService
   */
  public mapDataSource: BehaviorSubject<MapDataObservableModel> =
    new BehaviorSubject({ mapData: this.mapData, requestJSON: this.requestJSON, isViewCartOnMap: false, requestFrameTimings: [] });

  /**
   * map data observable object
   * @memberof GeoMapService
   */
  public mapData$: Observable<MapDataObservableModel> = this.mapDataSource.asObservable();

  /**
   * request json data subscriber
   * @type {Subscription}@memberof GeoMapService
   */
  requestJsonDataSubscriber: Subscription;

  selectedFrameFilterType: FrameFilterType = FrameFilterType.all;
  /**
   * frame filter source observable subject
   * @private
   * @memberof GeoMapService
   */
  private selectedFrameFilterTypeSource: BehaviorSubject<FrameFilterType> = new BehaviorSubject(this.selectedFrameFilterType);
  /**
   * frame filter summary observable
   * @memberof GeoMapService
   */
  public selectedFrameFilterType$: Observable<FrameFilterType> = this.selectedFrameFilterTypeSource.asObservable();

  /**
   * @description This would be used for process filter call for VP
   * @memberof GeoMapService
   */
  public concertinaRequest;

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  /**
   * @description This would be used to hide advertiser ID
   * @memberof GeoMapService
   */
  public concertinaHideAdvertiserId;

  private processRequest = {
    isValidRequestJsonGm: false,
    isProcessedGm: false,
    isValidRequestJsonVp: false,
    isProcessedVp: false,
    isAllowProcessGm: true,
    isAllowProcessVp: true
  };

  /**
  * process request source observable subject
  * @public
  * @memberof GeoMapService
  */
  public processRequestSource: BehaviorSubject<any> =
    new BehaviorSubject(this.processRequest);
  /**
   * process request observable object
   * @memberof GeoMapService
   */
  public processRequest$: Observable<any> = this.processRequestSource.asObservable();

  /**
   * to toggle marker labels long or short as per SM-10108
   */
  public shortenMarker: boolean = true;

  constructor(private http: HttpClient,
              private requestJsonService: RequestJsonService,
              private dataShareService: DataShareService,
              private brickBaseService: BrickBaseService,
              private logHelperService: LogHelperService) {
    this.requestJsonDataSubscriber = this.requestJsonService.requestJson$
      .subscribe(this.requestJSONSubscribe);
  }

  /**
   * request json data subscription method
   * @memberof GeoMapService
   */
  requestJSONSubscribe = (data) => {
    if (this.validateRequstJSON(data)) {
      if ((this.dataShareService.appName === AppNameEnum.geomapper && !this.processRequest.isAllowProcessGm) ||
      (this.dataShareService.appName === AppNameEnum.visualplanner && !this.processRequest.isAllowProcessVp)) {
        this.getLookupData(data).subscribe((mapData: MapDataModel & VipProcessFilterModel) => {
          this.dataShareService.setColumnConfig(mapData);
        }, (errorMsg) => {
          this.requestJSON = [];
          this.dataShareService.setColumnConfig(null);
          this.logHelperService.logError(errorMsg);
        });
      } else {
        this.getMapData(data).subscribe((mapData: MapDataModel & VipProcessFilterModel) => {
          if (this.dataShareService.appName === AppNameEnum.geomapper) {
            mapData.assetData.forEach((element: FurnitureModel) => {
              element.frames.sort((a: FrameModel, b: FrameModel) => {
                const x = String(a.frameName).toLowerCase();
                const y = String(b.frameName).toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
              });
            });
          }
          this.dataShareService.setColumnConfig(mapData.columnConfig);
          this.mapData = mapData.assetData;
          let planning: VipProcessFilterModel;
          if (this.dataShareService.appName === AppNameEnum.visualplanner) {
            planning = mapData;
          }
          this.requestJSON = data;
          this.mapDataSource.next({
            planning,
            mapData: this.mapData,
            requestJSON: this.requestJSON,
            requestFrameTimings: mapData.requestFrameTimings,
            isViewCartOnMap: false,
            indoorMaps: mapData.indoorMaps,
          });
        }, (errorMsg) => {
          this.mapData = [];
          this.requestJSON = [];
          this.mapDataSource.next({
            mapData: this.mapData,
            requestJSON: this.requestJSON,
            isViewCartOnMap: false,
            requestFrameTimings: []
          });
          this.dataShareService.setColumnConfig(null);
          this.logHelperService.logError(errorMsg);
        });
      }
    } else {
      this.mapData = [];
      this.requestJSON = [];
      this.mapDataSource.next({
        mapData: this.mapData,
        requestJSON: this.requestJSON,
        isViewCartOnMap: false,
        requestFrameTimings: []
      });
      this.dataShareService.setColumnConfig(null);
    }
  }

  /**
   * validate request Json data
   * @param {any} requestJSON
   * @returns
   * @memberof GeoMapService
   */
  validateRequstJSON(requestJSON): boolean {
    let validRequest = true;
    if (this.checkIfRequiredBricksAreMissing(requestJSON)) {
      validRequest = false;
    }
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      this.processRequest.isValidRequestJsonVp = validRequest;
    } else {
      this.processRequest.isValidRequestJsonGm = validRequest;
    }
    this.processRequestSource.next(this.processRequest);
    return validRequest;
  }

  /**
   * check whether required bric data availble in request json
   * @param {any} allItems
   * @returns
   * @memberof GeoMapService
   */
  checkIfRequiredBricksAreMissing(allItems) {
    let brickMissing = true;
    const initialConfig = this.dataShareService.getInitialConfig();
    if (!initialConfig) {
      return brickMissing;
    }
    const requiredBricks = initialConfig.uiControl.processingBrics;
    /* tslint:disable:prefer-for-of */
    for (let i = 0; i < requiredBricks.length; i++) {
      const selectionCriteriaText = this.brickBaseService.brickReqJsonText[requiredBricks[i]];
      let isExist = false;
      for (const item of allItems) {
        if (item[selectionCriteriaText]) {
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        brickMissing = true;
        break;
      }
      if (i === requiredBricks.length - 1) {
        brickMissing = false;
      }
    }
    return brickMissing;
  }

  /**
   * http call to get map data
   * @returns {Observable<any[]>}
   * @memberof GeoMapService
   */
  getMapData(reqJsonData): Observable<MapDataModel> {
    let mapDataURL: string = this.dataShareService.getServiceCallUrlByKey('GM_FILTER');
    let actionText = 'processGMFilter';
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      mapDataURL = this.dataShareService.getServiceCallUrlByKey('VP_FILTER');
      actionText = 'processVipFilter';
      this.processRequest.isProcessedVp = true;
      this.processRequestSource.next(this.processRequest);
    } else {
      this.processRequest.isProcessedGm = true;
      this.processRequestSource.next(this.processRequest);
    }
    const param = {
      action: actionText,
      data: {},
    };
    /* tslint:disable:no-string-literal */
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      const initialConfig = this.dataShareService.getInitialConfig();
      const request = { grouping: this.concertinaRequest };
      if (initialConfig.uiControl.hideShowAdvertiserIdEnabled) {
        request['hideAdvertiserId'] = this.concertinaHideAdvertiserId;
      }
      param['concertinaRequest'] = JSON.stringify(request);
    }

    param.data['bricsData'] = reqJsonData;
    param.data = JSON.stringify(param.data);

    const requestOptions = {
      headers: this.headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      mapDataURL,
      requestOptions
    )
      .map((response: ResponseModel<MapDataModel>) => {
        if (response.status === 'OK') {
          return response.data;
        } else if (response.status === 'KO') {
          this.logHelperService.logError(response.message);
          return {
            columnConfig: null,
            assetData: [],
            requestFrameTimings: [],
            indoorMaps: []
          };
        }
      })
      .catch(this.handleError);
  }

  /**
   * http call to get map data
   * @returns {Observable<any[]>}
   * @memberof GeoMapService
   */
   getLookupData(reqJsonData): Observable<MapDataModel> {
    let mapDataURL: string = this.dataShareService.getServiceCallUrlByKey('GET_BASKET');
    let actionText = 'getColumnConfigLookup';
    const param = {
      action: actionText,
      data: {},
    };
    /* tslint:disable:no-string-literal */
    if (this.dataShareService.appName === AppNameEnum.visualplanner) {
      const initialConfig = this.dataShareService.getInitialConfig();
      const request = { grouping: this.concertinaRequest };
      if (initialConfig.uiControl.hideShowAdvertiserIdEnabled) {
        request['hideAdvertiserId'] = this.concertinaHideAdvertiserId;
      }
      param['concertinaRequest'] = JSON.stringify(request);
    }

    param.data['bricsData'] = reqJsonData;
    param.data = JSON.stringify(param.data);

    const requestOptions = {
      headers: this.headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      mapDataURL,
      requestOptions
    )
      .map((response: ResponseModel<MapDataModel>) => {
        if (response.status === 'OK') {
          return response.data;
        } else if (response.status === 'KO') {
          this.logHelperService.logError(response.message);
          return {
            columnConfig: null,
            assetData: [],
            requestFrameTimings: [],
            indoorMaps: []
          };
        }
      })
      .catch(this.handleError);
  }

  setCartDataForMap(mapData: FurnitureModel[], indoorMaps: any[]) {
    this.mapDataSource.next({
      mapData,
      indoorMaps,
      requestJSON: [],
      isViewCartOnMap: true,
      requestFrameTimings: []
    });
  }
  /**
   * error handle
   * @private
   * @param {(Response | any)} error
   * @returns
   * @memberof GeoMapService
   */
  private handleError(error: Response | any) {
    console.error(error.message || error);
    return _throw(error.message || error);
  }

  /**
   * Get Furniture Availability Status
   * @param {FurnitureModel} furniture - Furniture object
   * @returns {FurnitureStatus} - status of furniture
   * @memberof GeoMapService
   */
  getFurnitureAvailabilityStatus(furniture: FurnitureModel): FurnitureStatus {
    let allAvailable = true;
    let allUnAvailable = true;
    furniture.frames.forEach((frame) => {
      if (frame.status === FrameStatus.available) {
        allUnAvailable = false;
      } else if (frame.status === FrameStatus.unavailable) {
        allAvailable = false;
      }
    });

    let furnitureStatus: FurnitureStatus;
    if (allAvailable) {
      furnitureStatus = FurnitureStatus.available;
    } else if (allUnAvailable) {
      furnitureStatus = FurnitureStatus.unavailable;
    } else {
      furnitureStatus = FurnitureStatus.partialAvailable;
    }

    return furnitureStatus;
  }

  /**
   * is furniture locked, all frames all either unavailable or locked
   * @param {FurnitureModel} furniture - Furniture object
   * @returns {boolean} - is locked
   * @memberof GeoMapService
   */
  isFurnitureLocked(furniture: FurnitureModel, isViewCartOnMap: boolean): boolean {
    let isFurnitureLocked = true;
    furniture.frames.forEach((frame) => {
      if (isViewCartOnMap) {
        if (frame.isDeletable) {
          isFurnitureLocked = false;
        }
      } else {
        if (frame.status === FrameStatus.available && frame.isDeletable) {
          isFurnitureLocked = false;
        }
      }
    });

    return isFurnitureLocked;
  }

  setSelectedFrameFilter(frameFilter: FrameFilterType): void {
    this.selectedFrameFilterType = frameFilter;
    this.selectedFrameFilterTypeSource.next(this.selectedFrameFilterType);
  }

  getSelectedFrameFilter(): FrameFilterType {
    return this.selectedFrameFilterType;
  }

  getSearchedCampaignList(searchText: string): Observable<LookupCampaignResponseModel> {
    const actionText = 'lookupCampaign';

    const param = {
      bricsCampaignId: GLOBAL.BRIC_CAMPAIGN_ID,
      action: actionText,
      searchString: searchText
    };

    const requestOptions = {
      headers: this.headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      this.dataShareService.getServiceCallUrlByKey('LOOKUP_CAMPAIGN'),
      requestOptions
    )
      .map((response: ResponseModel<LookupCampaignResponseModel>) => this.callBackforgetSearchedCampaign(response))
      .catch(this.handleError);
  }

  loadSelectedCampaign(bricsCampaignId: number): Observable<GetCampaignResponseModel> {
    const actionText = 'getCampaign';

    const param = {
      campaignId: bricsCampaignId,
      action: actionText
    };

    const requestOptions = {
      headers: this.headers,
      body: getFormData(param),
    };
    return this.http.request(
      GLOBAL.HTTP_METHOD,
      this.dataShareService.getServiceCallUrlByKey('GM_GET_CAMPAIGN'),
      requestOptions
    )
      .map((response: ResponseModel<GetCampaignResponseModel>) => this.callBackforgetSearchedCampaign(response))
      .catch(this.handleError);
  }

  callBackforgetSearchedCampaign(response) {
    if (response.status === 'OK') {
      return response.data;
    } else if (response.status === 'KO') {
      this.logHelperService.logError(response.message);
      // TODO : Changed from {} to new LookupCampaignResponseModel()
      return {} as any;
    }
  }

  getIndoorMapInsideBound(mapBounds: string): Observable<any[]> {
    const param = {
      bricsCampaignId: window['BRIC_CAMPAIGN_ID'],
      action: 'getIndoorMapInsideBound',
      data: JSON.stringify({
        bounds: mapBounds
      })
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const requestOptions = {
      headers,
      body: getFormData(param),

    };
    return this.http.request(
      window['HTTP_METHOD'],
      window['GET_INDOOR_MAP_INSIDE_BOUND'],
      requestOptions
    )
      .map((response: ResponseModel<any[]>) => {
        if (response.status === 'OK') {
          return response['data']['indoorMaps'] as any[];
        } else if (response.status === 'KO') {
          this.logHelperService.logError(response.message);
          return [];
        }
      })
      .catch(this.handleError);
  }

  allowProcessVp(execute: boolean) {
    this.processRequest.isAllowProcessVp = execute;
  }

  allowProcessGm(execute: boolean) {
    this.processRequest.isAllowProcessGm = execute;
  }

  resetProcessRequest() {
    this.processRequest.isValidRequestJsonGm = false;
    this.processRequest.isProcessedGm = false;
    this.processRequest.isValidRequestJsonVp = false;
    this.processRequest.isProcessedVp = false;
  }
}
