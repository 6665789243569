import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuditModel } from '../../geoplanner/src/app/models/auditModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppHeaderService {
  enabledPCM = false;
  private enabledPCMSource: BehaviorSubject<boolean> = new BehaviorSubject(this.enabledPCM);
  public enabledPCM$: Observable<boolean> = this.enabledPCMSource.asObservable();

  objectiveMode = false;
  private objectiveModeSource: BehaviorSubject<boolean> = new BehaviorSubject(this.objectiveMode);
  public objectiveMode$: Observable<boolean> = this.objectiveModeSource.asObservable();
  public backToWorkspace: Subject<boolean> = new Subject();

  lockedMessage: string = null;
  forceRerunMessage: string = null;
  forceRerun: boolean = false;
  private lockedMessageSource: BehaviorSubject<string> = new BehaviorSubject(this.lockedMessage);
  public lockedMessage$: Observable<string> = this.lockedMessageSource.asObservable();

  private forceRerunMessageSource: BehaviorSubject<string> = new BehaviorSubject(this.forceRerunMessage);
  public forceRerunMessage$: Observable<string> = this.forceRerunMessageSource.asObservable();

  private forceRerunSource: BehaviorSubject<boolean> = new BehaviorSubject(this.forceRerun);
  public forceRerun$: Observable<boolean> = this.forceRerunSource.asObservable();

  selectedEnvironment = '';
  private selectedEnvironmentSource: BehaviorSubject<string> = new BehaviorSubject(this.selectedEnvironment);
  public selectedEnvironment$: Observable<string> = this.selectedEnvironmentSource.asObservable();

  environmentList = [];
  private environmentListSource: BehaviorSubject<string[]> = new BehaviorSubject(this.environmentList);
  public environmentList$: Observable<string[]> = this.environmentListSource.asObservable();

  infoMessage: string = null;
  private infoMessageSource: BehaviorSubject<string> = new BehaviorSubject(this.infoMessage);
  public infoMessage$: Observable<string> = this.infoMessageSource.asObservable();

  accessToken: string = null;
  private accessTokenSource: BehaviorSubject<string> = new BehaviorSubject(this.accessToken);
  public accessToken$: Observable<string> = this.accessTokenSource.asObservable();

  /**
   * @description Observable to get initial configs from backend
   * @type {string}
   * @memberof AppHeaderService
   */
  resetConfig: ResetConfig = new ResetConfig();
  private resetConfigSource: BehaviorSubject<ResetConfig> = new BehaviorSubject(this.resetConfig);
  public resetConfig$: Observable<ResetConfig> = this.resetConfigSource.asObservable();

  /**
   * @description Observable to get audit records
   * @type {string}
   * @memberof AppHeaderService
   */
  auditRecords: AuditModel[] = [];
  private auditRecordsSource: BehaviorSubject<AuditModel[]> = new BehaviorSubject(this.auditRecords);
  public auditRecords$: Observable<AuditModel[]> = this.auditRecordsSource.asObservable();
  constructor(
    private http: HttpClient

  ) { }

  /**
   * PCM Mode change
   * @memberof AppHeaderService
   */
  changePCMMode(value: boolean): void {
    this.enabledPCM = value;
    this.enabledPCMSource.next(this.enabledPCM);
  }

  changeObjectiveMode(value: boolean): void {
    this.objectiveMode = value;
    this.objectiveModeSource.next(this.objectiveMode);
  }

  /**
   * change lock message - display in header
   * @memberof AppHeaderService
   */
  changeLockedMessage(value: string): void {
    this.lockedMessage = value;
    this.lockedMessageSource.next(this.lockedMessage);
  }

  changeforceRerunMessage(value: string): void {
    this.forceRerunMessage = value;
    this.forceRerunMessageSource.next(this.forceRerunMessage);
  }

  changeforceRerunStatus(value: boolean): void {
    this.forceRerun = value;
    this.forceRerunSource.next(this.forceRerun);
  }

  /**
   * change info message - display in header
   * @memberof AppHeaderService
   */
  changeInfoMessage(value: string): void {
    this.infoMessage = value;
    this.infoMessageSource.next(this.infoMessage);
  }

  changeAuthingAccessToken(value: string): void {
    this.accessToken = value;
    this.accessTokenSource.next(this.accessToken);
  }

  /**
   * change info message - display in header
   * @memberof AppHeaderService
   */
  changeResetConfig(value: ResetConfig): void {
    this.resetConfig = value;
    this.resetConfigSource.next(this.resetConfig);
  }

  /**
   * change Audit records - display in header
   * @memberof AppHeaderService
   */
  setAuditRecords(value: AuditModel[]): void {
    this.auditRecords = value;
    this.auditRecordsSource.next(this.auditRecords);
  }

  /**
   * @description detects environment change
   * @author Amit Mahida
   * @param {string} value
   * @memberof AppHeaderService
   */
  changeEnvironment(value: string, list: string[] = []): void {
    this.selectedEnvironment = value;
    this.selectedEnvironmentSource.next(this.selectedEnvironment);
    this.environmentList = list;
    this.environmentListSource.next(this.environmentList);
  }

  getCookie(cname: string) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  setCookie(name: string, value: string, expireDays: number, domain = '', path = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}; ${cpath}; domain=${domain}`;
  }

  getLandingConfig(configURL) {
    const requestOptions = {
      headers: new HttpHeaders({
        'noAuth': 'true'
      }),
    };
    return this.http.request(
      'GET',
      `${environment.landingAppUrl}/${configURL}?timestamp=${Date.now()}`,
      requestOptions
    );
  }
}

class ResetConfig {
  getConfig = false;
  getBricStructure = false;
}
