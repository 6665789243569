export class FrameDetails {
  familyName = '';
  subFamilyName = '';
  frameTypeName = '';
  illuminated = '';
  frameSizeFamilyName = '';
  furnitureName = '';
  orientationName = '';
  address = '';
}
