import { DataShareService } from '../../core/services/data-share.service';
import { SharedService } from '../../core/services/shared.service';
import { BrickBaseService } from '../../core/services/brick-base.service';
import { FormatBricSelectedDataModel, InitialConfigModel, SelectionItemModel, LookupModel } from '../../models';
import { AppHeaderService } from '../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../workspace/objective/objective.service';
import { StateService } from '../../core/services/state.service';

export class FormatBase {
  /**
   * @description It contians lookup data.
   * @type {LookupModel}
   * @memberof FormatBase
   */
  public lookups: LookupModel = { lookup: [], brickId: -1 };
  /**
   * @description It contains selected filter data.
   * @type {FormatBricSelectedDataModel}
   * @memberof FormatBase
   */
  public selected: FormatBricSelectedDataModel = {};
  /**
   * @description It contians initial config data.
   * @type {InitialConfigModel}
   * @memberof FormatBase
   */
  public initialConfig: InitialConfigModel;
  /**
   * @description It contians index of selected item.
   * @type {number}
   * @memberof FormatBase
   */
  public selectedItem: number;
  /**
   * @description It contians config lookup data.
   * @type {any[]}
   * @memberof FormatBase
   */
  public configLookup: any[] = [];

  /**
   * @description It contains search label string.
   * @type {string}
   * @memberof FormatBase
   */
  public searchForText = 'Search for';
  /**
   * @description It contains search title text.
   * @type {string}
   * @memberof FormatBase
   */
  public searchTitle: string;

  /**
   * Creates an instance of FormatBase.
   * @author Alkesh Shah
   * @param {DataShareService} dataShareService
   * @param {SharedService} sharedService
   * @param {BrickBaseService} brickBaseService
   * @memberof FormatBase
   */
  constructor(
    private dataShareService: DataShareService,
    private sharedService: SharedService,
    public brickBaseService: BrickBaseService,
    public appHeaderService: AppHeaderService,
    public objectiveService: ObjectiveService,
    public stateService: StateService
  ) {

    this.initialConfig = this.dataShareService.getInitialConfig();
  }

  /**
   * @description initialize the data of instance
   * @author Alkesh Shah
   * @param {*} columnConfig - column configuration data
   * @param {*} SelectedValue - selected value of cell
   * @memberof FormatBase
   */
  init(columnConfig, selectedValue) {
    const selectionIdsOfFormat =
      this.dataShareService.getSelectionIdsForCell(this.initialConfig.lookupColumn, this.brickBaseService.brickID.Format);
    this.configLookup = columnConfig ? columnConfig.lookupData : null;
    this.lookups = this.sharedService.getLookupColumnData(this.brickBaseService.brickID.Format, columnConfig);
    this.selected = {};
    const tempSelectedValue = Object.keys(selectedValue).length > 0 ? selectedValue : null;
    if (tempSelectedValue === null) {
      const lookup = this.lookups.lookup;
      for (const l of lookup) {
        this.selected[l.selectionId] = [];
      }

      this.selected.selectionCriteriaRestriction = {
        1: false, // Dominate Furniture
        2: false, // Back to Back Panels
        3: false // parallel panels
      };
    } else {
      this.selected = this.sharedService.compareSelectedValuesWithColumnConfig(selectionIdsOfFormat, this.configLookup, tempSelectedValue);
      if (tempSelectedValue.selectionCriteriaRestriction) {
        this.selected.selectionCriteriaRestriction = {
          1: !!tempSelectedValue.selectionCriteriaRestriction['1'], // Dominate Furniture
          2: !!tempSelectedValue.selectionCriteriaRestriction['2'], // Back to Back Panels
          3: !!tempSelectedValue.selectionCriteriaRestriction['3'] // parallel panels
        };
      }
    }

    if (this.appHeaderService.objectiveMode && Object.keys(this.selected).filter(o => (this.selected[o] as SelectionItemModel[]).length > 0).length === 0) {
      const result = this.objectiveService.getSelectedForCollectionBrick(this.lookups, this.brickBaseService.brickID.Format as number);
      if (Object.keys(result.selected).length) {
        this.selected = result.selected;
      }
      this.lookups = result.lookups;
    }

    this.showContent(0);
  }

  /**
   * @description To display content of particular selection.
   * @author Alkesh Shah
   * @param {number} index Index of selected item.
   * @memberof FormatBase
   */
  showContent(index: number): void {
    this.selectedItem = index;
    this.searchForText = this.initialConfig.userBundle['geoplanner.text.searchFor'] || 'Search for';
    if (this.lookups.lookup.length > index) {
      this.searchTitle = this.lookups.lookup[index].displayName;
    }
  }

  /**
   * @description hide element on UI or not
   * @author Alkesh Shah
   * @param {string} key - element key to check
   * @returns {boolean} - true if hide else false
   * @memberof FormatBase
   */
  hideUIElements(key: string): boolean {
    return this.sharedService.hideUIElements(key);
  }

  /**
   * @description To hightlight lable which contains selected data.
   * @author Alkesh Shah
   * @param {number} selectionId Id for particular display data.
   * @returns {boolean} It will return true if contains data else false.
   * @memberof FormatBase
   */
  isDataAvailable(selectionId: number): boolean {
    let isDataAvail = false;
    if (this.selected[selectionId] && (this.selected[selectionId] as SelectionItemModel[]).length > 0) {
      isDataAvail = true;
    }
    return isDataAvail;
  }

  /**
   * @description validate the selections
   * @author Alkesh Shah
   * @returns {boolean} - valid selection or not
   * @memberof FormatBase
   */
  isValidSelection(): boolean {
    let isValid = false;
    const keys = Object.keys(this.selected);
    for (const key of keys) {
      if ((this.selected[key] as SelectionItemModel[]).length > 0) {
        isValid = true;
        break;
      }
    }
    return isValid;
  }

  trackBySelectionId(index, lookup) {
    return lookup?.selectionId;
  }
}
