import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NguiMapModule } from '@ngui/map';
import { CoreModule } from './../core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { TagInputModule } from 'ngx-chips';

import { AgGridModule } from 'ag-grid-angular';
import { AngularDraggableModule } from 'angular2-draggable';

import { GeoMapComponent } from './geo-map.component';
import { AgFurnitureComponent } from './ag-furniture/ag-furniture.component';
import { AgFrameComponent } from './ag-frame/ag-frame.component';
import { AgCartListItemComponent } from './ag-cart-list-item/ag-cart-list-item.component';
import { GeoMapService } from './geo-map.service';
import { RequestJsonService } from './request-json.service';
import { ProposalComponent } from './proposal/proposal.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CartDetailComponent } from './cart-detail/cart-detail.component';
import { CartDetailService } from './cart-detail/cart-detail.service';
import { CartService } from './cart.service';
import { AgCalendarCellEditorComponent } from './ag-calendar-cell-editor/ag-calendar-cell-editor.component';
import { AgTextCellRendererComponent } from './ag-text-cell-renderer/ag-text-cell-renderer.component';
import { AgCheckboxComponent } from './ag-checkbox/ag-checkbox.component';
import { FolioImageComponent } from './folio-image/folio-image.component';
import { FolioImageService } from './folio-image/folio-image.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { SecurePipe } from '../core/pipes/secure-image.pipe';
import { UrlHelperService } from '../core/services/url-helper-service';
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  imports: [
    UiSwitchModule,
    CommonModule,
    CoreModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({      
      libraries: ['drawing']
    }),
    AgmSnazzyInfoWindowModule,    
    AgmMarkerClustererModule,
    NguiMapModule.forRoot(
      { apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyAI0SnbzKtFVj8rNTufKNtkH23kCOtVJkU&libraries=drawing,places' }
    ),
    ColorPickerModule,
    AngularDraggableModule,
    NgbModule,
    TagInputModule,
    NgSelectModule,
    AgGridModule.withComponents([AgFurnitureComponent, AgFrameComponent, AgCartListItemComponent,
      AgCalendarCellEditorComponent, AgCheckboxComponent, AgTextCellRendererComponent]),
      NgxSliderModule
  ],
  declarations: [
    SecurePipe,
    GeoMapComponent,
    ProposalComponent,
    ConfirmationComponent,
    CartDetailComponent,
    AgFurnitureComponent,
    AgFrameComponent,
    AgCartListItemComponent,
    AgCalendarCellEditorComponent,
    AgTextCellRendererComponent,
    AgCheckboxComponent,
    FolioImageComponent
  ],
  // entryComponents: [
  //   GeoMapComponent,
  //   ConfirmationComponent,
  //   ProposalComponent,
  //   CartDetailComponent,
  //   FolioImageComponent
  // ],
  exports: [
    GeoMapComponent,
    CartDetailComponent,
    NgxSliderModule
  ],
  providers: [
    GeoMapService,
    RequestJsonService,
    DatePipe,
    CartService,
    FolioImageService,
    CartDetailService,
    UrlHelperService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeoMapModule { }
