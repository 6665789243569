/**
 * Application Name
 * 0: Geo Mapper
 * 1: Visual Planner
 * @export
 * @enum {number}
 */
export enum AppNameEnum {
    geomapper = 0,
    visualplanner = 1,
    result = 2,
    workspace = 3,
    search = 4,
    commercial = 5
}

export enum CampaignStatus {
    search = -1,
    draft = 1,
    option = 2,
    proposal = 3,
    confirm = 4,
    cancel = 5,
    optionStack = 6,
    requestOption = 7,
    requestBooking = 8,
    stornowunsch = 9,
    flexAvailable = 10,
    flexOffered = 11
}

export enum ThirdParty {
    specialist = 2,
    agency = 3,
    subAgency = 8,
    internalAdvertiser = 5
}

export enum EnvironmentId {
    UK = 1,
    UK2 = 1001,
    ES = 2,
    Belgium = 11,
    Norway = 15,
    FI = 16,
    Germany = 18,
    NL = 25,
    Denmark = 26,
    Italy = 28,
    AE = 31,
    US = 50,
    AU = 70,
    HKAI = 71,
    HKM = 1071,
    HKCTS = 2071,
    CN = 72,
    CNGA = 1072,
    SG = 74,
    BR = 60,
    CNSHA = 2072,
    AT = 13,
    PT = 20
}

export enum CampaignType {
    standard = 0,
    fixed = 1,
    flexible = 2
}

export enum CampaignGroupTypeList {
  Default = 0,
  PMP = 1
}

/**
 * @description Used in discount grid brazil
 * @export
 * @enum {number}
 */
export enum NegotiationTypes  {
    Paga = 1,
    Permuta = 2,
    Bonificada = 3
}

/**
 * @description For Proximity
 * @export
 * @enum {string}
 */
export enum DistanceUnit  {
    Meters = 'M',
    KiloMeters = 'KM',
    Miles = 'MI'
}

export enum ProximityTypeSelection {
    PostCode = 0,
    POI = 1,
    Points = 2
}
