<app-sb-modal-wrapper [title]="userBundle['workspace.budget']" [titleBackgroundColor]="resolveObject.brick.color"
    [readOnlyModal]="readOnlyModal" (closed)="onModalClosed($event)" (saved)="onModalSaved($event)">
    <div class="modal_content" [ngClass]="{'readOnly' : readOnlyModal}">
        <div class="row">
            <div [ngClass]="{'col-md-6': localSolverEnabled}">
                <div class="control_group" *ngIf="!pcmMode">
                    <label for="rbt-rating">{{userBundle['workspace.budget.bric.price.price']}}</label>
                    <input *ngIf="!budget.relative" type="text" class="form_control" [(ngModel)]="budget.price" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" #defaultPrice [disabled]="productValidations?.price && !productValidations?.minPrice && !productValidations?.maxPrice"
                        (ngModelChange)="calculateFloorAndCeiling()" />
                    <input *ngIf="budget.relative" type="text" class="form_control" [(ngModel)]="budget.price" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" #defaultPrice [disabled]="productValidations?.price && !productValidations?.minPrice && !productValidations?.maxPrice"
                        (ngModelChange)="calculateFloorAndCeiling()" />
                    <span *ngIf="budget.relative" class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
                <div class="control_group" *ngIf="pcmMode">
                    <label for="rbt-rating">{{userBundle['workspace.budget.bric.price.price-pcm']}}</label>
                    <input *ngIf="pcmMode" type="text" class="form_control" [(ngModel)]="budget.price" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" #defaultPrice />
                </div>
            </div>
            <div class="col-md-6" *ngIf="localSolverEnabled">
                <div class="control_group">
                    <div class="btn-group">
                        <input type="radio" name="radio_groups_pd" [(ngModel)]="budget.relative" [value]="false" autocomplete="off" id="check_units_pd1" (change)="onChangeRelative(budget.relative)">
                        <label for="check_units_pd1" class="">
                            {{userBundle['common.amount'] || 'Amount'}}
                        </label>
                        <input type="radio" name="radio_groups_pd" [(ngModel)]="budget.relative" [value]="true" autocomplete="off" id="check_units_pd2" (change)="onChangeRelative(budget.relative)">
                        <label for="check_units_pd2" class="">
                            {{userBundle['common.percentage'] || 'Percentage'}}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="pcmMode">
            <div class="control_group">
                <label>{{userBundle['workspace.common.minPrice']}}</label>
                <input type="text" class="form_control txt" [(ngModel)]="budget.minPrice" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" #minPrice />
            </div>
        </div>
        <div class="row" *ngIf="pcmMode">
            <div class="control_group">
                <label>{{userBundle['workspace.common.maxPrice']}}</label>
                <input type="text" class="form_control txt" [(ngModel)]="budget.maxPrice" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" #maxPrice />
            </div>
        </div>

        <div class="row" *ngIf="uiControl.volumeRangeValidationEnabled && !(objectiveMode && !uiControl.objectiveSotFloorCeilingEnabled)">
            <div class="col-md-6">
                <div class="control_group">
                    <label>{{userBundle['workspace.common.floor']}}
                    </label>
                    <input type="text" class="form_control txt" [(ngModel)]="budget.sotFloor" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" [disabled]="disableFloorAndCeiling" #sotFloor />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="control_group">
                    <label>{{userBundle['workspace.common.ceiling']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="budget.sotCeiling" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" [disabled]="disableFloorAndCeiling" #sotCeiling />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-7">
                <div class="control_group">
                    <label style="width:45%">{{userBundle['workspace.budget.bric.price.priceAllocated.approx']}}</label>
                    <input type="text" class="form_control txt disable-network" [disabled]="true" [(ngModel)]="budget.allocated" [imask]="currencyInputMask" [unmask]="maskService.unmask.typed" readonly style="width:50%" />
                </div>
            </div>
            <div class="col-md-5" *ngIf="uiControl.toleranceEnabled">
                <div class="control_group">
                    <label style="width:50%">{{userBundle['workspace.common.tolerance']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="budget.tolerance" [imask]="percentageToleranceInputMask" [unmask]="maskService.unmask.typed" style="width: 43%" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="localSolverEnabled">
            <div class="col-md-6">
                <div class="control_group">
                    <label style="width: 60%">{{userBundle['workspace.bric.greenTolerance']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="budget.greenTolerance" appNumberMask [min]="0" [max]="100" [scale]="2" style="width: 40%" (blur)="setDefaultGreenTolerance()" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="control_group">
                    <label style="width: 60%">{{userBundle['workspace.bric.orangeTolerance']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="budget.orangeTolerance" appNumberMask [min]="0" [max]="100" [scale]="2" style="width: 40%" (blur)="setDefaultOrangeTolerance()" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="col-md-6" *ngIf="!uiControl.hideObjectivePriority">
                <div class="control_group">
                    <label>{{userBundle['workspace.bric.priority']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="budget.priority" appNumberMask [min]="0" [max]="999" [scale]="0" />
                </div>
            </div>
            <div class="col-md-6" *ngIf="!uiControl.hideObjectiveWeight">
                <div class="control_group">
                    <label>{{userBundle['workspace.bric.weight']}}</label>
                    <input type="text" class="form_control txt" [(ngModel)]="budget.weight" appNumberMask [min]="0" [max]="100" [scale]="2" />
                    <span class="percentage-illumination" style="display: flex; left: -8%;">
                        <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                        {{!localSolverEnabled ? '%' : ''}}
                    </span>
                </div>
            </div>
            <div class="control_group">
                <label style="width: 50%">{{userBundle['workspace.bric.boostedTarget']}}
                </label>
                <label class="switchAllowFrames">
                    <input type="checkbox" [(ngModel)]="budget.boostedTarget">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>

        <div class="row" *ngIf="!(objectiveMode && !uiControl.objectiveAllocateAllFramesEnabled)">
            <div class="control_group">
                <label style="width: 50%">{{userBundle['workspace.common.allocateAllFrames'] ? userBundle['workspace.common.allocateAllFrames'] : 'Allow Frames' }}
                </label>
                <label class="switchAllowFrames">
                    <input
                        [disabled]="!user.workspaceTabAccess.allocateAllFrames || (productValidations && productValidations.allocateAllFrames)"
                        type="checkbox" [(ngModel)]="budget.allocateAllFrames" (click)="updateAllocation($event)">
                    <span class="sliderAllowFrames round"></span>
                </label>
            </div>
        </div>

        <div class="control_group row" *ngIf="pcmMode">
            <label>
                {{userBundle['workspace.pcm.increment']}}
            </label>
            <input type="text" class="form_control txt" [disabled]="resolveObject.sot.disableSOTIncrement" [(ngModel)]="budget.sotIncrement" [imask]="percentageInputMask" [unmask]="maskService.unmask.typed" />
            <span class="percentage-illumination" style="display: flex;">
                <em *ngIf="localSolverEnabled" class="fa fa-percent" aria-hidden="true"></em>
                {{!localSolverEnabled ? '%' : ''}}
            </span>
        </div>
    </div>

    <div footer class="right pcm-validation-info" *ngIf="productValidations" appMasterTooltip [tooltipName]="'mastertooltip'" [alwaysDisplay]="false">
        <img height="20px" width="20px" src="assets/img/info-image.png" alt="info image"/>
        <div class="mastertooltip modaltooltip">
            <div class="triangle-up"></div>
            <h5>{{userBundle['workspace.common.validValues']}}</h5>
            <br>
            <div *ngIf="productValidations.price">
                {{userBundle['workspace.budget.bric.price.price-pcm']}} : {{productValidations.price}}
            </div>
            <div *ngIf="productValidations.minPrice">
                {{userBundle['workspace.common.minPrice']}} : {{productValidations.minPrice}}
            </div>
            <div *ngIf="productValidations.maxPrice">
                {{userBundle['workspace.common.maxPrice']}} : {{productValidations.maxPrice}}
            </div>
            <div *ngIf="productValidations.sotFloor">
                {{userBundle['workspace.common.minFloor']}} : {{productValidations.sotFloor}}
            </div>
            <div *ngIf="productValidations.sotCeiling">
                {{userBundle['workspace.common.maxCeiling']}} : {{productValidations.sotCeiling}}
            </div>
            <div *ngIf="productValidations.sotIncrement">
                {{userBundle['workspace.pcm.increment']}} : {{productValidations.sotIncrement}}
            </div>
        </div>
    </div>
</app-sb-modal-wrapper>
