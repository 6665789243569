declare let ga;
declare let pendo;

export class ConfigUtils {
  static email;
  static market;

  static addJavaScriptLibraryDynamicaly(url: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', url);
      script.onload = () => {
        resolve('loaded');
      };
      script.onerror = () => {
        reject('Error in loading map API');
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  static loadGoogleAnalytics(key: string) {
    // tslint:disable-next-line: no-ignored-initial-value
    ((i: any, s, o, g, r, a, m) => {
      // tslint:disable-next-line: only-arrow-functions
      i.GoogleAnalyticsObject = r;
      // tslint:disable-next-line: only-arrow-functions
      i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments);
      };
      i[r].l = 1 * new Date().getTime();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

    ga('create', key, { cookieDomain: 'none' });
  }

  static loadPendo(emailID: string, marketID: string, subscriptionKey: string, language: string) {
    this.email = emailID;
    this.market = marketID;

    (function (apiKey) {
      (function (p, e, n, d, o) {
        let v;
        let w;
        let x;
        let y;
        let z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] = o[m] || function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
          })(v[w]);
        }

        y = e.createElement(n);
        y.async = !0;
        y.src = `https://cdn.eu.pendo.io/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      pendo.initialize({
        visitor: {
          id: emailID,
          email: emailID,
          full_name: emailID,
          lang: language
        },
        account: {
          id: marketID
        },
        disableGuides: false
      });

    })(subscriptionKey);
  }

  static updatePendoOptions(emailID: string, marketID: string, language: string) {
    if (pendo) {
      pendo.updateOptions({
        visitor: {
          id: emailID,
          email: emailID,
          full_name: emailID,
          lang: language
        },
        account: {
          id: marketID
        },
        disableGuides: false
      });
    }
  }

  static manuallyPushEventsToPendo(key: string, options: any) {
    if (pendo) {
      options['visitorId'] = this.email;
      options['accountId'] = this.market;
      pendo.track(key, options);
    }
  }
}
