import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class LogHelperService {

  /**
   * Constructor
   * @param toastr toastr object
   */
  constructor(private toastr: ToasterService) {
    this.toastr = toastr;
  }

  /**
   * Display error message in toastr.
   * @param message Error message to display in toastr.
   */
  logError(message: string): void {
    this.showMessage('error', 'Error!', message);
  }

  /**
   * Display success message in toastr.
   * @param message Success message to display in toastr.
   */
  logSuccess(message: string): void {
    this.showMessage('success', 'Success!', message);
  }

  /**
   * Display success message in toastr.
   * @param message Success message to display in toastr.
   */
  logInfo(message: string): void {
    this.showMessage('info', '', message);
  }

  /**
   *
   * @param type Possible values: info, success, error
   * @param title title for error message popup window
   * @param message Message to display on error message window
   */
  private showMessage(type, title, message): void {
    if (message && message !== '') {
      this.toastr.pop(type, title, message);
    }
  }
}
