import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ng2-dnd';
import { NguiMapModule } from '@ngui/map';
import { ResultMapComponent } from './result-map.component';
import { CoreModule } from '../core/core.module';
import { ResultService } from '../result/result.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { MapComponent } from '../result/map/map.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    NgbModule,
    DndModule.forRoot(),
    CoreModule.forRoot(),
    AgmCoreModule.forRoot(),
    AgmMarkerClustererModule,
    NguiMapModule.forRoot(
      { apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyAI0SnbzKtFVj8rNTufKNtkH23kCOtVJkU&libraries=drawing,places' }
    )
  ],
  entryComponents: [
    ResultMapComponent
  ],
  declarations: [
    ResultMapComponent,
    MapComponent    
  ],
  providers: [
    ResultService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ResultMapModule { }
