import { Component, OnInit, Input, forwardRef, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TagInputDropdown } from 'ngx-chips';
import { SharedService } from './../../services/shared.service';
import { BrickBaseService } from './../../services/brick-base.service';
import { DataShareService } from './../../services/data-share.service';
import { LookupViewService } from './lookup-view.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LookupModelAccessor } from './lookup-modal-accessor';
import * as _ from 'lodash';
import { AppHeaderService } from '../../../../../../root/app-header/app-header.service';
import { ObjectiveService } from '../../../workspace/objective/objective.service';
import { StateService } from '../../services';
import { InitialConfigModel } from '../../../models';
import { Brick } from '../../../workspace/brick-model';
import { GLOBAL } from '../../utils/app.constant';
import { ListFilterService } from '../../../bricks/list/list-filter.service';
declare let saveAs; // FileSaver.js

/**
 * Defines custom accessor
 */
const CUSTOM_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LookupViewComponent),
  multi: true
};

/**
 * A component for entering a list of terms to be used with ngModel.
 */
@Component({
  selector: 'app-lookup-view',
  templateUrl: './lookup-view.component.html',
  styleUrls: ['./lookup-view.component.css'],
  providers: [CUSTOM_ACCESSOR]
})
export class LookupViewComponent extends LookupModelAccessor implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild(TagInputDropdown) public dropdownTagComp: TagInputDropdown;
  /**
   * It will take lookType as input.
   */
  @Input() lookupType: any;
  /**
   * It will take selectionId as input.
   */
  @Input() selectionId: any;

  /**
   * It will take selectedAll flag as input.
   */
  @Input() selectAll: any;
  /**
   * It will take placeholder for dropdowns as input.
   */
  @Input() placeholder: any;
  /**
   * It will take regular expression as input.
   */
  @Input() regexp = '';
  /**
   * It will take displayType as input.
   */
  @Input() displayType: number;
  /**
   * It will take configLookup as input.
   */
  @Input() configLookup: any;
  /**
   * It will take bric id of look up used.
   * mainely used for environment bric
   */
  @Input() bricId: any;
  /**
   * It will take bric id of look up used.
   * mainely used for environment bric
   */
  @Input() allowUpload = false;

  /**
   * List of files uploaded by user
   */
  @Input() fileList = {};
  /**
   * It will take isAutoHeight as boolean.
   */
   @Input() isAutoHeight = false;

  /**
   * It contains label value for selectAllText.
   */
  public selectAllText: string;
  /**
   * Flag to define all checkboxes are checked or not.
   */
  public allChecked: boolean;
  /**
   * It contains list of data.
   */
  public dataList: any[] = [];
  /**
   * It contains label value for headingText.
   */
  public headingText: string;

  /**
   * It will shows code.
   */
  @Input() displaycode: boolean;

  @Input() isRelativeFilterVisible: boolean;
  /**
   * Input property when we want to show file uploader
   */
  @Input() showFileUploader = false;

  /**
   * Input property when called from reshuffle popup. as it is having object based lookup
   * SM-10356
   */
  @Input() isObjectLookup = false;

  /**
   * Input property when called from reshuffle popup. This will have the lookup data to be populated
   * SM-10356
   */
  @Input() reshuffleLookupData;

  /**
   * Internal property to show hide file uploader
   * When user selects values then file uploader will be hidden
   */
  hideFileUploader = true;

  /**
   * Internal property to show hide tag input
   * When user selects file , tag input control will be hidden
   */
  hideTagInput = false;

  /**
   * @description
   * @type {String}
   * @memberof LookupViewComponent
   */
  searchText = '';

  initialConfig: InitialConfigModel;

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('routeFrameId')
  routeFrameId: ElementRef;
  uiControl: any = {};

  /**
   * Constructor
   * @param sharedServiceService injected shared service object.
   * @param lookupViewService injected lookupView service object.
   */
  constructor(
    private sharedServiceService: SharedService,
    private lookupViewService: LookupViewService,
    private brickBaseService: BrickBaseService,
    private dataShareService: DataShareService,
    private appHeaderService: AppHeaderService,
    private objectiveService: ObjectiveService,
    private stateService: StateService,
    private changeDetector: ChangeDetectorRef,
    private listFilterService: ListFilterService
  ) {
    super();
  }

  /**
   * Initialization
   */
  ngOnInit() {
    this.uiControl = this.dataShareService.getInitialConfigByKey('uiControl');
    this.hideFileUploader = !this.showFileUploader;
    this.allChecked = false;
    this.initialConfig = this.dataShareService.getInitialConfig();
    this.isRelativeFilterVisible = !_.isUndefined(this.isRelativeFilterVisible) ? this.isRelativeFilterVisible :
      (GLOBAL.localSolverEnabled && !this.appHeaderService.enabledPCM);
    this.selectAllText = this.initialConfig.userBundle['common.selectAll'] || 'SelectAll';
    let listData;
    if (!this.isObjectLookup) {
      listData = this.sharedServiceService.getLookupData(this.selectionId, this.configLookup);
      if (listData && listData.length > 0) {
        this.dataList = _.clone(listData[0].data);
      }
    }
    else {
      listData = this.reshuffleLookupData;
      this.dataList = _.cloneDeep(listData);
    }

    
    this.setAuthorizedChannels();
    if (parseInt(this.lookupType, 10) === 1 || parseInt(this.lookupType, 10) === 4) {
      for (const dl of this.dataList) {
        if (_.isUndefined(dl.disabled)) {
          dl.disabled = false;
        }
      }
    }
    if (this.displaycode) {
      for (const dl of this.dataList) {
        dl.displayCode = this.displaycode;
      }
    }

    if (this.appHeaderService.objectiveMode) {
      const brick: Brick = this.stateService.getObjectiveObject('currentObjectiveData').brick;
      const filterObj = this.stateService.getWorkspaceFilterObj();
      const bricRowIndex = filterObj.getExistingRowIndex(this.bricId);
      if (bricRowIndex >= 0) {
        const objectiveDataCellIndex = filterObj.rows[bricRowIndex].cells[brick.cellIndex] ? brick.cellIndex : brick.cellIndex - 1;
        const overlappingCellIndex = filterObj.rows[bricRowIndex].getOverlappingCell(objectiveDataCellIndex);
        const mainSelected = filterObj.rows[bricRowIndex].cells[overlappingCellIndex === -1 ? objectiveDataCellIndex : overlappingCellIndex].selected;
        this.dataList = !_.isEmpty(mainSelected) ? this.objectiveService.filterListBySelectedValues(this.dataList, this.bricId, mainSelected[this.selectionId]) : this.dataList;
      }
    }

    if (!this.fileList[this.selectionId]) {
      this.fileList[this.selectionId] = [];
    }
    if (this.fileList[this.selectionId].length > 0) {
      this.hideTagInput = true;
    }
  }

  ngAfterViewChecked() {
    // sm-8039
    this.changeDetector.detectChanges();
  }

  setAuthorizedChannels() {
    // SD : SM-4726 : Only authrorized Channels will be displayed to the user //
    if (this.bricId === this.brickBaseService.brickID.Environment) {
      const userModel = this.initialConfig.userData;
      if (userModel && userModel.authorizedChannel && userModel.authorizedChannel.length > 0) {
        this.dataList.forEach((element) => {
          if (userModel.authorizedChannel.includes(element.id)) {
            element.disabled = false;
          } else {
            element.disabled = true;
          }
        });
      } else {
        this.dataList.forEach((element) => {
          element.disabled = false;
        });
      }
    }
  }

  /**
   * initialization after render view.
   */
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.dataModel && this.dataModel.length > 0) {
        if (this.uiControl.locationInAlphabeticalOrder) {
          this.dataModel = _.sortBy(this.dataModel, 'name');
        }
        this.checkSelectAll(this.dataModel, null);
      }

      this.dataList.forEach((dl: any) => {
        this.dataModel.forEach((dm: any) => {
          if (dm.id === dl.id) {
            this.dataList[this.dataList.indexOf(dl)] = dm;
          }
        });
      });

      this.onAdd();
    }, 100);
  }

  /**
   * To toggle all checkbox value.
   * @param model boolean value of all checkbox.
   */
  toggleAllCheckboxes(model: boolean): void {
    if (model === true) {
      this.dataModel = [];
      this.dataModel = _.clone(this.dataList);
    } else {
      this.dataModel = [];
    }
  }

  /**
   * To check that cur lookup item is selected or not
   * @param curItem lookup item
   * @returns boolean
   */
  isSelected(curItem: any) {
    if (this.dataModel && this.dataModel.length) {
      return this.dataModel.findIndex((model: any) => model.id === curItem.id) !== -1;
    }
    return false;
  }

  /**
   * Check the all checkbox is selected or not
   * @param event array of evnts
   */
  checkSelectAll(event: any[], curItem: any): void {
    if (curItem) {
      curItem.relative = false;
    }

    if (this.bricId === this.brickBaseService.brickID.Environment) {
      const userModel = this.initialConfig.userData;
      const tempLinkedChannels = this.initialConfig.linkedChannels;
      let linkedChannels = [];
      if (tempLinkedChannels && tempLinkedChannels.length) {
        linkedChannels = tempLinkedChannels.find(channels => _.includes(channels, event[0] ? event[0].id : -1));
      }
      for (const obj of this.dataList) {
        if ((userModel &&
          userModel.authorizedChannel &&
          userModel.authorizedChannel.length > 0 &&
          userModel.authorizedChannel.includes(obj.id)) ||
          (userModel.authorizedChannel && userModel.authorizedChannel.length === 0)) {
          if (event[0] && obj.id !== event[0].id && (linkedChannels && linkedChannels.length && !_.includes(linkedChannels, obj.id))) {
            obj.disabled = true;
          } else {
            obj.disabled = false;
          }
        }
      }
    }
    if (this.dataList.length === event.length) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  changeRelativeFilter = (item) => {
    this.dataModel.forEach((element: any) => {
      if (item.disabled) {
        element.relative = false;
      } else if ((item.hasOwnProperty('lookupId') && item.lookupId === element.lookupId)
        || (item.hasOwnProperty('id') && item.id === element.id)) {
        element.relative = item.relative;
      }
    });
  }

  /**
   * Remove the  item by id.
   * @param index
   */
  removeItemById(index: number): void {
    if (index !== null) {
      this.dataModel.splice(index, 1);
      // tslint:disable-next-line: no-self-assignment
      this.dataModel = this.dataModel; // added this because setter method in geoplanner not working
      this.allChecked = false;
      this.onRemove();
    }
  }

  /**
   * added this because setter method in geoplanner not working
   */
  dataChange() {
    // tslint:disable-next-line: no-self-assignment
    this.dataModel = this.dataModel;
  }

  disableRelative(item: any): boolean {
    return this.dataModel.filter(d => d['id'] === item.id).length === 0;
  }

  /**
   * filter the data list by query.
   * @param query which will be used for filter dataList
   * @return filtered dataList
   */
  filterListByQuery = (query: string) => {
    const returnedData = this.dataList.filter((ele) => {
      return ele.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    const sortedData = _.sortBy(returnedData, 'name');
    if (this.uiControl.locationInAlphabeticalOrder) {
      this.dataModel = _.sortBy(this.dataModel, 'name');
    }
    return of(sortedData);
  }

  /**
   * Load tags data for Route ID using service call
   * @param query queryParameter for http call
   * @return responsed route data
   */
  loadRouteData = (query: string) => {
    const reg = new RegExp(this.regexp);
    if (query.match(reg)) {
      if (this.searchText.toLowerCase() === query.toLowerCase() && this.dataList) {
        return Observable.of(this.dataList);
      } else {
        this.searchText = query;
      }
      return this.lookupViewService.getRouteData(query)
        .map((data: any) => {
          let returnedData = [];
          if (data && data.routeFrame && data.routeFrame.length) {
            if (!data.routeFrame[0].frameAltCode && !data.routeFrame[0].address) {
              returnedData = data.routeFrame.map((obj) => {
                return {
                  routeFrameId: (obj.routeFrameId),
                  exclude: false,
                  displayText: `${obj.routeFrameId}`
                };
              });
            } else if (!data.routeFrame[0].frameAltCode) {
              returnedData = data.routeFrame.map((obj) => {
                return {
                  routeFrameId: (obj.routeFrameId),
                  exclude: false,
                  displayText: `${obj.routeFrameId} ${obj.address}`
                };
              });
            } else if (!data.routeFrame[0].address) {
              returnedData = data.routeFrame.map((obj) => {
                return {
                  routeFrameId: (obj.routeFrameId),
                  exclude: false,
                  displayText: `${obj.routeFrameId} <em><strong>${obj.frameAltCode}</strong></em>`
                };
              });
            } else {
              returnedData = data.routeFrame.map((obj) => {
                return {
                  routeFrameId: (obj.routeFrameId),
                  exclude: false,
                  displayText: `${obj.routeFrameId}  <em><strong>${obj.frameAltCode}</strong></em>  ${obj.address}`
                };
              });
            }
          }
          if (returnedData.length > 0) {
            setTimeout(() => {
              this.dropdownTagComp.dropdown.menu.dropdownState.dropdownState.select(this.dropdownTagComp.dropdown.menu.items[0], false);
            }, 10);
          }
          this.dataList = returnedData;
          return returnedData;
        });
    } else {
      return of([]);
    }
  }

  /**
   * Load the tag data using service call.
   * @param query queryParameter for http call
   * @return responsed tag data
   */
  loadTagData = (query: string) => {
    const reg = new RegExp(this.regexp);
    if (query.match(reg)) {
      if (this.searchText.toLowerCase() === query.toLowerCase() && this.dataList) {
        return Observable.of(this.dataList);
      } else {
        this.searchText = query;
      }
      const lookupRequest = {
        lookupSelectionId: this.selectionId,
        lookupQuery: query,
        otherLookupIds: []
      };
      return this.lookupViewService.getTagData(lookupRequest)
        .map((data: any) => {
          let returnedData = [];
          if (data) {
            returnedData = data.filter((ele) => {
              return ele.lookupName.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            });
          }
          this.dataList = returnedData;
          return returnedData;
        });
    } else {
      return of([]);
    }
  }

  /**
   * Route matching function
   * @param query to match with reg expression.
   * @param target
   * @return boolean value: true if match otherwise false.
   */
  routeMatchingFn = (query: string) => {
    const reg = new RegExp(this.regexp);
    if (query.match(reg)) {
      return true;
    } else {
      return false;
    }
  }

  trackByIndex(index, item) {
    return index;
  }

  /**
   * @description File save locally
   * @author Alkesh Shah
   * @param {*} data
   * @returns {boolean}
   * @memberof ListBase
   */
  saveFilelocally(data): boolean {
    if (this.fileList[this.selectionId].length < 2) {
      const file = data.file;
      for (const f of file) {
        if (file.length <= 2) {
          if (this.fileList[this.selectionId].length > 0) {
            this.fileList[this.selectionId].forEach((obj) => {
              if (obj.name !== f.name && this.fileList[this.selectionId].indexOf(f) === -1) {
                if (this.fileList[this.selectionId].length < 2) {
                  this.fileList[this.selectionId].push(f);
                } else {
                  return false;
                }
              }
            });
          } else {
            this.fileList[this.selectionId].push(f);
          }
        } else {
          break;
        }
      }
      for (const file of this.fileList[this.selectionId]) {
        if (!file.hasOwnProperty('exclude')) {
          file['exclude'] = false;
        }
      }
    }

    if (this.fileList[this.selectionId].length > 0) {
      this.hideTagInput = true;
    } else {
      this.hideTagInput = false;
    }
    return true;
  }

  /**
   * @description include, exclude file status change
   * @author Alkesh Shah
   * @param {*} event
   * @param {number} i
   * @memberof ListBase
   */
  fileStatusChanged(event, i: number) {
    this.fileList[this.selectionId][i].exclude = event;
  }

  /**
  * @description Remove uploaded file
  * @author Alkesh Shah
  * @param {number} index - index of file to remove
  * @memberof ListBase
  */
  removeFile(index: number) {
    if (index != null) {
      this.fileList[this.selectionId] = _.clone(this.fileList[this.selectionId]);
      this.fileList[this.selectionId].splice(index, 1);
    }

    if (this.fileList[this.selectionId].length > 0) {
      this.hideTagInput = true;
    } else {
      this.hideTagInput = false;
    }
  }

  /**
   * @description Download selected file
   * @author Alkesh Shah, Amit Mahida
   * @param {*} userSelectionId - file user selection id to send to backend
   * @memberof ListModalComponent
   */
  downloadListFile(userSelectionId, fileName) {
    // VJ: dt, 28/07/15, Reported by Brandon in flowdock, need to send singular value instead of an array
    // As discussed with Nishit, userSelectionId will be only 1 value for now
    const userSelectionIdParam = (userSelectionId && userSelectionId.length > 0) ? userSelectionId[0] : userSelectionId;
    this.listFilterService.downloadFile(userSelectionIdParam).then((file) => {
      saveAs(file.body, fileName);
    });
  }

  onAdd() {
    if (this.showFileUploader && this.dataModel.length) {
      this.hideFileUploader = true;
    }
  }

  onRemove() {
    if (this.showFileUploader) {
      if (this.dataModel.length) {
        this.hideFileUploader = true;
      } else {
        this.hideFileUploader = false;
      }
    }
  }
}
