<div [ngClass]="{remove: showRemoveBar}" [dndDropzone]="removeDropZone" (dndDrop)="onRemoveBrick($event)"
  dndDragoverClass="highlight-div-panning" id="removeBar">
  <div class="trash" [style.display]="showRemoveBar ? 'block' : 'none'">
    <span>{{userBundle['workspace.label.remove']}}</span>
  </div>
</div>

<div class="switchBRICdragging{{alternateDesign}}" style="display:none"></div>

<div class="sidebar bounceInLeft animated" [ngClass]="{'readOnly' : readOnlyModal}">
  <dl class="accordion">
    <ng-container *ngFor="let data of masterData; trackBy: trackByCategoryId; index as i">
      <dt class="cat_name">
        <a href="javascript:void(0)" class="title" [ngClass]="{'down': data.isAccordionDown, '': !data.isAccordionDown}"
          (click)="data.isAccordionDown = !data.isAccordionDown">
          <span class="icons animated" [style.background-color]="data.brics[0]?.color"></span>
          <span class="text" [style.color]="data.brics[0]?.color">{{data.name}}</span>
        </a>
      </dt>
      <dd [ngClass]="{'down': data.isAccordionDown, '': !data.isAccordionDown}">
        <div class="brick_container" *ngFor="let bric of data.brics; trackBy: trackByBricId; index as brickIndex">
          <div class="brick peg" id="abc{{bric.bricid}}" [style.backgroundColor]="bric.color" [dndDraggable]="bric"
            [dndType]="'master'" [dndEffectAllowed]="draggableMaster.effectAllowed"
            [dndDragImageOffsetFunction]="calDragImageOffset" [dndDisableIf]="draggableMaster.disable || readOnlyModal"
            (dndStart)="onMasterBricDragStart(bric)" (dndEnd)="onDragEnd()"
            dndDraggingClass="switchBRICdragging{{alternateDesign}}" [ngClass]="{'brick_disabled' : bric.disabled}">
            <img src="{{'assets/img/brick'+alternateDesign+'.png'}}" alt="Brick" />
            <div dndDragImageRef [style.backgroundColor]="bric.color"></div>
            <div class="panel-line ng-scope" *ngIf="alternateDesign==1"></div>
          </div>
          <div class="bricktext">{{bric.bricname}}</div>
        </div>
      </dd>
    </ng-container>
  </dl>
</div>


<div class="workspace bounceInRight animated">
  <!-- <app-campaign-summary [isValidCampaign]="filter.isMandatoryBricksAvailable()"></app-campaign-summary> -->

  <div class="workspace_block pcm-container">
    <div class="workspace_block_2 animated zoomIn" id="workspace">
      <!-- <app-allocation-adjustment [campaignParameters]="filter.campaignParameters"
        (campaignParametersChange)="campaignParametersChange($event)"></app-allocation-adjustment> -->
      <div id="divPanning" dndDropzone [ngClass]="{'highlight-div-panning': highlightDivPanning}"
        (dndDrop)="onDrop($event)" (dragover)="highlightWorkspace()" (dragleave)="highlightDivPanning=false;">
        <div class="panhandler-wrap" appPanZoom [zoomIn]="zoomIn" [zoomOut]="zoomOut" [zoomReset]="zoomReset"
          [refContainer]="panZoomContainer">
          <!-- <div *ngIf="waterMark" class="watermark">
            {{waterMark?.campaignReference}}
            <br> {{waterMark?.advertiser}}
            <br> {{waterMark?.agency}}
          </div> -->
          <!-- <div class="watermark_middle" [hidden]="waterMark.BKNumber || waterMark.advertiser || filter.rows.length > 0">
            {{userBundle['worksapce.defaultBackgroundText']}}
          </div> -->
          <div id="divZoom" #panZoomContainer>
            <ng-container *ngFor="let row of filter.rows; trackBy: trackByRowIndex; index as rowIndex">
              <div class="rows dropRow" [style.width]="(CELLWIDTH * row.cells.length) + rowWidthDelta + 'px'"
                [dndDraggable]="row" [dndEffectAllowed]="draggableRow.effectAllowed" dndDropzone [dndType]="'row'"
                (dndStart)="onBricDragStart(row.bric,null)" (dndEnd)="onDndEnd()"
                (dragover)="row.highlight(draggedBricHighlight)" (dragleave)="row.removeHighlight()"
                [ngClass]="{denmarkBRIC: alternateDesign == 2, 'disableCursor': row.disableCursorHighlight, 'lvl-over': row.highlightRow }">

                <div class="slot flat_brick switchBRIC_image{{alternateDesign}}" #cellElement
                  *ngFor="let cell of row.cells; trackBy: trackByCellIndex; index as colIndex" [hidden]="cell.isHidden" appResizable
                  (resizing)="onCellExpanding()" (resizeStop)="onCellExpanded($event,cell)"
                  [minResizeWidth]="CELLWIDTH" [maxResizeWidth]="(row.cells.length - colIndex)"
                  [actualCellWidth]="cell.cellWidth" (resizeStart)="onCellExpandBegin()"
                  [ngStyle]="{'width': (cell.cellWidth * CELLWIDTH) + 'px'}"  [style.backgroundColor]="row.bric.color"
                  [style.width]="(cell.width * CELLWIDTH) + 'px'"
                  [disableResize]="row.bric.bricid === brickBaseService.brickID.Audience
                  || row.bric.bricid === brickBaseService.brickID.SecondaryAudience
                  || row.bric.bricid === brickBaseService.brickID.PrimaryAudience"
                  [style.color]="row.bric.bricid === brickBaseService.brickID.Proximity ? '#545454' :'#ffffff'"
                  dndDraggingClass="switchBRICdragging{{alternateDesign}}" [ngClass]="{'unUsed':!cell.isHidden && cell.isEmpty, 'denmarkBRIC': alternateDesign == 2,
                  'readOnly': readOnlyModal, 'explodeicon':allowExplode,'spliticon':allowSplit,
                  'lvl-over': cell.highlightCell,
                  'lr-drop-target-before-brick': cell.highlightLeft,
                  'lr-drop-target-after-brick': cell.highlightRight,
                  'brick_error': !cell.isValid,
                  'half_opacity':cell.selected.disableObjective }"
                  (dragover)="cell.highlight($event, draggedBricHighlight, cellElement, row.bric.bricid); setExpandDirection(cell)"
                  (dragleave)="cell.removeHighlight()" dndDropzone [dndType]="'cell'"
                  (dblclick)="onBrickDblClick(row,cell)" [dndDraggable]="cell"
                  [dndDisableDragIf]="cell.isEmpty || isResizing || readOnlyModal" [dndEffectAllowed]="'move'"
                  [dndDragImageOffsetFunction]="calDragImageOffset" (dndDrop)="onDrop($event, rowIndex, colIndex)"
                  (click)="onBrickClick(cell)" (dndStart)="onBricDragStart(row.bric, cell.cellIndex)"
                  [attr.data-col-index]="colIndex" [attr.data-row-index]="rowIndex" (dndEnd)="onDndEnd()"
                  id="abc{{row.bric.bricid}}">

                  <div dndDragImageRef [style.backgroundColor]="row.bric.color"></div>
                  <div class="panel-line ng-scope" *ngIf="alternateDesign==1"></div>
                  <div *ngIf="row.bric.bricid == 28 && !cell.isHidden && !cell.isEmpty" >
                    <input type="checkbox" class="disableObjective" name="chkEditable" id="chkEditable" title="{{userBundle['common.info.notEditable']}}"
                    [(ngModel)]="cell.selected.disableObjective">
                  </div>
                  <div [innerHTML]='cell.displayText' appMasterTooltip [tooltipName]="'customtooltip'"
                    [tooltipScope]="1"></div>
                  <span [ngClass]="{'unUsed':cell.isEmpty,'used':!cell.isEmpty, 'selectedbrick': cell.markSelected }"
                    class="resize">
                    <em class="halficonbtn appResizableHandle">&nbsp;</em>
                  </span>
                  <div *ngIf="cell.toolTipText && cell.displayText !== userBundle['workspace.common.allSelection']" class="customtooltip">
                    <div [innerHTML]='cell.toolTipText'></div>
                  </div>
                </div>
              </div>

            </ng-container>

          </div>

        </div>

      </div>
    </div>
    <div style="clear: both;"></div>
  </div>
  <div class="zoom">
    <div class="left" [ngClass]="{'readOnly' : readOnlyModal}">
      <a href="javascript:void(0)" title="{{userBundle['workspace.button.tooltip.goToWorksapce']}}" class="zoombutton closebutton animated bounceIn"
        style="opacity: 1" (click)="backToWorkspace()"></a>
      <a id="zoomIn" #zoomIn href="javascript:void(0)" #zoomIn title="{{userBundle['workspace.button.tooltip.zoomIn']}}"
        class="zoombutton in animated bounceIn"></a>
      <a id="zoomReset" #zoomReset href="javascript:void(0)" #resetZoom
        title="{{userBundle['workspace.button.tooltip.defaultZoom']}}" class="zoombutton default animated bounceIn"></a>
      <a id="zoomOut" #zoomOut href="javascript:void(0)" #zoomOut
        title="{{userBundle['workspace.button.tooltip.zoomOut']}}" class="zoombutton out animated bounceIn"></a>
      <button type="button" class="zoombutton multidelete animated bounceIn" [ngClass]="{'active': allowCustomDelete}"
        title="{{userBundle['workspace.button.tooltip.multipleDelete']}}"
        (click)="toggleButtons('multiDelete')"></button>
      <a id="btnUndo" href="javascript:void(0)" title="{{userBundle['workspace.button.tooltip.undo']}}"
        class="zoombutton undobutton animated bounceIn" (click)="undoLastStep()"></a>
      <a id="btnRedo" href="javascript:void(0)" title="{{userBundle['workspace.button.tooltip.redo']}}"
        class="zoombutton redobutton animated bounceIn" (click)="redoLastUndoneStep()"></a>
      <button type="button" class="zoombutton explodebutton animated bounceIn" [ngClass]="{'active': allowExplode}"
        id="btnExplode" (click)="toggleButtons('explode')"
        title="{{userBundle['workspace.button.tooltip.explode']}}"></button>
      <a id="split" *ngIf="splitable" href="javascript:void(0)" [ngClass]="{'active': allowSplit}"
        title="{{userBundle['workspace.button.tooltip.split']}}" class="zoombutton split animated bounceIn"
        (click)="toggleButtons('split')"></a>
      <a id="btnClear" href="javascript:void(0)" [title]="userBundle['workspace.button.tooltip.wiper']"
        class="zoombutton clearbutton animated bounceIn" (click)="clearWorkspace()"></a>
      <button type="button" class="zoombutton downloadButton animated bounceIn" (click)="exportAllProducts()"
        [title]="userBundle['workspace.pcm.export.all'] || 'Export All Products'">
      </button>
    </div>
  </div>
  <div class="redbutton">
    <div class="right">
      <a href="javascript:void(0)" (click)="addNewProduct()" class="savebutton pcm-save animated bounceIn"
        title="{{userBundle['workspace.pcm.saveProduct'] || 'Save Product'}}"></a>
      <a href="javascript:void(0)" *ngIf="filter.productEditMode  && filter['productCatalogue']?.inUse"
        (click)="disableEnableProduct()" class="savebutton pcm-disable animated bounceIn" title="{{userBundle['workspace.pcm.disableProduct'] || 'Disable Product'}}"></a>
      <a href="javascript:void(0)" *ngIf="filter.productEditMode && !filter['productCatalogue']?.inUse"
        (click)="disableEnableProduct()" class="savebutton pcm-enable animated bounceIn" title="{{userBundle['workspace.pcm.enableProduct'] || 'Enable Product'}}"></a>
    </div>
  </div>

</div>
